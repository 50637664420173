import React, {useMemo} from 'react';
import {IIconProps} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {NAMESPACE_OF_BASELINE_CHANGES} from './BaselineComparisonPage.utils';
import {useCommand} from '../../../hooks';
import {v4 as uuidv4} from 'uuid';
import {
	DownloadBtn,
	exportBaselineRequirementsAndDownloadFile,
} from '../BaselineListPage/DownloadBtn';

interface Props {
	baselineId: string;
	selectedRequirements: any[];
	children?: React.ReactNode; // Add this line
}

export function DownloadButton({
	selectedRequirements,
	baselineId,
}: Props): JSX.Element {
	const {t} = useTranslation(NAMESPACE_OF_BASELINE_CHANGES);

	const iconProps = useMemo((): IIconProps => {
		return {iconName: 'Download'};
	}, []);

	useCommand(
		{
			key: uuidv4(),
			text: t('download'),
			disabled: selectedRequirements.length === 0,

			commandBarButtonAs(): JSX.Element {
				return (
					<DownloadBtn
						text={t('Download')}
						baselineId={baselineId}
						onlyRequirementsIds={selectedRequirements.map(
							requirement => requirement.id,
						)}
					/>
				);
			},
		},
		[selectedRequirements],
	);

	return <></>;
}
