import {
	DefaultButton,
	Dialog,
	ITextFieldProps,
	PrimaryButton,
	Stack,
} from '@fluentui/react';
import {
	useCreateAnswerMutation,
	QuestionsDocument,
	QuestionByIdDocument,
} from './Faq.generated';
import {TextFieldWithTooltip} from 'features/localizedTooltips';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AttachmentsField} from 'components/AttachmentsField';
import {useFileUpload} from 'hooks';

export const CreateReply: React.FC<any> = ({
	showDialog,
	setShowDialog,
	selectedQuestion,
}) => {
	const {t} = useTranslation('common/faq');
	const [value, setValue] = useState('');
	const {attachments, FileUploadComponent} = useFileUpload(undefined, {
		renderCategory: false,
	});

	const [createAnswer] = useCreateAnswerMutation();

	type OnChange = Exclude<ITextFieldProps['onChange'], undefined>;

	const handleChange: OnChange = (_event, newValue): void => {
		setValue(newValue ?? '');
	};
	const closeDialog = () => {
		setShowDialog(false);
		setValue('');
	};

	const postReply = () => {
		createAnswer({
			variables: {
				input: {
					text: value,
					attachments: attachments?.map(f => ({
						attachmentId: f.file.name,
						file: f.file,
					})),
				},
				questionId: selectedQuestion?.id,
			},
			refetchQueries: [QuestionsDocument, QuestionByIdDocument],
		});
		closeDialog();
	};
	return (
		<Dialog
			hidden={!showDialog}
			modalProps={{
				isBlocking: true,
				isDarkOverlay: true,
				styles: {main: {maxWidth: 650, minWidth: '400px !important'}},
			}}
			dialogContentProps={{
				title: t('PostReply'),
			}}
			onDismiss={closeDialog}
		>
			<TextFieldWithTooltip
				multiline
				label={t('PostReply')}
				placeholder={t('PostReply')}
				onChange={handleChange}
				value={value}
				styles={{
					root: {margin: '1rem 0 0.5rem'},
				}}
			/>
			<AttachmentsField t={t} FileUploadComponent={FileUploadComponent} />
			<Stack
				horizontal
				horizontalAlign='end'
				tokens={{childrenGap: 5, padding: '20px 0 0 0'}}
			>
				<Stack.Item>
					<PrimaryButton onClick={postReply} ariaLabel={'ConfirmDialogButton'}>
						{t('Confirm')}
					</PrimaryButton>
				</Stack.Item>
				<Stack.Item>
					<DefaultButton onClick={closeDialog} ariaLabel={'CancelDialogButton'}>
						{t('Cancel')}
					</DefaultButton>
				</Stack.Item>
			</Stack>
		</Dialog>
	);
};
