import {GetMyRequirementsReviewQuery} from 'features/Dashboard/hooks/myRequirementsReview.generated';
import {RequirementChangeType, RequirementStatus} from 'types';

const MockupRequirement = {
	id: 'UmVxdWlyZW1lbnQKZDY3OTI1ZTI1YmMzMWU4NTdmYmVhNzIzZg==',
	requirementId: 'R-10001',
	name: 'Blue Req',
	changeType: RequirementChangeType.Editorial,
	status: RequirementStatus.Approval,
	createdAt: '2025-01-23T15:20:05.872Z',
	modifiedAt: '2025-01-23T15:20:05.872Z',
	vexClusters: [
		{
			id: 'VmV4Q2x1c3RlcgpkNjcwZTQwNjNiZDFkY2VjZWMzZTdjMzIz',
			name: 'Pablo VEX-Cluster',
			__typename: 'VexCluster' as const,
		},
	],
	approvalWorkFlow: {
		sentToApprovalTimestamp: '2025-01-27T16:59:48.592Z',
		clearingTimestamp: null,
		__typename: 'ApprovalWorkFlow' as const,
	},
	__typename: 'Requirement' as const,
};

export const MyRequirementsReviewMockup: GetMyRequirementsReviewQuery = {
	myRequirementsReview: [
		{
			...MockupRequirement,
			requirementId: 'R-10001',
			name: 'Blue Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10002',
			name: 'Red Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10003',
			name: 'Green Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10001',
			name: 'Blue Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10002',
			name: 'Red Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10003',
			name: 'Green Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10001',
			name: 'Blue Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10002',
			name: 'Red Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10003',
			name: 'Green Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10001',
			name: 'Blue Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10002',
			name: 'Red Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10003',
			name: 'Green Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10001',
			name: 'Blue Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10002',
			name: 'Red Req',
		},
		{
			...MockupRequirement,
			requirementId: 'R-10003',
			name: 'Last Green Req',
		},
	],
};
