import React, {useState, useMemo, useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {PogisDocumentsTooltipTranslationProvider} from './components/PogisDocumentTranslationTooltipProvider';
import {
	AttachmentRef,
	InputMaybe,
	PogisDate,
	PogisDateInput,
	PogisDocument,
	Scalars,
} from 'types';
import {useSearchParams} from 'react-router-dom';
import {createSearchParams, useNavigate} from 'react-router-dom';
import {
	renderArrayField,
	renderAttachmentsPogis,
	renderDateString,
	renderLinkField,
	renderPogisDate,
} from 'components/EntityList/ColumnRenderers';
import {
	InfinityList,
	InfinityListColumn,
	InfinityListColumnFilterOptions,
	InfinityListFilter,
	InfinityListItemWithId,
	InfinityListURLParams,
	InfinityListView,
} from 'components/InfinityList/InfinityList';
import {DEFAULT_DATE} from 'components/EntityList/EntityUtils';
import {
	useGetPogisDocumentsNextLazyQuery,
	useGetPogisDocumentsQueryLazyQuery,
	useGetPogisSortOrdersQuery,
} from './hooks/PogisDocuments.generated';
import {decodeHTML} from 'entities';
import {v4 as uuidv4} from 'uuid';
import {getValue} from '@testing-library/user-event/dist/utils';
import {Pivot, PivotItem, SelectionMode, Stack} from '@fluentui/react';

interface DataStorePogisDocument {
	[key: string]: PogisDocumentModified;
}

interface HashMapIds {
	[key: string]: string;
}

export type PogisDocumentModified = {
	id: Scalars['String'];
	pogisId: Scalars['String'];
	keywords: Array<Scalars['String']>;
	markets: Array<Scalars['String']>;
	regulationStatus: Scalars['String'];
	shortInfo: Scalars['String'];
	effectiveDate?: InputMaybe<Scalars['DateTime']>;
	ntDate?: InputMaybe<Scalars['DateTime']>;
	nfDate?: InputMaybe<Scalars['DateTime']>;
	modelYear?: InputMaybe<Scalars['DateTime']>;
	otherDates?: Array<PogisDate>;
	standards?: InputMaybe<Array<Scalars['String']>>;
	associations?: InputMaybe<Array<Scalars['String']>>;
	relatedDocuments?: InputMaybe<Array<Scalars['String']>>;
	editor: Scalars['String'];
	docStatus: Scalars['String'];
	pogisAttachments?: InputMaybe<AttachmentRef[]>;
};

interface IMapPogisDocument {
	(pogisDocument: PogisDocument): PogisDocumentModified;
}

interface IFilters2Link {
	(allFilters: InfinityListFilter[]): InfinityListURLParams;
}

interface IFilters2MongoQuery {
	(allFilters: InfinityListFilter[]): string;
}
interface ILink2MongoQuery {
	(searchParams: URLSearchParams): string;
}

interface ILink2Filter {
	(searchParams: URLSearchParams): InfinityListFilter[];
}

const linkFields = [
	'id',
	'keywords',
	'markets',
	'standards',
	'regulationStatus',
	'editor',
];

const arrayFields = [
	'pogisId',
	'keywords',
	'markets',
	'standards',
	'regulationStatus',
	'editor',
];

const dateFilterFields = ['effectiveDate', 'nfDate', 'ntDate', 'modelYear'];

const dateFilterFeildsMongo = [
	'Effective Date',
	'New Registration',
	'New Types',
	'Model Year',
];

const arrSortInverted = [
	'pogisId',
	'effectiveDate',
	'ntDate',
	'nfDate',
	'modelYear',
];

const initialSortField = 'pogisId';
const initialSortOrder = 'DESC';
const intBatchSize = 50;

const funcMapPogisDocument: IMapPogisDocument = pogisDocument => {
	const pogisdates = pogisDocument.dates;
	const effDates = pogisdates.filter(x => x.dateType === 'Effective Date');
	const nfDates = pogisdates.filter(x => x.dateType === 'New Registration');
	const ntDates = pogisdates.filter(x => x.dateType === 'New Types');
	const myDates = pogisdates.filter(x => x.dateType === 'Model Year');
	const restDates = pogisdates.filter(
		x => !dateFilterFeildsMongo.includes(x.dateType),
	);
	const effDate = effDates.length > 0 ? effDates[0].date : DEFAULT_DATE;
	const nfDate = nfDates.length > 0 ? nfDates[0].date : DEFAULT_DATE;
	const ntDate = ntDates.length > 0 ? ntDates[0].date : DEFAULT_DATE;
	const myDate = myDates.length > 0 ? myDates[0].date : DEFAULT_DATE;

	const pogisDocMapped: PogisDocumentModified = {
		id: atob(pogisDocument.id).split('\n')[1].slice(1),
		pogisId: pogisDocument.pogisId,
		keywords: pogisDocument.keywords,
		markets: pogisDocument.markets,
		regulationStatus: pogisDocument.regulationStatus,
		shortInfo: pogisDocument.shortInfo,
		effectiveDate: effDate,
		ntDate,
		nfDate,
		modelYear: myDate,
		otherDates: restDates,
		standards: pogisDocument.standards,
		associations: pogisDocument.associations,
		relatedDocuments: pogisDocument.relatedDocuments,
		editor: pogisDocument.editor,
		docStatus: pogisDocument.docStatus,
		pogisAttachments: pogisDocument.pogisAttachments ?? [],
	};
	return pogisDocMapped;
};

const funcFilters2Link: IFilters2Link = allFilters => {
	const dataURL: InfinityListURLParams = {};

	allFilters
		.filter(x => arrayFields.includes(x.columnKey))
		.forEach(x => {
			const linkField = linkFields[arrayFields.indexOf(x.columnKey)];

			if (dataURL[linkField] === undefined) {
				dataURL[linkField] = [x.selection];
			} else {
				dataURL[linkField].push(x.selection);
			}
		});

	allFilters
		.filter(x => dateFilterFields.includes(x.columnKey))
		.forEach(x => {
			const linkField = x.columnKey;
			const value = `${x.operation}_${x.selection.slice(0, 10)}`;
			if (dataURL[linkField] === undefined) {
				dataURL[linkField] = [value];
			} else {
				dataURL[linkField].push(value);
			}
		});

	return dataURL;
};

const funcFilters2MongoQuery: IFilters2MongoQuery = allFilters => {
	const arrQueryStrings: string[] = [];

	arrayFields
		.map(x1 => {
			const lstOptions = allFilters
				.filter(x2 => x2.columnKey === x1)
				.map(x2 => `'${x2.selection}'`);
			if (lstOptions.length === 0) {
				return '';
			}
			return `${x1}:{$in:[${lstOptions}]}`;
		})
		.filter(x1 => x1 !== '')
		.forEach(x1 => arrQueryStrings.push(x1));

	dateFilterFields
		.map((x1, i1) => {
			const dateFrom = allFilters.find(
				x2 => x2.columnKey === x1 && x2.operation === 'gte',
			)?.selection;
			const dateTo = allFilters.find(
				x2 => x2.columnKey === x1 && x2.operation === 'lte',
			)?.selection;
			if (!(dateFrom || dateTo)) {
				return '';
			}
			let rangeCond = '';
			if (dateFrom && dateTo) {
				rangeCond = `$gte: ISODate('${dateFrom.slice(
					0,
					10,
				)}') , $lte: ISODate('${dateTo.slice(0, 10)}')`;
			} else if (dateFrom) {
				rangeCond = `$gte: ISODate('${dateFrom.slice(0, 10)}')`;
			} else if (dateTo) {
				rangeCond = `$lte: ISODate('${dateTo.slice(0, 10)}')`;
			}

			return `dates: {$elemMatch:{dateType:'${dateFilterFeildsMongo[i1]}', date:{${rangeCond} } } }`;
		})
		.filter(x1 => x1 !== '')
		.forEach(x1 => arrQueryStrings.push(x1));

	if (arrQueryStrings.length === 0) return '';
	return `{ ${arrQueryStrings.join(',')} }`;
};

const funcLink2Filter: ILink2Filter = searchParams => {
	const result: InfinityListFilter[] = [];
	linkFields
		.filter(x1 => searchParams.getAll(x1).length > 0)
		.forEach(x1 => {
			searchParams.getAll(x1).forEach(x2 =>
				result.push({
					columnKey: arrayFields[linkFields.indexOf(x1)],
					selection: x2,
					display: 'UNKNOWN',
					operation: 'eq',
				}),
			);
		});

	dateFilterFields
		.filter(x1 => searchParams.getAll(x1).length > 0)
		.forEach(x1 => {
			searchParams.getAll(x1).forEach(x2 =>
				result.push({
					columnKey: x1,
					selection: x2.split('_').at(1) ?? DEFAULT_DATE,
					display:
						(x2.split('_').at(0) === 'gte' ? '>' : '') +
						(x2.split('_').at(0) === 'lte' ? '<' : '') +
						(x2.split('_').at(1) ?? DEFAULT_DATE),
					operation: x2.split('_').at(0) ?? 'gte',
				}),
			);
		});

	return result;
};

// *page object ----------------------------------------------------------------------------------------------------------------------
const PogisDocumentPage: React.FC = () => {
	// *hooks for states --------------------------------------------------------------------------------------------------------------
	const {t} = useTranslation('features/pogisdocuments', {
		keyPrefix: 'PogisDocumentsList',
	});
	const {t: tViews} = useTranslation('features/pogisdocuments', {
		keyPrefix: 'Views',
	});

	const [lastFetchedId, setlastFetchedId] = useState('');

	const [cursor, setCursor] = useState(0);

	const [keyPrefix, setKeyPrefix] = useState(uuidv4());

	const [dsPogisDocument, setDSPogisDocument] =
		useState<DataStorePogisDocument>({});

	const [arrPogisDocument, setArrPogisDocument] = useState<
		PogisDocumentModified[]
	>([]);

	const {loading: loadingSortOrders, data: dataSortOrders} =
		useGetPogisSortOrdersQuery();

	const [gePogisDocumentQuery, {loading: loadingQuery, data: dataQuery}] =
		useGetPogisDocumentsQueryLazyQuery();

	const [
		getPogisDocumentsNext,
		{loading: loadingDocuments, data: dataDocuments},
	] = useGetPogisDocumentsNextLazyQuery();

	const [searchParams] = useSearchParams();

	const navigate = useNavigate();

	const views: InfinityListView[] = useMemo(
		() => [
			{headerText: tViews('STD'), accessKey: 'STD', query: ''},
			{
				headerText: tViews('ONLYPOGIS'),
				accessKey: 'ONLYPOGIS',
				query: `{regulationStatus : { $ne: 'GSO-Eintrag'}}`,
			},
			{
				headerText: tViews('ONLYGSO'),
				accessKey: 'ONLYGSO',
				query: `{regulationStatus : { $eq: 'GSO-Eintrag'}}`,
			},
		],
		[],
	);

	const {queryLink, linksFilters, initialView} = useMemo(() => {
		const linksFilters = funcLink2Filter(searchParams);
		const queryLink = funcFilters2MongoQuery(linksFilters);
		const initialView =
			searchParams.getAll('view').length > 0
				? searchParams.getAll('view').at(0) ?? ''
				: 'STD';
		return {queryLink, linksFilters, initialView};
	}, [searchParams]);

	// *functions for summarizing prop functions --------------------------------------------------------------------------------------
	const getSortIds = (sortField: string) => {
		switch (sortField) {
			case 'markets':
				return dataSortOrders?.pogisSortOrders.byMarkets;
			case 'effectiveDate':
				return dataSortOrders?.pogisSortOrders.byEffectiveDate;
			case 'ntDate':
				return dataSortOrders?.pogisSortOrders.byNT;
			case 'nfDate':
				return dataSortOrders?.pogisSortOrders.byNF;
			case 'modelYear':
				return dataSortOrders?.pogisSortOrders.byModelYear;
			case 'regulationStatus':
				return dataSortOrders?.pogisSortOrders.byRegulationStatus;
			case 'editor':
				return dataSortOrders?.pogisSortOrders.byEditor;
			default:
				return dataSortOrders?.pogisSortOrders.byPogisIds;
		}
	};

	const getSortOrderDefault = (sortField: string) => {
		return arrSortInverted.includes(sortField) ? 'DESC' : 'ASC';
	};

	const getSortOrderServer = (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		return (arrSortInverted.includes(sortField) && sortOrder === 'ASC') ||
			(!arrSortInverted.includes(sortField) && sortOrder === 'DESC')
			? 'DESC'
			: 'ASC';
	};

	const getSortInverted = (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		return (
			(arrSortInverted.includes(sortField) && sortOrder === 'ASC') ||
			(!arrSortInverted.includes(sortField) && sortOrder === 'DESC')
		);
	};

	const getIdsNext = (
		arrIds: string[],
		sortField: string,
		sortOrderEff: string,
		start: number,
		end: number,
	) => {
		const setIds = new Set(arrIds);

		const arrSortIds = (getSortIds(sortField) ?? '').split(';');

		if (getSortInverted(sortField, sortOrderEff)) {
			arrSortIds.reverse();
		}

		const arrIdsFromStart: string[] = [];

		for (let ii = 0; ii < arrSortIds.length; ii++) {
			if (setIds.has(arrSortIds[ii])) {
				arrIdsFromStart.push(arrSortIds[ii]);
			}
			if (arrIdsFromStart.length === end) {
				break;
			}
		}

		return arrIdsFromStart.slice(start, end);
	};

	const fetchServerNextData = async (arrIdsNext: string[]) => {
		const arrIdsToFetch = arrIdsNext.filter(
			x => dsPogisDocument[x] === undefined,
		);

		if (arrIdsToFetch.length > 0) {
			const dsPogisDocumentNext: DataStorePogisDocument = {};

			const strIdsToFetch = arrIdsToFetch.join(';');
			const {data: dataDocumentsNext} = await getPogisDocumentsNext({
				variables: {
					filteredIds: strIdsToFetch,
				},
			});

			(dataDocumentsNext?.pogisDocumentsNext ?? [])
				.map(x => funcMapPogisDocument(x as PogisDocument))
				.forEach(x => {
					dsPogisDocumentNext[x.id] = x;
				});

			const dsPogisDocumentNew = {
				...dsPogisDocument,
				...dsPogisDocumentNext,
			};

			const arrPogisDocumentNext = arrIdsNext
				.map(x => dsPogisDocumentNew[x])
				.filter(x => x !== undefined);

			setDSPogisDocument(dsPogisDocumentNew);

			return {dsPogisDocumentNew, arrPogisDocumentNext};
		}

		const arrPogisDocumentNext = arrIdsNext
			.map(x => dsPogisDocument[x])
			.filter(x => x !== undefined);

		return {dsPogisDocument, arrPogisDocumentNext};
	};

	// *prop functions ----------------------------------------------------------------------------------------------------------------
	const firstRender = async () => {
		// *firstRender called only once at begin
		const arrPogisDocumentNext: PogisDocumentModified[] = [];
		const dsPogisDocumentNext: DataStorePogisDocument = {};
		const sortOrderServer = getSortOrderServer(
			initialSortField,
			initialSortOrder,
		);

		const queryView =
			views.find(x => x.accessKey === (initialView ?? 'STD'))?.query ?? '';

		let queryFinal = '';
		if (queryLink === '') {
			queryFinal = queryView;
		} else if (queryView === '') {
			queryFinal = queryLink;
		} else {
			queryFinal = `{$and:[${queryView},${queryLink}]}`;
		}

		const {data: dataQuery} = await gePogisDocumentQuery({
			variables: {
				query: queryFinal,
				sortField: initialSortField,
				sortOrder: sortOrderServer,
			},
		});

		(dataQuery?.pogisDocumentsQuery.pogisDocuments ?? []).forEach(x => {
			arrPogisDocumentNext.push(funcMapPogisDocument(x as PogisDocument));
		});

		arrPogisDocumentNext.forEach(x => {
			dsPogisDocumentNext[x.id] = x;
		});

		const lastFetchdIdNext = arrPogisDocumentNext.at(-1)!.id;

		setDSPogisDocument(dsPogisDocumentNext);

		setArrPogisDocument(arrPogisDocumentNext);

		setlastFetchedId(lastFetchdIdNext);

		setCursor(arrPogisDocumentNext.length);
	};

	const applyFilter = async (
		allFilters: InfinityListFilter[],
		sortField: string,
		sortOrder: string,
		currentView?: string,
	) => {
		const dataURL = funcFilters2Link(allFilters);
		dataURL.view = [currentView ?? 'STD'];
		const queryFilter =
			allFilters.length > 0 ? funcFilters2MongoQuery(allFilters) : '';

		const queryView =
			views.find(x => x.accessKey === (currentView ?? 'STD'))?.query ?? '';

		let queryFinal = '';
		if (queryFilter === '') {
			queryFinal = queryView;
		} else if (queryView === '') {
			queryFinal = queryFilter;
		} else {
			queryFinal = `{$and:[${queryView},${queryFilter}]}`;
		}

		navigate({
			pathname: '',
			search: createSearchParams(dataURL).toString(),
		});

		const arrPogisDocumentNext: PogisDocumentModified[] = [];
		const dsPogisDocumentNext: DataStorePogisDocument = {};

		const sortOrderServer = getSortOrderServer(sortField, sortOrder);

		const {data: dataQueryFilter} = await gePogisDocumentQuery({
			variables: {query: queryFinal, sortField, sortOrder: sortOrderServer},
		});

		(dataQueryFilter?.pogisDocumentsQuery.pogisDocuments ?? []).forEach(x => {
			arrPogisDocumentNext.push(funcMapPogisDocument(x as PogisDocument));
		});

		arrPogisDocumentNext.forEach(x => {
			dsPogisDocumentNext[x.id] = x;
		});

		const lastFetchdIdNext = arrPogisDocumentNext.at(-1)!.id;

		setDSPogisDocument({
			...dsPogisDocument,
			...dsPogisDocumentNext,
		});

		setArrPogisDocument(
			arrPogisDocumentNext.length > 0 ? arrPogisDocumentNext : [],
		);

		setlastFetchedId(lastFetchdIdNext);

		setCursor(arrPogisDocumentNext.length);

		setKeyPrefix(uuidv4());
	};

	const applySort = useCallback(
		async (
			sortField: string,
			sortOrder: string | undefined,
			setSortOrder: (sortOrder: string) => void,
		) => {
			const arrIds = (dataQuery?.pogisDocumentsQuery.pogisIds ?? '').split(';');
			const sortOrderEff = sortOrder
				? sortOrder
				: getSortOrderDefault(sortField);

			setSortOrder(sortOrderEff);

			const arrIdsNext = getIdsNext(arrIds, sortField, sortOrderEff, 0, 50);

			if (arrIdsNext.length > 0) {
				const {dsPogisDocumentNew, arrPogisDocumentNext} =
					await fetchServerNextData(arrIdsNext);

				const lastIdNext = arrIdsNext.at(-1) ?? '';

				setArrPogisDocument(arrPogisDocumentNext);

				setlastFetchedId(lastIdNext);

				setCursor(arrIdsNext.length);
				setKeyPrefix(uuidv4());
			}
		},
		[dataQuery?.pogisDocumentsQuery.pogisIds],
	);

	const loadItemsNext = useCallback(
		async (
			currentId: string,
			sortField: string,
			sortOrder: string | undefined,
		) => {
			if (currentId === lastFetchedId) {
				// *currentId is equal to known lastFetchedId (last row has been mounted)
				// 		*ids are known -> fetch next batch -> set lastFetchdId and cursor correctly
				const arrIds = (dataQuery?.pogisDocumentsQuery.pogisIds ?? '').split(
					';',
				);
				const sortOrderEff = sortOrder
					? sortOrder
					: getSortOrderDefault(sortField);

				const intEnd = cursor + intBatchSize;

				const arrIdsNext = getIdsNext(
					arrIds,
					sortField,
					sortOrderEff,
					cursor,
					intEnd,
				);

				if (arrIdsNext.length > 0) {
					const {dsPogisDocumentNew, arrPogisDocumentNext} =
						await fetchServerNextData(arrIdsNext);

					const lastIdNext = arrIdsNext.at(-1) ?? '';

					setlastFetchedId(lastIdNext);

					setCursor(intEnd);

					setArrPogisDocument(prevItems => [
						...prevItems,
						...arrPogisDocumentNext,
					]);
				}
			}
		},
		[
			dataQuery,
			arrPogisDocument,
			lastFetchedId,
			getIdsNext,
			fetchServerNextData,
		],
	);

	const retrieveItemsAll = async (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		const arrIds = (dataQuery?.pogisDocumentsQuery.pogisIds ?? '').split(';');
		const sortOrderEff = sortOrder ? sortOrder : getSortOrderDefault(sortField);
		const arrIdsNext = getIdsNext(
			arrIds,
			sortField,
			sortOrderEff,
			0,
			arrIds.length,
		);

		const {dsPogisDocumentNew, arrPogisDocumentNext} =
			await fetchServerNextData(arrIdsNext);

		return arrPogisDocumentNext;
	};

	const getValue = (item: string) => {
		return decodeHTML(item);
	};

	const handleViewingItem = (pogis: PogisDocumentModified) => {
		navigate({
			pathname: '',
			search: createSearchParams({
				id: pogis.pogisId,
			}).toString(),
		});
		setArrPogisDocument(prev => prev.filter(x => x.id === pogis.id));
		setlastFetchedId('');
		setCursor(1);
	};

	const createHref = (item: unknown) =>
		`?id=${(item as PogisDocumentModified).pogisId}`;

	const numberOfElements = useMemo(
		() =>
			dataQuery?.pogisDocumentsQuery.pogisIds.split(';').filter(x => x.trim())
				.length ?? 1,
		[dataQuery],
	);

	// *columns -----------------------------------------------------------------------------------------------------------------------
	const columns: InfinityListColumn[] = useMemo(
		() => [
			{
				key: 'pogisId',
				name: t('POGISID'),
				fieldName: 'pogisId',
				minWidth: 75,
				maxWidth: 75,
				sortable: true,
				filterOnFilter: true,
				filterType: 'Array',
			},
			{
				key: 'keywords',
				name: t('Keywords'),
				fieldName: 'keywords',
				minWidth: 200,
				maxWidth: 300,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'markets',
				name: t('Markets'),
				fieldName: 'markets',
				minWidth: 150,
				maxWidth: 200,
				sortable: true,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'standards',
				name: t('Standards'),
				fieldName: 'standards',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(getValue),
			},
			{
				key: 'shortInfo',
				name: t('ShortInfo'),
				fieldName: 'shortInfo',
				minWidth: 400,
				maxWidth: 550,
				isResizable: true,
				isMultiline: true,
				onRender(item: PogisDocumentModified) {
					return (
						<span
							dangerouslySetInnerHTML={{
								__html: item.shortInfo ?? '',
							}}
						></span>
					);
				},
			},
			{
				key: 'attachments',
				name: t('Attachments'),
				fieldName: 'pogisAttachments',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderAttachmentsPogis(),
			},
			{
				key: 'effectiveDate',
				name: t('Effective Date'),
				fieldName: 'effectiveDate',
				minWidth: 160,
				maxWidth: 160,
				sortable: true,
				filterType: 'Date',
				onRender: renderDateString(),
			},
			{
				key: 'ntDate',
				name: t('NTDate'),
				fieldName: 'ntDate',
				minWidth: 150,
				maxWidth: 150,
				sortable: true,
				filterType: 'Date',
				onRender: renderDateString(),
			},
			{
				key: 'nfDate',
				name: t('NFDate'),
				fieldName: 'nfDate',
				minWidth: 150,
				maxWidth: 150,
				sortable: true,
				filterType: 'Date',
				onRender: renderDateString(),
			},
			{
				key: 'modelYear',
				name: t('ModelYear'),
				fieldName: 'modelYear',
				minWidth: 150,
				maxWidth: 150,
				sortable: true,
				filterType: 'Date',
				onRender(item: PogisDocumentModified) {
					return (
						<div>
							{item.modelYear.startsWith('1970')
								? ''
								: t('MY') +
								  new Date(item.modelYear).getFullYear().toString().slice(2, 4)}
						</div>
					);
				},
			},
			{
				key: 'otherDates',
				name: t('Dates'),
				fieldName: 'otherDates',
				minWidth: 150,
				maxWidth: 150,
				onRender: renderPogisDate(),
			},
			{
				key: 'regulationStatus',
				name: t('RegulationStatus'),
				fieldName: 'regulationStatus',
				minWidth: 150,
				maxWidth: 200,
				sortable: true,
				filterType: 'Array',
				fieldValuesUnique:
					dataQuery?.pogisDocumentsQuery.pogisFilterOptions.regStatusOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					),
				isResizable: true,
				showSortIconWhenUnsorted: true,
			},
			{
				key: 'editor',
				name: t('Editor'),
				fieldName: 'editor',
				minWidth: 150,
				maxWidth: 250,
				sortable: true,
				isResizable: true,
				isMultiline: false,
				filterOnFilter: true,
				filterType: 'Array',
				showSortIconWhenUnsorted: true,
			},
			{
				key: 'associations',
				name: t('Associations'),
				fieldName: 'associations',
				minWidth: 150,
				maxWidth: 150,
				isMultiline: true,
				onRender: renderArrayField(),
			},
			{
				key: 'relatedDocuments',
				name: t('RelatedDocuments'),
				fieldName: 'relatedDocuments',
				isMultiline: true,
				minWidth: 150,
				maxWidth: 150,
				onRender: renderArrayField(),
			},
			{
				key: 'linkVersion',
				name: t('LinkVersion'),
				fieldName: 'linkVersion',
				minWidth: 200,
				maxWidth: 200,
				onRender: renderLinkField(),
			},
			{
				key: 'docStatus',
				name: t('DocStatus'),
				fieldName: 'docStatus',
				minWidth: 150,
				maxWidth: 150,
				isMultiline: false,
			},
		],
		[],
	);

	const columnFilterOptions: InfinityListColumnFilterOptions[] = useMemo(
		() => [
			{
				key: 'pogisId',
				fieldValuesUnique:
					(
						dataQuery?.pogisDocumentsQuery.pogisFilterOptions.pogisIdOptions ??
						''
					)
						.split(';')
						.map((value, index) => {
							return {id: value, value};
						}) ?? [],
			},
			{
				key: 'keywords',
				fieldValuesUnique:
					dataQuery?.pogisDocumentsQuery.pogisFilterOptions.keywordOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'markets',
				fieldValuesUnique:
					dataQuery?.pogisDocumentsQuery.pogisFilterOptions.marketOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'standards',
				fieldValuesUnique:
					dataQuery?.pogisDocumentsQuery.pogisFilterOptions.standardOptions.map(
						x => {
							const standardName = x.name;
							return {id: x.optionKey, value: decodeHTML(standardName)};
						},
					) ?? [],
			},
			{
				key: 'regulationStatus',
				fieldValuesUnique:
					dataQuery?.pogisDocumentsQuery.pogisFilterOptions.regStatusOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'editor',
				fieldValuesUnique:
					dataQuery?.pogisDocumentsQuery.pogisFilterOptions.editorOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
		],
		[dataQuery],
	);

	// *react object ------------------------------------------------------------------------------------------------------------------
	return (
		<InfinityList
			pageName={t('Archive')}
			items={arrPogisDocument}
			sticky={true}
			columns={columns}
			columnFilterOptions={columnFilterOptions}
			selectionMode={SelectionMode.none}
			stillLoading={loadingQuery || loadingDocuments}
			initalFilters={linksFilters}
			initialSortField={initialSortField}
			initialSortOrder={initialSortOrder}
			initialView={initialView}
			keyPrefix={keyPrefix}
			firstRender={firstRender}
			loadItemsNext={loadItemsNext}
			retrieveItemsAll={retrieveItemsAll}
			applyFilter={applyFilter}
			applySort={applySort}
			handleInvokeItem={handleViewingItem}
			createHref={createHref}
			numberOfElements={numberOfElements}
			views={views}
		/>
	);
};

export default PogisDocumentPage;
