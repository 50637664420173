import {Breadcrumb, IBreadcrumbItem} from '@fluentui/react';
import {DetailsField, DetailsSection} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {ParagraphsList} from 'features/RegulatoryDocuments/components/DocumentDetails';
import {ParagraphsContextProvider} from 'features/RegulatoryDocuments/components/DocumentDetails/ParagraphsContext';
import React, {useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
	RegulatoryDocumentParagraph,
	RequirementStatus,
	UserRole,
	WorkflowStatus,
} from 'types';
import {useRequirementDetailsQuery} from '../hooks/requirementDetails.generated';
import {useRequirementDetailsPageNonEditCmds} from './useRequirementDetailsPageCmds';
import {
	RequirementDetailsResult,
	RequirementFromDetailsPage,
} from './requirementDetailsPage.types';
import {
	RequirementDetailsPageFormInfo,
	useRequirementDetailsPageEditCmdAndFormInfo as useEditCmdAndFormInfo,
} from './useRequirementDetailsPageEditCmdAndFormInfo';
import {
	useEnumsTranslation,
	useRequirementsTranslation,
} from '../hooks/useRequirementsTranslation';
import {RequirementDetailsPagePanelsForEditing} from './RequirementDetailsPageFormPanels';
import {KeywordsField} from 'components/DetailsSection/KeywordsField';
import {useCommand} from 'hooks/useCommand';
import {DialogInfo, useDialogState} from 'components/Dialogs';
import {WorkFlowDialogHandler} from './WorkFlowDialog/WorkFlowDialogHandler';
import {useUserContext} from 'authentication/UserContext';
import {useGetMyRequirementsReviewQuery} from 'features/Dashboard/hooks/myRequirementsReview.generated';
import {useRequirementsVersions} from '../hooks/useRequirementsVersions';
import {EntityListContextProvider} from '../../../components/EntityList/EntityListContext';
import {RequirementsTooltipTranslationProvider} from '../RequirementsTooltipTranslationProvider';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';
import {CreateQuestionDialog} from 'components/Faq/CreateQuestionDialog';
import {useTranslation} from 'react-i18next';
import {useQuestionCountsByEntityTypeQuery} from 'components/Faq/Faq.generated';
import {RequirementsCollectionName} from 'components/Faq/EntityTypeMapper';
import {FaqPanelMode} from 'components/Faq/FaqPanel';

export enum RequirementStatusType {
	None,
	Draft,
	SendToApproval,
	CompleteWithoutApproval,
	ClearingOK,
	ClearingNOK,
}

interface PageParams {
	requirementId: string;
}

const RequirementDetailsPage: React.FC = () => {
	const {t} = useRequirementsTranslation();
	const {t: faqT} = useTranslation('common/faq');
	const {tStatus, tCategory} = useEnumsTranslation();
	const [showAudit, setShowAudit] = React.useState(false);
	const navigate = useNavigate();
	const {requirementId} = useParams() as Readonly<PageParams>;

	const [showQuestionDialog, setShowQuestionDialog] = React.useState(false);

	const {myVexClusters, isAdmin, isApprover} = useUserContext();
	const {loading, data}: RequirementDetailsResult = useRequirementDetailsQuery({
		variables: {id: requirementId || ''},
	});
	const {data: questionSummaryData} = useQuestionCountsByEntityTypeQuery({
		variables: {
			entityType: RequirementsCollectionName,
			parentId: requirementId,
		},
	});
	const shouldFetchPendingApprovals = isAdmin || isApprover;
	const {data: pendingApprovals} = useGetMyRequirementsReviewQuery({
		skip: !shouldFetchPendingApprovals,
	});
	const {hasEditableChildren} = useRequirementsVersions();

	const [dialogType, setDialogType] = React.useState<RequirementStatusType>(
		RequirementStatusType.None,
	);
	const [dialogState, showDialog, hideDialog]: DialogInfo = useDialogState();

	const setAndShowDialog = useCallback(
		(statusType: RequirementStatusType) => {
			setDialogType(statusType);
			showDialog();
		},
		[showDialog],
	);

	const requirement: RequirementFromDetailsPage = React.useMemo(() => {
		const requirement = data?.requirement ?? ({} as RequirementFromDetailsPage);
		return requirement.status === RequirementStatus.Interim ||
			requirement.status === RequirementStatus.InterimCompleted
			? {
					...requirement,
					createdBy: {...requirement.createdBy, name: 'System'},
					modifiedBy: {
						...requirement.modifiedBy,
						name: 'System',
					},
			  }
			: requirement;
	}, [data]);

	const paragraphs = React.useMemo(() => {
		const assignedParagraphs = JSON.parse(
			JSON.stringify(requirement.assignedParagraphs || []),
		).filter((elem: RegulatoryDocumentParagraph) => {
			return elem?.parent?.workflow?.status !== WorkflowStatus.Modified;
		}) as RegulatoryDocumentParagraph[];

		if (assignedParagraphs.length < 2) {
			return assignedParagraphs;
		}

		return assignedParagraphs.sort((pA, pB) => {
			const regulationA = `${pA.parent.regulation?.regulationNumber} ${pA.parent.regulation?.name}`;
			const regulationB = `${pB.parent.regulation?.regulationNumber} ${pB.parent.regulation?.name}`;
			if (regulationA > regulationB) {
				return 1;
			}

			return regulationA < regulationB ? -1 : 0;
		});
	}, [requirement]);

	const auditLogs = React.useMemo(
		() => data?.requirement?.auditLog ?? [],
		[data],
	);

	const itemsWithHref: IBreadcrumbItem[] = [
		{
			text: t('RequirementsHeader'),
			key: 'requirements',
			onClick: () => navigate('/requirements'),
		},
		{
			text: `${requirement?.name}`,
			key: 'name',
		},
	];

	useRequirementDetailsPageNonEditCmds({
		requirementId,
		toggleAuditCmdFields: {setShowAudit, showAudit},
	});

	const vexClusterMatch = React.useMemo(
		() =>
			requirement?.vexClusters?.some(vc =>
				myVexClusters?.some(mvc => mvc.id === vc.id),
			),
		[requirement, myVexClusters],
	);

	const myReviews = React.useMemo(
		() =>
			pendingApprovals?.myRequirementsReview.filter(
				r => r.status === RequirementStatus.Approval,
			),
		[pendingApprovals],
	);
	useCommand(
		{
			key: 'sentToApproval',
			priority: 2,
			text: t('Complete'),
			iconProps: {iconName: 'ReleaseGate'},

			disabled:
				requirement.status === RequirementStatus.Approval ||
				requirement.status === RequirementStatus.Interim ||
				requirement.status === RequirementStatus.InterimCompleted ||
				requirement.status === RequirementStatus.Final ||
				!vexClusterMatch,
			roles: [UserRole.Vex, UserRole.SystemAdministrator],
			subMenuProps: {
				items: [
					{
						key: 'Approval',
						text: t('Approval'),

						onClick() {
							setAndShowDialog(RequirementStatusType.SendToApproval);
						},
					},
					{
						key: 'CompleteWithoutApproval',
						text: t('WorkflowDialog.CompleteWithoutApproval'),

						onClick() {
							setAndShowDialog(RequirementStatusType.CompleteWithoutApproval);
						},
					},
				],
			},
		},
		[requirement],
	);

	useCommand(
		{
			key: 'approveRequirement',
			priority: 2,
			text: t('AcceptApproval'),
			iconProps: {iconName: 'CheckMark'},
			onClick: () => setAndShowDialog(RequirementStatusType.ClearingOK),
			disabled:
				requirement.status !== RequirementStatus.Approval ||
				myReviews === undefined ||
				myReviews?.length === 0,
			roles: [UserRole.ShApprover, UserRole.SystemAdministrator],
		},
		[],
	);

	useCommand(
		{
			key: 'declineRequirement',
			priority: 2,
			text: t('DeclineApproval'),
			iconProps: {iconName: 'Cancel'},
			onClick: () => setAndShowDialog(RequirementStatusType.ClearingNOK),
			disabled:
				requirement.status !== RequirementStatus.Approval ||
				myReviews === undefined ||
				myReviews?.length === 0,
			roles: [UserRole.ShApprover, UserRole.SystemAdministrator],
		},
		[requirement],
	);

	useCommand(
		{
			key: 'PostQuestionButtonRequirementDetails',
			priority: 4,
			text: faqT('PostQuestionButton'),
			iconProps: {iconName: 'Chat'},
			onClick: () => setShowQuestionDialog(true),

			hidden: !(isDevEnvironment() || isTestEnvironment()) || !requirement?.id,
			disabled: !requirement?.id,
		},
		[requirement],
	);

	const initialFieldRows = React.useMemo((): DetailsField[][] => {
		const fields: DetailsField[][] = [
			[
				{
					data: requirement.name,
					type: 'text',
					label: t('Name'),
					audit: showAudit,
					auditFieldName: 'name',
				},
				{
					data: requirement.requirementId,
					type: 'text',
					label: t('RequirementId'),
					audit: showAudit,
					auditFieldName: 'requirementId',
				},
				{
					data:
						requirement.status === RequirementStatus.Final
							? requirement.version
							: '-',
					type: 'text',
					label: t('Versionsinfo'),
					audit: showAudit,
					auditFieldName: 'version',
				},
				{
					data: tStatus(requirement.status),
					type: 'text',
					label: t('Status'),
					audit: showAudit,
					auditFieldName: 'status',
				},
				{
					data: tCategory(requirement.category),
					type: 'text',
					label: t('RequirementCategoryTagPickerLabel'),
					audit: showAudit,
					auditFieldName: 'category',
				},
			],
			[
				{
					data: requirement.definition,
					type: 'richtext',
					label: t('Definition'),
					audit: showAudit,
					auditFieldName: 'definition',
				},
			],
			[
				{
					data: requirement.vexClusters,
					type: 'array',
					label: t('VexCluster'),
					audit: showAudit,
					auditFieldName: 'vexClusterRefs',
				},
				{
					data: requirement.systemLevels,
					type: 'array',
					label: t('SystemLevels'),
					audit: showAudit,
					auditFieldName: 'systemLevelRefs',
				},
				{
					data: requirement.markets,
					type: 'array',
					label: t('Markets'),
					audit: showAudit,
					auditFieldName: 'marketRefs',
				},
				{
					data: requirement.keywords,
					label: t('Keywords'),
					audit: showAudit,
					auditFieldName: 'keywordRefs',
					render: KeywordsField,
				},
			],
			[
				{
					data: requirement.driveVariants,
					type: 'array',
					label: t('DriveVariants'),
					audit: showAudit,
					auditFieldName: 'driveVariantRefs',
				},
				{
					data: requirement.engineVariants,
					type: 'array',
					label: t('EngineVariants'),
					audit: showAudit,
					auditFieldName: 'engineVariantRefs',
				},
				{
					data: requirement.gearboxVariants,
					type: 'array',
					label: t('GearboxVariants'),
					audit: showAudit,
					auditFieldName: 'gearboxVariantRefs',
				},
			],
			[
				{
					data: requirement.bodyworkVariants,
					type: 'array',
					label: t('BodyworkVariants'),
					audit: showAudit,
					auditFieldName: 'bodyworkVariantRefs',
				},
				{
					data: requirement.activeTypeMarkets,
					type: 'array',
					label: t('ActiveTypeMarkets'),
					audit: showAudit,
					auditFieldName: 'activeTypeMarketRefs',
				},
				{
					data: requirement.vehicleCategories,
					type: 'array',
					label: t('VehicleCategory'),
					audit: showAudit,
					auditFieldName: 'vehicleCategoryRefs',
				},
				{
					data: requirement.tags,
					type: 'array',
					label: t('Tags'),
					audit: showAudit,
					auditFieldName: 'tagRefs',
				},
			],
			[
				{
					data: requirement.createdBy,
					anonymous: requirement?.anonymous,
					type: 'personas',
					label: t('CreatedBy'),
				},
				{
					data: requirement.createdAt,
					type: 'date',
					label: t('CreatedAt'),
				},
				{
					data: requirement.modifiedBy,
					anonymous: requirement?.anonymous,
					type: 'personas',
					label: t('ModifiedBy'),
				},
				{
					data: requirement.modifiedAt,
					type: 'date',
					label: t('ModifiedAt'),
				},
			],
			[
				{
					data: requirement.attachments,
					type: 'attachments',
					label: t('Attachments'),
				},
				{
					data: {
						...questionSummaryData?.questionCountsByEntityType?.find(
							t => t.entityId === requirement.id,
						),
						entityPanelMode: FaqPanelMode.RequirementDetails,
						text: faqT('FaqText'),
					},
					type: 'faq',
					label: faqT('FaqLabel'),
				},
			],
			[
				{
					data: requirement.documentReferences,
					type: 'references',
					label: t('DocumentReferences'),
				},
			],
		];

		if (requirement.parentRef || requirement.childrenRefs?.length) {
			const data = {
				parent: requirement.parentRef,
				childs: requirement.childrenRefs,
			};

			fields.push([
				{
					data,
					type: 'requirementRef',
					label: t('requirementReferences'),
				},
			]);
		}

		return fields;
	}, [requirement, t, showAudit, tStatus, tCategory, questionSummaryData]);

	const requirementHasEditableChildren = React.useMemo((): boolean => {
		return hasEditableChildren(requirement);
	}, [requirement, hasEditableChildren]);

	const {formDataLoading, ...formInfo}: RequirementDetailsPageFormInfo =
		useEditCmdAndFormInfo(data, requirementHasEditableChildren);

	if (!loading && !data?.requirement) {
		return (
			<h4 style={{marginTop: '50px', marginLeft: '20px'}}>
				{t('DocumentNotFound')}
			</h4>
		);
	}
	return (
		<>
			<RequirementsTooltipTranslationProvider>
				{' '}
				{showQuestionDialog && (
					<CreateQuestionDialog
						showDialog={showQuestionDialog}
						setShowDialog={setShowQuestionDialog}
						initQuestion={requirement}
						faqPanelMode={FaqPanelMode.RequirementDetails}
					/>
				)}
				<LoadWrapper loading={loading || formDataLoading}>
					<Breadcrumb items={itemsWithHref} />
					<DetailsSection
						title={requirement.name}
						initialFieldRows={initialFieldRows}
						auditLogData={auditLogs as any}
					/>
					<RequirementDetailsPagePanelsForEditing
						requirement={requirement}
						{...formInfo}
					/>
					<h3 style={{marginLeft: 20, marginTop: 20}}>
						{t('AssignedParagraphs')}
					</h3>
					<EntityListContextProvider pageDetails={requirement}>
						<ParagraphsContextProvider>
							<ParagraphsList
								paragraphs={paragraphs}
								includeRegulationAndVersionName
								fromRequirements={true}
								parentId={requirement.id}
							/>
						</ParagraphsContextProvider>
					</EntityListContextProvider>
					<WorkFlowDialogHandler
						dialogState={dialogState}
						dialogType={dialogType}
						hideDialog={hideDialog}
						requirementDetails={requirement}
					/>
				</LoadWrapper>
			</RequirementsTooltipTranslationProvider>
		</>
	);
};

export default RequirementDetailsPage;
