import {
	renderArrayField,
	renderRequirementChangeType,
	renderRequirementStatus,
} from 'components/EntityList/ColumnRenderers';
import {EntityListColumn} from 'components/EntityList';
import {useTranslation} from 'react-i18next';
import {useCallback} from 'react';
import {createHrefFromRequirement} from 'features/Requirements/requirementLinks.utils';
import {Requirement, RequirementStatus} from 'types';
import {useTheme} from '@fluentui/react';
import {getListClassNames} from 'styles';
import {DateTimeFormat, formatDateTime} from 'i18n/localeDateFormat';
import React from 'react';
import i18n from 'i18n/i18n';
import {Link} from 'react-router-dom';

type OptionalColumnFieldNames = 'minWidth' | 'maxWidth' | 'filterable';
type OptionalColumnFields = Partial<
	Pick<EntityListColumn, OptionalColumnFieldNames>
>;
type Column = Omit<EntityListColumn, OptionalColumnFieldNames> &
	OptionalColumnFields;

const createColumn = ({
	key,
	name,
	fieldName,
	filterable = false,
	minWidth = 150,
	maxWidth = 200,
	onRender,
	getFilterLabel,

	...other
}: Column): EntityListColumn => ({
	key,
	name,
	fieldName,
	filterable,
	minWidth,
	maxWidth,
	isResizable: true,
	onRender,
	getFilterLabel,

	...other,
});

export const useRequirementTileColumns = (
	section: 'inProgress' | 'inApproval' | 'completed',
): EntityListColumn[] => {
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'RequirementsHeader',
	});
	const theme = useTheme();
	const classNames = getListClassNames(theme);
	const createCustomColumn = useCallback(
		({
			key,
			name,
			fieldName,
			minWidth,
			maxWidth,
			isResizable = true,
			onRender,
		}: any): EntityListColumn => ({
			key,
			name: t(name),
			fieldName,
			minWidth,
			maxWidth,
			isResizable,
			onRender,
		}),
		[t],
	);
	const renderRequirementId: EntityListColumn['onRender'] = (
		requirement: any,
	) => {
		if (!requirement.requirementId) return null;
		return (
			<div
				className={classNames.link}
				style={{fontSize: 12, color: theme.palette.neutralDark}}
			>
				{requirement.requirementId}
			</div>
		);
	};
	const renderRequirementName: EntityListColumn['onRender'] = (
		requirement: any,
	) => {
		if (!requirement.name) return null;

		return (
			<div
				className={classNames.link}
				style={{fontSize: 14, color: theme.palette.neutralDark}}
			>
				{requirement.name}
			</div>
		);
	};

	const renderApproverNames: EntityListColumn['onRender'] = (
		requirement: any,
	) => {
		if (
			requirement?.approvalWorkFlow?.sentToApprovalTimestamp &&
			requirement?.approvalWorkFlow?.approverRefs?.length
		)
			return (
				<div>
					{requirement.approvalWorkFlow.approverRefs.map(
						(approver: any, index: number) => (
							<div key={index}>{approver.name}</div>
						),
					)}
				</div>
			);
		return <span></span>;
	};
	const baseColumns: EntityListColumn[] = [
		createCustomColumn({
			key: 'title',
			name: 'Title',
			fieldName: 'name',
			isResizable: true,
			minWidth: 80,
			maxWidth: 100,
			onRender: renderRequirementName,
		}),
		createCustomColumn({
			key: 'id',
			name: 'Id',
			fieldName: 'requirementId',
			onRender: renderRequirementId,
			minWidth: 50,
			maxWidth: 50,
			isResizable: true,
		}),
		{
			key: 'vexCluster',
			name: t('VexCluster'),
			fieldName: 'vexClusters',
			minWidth: 150,
			maxWidth: 200,
			isResizable: true,
			onRender: renderArrayField(),
		},
	];
	const columnConfig: Record<
		'inProgress' | 'inApproval' | 'completed',
		EntityListColumn[]
	> = {
		inProgress: [
			...baseColumns,
			createCustomColumn({
				key: 'status',
				name: 'Status',
				fieldName: 'status',
				minWidth: 80,
				maxWidth: 100,
				onRender: renderRequirementStatus(),
			}),
		],
		inApproval: [
			...baseColumns,
			createCustomColumn({
				key: 'version',
				name: 'Version',
				fieldName: 'version',
				minWidth: 50,
				maxWidth: 50,
			}),
			createCustomColumn({
				key: 'approvers',
				name: 'Approvers',
				fieldName: 'approvalWorkFlow',
				onRender: renderApproverNames,
				minWidth: 50,
				maxWidth: 100,
				isResizable: true,
			}),
		],
		completed: [
			...baseColumns,
			createCustomColumn({
				key: 'version',
				name: 'Version',
				fieldName: 'version',
				minWidth: 50,
				maxWidth: 100,
			}),
		],
	};
	return columnConfig[section] || [];
};

export const useApproverTileColumns = (): EntityListColumn[] => {
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'ApproverHeader',
	});
	const theme = useTheme();
	const classNames = getListClassNames(theme);
	const createCustomColumn = useCallback(
		({name, filterable = false, ...other}: Column): EntityListColumn => {
			return createColumn({name: t(name), filterable, ...other});
		},
		[t],
	);

	const createCustomColumnFromParams = useCallback(
		(
			key: string,
			name: string,
			fieldName: string,
			filterable: boolean,
			onRender?: EntityListColumn['onRender'],
			getFilterLabel?: any,
			filterOnFilter?: boolean,
		): EntityListColumn =>
			createCustomColumn({
				key,
				name,
				fieldName,
				filterable,
				onRender,
				getFilterLabel,
				filterOnFilter,
			}),
		[createCustomColumn],
	);
	const renderRequirementId: EntityListColumn['onRender'] = (
		requirement: any,
	) => {
		if (!requirement.requirementId) return null;
		return (
			<Link
				to={createHrefFromRequirement(requirement)}
				className={classNames.link}
				style={{fontSize: 12, color: theme.palette.neutralDark}}
			>
				{requirement.requirementId}
			</Link>
		);
	};
	const renderRequirementName: EntityListColumn['onRender'] = (
		requirement: Requirement,
	) => {
		if (!requirement.name) return null;
		return (
			<Link
				to={createHrefFromRequirement(requirement)}
				className={classNames.link}
				style={{fontSize: 14, color: theme.palette.neutralDark}}
			>
				{requirement.name}
				{requirement.status === RequirementStatus.Approval && (
					<div className={classNames.itemSubtext}>
						{t('ApprovalSentTime')}
						{': '}
						{formatDateTime(
							new Date(requirement.approvalWorkFlow?.sentToApprovalTimestamp),
							i18n,
							DateTimeFormat.DateMonthWeekday,
						)}
					</div>
				)}
			</Link>
		);
	};

	const useColumns = [
		createCustomColumn({
			key: 'title',
			name: 'Title',
			fieldName: 'name',
			isResizable: true,
			minWidth: 80,
			maxWidth: 100,
			onRender: renderRequirementName,
		}),
		createCustomColumn({
			key: 'id',
			name: 'Id',
			fieldName: 'requirementId',
			onRender: renderRequirementId,
			minWidth: 50,
			maxWidth: 100,
			isResizable: true,
		}),
		createCustomColumnFromParams(
			'vexcluster',
			'VexCluster',
			'vexClusters',
			false,
			renderArrayField(),
		),
		createCustomColumn({
			key: 'changeType',
			name: 'ChangeType',
			fieldName: 'changeType',
			onRender: renderRequirementChangeType(),
			minWidth: 80,
			maxWidth: 100,
			isResizable: true,
		}),
	];
	return useColumns;
};
