import {PanelType, PersonaSize, SelectionMode} from '@fluentui/react';

import React, {useEffect, useMemo, useState} from 'react';

import {ConvoluteType, RegulatoryDocumentParagraph} from 'types';
import {
	InfinityList,
	InfinityListColumn,
	InfinityListColumnFilterOptions,
	InfinityListFilter,
	InfinityListItemWithId,
	InfinityListURLParams,
	InfinityListView,
} from 'components/InfinityList';
import {CellWithEntityLink} from 'components/EntityList/CellWithEntityLink';
import {
	renderArrayField,
	renderAttachments,
	renderDate,
	renderDateString,
	renderFaqs,
	renderParagraph,
	renderPersona,
	renderPhase,
	renderReferenceField,
	renderRichtext,
} from 'components/EntityList/ColumnRenderers';
import {useTranslation} from 'react-i18next';
import {v4 as uuidv4} from 'uuid';
import {
	createSearchParams,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';
import {useUserContext} from 'authentication/UserContext';
import {useGetConvolutesQuery} from 'features/AdminSection/hooks/convolutes.generated';
import {useGetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';

import {EntityPage} from 'components';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';
import {InfinityPage} from 'components/InfinityPage/InfinityPage';
import {FaqCommand} from 'components/Faq/FaqCommand';
import {FaqPanelMode} from 'components/Faq/FaqPanel';
import {DEFAULT_DATE} from 'components/EntityList/EntityUtils';
import {
	GetVehicleProjectDetailsOptimizedQuery,
	useGetVehProjectParagraphsNexzLazyQuery,
	useGetVehProjectParagraphsQueryLazyQuery,
} from './hooks';

interface DataStoreParagraph {
	[key: string]: RegulatoryDocumentParagraph;
}

interface DataStoreSortOrders {
	[key: string]: string;
}

interface IFilters2Link {
	(allFilters: InfinityListFilter[]): InfinityListURLParams;
}

interface IFilters2MongoQuery {
	(allFilters: InfinityListFilter[]): string;
}
interface ILink2MongoQuery {
	(searchParams: URLSearchParams): string;
}

interface ILink2Filter {
	(searchParams: URLSearchParams): InfinityListFilter[];
}

interface IDecodeHex24 {
	(x: string): string;
}

const sortInvertedFields = ['modifiedAt'];

const initialSortField = 'name';
const initialSortOrder = 'ASC';
const intBatchSize = 40;

const funcFilters2MongoQuery: IFilters2MongoQuery = allFilters => {
	const arrQueryStrings: string[] = [];
	return `{ ${arrQueryStrings.join(',')} }`;
};

const funcDecodeHex24: IDecodeHex24 = x => {
	return atob(x).split('\n')[1].slice(1);
};

export type VehicleProjectDetailsTabProps = {
	dataVehPro: GetVehicleProjectDetailsOptimizedQuery | undefined;
	loadingVehPro: boolean;
	hidden: boolean;
};

const VehicleProjectDetailsTabParagraphs: React.FC<
	VehicleProjectDetailsTabProps
> = ({dataVehPro, loadingVehPro, hidden}) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'ParagraphsList',
	});

	const {t: tViews} = useTranslation('features/Paragraphs', {
		keyPrefix: 'Views',
	});

	const [keyPrefix, setKeyPrefix] = useState(uuidv4());

	const [lastFetchedId, setlastFetchedId] = useState('');

	const [cursor, setCursor] = useState(0);

	const [dsParagraph, setDSParagraph] = useState<DataStoreParagraph>({});

	const [dsSortOrders, setDSSortOrders] = useState<DataStoreSortOrders>({});

	const [arrParagraph, setArrParagraph] = useState<
		RegulatoryDocumentParagraph[]
	>([]);

	const [getParagraphsQuery, {loading: loadingQuery, data: dataQuery}] =
		useGetVehProjectParagraphsQueryLazyQuery();

	const [getParagraphsNext, {loading: loadingDocuments, data: dataDocuments}] =
		useGetVehProjectParagraphsNexzLazyQuery();

	const views: InfinityListView[] = useMemo(() => {
		return [
			{
				headerText: tViews('STD'),
				accessKey: 'STD',
				query: '',
			},
		];
	}, []);

	const {queryLink, linksFilters, initialView} = useMemo(() => {
		const linksFilters: InfinityListFilter[] = [];
		const queryLink = '';
		const initialView = 'STD';
		return {queryLink, linksFilters, initialView};
	}, []);

	const navigate = useNavigate();

	// *functions for summarizing prop functions --------------------------------------------------------------------------------------
	const getIdsNext = async (arrIds: string[], start: number, end: number) => {
		const arrSortIds = arrIds
			.map(x => {
				const regDocID = x.split('_').at(1) ?? '';
				const paraIDs = x.split('_').at(1)?.split(';') ?? [];
				return paraIDs.map(y => `${regDocID}_${y}`);
			})
			.flat();
		return arrSortIds.slice(start, end);
	};

	const fetchServerNextData = async (arrIdsNext: string[]) => {
		const arrIdsToFetch = arrIdsNext.filter(x => dsParagraph[x] === undefined);

		if (arrIdsToFetch.length > 0) {
			const dsParagraphNext: DataStoreParagraph = {};

			const strIdsToFetch = arrIdsToFetch.join(';');
			const {data: dataDocumentsNext} = await getParagraphsNext({
				variables: {
					filteredIds: strIdsToFetch,
				},
			});

			(dataDocumentsNext?.vehProjectParagraphsNext ?? []).forEach(x => {
				dsParagraphNext[funcDecodeHex24(x.id)] =
					x as RegulatoryDocumentParagraph;
			});

			const dsParagraphNew = {
				...dsParagraph,
				...dsParagraphNext,
			};

			const arrParagraphNext = arrIdsNext
				.map(x => dsParagraphNew[x])
				.filter(x => x !== undefined);

			setDSParagraph(dsParagraphNew);

			return {dsParagraphNew, arrParagraphNext};
		}

		const arrParagraphNext = arrIdsNext
			.map(x => dsParagraph[x])
			.filter(x => x !== undefined);

		return {dsParagraph, arrParagraphNext};
	};

	// *prop functions ----------------------------------------------------------------------------------------------------------------
	const firstRender = async () => {
		return true;
	};

	useEffect(() => {
		(async () => {
			if (dataVehPro && !loadingVehPro && !dataQuery && hidden === false) {
				const arrParagraphNext: RegulatoryDocumentParagraph[] = [];
				const dsParagraphNext: DataStoreParagraph = {};

				const paraIds =
					dataVehPro?.vehicleProjectDetailsOptimized.vehProParagraphRefs;

				if (paraIds) {
					const {data: dataQueryPara} = await getParagraphsQuery({
						variables: {
							query: ``,
							sortField: ``,
							filteredIds: paraIds
								.map(x => `${x.regdocId}_${x.paragraphIds}`)
								.join('#'),
						},
					});

					(
						dataQueryPara?.vehProjectParagraphsQuery
							.regulatoryDocumentParagraphs ?? []
					).forEach(x => {
						arrParagraphNext.push(x as RegulatoryDocumentParagraph);
					});

					arrParagraphNext.forEach(x => {
						dsParagraphNext[
							`${funcDecodeHex24(x.parent.id)}_${funcDecodeHex24(x.id)}`
						] = x;
					});
				}

				const lastFetchdIdNext = arrParagraphNext.at(-1)!.id;

				setDSParagraph(dsParagraphNext);

				setArrParagraph(arrParagraphNext);

				setlastFetchedId(lastFetchdIdNext);

				setCursor(arrParagraphNext.length);
			}
		})().catch(() => {
			console.log('para query failed');
		});
	}, [dataVehPro, loadingVehPro, dataQuery, hidden]);

	const applyFilter = async (
		allFilters: InfinityListFilter[],
		sortField: string,
		sortOrder: string,
		currentView?: string,
	) => {
		setKeyPrefix(uuidv4());
	};

	const applySort = async (
		sortField: string,
		sortOrder: string | undefined,
		setSortOrder: (sortOrder: string) => void,
	) => {
		return true;
	};

	const loadItemsNext = async (
		currentId: string,
		sortField: string,
		sortOrder: string | undefined,
	) => {
		if (currentId === lastFetchedId) {
			// *currentId is equal to known lastFetchedId (last row has been mounted)
			// 		*ids are known -> fetch next batch -> set lastFetchdId and cursor correctly

			const arrIds = (
				dataQuery?.vehProjectParagraphsQuery.vehProParagraphIDs ?? ''
			).split('#');
			const intEnd = cursor + intBatchSize;

			const arrIdsNext = await getIdsNext(arrIds, cursor, intEnd);

			if (arrIdsNext.length > 0) {
				const {dsParagraphNew, arrParagraphNext} = await fetchServerNextData(
					arrIdsNext,
				);

				const lastIdNext = arrParagraphNext.at(-1)!.id ?? '';

				setlastFetchedId(lastIdNext);

				setCursor(intEnd);

				setArrParagraph(prevItems => [...prevItems, ...arrParagraphNext]);
			}
		}
	};

	const retrieveItemsAll = async (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		return [];
	};

	const handleViewingItem = (item: RegulatoryDocumentParagraph) => {
		navigate({
			pathname: `${item.id}`,
		});
	};

	const numberOfElements = useMemo(() => {
		return (
			dataVehPro?.vehicleProjectDetailsOptimized.vehProParagraphRefs
				.map(x => x.paragraphIds.split(';'))
				.flat().length ?? 1
		);
	}, [dataQuery, dataVehPro]);

	// *columns -----------------------------------------------------------------------------------------------------------------------
	const columns: InfinityListColumn[] = useMemo(
		() => [
			{
				key: 'regulation',
				name: t('Regulation'),
				minWidth: 100,
				isResizable: true,
				onRender: item => item.parent?.regulation?.regulationNumber,
			},
			{
				key: 'parent',
				name: t('Version'),
				minWidth: 100,
				isResizable: true,
				onRender: item => item.parent?.name,
			},
			{
				key: 'paragraph',
				name: t('Paragraph'),
				minWidth: 400,
				onRender: (item: any) => renderParagraph(item, t),
			},
			{
				key: 'categories',
				name: t('Categories'),
				minWidth: 100,
				onRender: renderArrayField(),
			},
			{
				key: 'keywords',
				name: t('Keywords'),
				fieldName: 'keywords',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				onRender: renderArrayField(),
			},
			{
				key: 'tags',
				name: t('Tags'),
				fieldName: 'tags',
				minWidth: 150,
				onRender: renderArrayField(),
			},
			{
				key: 'vehicleCategories',
				name: t('VehicleCategory'),
				minWidth: 100,
				onRender: renderArrayField(),
			},
			{
				key: 'drive-variants',
				name: t('DriveVariants'),
				minWidth: 100,
				onRender: renderArrayField(),
			},
			{
				key: 'dateEffective',
				name: t('DateEffective'),
				fieldName: 'dateEffective',
				minWidth: 120,
				maxWidth: 120,
				onRender: renderDateString(),
			},
			{
				key: 'dateNewTypes',
				name: t('DateNewTypes'),
				fieldName: 'dateNewTypes',
				minWidth: 120,
				maxWidth: 120,
				onRender: renderDateString(),
			},
			{
				key: 'dateNewRegistration',
				name: t('DateNewRegistration'),
				fieldName: 'dateNewRegistration',
				minWidth: 120,
				maxWidth: 120,
				onRender: renderDateString(),
			},
			{
				key: 'comprehensive',
				name: t('DateImplementation'),
				fieldName: 'comprehensive',
				minWidth: 120,
				maxWidth: 120,
				onRender: renderDateString(),
			},
			{
				key: 'modelYear',
				name: t('ModelYear'),
				fieldName: 'modelYear',
				minWidth: 120,
				maxWidth: 120,
			},
			{
				key: 'phaseIn',
				name: t('PhaseIn'),
				fieldName: 'phaseIn',
				minWidth: 120,
				maxWidth: 120,
				onRender: renderPhase(),
			},
			{
				key: 'phaseOut',
				name: t('PhaseOut'),
				fieldName: 'phaseOut',
				minWidth: 120,
				maxWidth: 120,
				onRender: renderPhase(),
			},
		],
		[],
	);

	const columnFilterOptions: InfinityListColumnFilterOptions[] = useMemo(
		() => [],
		[],
	);

	const createHref = (item: unknown) =>
		`/Paragraphs/${(item as InfinityListItemWithId).id}`;

	return (
		<>
			{!hidden && (
				<InfinityList
					pageName={''}
					items={arrParagraph}
					sticky={true}
					columns={columns}
					columnFilterOptions={columnFilterOptions}
					selectionMode={SelectionMode.single}
					stillLoading={loadingQuery || loadingDocuments || loadingVehPro}
					initalFilters={linksFilters}
					initialSortField={initialSortField}
					initialSortOrder={initialSortOrder}
					initialView={initialView}
					keyPrefix={keyPrefix}
					firstRender={firstRender}
					loadItemsNext={loadItemsNext}
					retrieveItemsAll={retrieveItemsAll}
					applyFilter={applyFilter}
					applySort={applySort}
					handleInvokeItem={handleViewingItem}
					createHref={createHref}
					numberOfElements={numberOfElements}
					showHeader={false}
					views={views}
				/>
			)}
		</>
	);
};

export default VehicleProjectDetailsTabParagraphs;
