import {useTranslation} from 'react-i18next';
import {getListClassNames} from 'styles';
import React from 'react';
import {
	DetailsRow,
	IDetailsRowBaseProps,
	IDetailsRowProps,
	Link,
	useTheme,
} from '@fluentui/react';
import {LoadWrapper} from 'components/LoadWrapper';
import {WorkbenchHeader} from '../Headers';
import {RequirementStatus} from 'types';
import {EntityList} from 'components/EntityList';
import {DashboardTooltipTranslationProvider} from '../DashboardTooltipTranslationProvider';
import {useRequirementTileColumns} from '../../hooks/useTileColumns';
import {TileContainer} from '../../hooks/useTileContainer';
import {DashboardCardProps} from './VexWorkbenchItems';
import {sortObjectsByDate} from 'helpers/sortHelpers';
import {useDashboardQueries} from 'features/Dashboard/hooks/useDashboardQueries';
import {createHrefFromRequirement} from 'features/Requirements/requirementLinks.utils';

export const MyRequirementsItems: React.FC<DashboardCardProps> = ({
	halfHeight = false,
	itemCountLoaded,
}) => {
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'RequirementsOverview',
	});

	const {
		myRequirementsReturnData: {data, loading},
	} = useDashboardQueries();
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	const inProgress = React.useMemo(() => {
		return sortObjectsByDate(
			data?.myRequirements?.filter(
				r =>
					r.status === RequirementStatus.Draft ||
					r.status === RequirementStatus.Modified,
			) ?? [],
			'createdAt',
		);
	}, [data]);

	const inApproval = React.useMemo(() => {
		return sortObjectsByDate(
			data?.myRequirements?.filter(
				r => r.status === RequirementStatus.Approval,
			) ?? [],
			'createdAt',
		);
	}, [data]);

	const completed = React.useMemo(() => {
		return sortObjectsByDate(
			data?.myRequirements?.filter(r => r.status === RequirementStatus.Final) ??
				[],
			'approvalWorkFlow.clearingTimestamp',
			false,
		);
	}, [data]);

	React.useEffect(() => {
		if (!loading && data?.myRequirements)
			itemCountLoaded?.(
				data?.myRequirements?.filter(
					r =>
						r.status === RequirementStatus.Final ||
						r.status === RequirementStatus.Approval ||
						r.status === RequirementStatus.Modified ||
						r.status === RequirementStatus.Draft,
				).length,
			);
	}, [itemCountLoaded, data, loading]);

	const inProgressColumns = useRequirementTileColumns('inProgress');
	const inApprovalColumns = useRequirementTileColumns('inApproval');
	const completedColumns = useRequirementTileColumns('completed');

	const RequirementsList = ({items, columns}: any) => (
		<DashboardTooltipTranslationProvider>
			<EntityList
				sticky={false}
				selectionMode={0}
				items={items}
				columns={columns}
				onShouldVirtualize={() => true}
				onRenderRow={(props?: IDetailsRowProps) => (
					<a href={createHrefFromRequirement(props?.item)}>
						<DetailsRow {...(props as IDetailsRowBaseProps)} />
					</a>
				)}
			/>
		</DashboardTooltipTranslationProvider>
	);

	const visibleTiles = React.useMemo(() => {
		const tiles = [];
		if (loading) {
			tiles.push(<LoadWrapper loading={true} />);
			return tiles;
		}
		if (inProgress.length > 0) {
			tiles.push(
				<div className={classNames.dashboardActivityContainer} key='inProgress'>
					<WorkbenchHeader
						halfHeight
						title={t('ReqsInProgress')}
						items={inProgress.length}
					/>
					<div className={classNames.container} data-is-scrollable>
						<RequirementsList items={inProgress} columns={inProgressColumns} />
					</div>
				</div>,
			);
		}

		if (inApproval.length > 0) {
			tiles.push(
				<div className={classNames.dashboardActivityContainer} key='inApproval'>
					<WorkbenchHeader
						halfHeight
						title={t('ReqsInReview')}
						items={inApproval.length}
					/>
					<div className={classNames.container} data-is-scrollable>
						<RequirementsList items={inApproval} columns={inApprovalColumns} />
					</div>
				</div>,
			);
		}

		if (completed.length > 0) {
			tiles.push(
				<div className={classNames.dashboardActivityContainer} key='completed'>
					<WorkbenchHeader halfHeight title={t('ReqsInConfirmed')} />
					<div className={classNames.container} data-is-scrollable>
						<RequirementsList items={completed} columns={completedColumns} />
					</div>
				</div>,
			);
		}

		return tiles;
	}, [inProgress, inApproval, completed, loading, t, classNames]);

	return <TileContainer visibleTiles={visibleTiles} />;
};
