import {
	DefaultButton,
	IDetailsList,
	Panel,
	PanelType,
	PrimaryButton,
	Separator,
	Stack,
	Toggle,
} from '@fluentui/react';
import {
	InfinityListColumn,
	InfinityListColumnFilterOptions,
	InfinityListFilter,
} from './InfinityList';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useBoolean} from '@fluentui/react-hooks';
import {FilterItem, FilterItemDate} from './InfinityListFilterItem';
import {useCommand} from 'hooks';
import {v4 as uuidv4} from 'uuid';
import InfinityListAdvancedFilterTree from './InfinityListAdvancedFilter';

export type InfinityListFilterPanelProps = {
	sortField: string;
	sortOrder: string;
	currentView: string;
	isPanelOpened: boolean;
	columns: InfinityListColumn[];
	columnFilterOptions?: InfinityListColumnFilterOptions[];
	allListFilters: InfinityListFilter[];
	appliedFilters: InfinityListFilter[];
	listRef: React.MutableRefObject<IDetailsList | undefined>;
	openPanel: () => void;
	dismissPanel: () => void;
	setAllListFilters: (allListFilters: InfinityListFilter[]) => void;
	setAppliedFilters: (appliedFilters: InfinityListFilter[]) => void;
	applyFilter: (
		filters: InfinityListFilter[],
		sortField: string,
		sortOrder: string,
		currentView?: string,
	) => void;
};

export const InfinityListFilterPanel: React.FC<
	InfinityListFilterPanelProps
> = ({
	sortField,
	sortOrder,
	currentView,
	isPanelOpened,
	columns,
	columnFilterOptions,
	allListFilters,
	appliedFilters,
	listRef,
	openPanel,
	dismissPanel,
	setAllListFilters,
	setAppliedFilters,
	applyFilter,
}) => {
	const {t} = useTranslation('components/infinitylist');

	const [advancedSearchOn, setAdvancedSearchOn] = useState(false);

	const arrFieldValuesUnique = useMemo(
		() =>
			columns
				.filter(col => col.filterType === 'Array')
				.map(
					col =>
						(columnFilterOptions ?? []).find(x => x.key === col.key)
							?.fieldValuesUnique,
				),
		[columns, columnFilterOptions],
	);

	const filterContent = useMemo(
		() => (
			<>
				{columns
					.filter(col => col.filterType === 'Array')
					.map((col, i) => {
						return (
							<FilterItem
								key={col.key}
								col={col}
								fieldValuesUnique={arrFieldValuesUnique.at(i)}
								allListFilters={allListFilters}
								setAllListFilters={setAllListFilters}
							/>
						);
					})}
				{columns
					.filter(col => col.filterType === 'Date')
					.map((col, i) => {
						return (
							<FilterItemDate
								key={col.key}
								col={col}
								allListFilters={allListFilters}
								setAllListFilters={setAllListFilters}
							/>
						);
					})}
			</>
		),

		[allListFilters, columns, columnFilterOptions],
	);

	const onRenderFooterContent = React.useCallback(
		() => (
			<>
				<Stack horizontal tokens={{childrenGap: 10}}>
					<PrimaryButton
						text={t('ApplyFilters')}
						onClick={() => {
							applyFilter(allListFilters, sortField, sortOrder, currentView);
							setAppliedFilters([...allListFilters]);
							dismissPanel();
						}}
					/>
					<DefaultButton
						text={t('ClearFilters')}
						onClick={() => {
							listRef.current?.scrollToIndex(0);
							setAllListFilters([]);
							setAppliedFilters([]);
							applyFilter([], sortField, sortOrder, currentView);
						}}
					/>
				</Stack>
				<Separator />
			</>
		),
		[allListFilters, applyFilter, advancedSearchOn, currentView],
	);

	useCommand(
		{
			key: uuidv4(),
			priority: 1,
			iconProps: {
				iconName: 'Filter',
			},
			farCommand: true,
			onClick() {
				listRef.current?.scrollToIndex(0);
				openPanel();
			},
			ariaLabel: 'Filter',
			title: 'Filter',
		},
		[listRef.current, appliedFilters],
	);

	useCommand(
		{
			key: 'clearFilter',
			farCommand: true,
			hidden: allListFilters.length === 0,
			iconProps: {
				iconName: 'ClearFilter',
			},
			onClick() {
				listRef.current?.scrollToIndex(0);
				setAllListFilters([]);
				setAppliedFilters([]);
				applyFilter([], sortField, sortOrder, currentView);
			},
		},
		[
			listRef.current,
			applyFilter,
			allListFilters,
			sortField,
			sortOrder,
			currentView,
		],
	);

	return (
		<Panel
			type={PanelType.medium}
			isLightDismiss
			isOpen={isPanelOpened}
			closeButtonAriaLabel='Close'
			onDismiss={() => {
				setAllListFilters([]);
				dismissPanel();
			}}
			onRenderFooterContent={onRenderFooterContent}
			headerText='Filter'
			isFooterAtBottom={true}
		>
			{!advancedSearchOn && filterContent}
		</Panel>
	);
};
