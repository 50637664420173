import React, {useCallback, useMemo} from 'react';

import {FetchResult} from '@apollo/client';
import {
	Callout,
	CommandBarButton,
	IButtonStyles,
	IIconProps,
	IStyle,
	IconButton,
	Spinner,
	SpinnerSize,
} from '@fluentui/react';

import {BaselineOfBaselineListPage} from './BaselinesPage.types';
import {useBaselineListPageTranslations} from './BaselinesPage.utils';
import {getConfig} from '../../../config';
import {useGetBaseLineReqIfLazyQuery} from '../hooks/baseline.generated';
import {saveAs} from 'file-saver';

interface Props {
	text: string;
	baselineId: string;
	onlyRequirementsIds?: string[];
}

export const exportBaselineRequirementsAndDownloadFile = async (
	baselineId: string,
	onlyRequirementsIds: Array<string>,
) => {
	const config = getConfig();
	const token = getCookieValue('trace-msalAuthToken');
	try {
		const response = await fetch(
			config.REACT_APP_SERVICE_URL.replace('/graphql', '') +
				'/download/baseline',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({
					baselineId,
					onlyRequirementsIds,
				}),
			},
		);

		if (!response.ok) {
			throw new Error('Network response was not ok');
		}

		const filename = response.headers.get('Filename') ?? 'filename';

		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;

		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	} catch (error) {
		console.error('There was an error downloading the file:', error);
	}
};

export function DownloadBtn({
	text,
	baselineId,
	onlyRequirementsIds,
}: Props): JSX.Element {
	const {t} = useBaselineListPageTranslations();

	const [getBaseLineReqIF, {loading, data}] = useGetBaseLineReqIfLazyQuery();

	const styles: IButtonStyles = useMemo(() => {
		return {root: {height: '100%'}};
	}, []);

	const iconProps: IIconProps = useMemo(() => {
		return {iconName: 'Download'};
	}, []);

	return (
		<>
			<Callout
				role='alertdialog'
				target={{left: window.innerWidth - 20, top: window.innerHeight / 4}} // Place it at the right side
				hidden={!loading}
				isBeakVisible={false}
			>
				<div style={{padding: 20}}>
					{<Spinner size={SpinnerSize.medium} />}
					<p>{'Loading REQIF Data!'}</p>
				</div>
			</Callout>
			<CommandBarButton
				iconProps={iconProps}
				text={text}
				onClick={async () => {
					const queryReIF = await getBaseLineReqIF({
						variables: {
							baselineId,
							onlyRequirementsIds: onlyRequirementsIds ?? [],
						},
					});
					console.log('REQIF Data over Graphql!');
					const strDate = new Date()
						.toISOString()
						.replace('T', ' ')
						.slice(0, 10)
						.replaceAll('-', '');
					const blobReqIF = new Blob(
						[queryReIF.data?.baseLineReqIfExport ?? ''],
						{
							type: 'text',
						},
					);
					const fileName =
						strDate + 'BaseLine_ReqIf_EXPORT_' + baselineId + '.reqif';
					saveAs(blobReqIF, fileName);
				}}
				title={t('DownloadBtnTitle')}
				styles={styles}
			/>
		</>
	);
}

function getCookieValue(name: string) {
	const regex = new RegExp(`(^| )${name}=([^;]+)`);
	const match = document.cookie.match(regex);
	if (match) {
		return match[2];
	}
}
