import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVoProRegDocQueryQueryVariables = Types.Exact<{
  query: Types.Scalars['String'];
  sortField: Types.Scalars['String'];
  sortOrder: Types.Scalars['String'];
}>;


export type GetVoProRegDocQueryQuery = { __typename?: 'Query', voProRegDocQuery: { __typename?: 'VoProRegDocQueryResults', voProRegDocIds: string, voProRegDocFilterOptions: { __typename?: 'VoProRegDocFilterOptions', statusOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }>, workFlowOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }>, regulationOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }>, marketOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }>, mainKeywordOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }>, keywordOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }>, contactPersonOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }> }, voProRegDocs: Array<{ __typename?: 'RegulatoryDocument', id: string, name: string, modifiedAt?: any | null, modelYear?: number | null, dateEffective?: any | null, dateNewTypes?: any | null, dateNewRegistration?: any | null, comprehensive?: any | null, status: Types.RegulatoryDocumentStatus, workflow: { __typename?: 'Workflow', status: Types.WorkflowStatus }, markets: Array<{ __typename?: 'Market', id: string, name: string }>, mainKeywords: Array<{ __typename?: 'Keyword', id: string, name: string }>, keywords: Array<{ __typename?: 'Keyword', id: string, name: string }>, phaseOut: Array<{ __typename?: 'PhaseDate', date?: any | null, status?: string | null }>, phaseIn: Array<{ __typename?: 'PhaseDate', date?: any | null, status?: string | null }>, contactPersons: Array<{ __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }>, regulation?: { __typename?: 'Regulation', id: string, regulationNumber: string, name: string } | null }> } };

export type GetVoProRegDocNextQueryVariables = Types.Exact<{
  filteredIds: Types.Scalars['String'];
}>;


export type GetVoProRegDocNextQuery = { __typename?: 'Query', voProRegDocNext: Array<{ __typename?: 'RegulatoryDocument', id: string, name: string, modifiedAt?: any | null, modelYear?: number | null, dateEffective?: any | null, dateNewTypes?: any | null, dateNewRegistration?: any | null, comprehensive?: any | null, status: Types.RegulatoryDocumentStatus, workflow: { __typename?: 'Workflow', status: Types.WorkflowStatus }, markets: Array<{ __typename?: 'Market', id: string, name: string }>, mainKeywords: Array<{ __typename?: 'Keyword', id: string, name: string }>, keywords: Array<{ __typename?: 'Keyword', id: string, name: string }>, phaseOut: Array<{ __typename?: 'PhaseDate', date?: any | null, status?: string | null }>, phaseIn: Array<{ __typename?: 'PhaseDate', date?: any | null, status?: string | null }>, contactPersons: Array<{ __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }>, regulation?: { __typename?: 'Regulation', id: string, regulationNumber: string, name: string } | null }> };

export type GetVoProSummaryQueryVariables = Types.Exact<{
  filteredIds: Types.Scalars['String'];
}>;


export type GetVoProSummaryQuery = { __typename?: 'Query', voProRegDocNext: Array<{ __typename?: 'RegulatoryDocument', id: string, summary?: string | null, changeHistoryAuditLogs: Array<{ __typename?: 'AuditLog', createdAt: any, change: { __typename?: 'AuditLogChange', value?: any | null } }> }> };

export type GetVoProRegDocSortOrderQueryVariables = Types.Exact<{
  filteredIds: Types.Scalars['String'];
  sortField: Types.Scalars['String'];
}>;


export type GetVoProRegDocSortOrderQuery = { __typename?: 'Query', voProRegDocSortOrder: string };


export const GetVoProRegDocQueryDocument = gql`
    query GetVoProRegDocQuery($query: String!, $sortField: String!, $sortOrder: String!) {
  voProRegDocQuery(
    strQuery: $query
    strSortField: $sortField
    strSortOrder: $sortOrder
  ) {
    voProRegDocIds
    voProRegDocFilterOptions {
      statusOptions {
        optionKey
        name
      }
      workFlowOptions {
        optionKey
        name
      }
      regulationOptions {
        optionKey
        name
      }
      marketOptions {
        optionKey
        name
      }
      mainKeywordOptions {
        optionKey
        name
      }
      keywordOptions {
        optionKey
        name
      }
      contactPersonOptions {
        optionKey
        name
      }
    }
    voProRegDocs {
      id
      name
      workflow {
        status
      }
      modifiedAt
      modelYear
      markets {
        id
        name
      }
      mainKeywords {
        id
        name
      }
      keywords {
        id
        name
      }
      phaseOut {
        date
        status
      }
      phaseIn {
        date
        status
      }
      contactPersons {
        givenName
        id
        mail
        name
        surname
        userPrincipalName
      }
      dateEffective
      dateNewTypes
      dateNewRegistration
      comprehensive
      status
      regulation {
        id
        regulationNumber
        name
      }
    }
  }
}
    `;

/**
 * __useGetVoProRegDocQueryQuery__
 *
 * To run a query within a React component, call `useGetVoProRegDocQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoProRegDocQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoProRegDocQueryQuery({
 *   variables: {
 *      query: // value for 'query'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetVoProRegDocQueryQuery(baseOptions: Apollo.QueryHookOptions<GetVoProRegDocQueryQuery, GetVoProRegDocQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVoProRegDocQueryQuery, GetVoProRegDocQueryQueryVariables>(GetVoProRegDocQueryDocument, options);
      }
export function useGetVoProRegDocQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVoProRegDocQueryQuery, GetVoProRegDocQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVoProRegDocQueryQuery, GetVoProRegDocQueryQueryVariables>(GetVoProRegDocQueryDocument, options);
        }
export type GetVoProRegDocQueryQueryHookResult = ReturnType<typeof useGetVoProRegDocQueryQuery>;
export type GetVoProRegDocQueryLazyQueryHookResult = ReturnType<typeof useGetVoProRegDocQueryLazyQuery>;
export type GetVoProRegDocQueryQueryResult = Apollo.QueryResult<GetVoProRegDocQueryQuery, GetVoProRegDocQueryQueryVariables>;
export const GetVoProRegDocNextDocument = gql`
    query GetVoProRegDocNext($filteredIds: String!) {
  voProRegDocNext(strFilteredIds: $filteredIds) {
    id
    name
    workflow {
      status
    }
    modifiedAt
    modelYear
    markets {
      id
      name
    }
    mainKeywords {
      id
      name
    }
    keywords {
      id
      name
    }
    phaseOut {
      date
      status
    }
    phaseIn {
      date
      status
    }
    contactPersons {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    dateEffective
    dateNewTypes
    dateNewRegistration
    comprehensive
    status
    regulation {
      id
      regulationNumber
      name
    }
  }
}
    `;

/**
 * __useGetVoProRegDocNextQuery__
 *
 * To run a query within a React component, call `useGetVoProRegDocNextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoProRegDocNextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoProRegDocNextQuery({
 *   variables: {
 *      filteredIds: // value for 'filteredIds'
 *   },
 * });
 */
export function useGetVoProRegDocNextQuery(baseOptions: Apollo.QueryHookOptions<GetVoProRegDocNextQuery, GetVoProRegDocNextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVoProRegDocNextQuery, GetVoProRegDocNextQueryVariables>(GetVoProRegDocNextDocument, options);
      }
export function useGetVoProRegDocNextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVoProRegDocNextQuery, GetVoProRegDocNextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVoProRegDocNextQuery, GetVoProRegDocNextQueryVariables>(GetVoProRegDocNextDocument, options);
        }
export type GetVoProRegDocNextQueryHookResult = ReturnType<typeof useGetVoProRegDocNextQuery>;
export type GetVoProRegDocNextLazyQueryHookResult = ReturnType<typeof useGetVoProRegDocNextLazyQuery>;
export type GetVoProRegDocNextQueryResult = Apollo.QueryResult<GetVoProRegDocNextQuery, GetVoProRegDocNextQueryVariables>;
export const GetVoProSummaryDocument = gql`
    query GetVoProSummary($filteredIds: String!) {
  voProRegDocNext(strFilteredIds: $filteredIds) {
    id
    changeHistoryAuditLogs {
      createdAt
      change {
        value
      }
    }
    summary
  }
}
    `;

/**
 * __useGetVoProSummaryQuery__
 *
 * To run a query within a React component, call `useGetVoProSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoProSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoProSummaryQuery({
 *   variables: {
 *      filteredIds: // value for 'filteredIds'
 *   },
 * });
 */
export function useGetVoProSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetVoProSummaryQuery, GetVoProSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVoProSummaryQuery, GetVoProSummaryQueryVariables>(GetVoProSummaryDocument, options);
      }
export function useGetVoProSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVoProSummaryQuery, GetVoProSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVoProSummaryQuery, GetVoProSummaryQueryVariables>(GetVoProSummaryDocument, options);
        }
export type GetVoProSummaryQueryHookResult = ReturnType<typeof useGetVoProSummaryQuery>;
export type GetVoProSummaryLazyQueryHookResult = ReturnType<typeof useGetVoProSummaryLazyQuery>;
export type GetVoProSummaryQueryResult = Apollo.QueryResult<GetVoProSummaryQuery, GetVoProSummaryQueryVariables>;
export const GetVoProRegDocSortOrderDocument = gql`
    query GetVoProRegDocSortOrder($filteredIds: String!, $sortField: String!) {
  voProRegDocSortOrder(strFilteredIds: $filteredIds, strSortField: $sortField)
}
    `;

/**
 * __useGetVoProRegDocSortOrderQuery__
 *
 * To run a query within a React component, call `useGetVoProRegDocSortOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoProRegDocSortOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoProRegDocSortOrderQuery({
 *   variables: {
 *      filteredIds: // value for 'filteredIds'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetVoProRegDocSortOrderQuery(baseOptions: Apollo.QueryHookOptions<GetVoProRegDocSortOrderQuery, GetVoProRegDocSortOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVoProRegDocSortOrderQuery, GetVoProRegDocSortOrderQueryVariables>(GetVoProRegDocSortOrderDocument, options);
      }
export function useGetVoProRegDocSortOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVoProRegDocSortOrderQuery, GetVoProRegDocSortOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVoProRegDocSortOrderQuery, GetVoProRegDocSortOrderQueryVariables>(GetVoProRegDocSortOrderDocument, options);
        }
export type GetVoProRegDocSortOrderQueryHookResult = ReturnType<typeof useGetVoProRegDocSortOrderQuery>;
export type GetVoProRegDocSortOrderLazyQueryHookResult = ReturnType<typeof useGetVoProRegDocSortOrderLazyQuery>;
export type GetVoProRegDocSortOrderQueryResult = Apollo.QueryResult<GetVoProRegDocSortOrderQuery, GetVoProRegDocSortOrderQueryVariables>;