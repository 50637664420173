import {EntityList, EntityListColumn} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {User, UserRole} from 'types';
import {NotificationTooltipTranslationProvider} from 'features/localizedTooltips';
import {HeaderForPagesWithEntityPage} from 'components/HeaderForPagesWithEntityPage';
import {renderArrayField} from 'components/EntityList/ColumnRenderers';
import useGetNotificationUsers, {
	MockedNotificationType,
} from './hooks/useGetNotificationUsers';
import {useEditEntityCmd} from 'hooks/useEditEntityCmd';
import {useSelection} from 'hooks';
import AdminNotificatonEditKeywordForm from './AdminNotificatonEditKeywordForm';

const AdminNotificationPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminNotifications',
	});
	const {loading, data} = useGetNotificationUsers();
	const [editFormOpen, setEditFormOpen] = useState(false);

	const columns: EntityListColumn[] = useMemo(
		() => [
			{
				key: 'name',
				fieldName: 'name',
				name: t('Notification'),
				minWidth: 200,
				targetWidthProportion: 1,
			},
			{
				key: 'users',
				fieldName: 'users',
				name: t('Users'),
				minWidth: 600,
				targetWidthProportion: 3,
				onRender: renderArrayField(),
			},
		],
		[],
	);

	const [selection] = useSelection<MockedNotificationType>({
		getKey: item => item.id,
	});

	useEditEntityCmd(
		{
			key: 'edit',
			onClick() {
				// Currently we support only Edit Keyword Form
				setEditFormOpen(true);
			},
			disabled: selection?.count === 0,
		},
		[selection?.count],
	);

	const onKeywordFormSave = (users: User[]) => {
		//TODO: handle save here
		console.log(
			`>> Keyword subscriber list saved. User list is ${users
				.map(u => u.name)
				.join(',')}`,
		);
	};

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<HeaderForPagesWithEntityPage>
				<h3>{t('Notifications')}</h3>
			</HeaderForPagesWithEntityPage>
			<AdminNotificatonEditKeywordForm
				data={data[0]}
				isOpen={editFormOpen}
				onClose={() => setEditFormOpen(false)}
				onConfirm={onKeywordFormSave}
			/>
			<LoadWrapper loading={loading}>
				<NotificationTooltipTranslationProvider>
					<EntityList
						items={data}
						columns={columns}
						selection={selection}
						hideExcelButton={true}
					/>
				</NotificationTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminNotificationPage;
