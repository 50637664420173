import React, {useMemo, useCallback, useState, useContext} from 'react';
import {
	FontIcon,
	CommandBarButton,
	IButtonStyles,
	IIconProps,
	TooltipHost,
} from '@fluentui/react';
import {ActionButton} from '@fluentui/react';
import {useParagraphsListContext} from '../ParagraphsList.context';
import {useEntityListContext} from '../../../../../components/EntityList/EntityListContext';
import {useTranslation} from 'react-i18next';
import {RegulatoryDocument} from 'types';
import {useGetDocumentUriLazyQuery} from '../hooks/documentURI.generated';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

interface IFictiousSegment {
	text: string;
	item?: any;
}

export const FictiousSegment: React.FC<IFictiousSegment> = props => {
	const buttonIcon: IIconProps = {
		iconName: props.text.startsWith('VKO_PDF_DISMANTLED_TRUE_REQIF')
			? 'FileSymlink'
			: 'PDF',
	};
	const {t} = useTranslation();

	const {setShowPDFDialog, setUriPDFDoc} = useParagraphsListContext();
	const [uriPDFDocInternal, setUriPDFDocInternal] = useState<string>('');

	const [getDocumentURI, {loading, error, data}] = useGetDocumentUriLazyQuery();

	const {pageDetails} = useEntityListContext();
	const navigate = useNavigate();
	const location = useLocation();

	const strLocationTrue = location.pathname
		.split('/')
		.filter(x => x !== '')
		.join('/');
	const {
		regulationId,
		regulatoryDocumentId,
		paragraphId,
		requirementId,
		modelSeries,
		generation,
		id,
	} = useParams();

	const strCommandText = useMemo(() => {
		return (
			' ' +
			t(
				props.text.startsWith('VKO_PDF_DISMANTLED_TRUE_REQIF')
					? 'DismantledLink'
					: 'ViewPdf',
				{
					ns: 'features/regulatorydocuments',
					keyPrefix: 'RegulatoryDocumentDetailsPage',
				},
			) +
			'\n'
		);
	}, [props]);

	const boolVersionsPage = useMemo(() => {
		return (
			strLocationTrue ===
				'regulations/' +
					regulationId +
					'/' +
					regulatoryDocumentId +
					'/paragraphs' ||
			strLocationTrue === 'regulatoryDocuments/' + regulatoryDocumentId ||
			strLocationTrue === 'regulatorydocuments/' + regulatoryDocumentId
		);
	}, [strLocationTrue]);

	const boolOtherValidPages = useMemo(() => {
		return (
			strLocationTrue === 'requirements/' + requirementId ||
			strLocationTrue ===
				'vehicleProjects/' +
					encodeURIComponent(modelSeries ?? '') +
					'/' +
					encodeURIComponent(generation ?? '') ||
			strLocationTrue === 'simulations/' + id
		);
	}, [strLocationTrue]);

	const boolInsideParagraphContext = setUriPDFDoc !== undefined;

	const handleClick = useCallback(async () => {
		if (props.text.startsWith('VKO_PDF_DISMANTLED_FALSE')) {
			if (uriPDFDocInternal === '') {
				if (boolVersionsPage) {
					const queryData = await getDocumentURI({
						variables: {regulatoryDocumentId: regulatoryDocumentId!},
					});
					setUriPDFDocInternal(
						queryData.data?.regulatoryDocument?.document?.uri,
					);
					setUriPDFDoc(queryData.data?.regulatoryDocument?.document?.uri);
					setShowPDFDialog(true);
				} else if (boolOtherValidPages) {
					const doc = props.item?.parent as RegulatoryDocument;

					const queryData = await getDocumentURI({
						variables: {regulatoryDocumentId: doc.id},
					});
					setUriPDFDocInternal(
						queryData.data?.regulatoryDocument?.document?.uri,
					);
					setUriPDFDoc(queryData.data?.regulatoryDocument?.document?.uri);
					setShowPDFDialog(true);
				} else {
					setShowPDFDialog(false);
				}
			} else {
				setUriPDFDoc(uriPDFDocInternal);
				setShowPDFDialog(true);
			}
		} else {
			navigate(
				`/regulations/${props.item?.parent?.regulation?.id}/${props.item?.parent?.id}/paragraphs`,
			);
		}
	}, [props, pageDetails]);

	return (
		<>
			<p>
				{props.text.startsWith('VKO_PDF_DISMANTLED_FALSE')
					? t('DismantledFalse', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
					  })
					: t('DismantledTrue', {
							ns: 'features/regulatorydocuments',
							keyPrefix: 'RegulatoryDocumentDetailsPage',
					  })}
			</p>
			{!(
				boolVersionsPage &&
				props.text.startsWith('VKO_PDF_DISMANTLED_TRUE_REQIF')
			) &&
				boolInsideParagraphContext &&
				(boolVersionsPage || boolOtherValidPages) && (
					<ActionButton
						iconProps={buttonIcon}
						onClick={e => {
							e.stopPropagation();
							handleClick();
						}}
						text={strCommandText}
					></ActionButton>
				)}
		</>
	);
};
