import {useCommand} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {CreateQuestionDialog} from './CreateQuestionDialog';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import {UserRole} from 'types';
import {FaqPanelMode} from './FaqPanel';

export const FaqCommand = (props: {
	faqPanelMode: FaqPanelMode;
	parentId?: string;
}) => {
	const {t} = useTranslation('common/faq');
	const [showQuestionDialog, setShowQuestionDialog] = React.useState(false);
	const {selectedItems} = useEntityContext<any>();

	useCommand(
		{
			key: 'PostQuestionButton',
			priority: 10,
			text: t('PostQuestionButton'),
			iconProps: {iconName: 'Chat'},
			onClick() {
				setShowQuestionDialog(true);
			},
			hidden: !(isDevEnvironment() || isTestEnvironment()),
			disabled: selectedItems?.length === 0,
			roles: [UserRole.SystemAdministrator, UserRole.Vex],
		},
		[selectedItems],
	);

	return (
		<>
			{showQuestionDialog && (
				<CreateQuestionDialog
					showDialog={showQuestionDialog}
					setShowDialog={setShowQuestionDialog}
					faqPanelMode={props.faqPanelMode}
					parentId={props.parentId}
				/>
			)}
		</>
	);
};
