import {EntityList, EntityListColumn, EntityListProps} from 'components';
import React, {useCallback, useMemo, useState} from 'react';
import {ConstrainMode, IColumn, SelectionMode} from '@fluentui/react';
import {renderDate} from 'components/EntityList/ColumnRenderers';
import {Baseline} from 'types';

import {DownloadBtn} from './DownloadBtn';
import {
	NavigateToBaselineDetailsPage,
	useNavigateToBaselineDetailsPage,
} from '../BaselineDetailsPage/BaselineDetailsPage.utils';
import {ProjectDetailsPageParams} from '../vehicleProjectPagePaths';
import {TimeCell} from './TimeCell';
import _ from 'lodash';
import {BaselineRow} from './BaselineRow';
import {entityListStylesWithAutoOverflowX} from '../entityListStyles';
import {useBaselineListPageTranslations} from './BaselinesPage.utils';
import {useSelection} from '../../../hooks';
import {DATE_TYPE} from 'components/EntityList/EntityUtils';

interface Props {
	baselineList: Array<any>;
	params: ProjectDetailsPageParams;
	setBaselines: (selectedItems: Baseline[]) => void;
}

export function BaselinesList({
	baselineList,
	params,
	setBaselines,
}: Props): JSX.Element {
	const {t: getColumnTranslation} = useBaselineListPageTranslations({
		keyPrefix: 'Columns',
	});

	const navigateToBaselineDetailsPage: NavigateToBaselineDetailsPage =
		useNavigateToBaselineDetailsPage(params);

	const sortedBaselines: Baseline[] = useMemo(() => {
		const compareDatesOfBaselines = (
			{createdAt: dateOfBaseline1}: Baseline,
			{createdAt: dateOfBaseline2}: Baseline,
		): number => {
			if (dateOfBaseline1 && dateOfBaseline2) {
				return dateOfBaseline1 >= dateOfBaseline2 ? -1 : 1;
			}

			/**
			 * This ensures that baselines without dates always show up last.
			 */
			return dateOfBaseline1 ? -1 : 1;
		};

		/**
		 * See the comparison function if you need more details about how they are
		 * sorted.
		 */
		const sortByDate = (baselineList: Baseline[]): Baseline[] => {
			const baselinesCopy: Baseline[] = _.clone(baselineList);
			return baselinesCopy.sort(compareDatesOfBaselines);
		};

		if (baselineList) return sortByDate(baselineList);
		/* We show an empty array just in case the server has an error. */
		return [];
	}, [baselineList]);

	const [sortedItems, setSortedItems] = useState(sortedBaselines);
	const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
	const [isSortedDescending, setIsSortedDescending] = useState<boolean>(false);

	const sortItems = (
		items: Baseline[],
		columnKey: string,
		isDescending: boolean,
	) => {
		const sorted = [...items].sort((a, b) => {
			const aValue = (a as any)[columnKey];
			const bValue = (b as any)[columnKey];

			// Check if the values are dates
			const aDate = new Date(aValue);
			const bDate = new Date(bValue);

			if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
				// Both values are valid dates
				if (aDate < bDate) {
					return isDescending ? 1 : -1;
				}
				if (aDate > bDate) {
					return isDescending ? -1 : 1;
				}
				return 0;
			}
			if (aValue < bValue) {
				return isDescending ? 1 : -1;
			}
			if (aValue > bValue) {
				return isDescending ? -1 : 1;
			}
			return 0;
		});
		return sorted;
	};

	const columns = useMemo((): EntityListColumn[] => {
		const renderDownloadBtn = (baseline: Baseline): JSX.Element => {
			return <DownloadBtn text={''} baselineId={baseline.id} />;
		};

		const renderTime = ({createdAt}: Baseline): JSX.Element | null => {
			return <TimeCell createdAt={createdAt} />;
		};

		const onColumnClick = (
			ev: React.MouseEvent<HTMLElement>,
			column: IColumn,
		) => {
			const {key: columnKey, isSortedDescending} = column;
			const newIsSortedDescending = !isSortedDescending;
			const sorted = sortItems(
				sortedBaselines,
				columnKey,
				newIsSortedDescending,
			);

			setSortedItems(sorted);
			setSortColumn(columnKey);
			setIsSortedDescending(newIsSortedDescending);
		};

		return [
			{
				key: 'name',
				name: getColumnTranslation('Name'),
				fieldName: 'name',
				minWidth: 200,
				isResizable: true,
				isMultiline: true,
				sortable: true,
				filterable: true,
				isSorted: sortColumn === 'Name',
				isSortedDescending,
				onColumnClick,
			},
			{
				key: 'createdAt',
				name: getColumnTranslation('createdAt'),
				fieldName: 'createdAt',
				onRender: renderDate(),
				dataType: DATE_TYPE,
				minWidth: 70,
				filterable: true,
				sortable: true,
				isSorted: sortColumn === 'createdAt',
				isSortedDescending,
				onColumnClick,
			},
			{
				key: 'Time',
				name: getColumnTranslation('Time'),
				fieldName: 'createdAt',
				onRender: renderTime,
				dataType: DATE_TYPE,
				sortable: true,
				filterable: true,
				minWidth: 50,
				isSorted: sortColumn === 'Time',
				isSortedDescending,
				onColumnClick,
			},
			{
				key: 'Download',
				name: getColumnTranslation('Download'),
				fieldName: 'Download',
				filterable: true,
				onRender: renderDownloadBtn,
				minWidth: 130,
			},
		];
	}, [getColumnTranslation, isSortedDescending, sortColumn, sortedBaselines]);

	const navigateToDetailsPageFromBaseline = useCallback(
		(baseline: Baseline): void => {
			navigateToBaselineDetailsPage(baseline.id);
		},
		[navigateToBaselineDetailsPage],
	);

	const renderRow: EntityListProps['onRenderRow'] = (props, defaultRender) => {
		return <BaselineRow defaultProps={props} defaultRender={defaultRender} />;
	};

	const getKey = (item: Baseline): Baseline['id'] => {
		return item.id;
	};

	const [selection] = useSelection<Baseline>({
		onSelectionChanged(selectedItems) {
			setBaselines(selectedItems);
		},
		getKey,
	});

	return (
		<EntityList
			selection={selection}
			columns={columns}
			items={sortedBaselines}
			constrainMode={ConstrainMode.unconstrained}
			styles={entityListStylesWithAutoOverflowX}
			selectionMode={SelectionMode.single}
			onItemInvoked={navigateToDetailsPageFromBaseline}
			onRenderRow={renderRow}
			sticky
			getKey={item => item.id}
		/>
	);
}
