import {
	Callout,
	CheckboxVisibility,
	ConstrainMode,
	DefaultButton,
	DetailsList,
	DetailsListBase,
	DetailsRow,
	IColumn,
	IconButton,
	IDetailsHeaderProps,
	IDetailsList,
	IDetailsListProps,
	IDetailsListStyles,
	IDetailsRowProps,
	IRefObject,
	IRenderFunction,
	ISelection,
	Label,
	MarqueeSelection,
	Panel,
	PanelType,
	Pivot,
	PivotItem,
	PrimaryButton,
	SelectionMode,
	Separator,
	Spinner,
	SpinnerSize,
	Stack,
	Toggle,
	useTheme,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {getRendererForHeaderWithTooltips} from '../../features/localizedTooltips/componentsWithTooltips/HeaderWithTooltipsAndStickySupport';
import {ColumnWithTooltipOptions} from 'features/localizedTooltips';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {useCommand, useSelection} from 'hooks';
import {ExcelExportButton} from './Excel/ExcelExportButton';
import {useTranslation} from 'react-i18next';
import {
	FilterBubble,
	InfinityListFilterBubbles,
} from './InfinityListFilterBubbles';
import {
	InfinityListFilterPanel,
	InfinityListFilterPanelProps,
} from './InfinityListFilter';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import {getRenderRowWithContextualMenu} from 'components/EntityList/RowWithContextualMenu/RowWithContextualMenu';
import {createHrefFromRegulation} from 'features/Regulations/components/RegulationsList/regulationsListLinks.utils';
import {CreateEntityHref} from 'components/EntityList/EntityList.types';

export type InfinityListFilterOption = {
	id: string;
	value: string;
};

export type InfinityListColumn = ColumnWithTooltipOptions & {
	sortable?: boolean;
	filterable?: boolean;
	hidden?: boolean;
	filterOnFilter?: boolean;
	filterType?: string;
	hiddenAlways?: boolean;
};

export type InfinityListView = {
	headerText: string;
	accessKey: string;
	query: string;
};

export type InfinityListColumnFilterOptions = {
	key: string;
	fieldValuesUnique: InfinityListFilterOption[];
};

export type InfinityListProps = Omit<IDetailsListProps, 'columns'> & {
	pageName: string;
	tableId?: string;
	fixedHeight?: string | number;
	groupByFieldName?: string;
	initialSortField: string;
	initialSortOrder: string;
	initialView: string;
	keyPrefix: string;
	listId?: string;
	numberOfElements: number;
	sticky?: boolean;
	showHeader?: boolean;
	stillLoading: boolean;
	initalFilters: InfinityListFilter[];
	columns: InfinityListColumn[];
	columnFilterOptions?: InfinityListColumnFilterOptions[];
	views: InfinityListView[];
	firstRender: () => void;
	loadItemsNext: (
		currentId: string,
		sortField: string,
		sortOrder: string | undefined,
	) => void;
	retrieveItemsAll: (
		sortField: string,
		sortOrder: string | undefined,
	) => Promise<any[]>;
	applyFilter: (
		filters: InfinityListFilter[],
		sortField: string,
		sortOrder: string,
		currentView?: string,
	) => void;
	applySort: (
		sortField: string,
		sortOrder: string | undefined,
		setSortOrder: (sortOrder: string) => void,
	) => void;
	handleInvokeItem: (item: any) => void;
	createHref?: (item: unknown) => string;
};

export type InfinityListFilter = {
	columnKey: string;
	selection: string;
	display: string;
	operation: string;
};

export interface InfinityListURLParams {
	[key: string]: any;
}

export type InfinityListItemWithId = {
	id: string;
};

export const InfinityList: React.FC<InfinityListProps> = ({
	pageName,
	initialSortField,
	initialSortOrder,
	showHeader,
	stillLoading,
	sticky,
	numberOfElements,
	items,
	columns,
	columnFilterOptions,
	views,
	initalFilters,
	firstRender,
	loadItemsNext,
	applyFilter,
	applySort,
	handleInvokeItem,
	createHref,
	...props
}) => {
	const {t} = useTranslation('components/infinitylist');

	const [sortField, setSortField] = useState(initialSortField ?? '');

	const [sortOrder, setSortOrder] = useState(initialSortOrder ?? 'ASC');

	const [appliedFilters, setAppliedFilters] = useState<InfinityListFilter[]>([
		...initalFilters,
	]);

	const [allListFilters, setAllListFilters] = useState<InfinityListFilter[]>([
		...initalFilters,
	]);

	const listRef = useRef<IDetailsList>();

	const {setSelectedItems} = useEntityContext<any>();

	const [selection] = useSelection<any>({
		onSelectionChanged(selectedItems) {
			setSelectedItems?.(selectedItems);
		},
	});

	// Initial load of items
	useEffect(() => {
		firstRender();
	}, []);

	const onColumnClick = useCallback(
		(
			_event: React.MouseEvent<HTMLElement>,
			column: InfinityListColumn,
		): void => {
			if (column.isSorted) {
				const sortOrderNew = column.isSortedDescending ? 'ASC' : 'DESC';
				applySort(column.key, sortOrderNew, setSortOrder);
				listRef.current?.scrollToIndex(0);
			} else if (column.sortable) {
				setSortField(column.key);
				applySort(column.key, undefined, setSortOrder);
				listRef.current?.scrollToIndex(0);
			}
		},
		[applySort, sortField, sortOrder],
	);

	const itemsInfinity = useMemo(() => {
		return items;
	}, [items]);

	const columnsInfinity = useMemo(
		() =>
			columns
				.filter(x => x.hiddenAlways !== true)
				.map(x => {
					return {
						...x,
						onColumnClick,
						isSorted: sortField === x.key,
						isSortedDescending: sortOrder === 'DESC',
						showSortIconWhenUnsorted: x.sortable,
					};
				}),
		[columns, onColumnClick],
	);

	const {
		stylesInfinity,

		checkboxVisibilityInfinity,

		constrainModeInfinity,
	} = useMemo(() => {
		const stylesInfinity = props.fixedHeight
			? getFixedHeightListStyles(props.fixedHeight)
			: props.styles;

		const checkboxVisibilityInfinity =
			props.checkboxVisibility ?? props.selectionMode === SelectionMode.multiple
				? CheckboxVisibility.always
				: CheckboxVisibility.onHover;

		const constrainModeInfinity =
			props.constrainMode || ConstrainMode.unconstrained;

		return {stylesInfinity, checkboxVisibilityInfinity, constrainModeInfinity};
	}, [
		props.fixedHeight,
		props.styles,
		props.checkboxVisibility,
		props.constrainMode,
	]);

	const onRowDidMount = useCallback(
		(item: any) => {
			loadItemsNext(item.id, sortField, sortOrder);
		},
		[loadItemsNext, sortField, sortOrder],
	);

	const onItemInvoked = useCallback(
		(item: any) => {
			handleInvokeItem(item);
		},
		[handleInvokeItem],
	);

	const onRenderDetailsHeader = useCallback(
		() => getRendererForHeaderWithTooltips(sticky),
		[sticky],
	);

	const onRenderRow = useMemo(
		() => getRenderRowWithContextualMenu(createHref!),
		[getRenderRowWithContextualMenu],
	);

	const getKey = useCallback(
		(item: InfinityListItemWithId) => {
			return props.keyPrefix + item.id;
		},
		[props.keyPrefix],
	);

	return (
		<div style={{marginLeft: 20, marginTop: 20}}>
			<InfinityListHeader
				pageName={pageName}
				views={views}
				showHeader={showHeader}
				sortField={sortField}
				sortOrder={sortOrder}
				initialView={props.initialView ?? 'STD'}
				columns={columns}
				columnFilterOptions={columnFilterOptions}
				appliedFilters={appliedFilters}
				allListFilters={allListFilters}
				listRef={listRef}
				setAppliedFilters={setAppliedFilters}
				setAllListFilters={setAllListFilters}
				applyFilter={applyFilter}
			/>
			<InfinityListCallout stillLoading={stillLoading} />
			<InfinityListNumberElementsCommand numberOfElements={numberOfElements} />
			{numberOfElements > 0 ? (
				<MarqueeSelection
					selection={props.selection as ISelection}
					isDraggingConstrainedToRoot={true}
					isEnabled={props.selectionMode === SelectionMode.multiple}
				>
					<DetailsList
						getKey={getKey}
						selection={selection}
						selectionMode={props.selectionMode}
						items={itemsInfinity}
						columns={columnsInfinity}
						onRowDidMount={onRowDidMount}
						onItemInvoked={onItemInvoked}
						useReducedRowRenderer={true}
						onRenderDetailsHeader={onRenderDetailsHeader()}
						styles={stylesInfinity}
						checkboxVisibility={checkboxVisibilityInfinity}
						onRenderRow={onRenderRow}
						constrainMode={constrainModeInfinity}
					/>
				</MarqueeSelection>
			) : (
				<p>{t('NoResult')}</p>
			)}
		</div>
	);
};

type InfinityListCalloutProps = {
	stillLoading: boolean;
};

const InfinityListCallout: React.FC<InfinityListCalloutProps> = ({
	stillLoading,
}) => {
	const {t} = useTranslation('components/infinitylist');
	return (
		<Callout
			role='alertdialog'
			target={{left: window.innerWidth - 20, top: window.innerHeight / 4}} // Place it at the right side
			hidden={!stillLoading}
			isBeakVisible={false}
		>
			<div style={{padding: 20}}>
				{<Spinner size={SpinnerSize.medium} />}
				<p>{t('Loading')}</p>
			</div>
		</Callout>
	);
};

type InfinityListNumberElementsCommandProps = {
	numberOfElements: number;
};

const InfinityListNumberElementsCommand: React.FC<
	InfinityListNumberElementsCommandProps
> = ({numberOfElements}) => {
	const {t} = useTranslation('components/infinitylist');
	const theme = useTheme();
	useCommand(
		{
			key: uuidv4(),
			farCommand: true,
			priority: 1000,
			commandBarButtonAs(): JSX.Element {
				return (
					<div
						style={{
							paddingTop: 12,
							paddingRight: 20,
							paddingLeft: 20,
							background: theme.palette.neutralLight,
						}}
					>
						{numberOfElements ?? 0}&nbsp;{t('Elements')}
					</div>
				);
			},
		},
		[numberOfElements],
	);
	return <></>;
};

type InfinityListHeaderProps = {
	pageName: string;
	views: InfinityListView[];
	showHeader?: boolean;
	sortField: string;
	sortOrder: string;
	initialView: string;
	columns: InfinityListColumn[];
	columnFilterOptions?: InfinityListColumnFilterOptions[];
	allListFilters: InfinityListFilter[];
	appliedFilters: InfinityListFilter[];
	listRef: React.MutableRefObject<IDetailsList | undefined>;
	setAppliedFilters: (appliedFilters: InfinityListFilter[]) => void;
	setAllListFilters: (allListFilters: InfinityListFilter[]) => void;
	applyFilter: (
		filters: InfinityListFilter[],
		sortField: string,
		sortOrder: string,
		currentView?: string,
	) => void;
};

const InfinityListHeader: React.FC<InfinityListHeaderProps> = ({
	pageName,
	views,
	showHeader,
	sortField,
	sortOrder,
	appliedFilters,
	applyFilter,
	...props
}) => {
	const [currentViewKey, setCurrentViewKey] = useState<string | undefined>(
		props.initialView,
	);

	const [isPanelOpened, {setTrue: openPanel, setFalse: dismissPanel}] =
		useBoolean(false);

	const onLinkClick = (item?: PivotItem) => {
		setCurrentViewKey(item?.props.accessKey);
		applyFilter(appliedFilters, sortField, sortOrder, item?.props.accessKey);
	};

	return (
		<>
			{showHeader !== false && (
				<Stack
					horizontal
					tokens={{
						childrenGap: 500,
					}}
				>
					<Stack.Item>
						<div style={{paddingTop: 10}}>
							<b>{pageName}</b>
						</div>
					</Stack.Item>
					<Stack.Item>
						<Stack horizontal>
							<IconButton
								iconProps={{iconName: 'Filter'}}
								style={{paddingTop: 10}}
								onClick={() => {
									props.listRef.current?.scrollToIndex(0);
									openPanel();
								}}
							/>
							<Pivot
								aria-label='Views'
								onLinkClick={onLinkClick}
								defaultSelectedKey={props.initialView}
							>
								{views.map((v, i) => (
									<PivotItem
										key={'view' + i}
										headerText={v.headerText}
										accessKey={v.accessKey}
										itemKey={v.accessKey}
										itemIcon={
											currentViewKey === v.accessKey ? 'List' : undefined
										}
									></PivotItem>
								))}
							</Pivot>
						</Stack>
					</Stack.Item>
				</Stack>
			)}
			<InfinityListFilterPanel
				isPanelOpened={isPanelOpened}
				openPanel={openPanel}
				dismissPanel={dismissPanel}
				applyFilter={applyFilter}
				appliedFilters={appliedFilters}
				sortOrder={sortOrder}
				sortField={sortField}
				currentView={currentViewKey ?? ''}
				{...props}
			/>
			<InfinityListFilterBubbles
				sortField={sortField}
				sortOrder={sortOrder}
				currentView={currentViewKey ?? ''}
				appliedFilters={appliedFilters}
				applyFilter={applyFilter}
				{...props}
			/>
		</>
	);
};

const getFixedHeightListStyles: (
	height: string | number,
) => Partial<IDetailsListStyles> = height => ({
	root: {
		overflowX: 'scroll',
		selectors: {
			'& [role=grid]': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'start',
				height,
			},
		},
	},
	headerWrapper: {
		flex: '0 0 auto',
	},
	contentWrapper: {
		flex: '1 1 auto',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
});
