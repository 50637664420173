import {
	DefaultButton,
	Dialog,
	ITextFieldProps,
	PrimaryButton,
	Stack,
	TextField,
} from '@fluentui/react';

import {Controller, useForm} from 'react-hook-form';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {ControlledDropdown} from 'components/hookForms';

import _ from 'lodash';

import {useMatkonsPropertiesData} from './hooks/useMatkonsPropertiesData';
import {
	MatkonApplicationsDocument,
	useCreateMatkonApplicationMutation,
} from './Matkon.generated';

export const CreateMatkonApplicationDialog: React.FC<any> = ({
	showDialog,
	setShowDialog,
	initQuestion = null,
}) => {
	const {t} = useTranslation('common/matkon');
	const {imdsApplications} = useMatkonsPropertiesData({t});

	const [selectedItem, setSelectedItem] = useState(initQuestion);
	const {selectedItems} = useEntityContext<any>();

	// useEntityContext is set to null after clicking on any button, panel so we need to preserve selection
	useEffect(() => {
		if (selectedItems && selectedItems.length > 0) {
			setSelectedItem(selectedItems[0]);
		}
	}, [selectedItems]);

	const [value, setValue] = useState('');

	const [createMatkonApplicationMutation] =
		useCreateMatkonApplicationMutation();

	const {
		control,
		getValues,
		reset,
		watch,
		setValue: setValFrom,
	} = useForm<any>({
		reValidateMode: 'onSubmit',
		mode: 'onBlur',
	});

	const IMDS_APPLICATION = 'imdsApplication';
	const APPLICATION = 'Application';

	type OnChange = Exclude<ITextFieldProps['onChange'], undefined>;

	const handleChange: OnChange = (_event, newValue): void => {
		setValue(newValue ?? '');
	};

	const closeDialog = () => {
		setShowDialog(false);
		setValue('');
		reset();
	};
	const postApplication = () => {
		const imdsApplicationIri = getValues(IMDS_APPLICATION);
		const description = getValues(APPLICATION);

		createMatkonApplicationMutation({
			variables: {
				input: {
					description,
					imdsApplicationIri,
				},
			},
			refetchQueries: [MatkonApplicationsDocument],
		});

		closeDialog();
	};

	const [isVex, setisVex] = useState<boolean>(false);

	return (
		<Dialog
			hidden={!showDialog}
			styles={{main: {maxWidth: '600px !important'}}}
			modalProps={{
				isBlocking: true,
				isDarkOverlay: true,
				styles: {main: {width: '750px !important'}},
			}}
			dialogContentProps={{
				title: `${t('PostQuestion')} ${
					(selectedItem as any)?.name || (selectedItem as any)?.enumeration
				}`,
			}}
			onDismiss={closeDialog}
		>
			<Controller
				name={APPLICATION}
				control={control}
				defaultValue=''
				render={({field}) => (
					<TextField label={'Prämisse'} required={true} {...field} />
				)}
			/>
			<ControlledDropdown
				label={`IMDS-Applikation`}
				name={IMDS_APPLICATION}
				control={control}
				options={imdsApplications}
				required={true}
				rules={{required: true}}
			/>
			<Stack
				horizontal
				horizontalAlign='end'
				tokens={{childrenGap: 5, padding: '20px 0 0 0'}}
			>
				<Stack.Item>
					<PrimaryButton
						onClick={postApplication}
						ariaLabel={'ConfirmDialogButton'}
						disabled={
							watch(IMDS_APPLICATION)?.length === 0 || watch(APPLICATION) === ''
						}
					>
						{t('Confirm')}
					</PrimaryButton>
				</Stack.Item>
				<Stack.Item>
					<DefaultButton onClick={closeDialog} ariaLabel={'CancelDialogButton'}>
						{t('Cancel')}
					</DefaultButton>
				</Stack.Item>
			</Stack>
		</Dialog>
	);
};
