import React from 'react';
import {useTranslation} from 'react-i18next';
import {useUserContext} from 'authentication/UserContext';
import {ArrayValues} from 'components/EntityList/ColumnRenderers';
import {Separator, Stack, Text} from '@fluentui/react';
import {Maybe, RegulatoryDocumentParagraph} from 'types';
import {formatDateTime} from 'i18n/localeDateFormat';

export const useMetadata = () => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'CompareVersionPanel',
	});
	const {isVko, isAdmin} = useUserContext();
	const {i18n} = useTranslation();

	const renderArraySection = (arr: Maybe<any[]> | undefined, tKey: string) =>
		(arr || []).length > 0 ? (
			<Stack.Item>
				<Stack horizontal tokens={{childrenGap: 4}}>
					<Stack.Item>{`${t(tKey)}: `}</Stack.Item>
					<Stack.Item>
						<ArrayValues
							array={arr || []}
							getTextStyles={() => ({
								root: {
									marginBottom: 0,
								},
							})}
						/>
					</Stack.Item>
				</Stack>
			</Stack.Item>
		) : (
			<></>
		);

	const renderDateSection = (date: Date, tKey: string) =>
		date ? (
			<Stack.Item>
				<Stack horizontal>
					<Stack.Item>{`${t(tKey)}: `}</Stack.Item>
					<Stack.Item>
						<Text>{formatDateTime(new Date(date), i18n)}</Text>
					</Stack.Item>
				</Stack>
			</Stack.Item>
		) : (
			<></>
		);

	const renderStringSection = (val: string, tKey: string) =>
		val ? (
			<Stack.Item>
				<Stack horizontal>
					<Stack.Item>{`${t(tKey)}: `}</Stack.Item>
					<Stack.Item>
						<Text>{val}</Text>
					</Stack.Item>
				</Stack>
			</Stack.Item>
		) : (
			<></>
		);

	const renderPhase = (
		val: Maybe<{date?: Date; status?: Maybe<string>}[]> | undefined,
		tKey: string,
	) =>
		val ? (
			renderArraySection(
				val.map(phase => {
					if (!phase.date) {
						return undefined;
					}
					return phase.status
						? `${formatDateTime(new Date(phase.date), i18n)} (${phase.status})`
						: `${formatDateTime(new Date(phase.date), i18n)}`;
				}),
				tKey,
			)
		) : (
			<></>
		);

	const isVkoOrAdmin = React.useMemo(() => isVko || isAdmin, [isVko, isAdmin]);

	const renderMetadata = (paragraph: RegulatoryDocumentParagraph) => (
		<>
			{(paragraph.vehicleCategories.length > 0 ||
				paragraph.keywords.length > 0 ||
				paragraph.categories.length > 0 ||
				paragraph.driveVariants.length > 0) && <Separator />}
			<Stack>
				{isVkoOrAdmin && (
					<>
						{renderArraySection(
							paragraph.vehicleCategories,
							'VehicleCategories',
						)}
						{renderArraySection(paragraph.keywords, 'Keywords')}
						{renderArraySection(paragraph.categories, 'Categories')}
						{renderArraySection(paragraph.driveVariants, 'DriveVariants')}

						{renderDateSection(paragraph.dateNewTypes, 'NewTypes')}
						{renderDateSection(
							paragraph.dateNewRegistration,
							'NewRegistration',
						)}
						{renderDateSection(paragraph.comprehensive, 'Implementation')}
						{paragraph.modelYear &&
							renderStringSection(String(paragraph.modelYear), 'ModelYear')}

						{renderPhase(paragraph.phaseIn, 'PhaseIn')}
						{renderPhase(paragraph.phaseOut, 'PhaseOut')}
						{renderArraySection(
							paragraph.keywordAssignments?.map(ka => ka.keyword.name),
							'VexClusters',
						)}
						{renderArraySection(
							paragraph.attachments?.map(a => a.attachmentId),
							'Attachments',
						)}

						{paragraph.summary &&
							renderStringSection(String(paragraph.summary), 'Comment')}
					</>
				)}
				{renderArraySection(paragraph.tags, 'Tags')}
				{renderArraySection(paragraph.requirements, 'Requirements')}
			</Stack>
		</>
	);

	return {renderMetadata};
};
