import {PanelType, PersonaSize, SelectionMode} from '@fluentui/react';

import React, {useMemo, useState} from 'react';

import {ConvoluteType, Requirement, RequirementStatus} from 'types';
import {
	InfinityList,
	InfinityListColumn,
	InfinityListColumnFilterOptions,
	InfinityListFilter,
	InfinityListItemWithId,
	InfinityListURLParams,
	InfinityListView,
} from 'components/InfinityList';
import {CellWithEntityLink} from 'components/EntityList/CellWithEntityLink';
import {
	renderArrayField,
	renderAttachments,
	renderDate,
	renderFaqs,
	renderPersona,
	renderReferenceField,
	renderRequirementCategory,
	renderRequirementStatus,
	renderRichtext,
} from 'components/EntityList/ColumnRenderers';
import {useTranslation} from 'react-i18next';
import {v4 as uuidv4} from 'uuid';
import {
	useGetRequirementsNextLazyQuery,
	useGetRequirementsQueryLazyQuery,
	useGetRequirementsSortOrderLazyQuery,
} from './hooks/requirementsInfinity.generated';
import {
	createSearchParams,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import {createHrefFromRequirement} from './requirementLinks.utils';
import {useRequirementsTranslation} from './hooks/useRequirementsTranslation';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';
import {useUserContext} from 'authentication/UserContext';
import {useRequirementsVersions} from './hooks/useRequirementsVersions';
import {useCloneRequirement} from './hooks/useCloneRequirement';
import {
	useCreateRequirementMutation,
	useDeleteRequirementMutation,
} from './hooks/requirements.generated';
import {useGetConvolutesQuery} from 'features/AdminSection/hooks/convolutes.generated';
import {useGetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';
import {RequirementUtilsService} from './RequirementUtils.service';
import {
	UpdateRequirement,
	UpdateRequirementAttachments,
	useRequirementAttachmentsUpdater,
	useRequirementsFormElementsRenderer,
	useRequirementUpdater,
} from './hooks/requirementFormHooks';
import {EntityPage} from 'components';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';
import {InfinityPage} from 'components/InfinityPage/InfinityPage';
import {RequirementFromListPage} from './requirementPage.types';
import {RequirementsTooltipTranslationProvider} from './RequirementsTooltipTranslationProvider';
import {NewRequirementVersionPanel} from './components';
import {FaqCommand} from 'components/Faq/FaqCommand';
import {FaqPanelMode} from 'components/Faq/FaqPanel';
import {DEFAULT_DATE} from 'components/EntityList/EntityUtils';
import {RequirementsPageRequirementForm} from './components/RequirementsPageRequirementForm';

interface DataStoreRequirement {
	[key: string]: Requirement;
}

interface DataStoreSortOrders {
	[key: string]: string;
}

interface IFilters2Link {
	(allFilters: InfinityListFilter[]): InfinityListURLParams;
}

interface IFilters2MongoQuery {
	(allFilters: InfinityListFilter[]): string;
}
interface ILink2MongoQuery {
	(searchParams: URLSearchParams): string;
}

interface ILink2Filter {
	(searchParams: URLSearchParams): InfinityListFilter[];
}

interface IDecodeHex24 {
	(x: string): string;
}

const sortInvertedFields = ['modifiedAt'];

const sortFields = [
	'name',
	'requirementId',
	'markets',
	'status',
	'modifiedAt',
	'category',
];

const arrayFields = [
	'vexClusters',
	'regulations',
	'regulatoryDocuments',
	'systemLevels',
	'markets',
	'keywords',
	'tags',
	'createdBy',
];

const dateFilterFields = ['modifiedAt'];

const arrayFieldMongoDb = [
	'vexClusterRefs._id',
	'requirementParagraphRefs.regulationRef._id',
	'requirementParagraphRefs.regdocRef._id',
	'systemLevelRefs._id',
	'marketRefs._id',
	'keywordRefs._id',
	'tagRefs._id',
	'createdById',
];

const enumFields = ['status', 'category'];

const enumFieldsMongoDb = ['status', 'category'];

const initialSortField = 'name';
const initialSortOrder = 'ASC';
const intBatchSize = 10;

const funcFilters2Link: IFilters2Link = allFilters => {
	const dataURL: InfinityListURLParams = {};

	allFilters
		.filter(x => x.columnKey === 'name')
		.forEach(x => {
			const linkField = 'id';
			if (dataURL[linkField] === undefined) {
				dataURL[linkField] = [x.selection];
			} else {
				dataURL[linkField].push(x.selection);
			}
		});

	allFilters
		.filter(x => arrayFields.includes(x.columnKey))
		.forEach(x => {
			const linkField = arrayFields[arrayFields.indexOf(x.columnKey)];
			if (dataURL[linkField] === undefined) {
				dataURL[linkField] = [x.selection];
			} else {
				dataURL[linkField].push(x.selection);
			}
		});

	allFilters
		.filter(x => enumFields.includes(x.columnKey))
		.forEach(x => {
			const linkField = enumFields.find(y => y === x.columnKey)!;
			if (dataURL[linkField] === undefined) {
				dataURL[linkField] = [x.selection];
			} else {
				dataURL[linkField].push(x.selection);
			}
		});

	allFilters
		.filter(x => dateFilterFields.includes(x.columnKey))
		.forEach(x => {
			const linkField = x.columnKey;
			const value = `${x.operation}_${x.selection.slice(0, 10)}`;
			if (dataURL[linkField] === undefined) {
				dataURL[linkField] = [value];
			} else {
				dataURL[linkField].push(value);
			}
		});

	return dataURL;
};

const funcLink2Filter: ILink2Filter = searchParams => {
	const result: InfinityListFilter[] = [];

	searchParams.getAll('id').forEach(x2 =>
		result.push({
			columnKey: 'name',
			selection: x2,
			display: 'UNKNOWN',
			operation: 'eq',
		}),
	);

	arrayFields
		.filter(x1 => searchParams.getAll(x1).length > 0)
		.forEach(x1 => {
			searchParams.getAll(x1).forEach(x2 =>
				result.push({
					columnKey: x1,
					selection: x2,
					display: 'UNKNOWN',
					operation: 'eq',
				}),
			);
		});

	enumFields
		.filter(x1 => searchParams.getAll(x1).length > 0)
		.forEach(x1 => {
			searchParams.getAll(x1).forEach(x2 =>
				result.push({
					columnKey: x1,
					selection: x2,
					display: 'UNKNOWN',
					operation: 'eq',
				}),
			);
		});

	dateFilterFields
		.filter(x1 => searchParams.getAll(x1).length > 0)
		.forEach(x1 => {
			searchParams.getAll(x1).forEach(x2 =>
				result.push({
					columnKey: x1,
					selection: x2.split('_').at(1) ?? DEFAULT_DATE,
					display:
						(x2.split('_').at(0) === 'gte' ? '>' : '') +
						(x2.split('_').at(0) === 'lte' ? '<' : '') +
						(x2.split('_').at(1) ?? DEFAULT_DATE),
					operation: x2.split('_').at(0) ?? 'gte',
				}),
			);
		});
	return result;
};

const funcFilters2MongoQuery: IFilters2MongoQuery = allFilters => {
	const arrQueryStrings: string[] = [];

	const lstIdOptions = allFilters
		.filter(x2 => x2.columnKey === 'name')
		.map(x2 => `ObjectId('${x2.selection}')`);

	if (lstIdOptions.length > 0) {
		arrQueryStrings.push(`_id:{$in:[${lstIdOptions}]}`);
	}

	arrayFields
		.map((x1, index) => {
			const lstOptions = allFilters
				.filter(x2 => x2.columnKey === x1)
				.map(x2 => `'${x2.selection}'`);

			if (lstOptions.length === 0) {
				return '';
			}
			return `'${arrayFieldMongoDb.at(index)}':{$in:[${lstOptions}]}`;
		})
		.filter(x1 => x1 !== '')
		.forEach(x1 => arrQueryStrings.push(x1));

	enumFields
		.map((x1, index) => {
			const lstOptions = allFilters
				.filter(x2 => x2.columnKey === x1)
				.map(x2 => `${x2.selection}`);
			if (lstOptions.length === 0) {
				return '';
			}
			return `'${enumFieldsMongoDb.at(index)}':{$in:[${lstOptions}]}`;
		})
		.filter(x1 => x1 !== '')
		.forEach(x1 => arrQueryStrings.push(x1));

	dateFilterFields
		.map((x1, i1) => {
			const dateFrom = allFilters.find(
				x2 => x2.columnKey === x1 && x2.operation === 'gte',
			)?.selection;
			const dateTo = allFilters.find(
				x2 => x2.columnKey === x1 && x2.operation === 'lte',
			)?.selection;
			if (!(dateFrom || dateTo)) {
				return '';
			}

			let rangeCond = '';
			if (dateFrom && dateTo) {
				rangeCond = `$gte: ISODate('${dateFrom.slice(
					0,
					10,
				)}') , $lte: ISODate('${dateTo.slice(0, 10)}')`;
			} else if (dateFrom) {
				rangeCond = `$gte: ISODate('${dateFrom.slice(0, 10)}')`;
			} else if (dateTo) {
				rangeCond = `$lte: ISODate('${dateTo.slice(0, 10)}')`;
			}

			return `${x1}:{${rangeCond}}`;
		})
		.filter(x1 => x1 !== '')
		.forEach(x1 => arrQueryStrings.push(x1));

	return `{ ${arrQueryStrings.join(',')} }`;
};

const funcDecodeHex24: IDecodeHex24 = x => {
	return atob(x).split('\n')[1].slice(1);
};

const RequirementsPage: React.FC = () => {
	const {t} = useRequirementsTranslation();

	const {t: tStatus} = useTranslation('common/enums', {
		keyPrefix: 'RequirementStatus',
	});
	const {t: tCategory} = useTranslation('common/enums', {
		keyPrefix: 'RequirementCategory',
	});
	const {t: tTitle} = useTranslation('appShell/sidenavigation', {
		keyPrefix: 'SideNavigation',
	});

	const {t: tViews} = useTranslation('features/requirements', {
		keyPrefix: 'Views',
	});

	const {t: tFav} = useTranslation('appShell/sidenavigation', {
		keyPrefix: 'SideNavigation',
	});

	const [keyPrefix, setKeyPrefix] = useState(uuidv4());

	const [lastFetchedId, setlastFetchedId] = useState('');

	const [cursor, setCursor] = useState(0);

	const [dsRequirement, setDSRequirement] = useState<DataStoreRequirement>({});

	const [dsSortOrders, setDSSortOrders] = useState<DataStoreSortOrders>({});

	const [arrRequirement, setArrRequirement] = useState<Requirement[]>([]);

	const [getSortOrder, {loading: loadingSortOrder, data: dataSortOrder}] =
		useGetRequirementsSortOrderLazyQuery();

	const [getRequirementsQuery, {loading: loadingQuery, data: dataQuery}] =
		useGetRequirementsQueryLazyQuery();

	const [
		getRequirementsNext,
		{loading: loadingDocuments, data: dataDocuments},
	] = useGetRequirementsNextLazyQuery();

	const [searchParams] = useSearchParams();

	const navigate = useNavigate();

	const {isAdmin, isBedarfskoordinator, myVexClusters, favoriteIds} =
		useUserContext();

	const views: InfinityListView[] = useMemo(() => {
		const viewsTemp = [
			{headerText: tViews('STD'), accessKey: 'STD', query: `{status:2}`},
		];
		if (myVexClusters.length > 0) {
			const myVexClusterIds = myVexClusters.map(
				x => `'${funcDecodeHex24(x.id)}'`,
			);
			viewsTemp.push({
				headerText: tViews('MYVC'),
				accessKey: 'MYVC',
				query: `{'vexClusterRefs._id':{$in:[${myVexClusterIds.join(
					',',
				)}]}, status: {$nin: [4,5]} }`,
			});
		}
		if (isAdmin || isBedarfskoordinator) {
			viewsTemp.push({
				headerText: tViews('INTERIM'),
				accessKey: 'INTERIM',
				query: `{status: {$in: [4,5]}}`,
			});
		}

		if (favoriteIds) {
			const favoriteRegIds = Object.keys(favoriteIds).filter(
				x => atob(x).split('\n')[0] === 'Requirement',
			);
			if (favoriteRegIds.length > 0) {
				const queryIds = favoriteRegIds
					.map(x => `ObjectId('${funcDecodeHex24(x)}')`)
					.join(',');
				viewsTemp.push({
					headerText: tFav('Favorites'),
					accessKey: 'FAV',
					query: `{_id:{$in:[${queryIds}]}}`,
				});
			}
		}

		return viewsTemp;
	}, [isAdmin, isBedarfskoordinator, myVexClusters, favoriteIds]);

	const initialView = 'STD';

	const {queryLink, linksFilters} = useMemo(() => {
		const linksFilters = funcLink2Filter(searchParams);
		const queryLink =
			linksFilters.length > 0 ? funcFilters2MongoQuery(linksFilters) : '{}';

		return {queryLink, linksFilters};
	}, [searchParams]);

	const {
		formDataResult: {data: formData, loading: formDataLoading},
		renderFormElements,
	} = useRequirementsFormElementsRenderer<Requirement>();

	// *functions for summarizing prop functions --------------------------------------------------------------------------------------
	const getSortIds = async (lstFilteredIds: string[], sortField: string) => {
		const boolCanSortLocally =
			lstFilteredIds.filter(x => dsRequirement[x] !== undefined).length ===
			lstFilteredIds.length;

		const boolSortisCalculated =
			dsSortOrders[sortField] !== undefined &&
			dsSortOrders[sortField].length > 0;

		if (boolSortisCalculated) {
			return dsSortOrders[sortField].split(';');
		}

		const filteredIds = lstFilteredIds.join(';');
		const {data: dataSortOrder} = await getSortOrder({
			variables: {filteredIds, sortField},
		});
		if (dataSortOrder?.requirementsSortOrder) {
			dsSortOrders[sortField] = dataSortOrder?.requirementsSortOrder;
			setDSSortOrders({...dsSortOrders});
		}

		return dataSortOrder?.requirementsSortOrder.split(';') ?? lstFilteredIds;
	};

	const getSortOrderDefault = (sortField: string) => {
		return sortInvertedFields.includes(sortField) ? 'DESC' : 'ASC';
	};

	const getSortDirectionServer = (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		return (sortInvertedFields.includes(sortField) && sortOrder === 'ASC') ||
			(!sortInvertedFields.includes(sortField) && sortOrder === 'DESC')
			? 'DESC'
			: 'ASC';
	};

	const getSortInverted = (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		return (
			(sortInvertedFields.includes(sortField) && sortOrder === 'ASC') ||
			(!sortInvertedFields.includes(sortField) && sortOrder === 'DESC')
		);
	};

	const getIdsNext = async (
		arrIds: string[],
		sortField: string,
		sortOrderEff: string,
		start: number,
		end: number,
	) => {
		const arrSortIds = await getSortIds(arrIds, sortField);

		if (getSortInverted(sortField, sortOrderEff)) {
			arrSortIds.reverse();
		}

		return arrSortIds.slice(start, end);
	};

	const fetchServerNextData = async (arrIdsNext: string[]) => {
		const arrIdsToFetch = arrIdsNext.filter(
			x => dsRequirement[x] === undefined,
		);

		if (arrIdsToFetch.length > 0) {
			const dsRequirementNext: DataStoreRequirement = {};

			const strIdsToFetch = arrIdsToFetch.join(';');
			const {data: dataDocumentsNext} = await getRequirementsNext({
				variables: {
					filteredIds: strIdsToFetch,
				},
			});

			(dataDocumentsNext?.requirementsNext ?? []).forEach(x => {
				dsRequirementNext[funcDecodeHex24(x.id)] = x as Requirement;
			});

			const dsRequirementNew = {
				...dsRequirement,
				...dsRequirementNext,
			};

			const arrRequirementNext = arrIdsNext
				.map(x => dsRequirementNew[x])
				.filter(x => x !== undefined);

			setDSRequirement(dsRequirementNew);

			return {dsRequirementNew, arrRequirementNext};
		}

		const arrRequirementNext = arrIdsNext
			.map(x => dsRequirement[x])
			.filter(x => x !== undefined);

		return {dsRequirement, arrRequirementNext};
	};

	// *prop functions ----------------------------------------------------------------------------------------------------------------
	const firstRender = async () => {
		// *firstRender called only once at begin
		const arrRequirementNext: Requirement[] = [];
		const dsRequirementNext: DataStoreRequirement = {};
		const dsSortOrdersNext: DataStoreSortOrders = {};

		const sortOrderServer = getSortDirectionServer(
			initialSortField,
			initialSortOrder,
		);

		const queryView =
			views.find(x => x.accessKey === (initialView ?? 'STD'))?.query ?? '{}';
		let queryFinal = '';
		if (queryLink === '{}') {
			queryFinal = queryView;
		} else if (queryView === '{}') {
			queryFinal = queryLink;
		} else {
			queryFinal = `{$and:[${queryView},${queryLink}]}`;
		}

		const {data: dataQuery} = await getRequirementsQuery({
			variables: {
				query: queryFinal,
				sortField: initialSortField,
			},
		});

		(dataQuery?.requirementsQuery.requirements ?? []).forEach(x => {
			arrRequirementNext.push(x as Requirement);
		});

		arrRequirementNext.forEach(x => {
			dsRequirementNext[funcDecodeHex24(x.id)] = x;
		});

		const lastFetchdIdNext = arrRequirementNext.at(-1)!.id;

		sortFields.forEach(x => {
			dsSortOrdersNext[x] = '';
		});
		dsSortOrdersNext[initialSortField] =
			dataQuery?.requirementsQuery.requirementIds ?? '';

		setDSRequirement(dsRequirementNext);

		setDSSortOrders(dsSortOrdersNext);

		setArrRequirement(arrRequirementNext);

		setlastFetchedId(lastFetchdIdNext);

		setCursor(arrRequirementNext.length);
	};
	const applyFilter = async (
		allFilters: InfinityListFilter[],
		sortField: string,
		sortOrder: string,
		currentView?: string,
	) => {
		const dataURL = funcFilters2Link(allFilters);
		dataURL.view = [currentView ?? 'STD'];
		const queryFilter =
			allFilters.length > 0 ? funcFilters2MongoQuery(allFilters) : '{}';

		const queryView =
			views.find(x => x.accessKey === (currentView ?? 'STD'))?.query ?? '{}';

		let queryFinal = '';
		if (queryFilter === '{}') {
			queryFinal = queryView;
		} else if (queryView === '{}') {
			queryFinal = queryFilter;
		} else {
			queryFinal = `{$and:[${queryView},${queryFilter}]}`;
		}
		navigate({
			pathname: '',
			search: createSearchParams(dataURL).toString(),
		});

		const arrRequirementNext: Requirement[] = [];
		const dsRequirementNext: DataStoreRequirement = {};
		const dsSortOrdersNext: DataStoreSortOrders = {};

		const sortOrderServer = getSortDirectionServer(
			initialSortField,
			initialSortOrder,
		);

		const {data: dataQuery} = await getRequirementsQuery({
			variables: {
				query: queryFinal,
				sortField: initialSortField,
			},
		});

		(dataQuery?.requirementsQuery.requirements ?? []).forEach(x => {
			arrRequirementNext.push(x as Requirement);
		});

		arrRequirementNext.forEach(x => {
			dsRequirementNext[x.id] = x;
		});

		const lastFetchdIdNext = arrRequirementNext.at(-1)!.id;

		dsSortOrdersNext[initialSortField] =
			dataQuery?.requirementsQuery.requirementIds ?? '';

		setDSRequirement({
			...dsRequirement,
			...dsRequirementNext,
		});

		setArrRequirement(arrRequirementNext);

		setDSSortOrders({});

		setlastFetchedId(lastFetchdIdNext);

		setCursor(arrRequirementNext.length);
		setKeyPrefix(uuidv4());
	};

	const applySort = async (
		sortField: string,
		sortOrder: string | undefined,
		setSortOrder: (sortOrder: string) => void,
	) => {
		const arrIds = (dataQuery?.requirementsQuery.requirementIds ?? '').split(
			';',
		);
		const sortOrderEff = sortOrder ? sortOrder : getSortOrderDefault(sortField);

		setSortOrder(sortOrderEff);

		const arrIdsNext = await getIdsNext(
			arrIds,
			sortField,
			sortOrderEff,
			0,
			intBatchSize,
		);

		if (arrIdsNext.length > 0) {
			const {dsRequirementNew, arrRequirementNext} = await fetchServerNextData(
				arrIdsNext,
			);

			const lastIdNext = arrRequirementNext.at(-1)!.id ?? '';

			setArrRequirement(arrRequirementNext);

			setlastFetchedId(lastIdNext);

			setCursor(arrIdsNext.length);
			setKeyPrefix(uuidv4());
		}
	};

	const loadItemsNext = async (
		currentId: string,
		sortField: string,
		sortOrder: string | undefined,
	) => {
		if (currentId === lastFetchedId) {
			// *currentId is equal to known lastFetchedId (last row has been mounted)
			// 		*ids are known -> fetch next batch -> set lastFetchdId and cursor correctly

			const arrIds = (dataQuery?.requirementsQuery.requirementIds ?? '').split(
				';',
			);
			const sortOrderEff = sortOrder
				? sortOrder
				: getSortOrderDefault(sortField);

			const intEnd = cursor + intBatchSize;

			const arrIdsNext = await getIdsNext(
				arrIds,
				sortField,
				sortOrderEff,
				cursor,
				intEnd,
			);

			if (arrIdsNext.length > 0) {
				const {dsRequirementNew, arrRequirementNext} =
					await fetchServerNextData(arrIdsNext);

				const lastIdNext = arrRequirementNext.at(-1)!.id ?? '';

				setlastFetchedId(lastIdNext);

				setCursor(intEnd);

				setArrRequirement(prevItems => [...prevItems, ...arrRequirementNext]);
			}
		}
	};

	const retrieveItemsAll = async (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		const arrIds = (dataQuery?.requirementsQuery.requirementIds ?? '').split(
			';',
		);
		const sortOrderEff = sortOrder ? sortOrder : getSortOrderDefault(sortField);
		const arrIdsNext = await getIdsNext(
			arrIds,
			sortField,
			sortOrderEff,
			0,
			arrIds.length,
		);

		const {dsRequirementNew, arrRequirementNext} = await fetchServerNextData(
			arrIdsNext,
		);

		return arrRequirementNext;
	};

	const handleViewingItem = (item: Requirement) => {
		navigate({
			pathname: `${item.id}`,
		});
	};

	const shouldShowFaq = isDevEnvironment() || isTestEnvironment();

	// *columns -----------------------------------------------------------------------------------------------------------------------
	const columns: InfinityListColumn[] = useMemo(() => {
		const tempColumns: InfinityListColumn[] = [
			{
				key: 'attachments',
				name: t('Attachments'),
				fieldName: 'attachments',
				minWidth: 20,
				maxWidth: 20,
				onRender: renderAttachments(),
				isIconOnly: true,
				iconName: 'Attach',
			},
			{
				key: 'name',
				name: t('Name'),
				fieldName: 'name',
				minWidth: 200,
				maxWidth: 250,
				isResizable: true,
				sortable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender(item: Requirement) {
					return (
						<CellWithEntityLink href={createHrefFromRequirement(item)}>
							{item.status === RequirementStatus.Interim ||
							item.status === RequirementStatus.InterimCompleted ? (
								<div style={{color: 'gray'}}>
									<i>{item.name}</i>
								</div>
							) : (
								item.name
							)}
						</CellWithEntityLink>
					);
				},
			},
			{
				key: 'requirementId',
				name: t('RequirementId'),
				fieldName: 'requirementId',
				minWidth: 75,
				maxWidth: 75,
				sortable: true,
				onRender: (item: Requirement) => (
					<CellWithEntityLink href={`/requirements/${item.id}`}>
						{item.requirementId}
					</CellWithEntityLink>
				),
			},
			{
				key: 'vexClusters',
				name: t('VexCluster'),
				fieldName: 'vexClusters',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'regulations',
				name: t('Regulations'),
				fieldName: 'regulations',
				minWidth: 150,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
				hiddenAlways: true,
			},
			{
				key: 'regulatoryDocuments',
				name: t('RegulatoryDocuments'),
				fieldName: 'regulatoryDocumentss',
				minWidth: 150,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
				hiddenAlways: true,
			},
			{
				key: 'status',
				name: t('Status'),
				fieldName: 'status',
				minWidth: 70,
				maxWidth: 70,
				sortable: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderRequirementStatus(),
			},
			{
				key: 'definition',
				name: t('Definition'),
				fieldName: 'definition',
				minWidth: 400,
				maxWidth: 550,
				isResizable: true,
				isMultiline: true,
				filterType: 'String',
				onRender: renderRichtext(),
			},
			{
				key: 'systemLevels',
				name: t('SystemLevels'),
				fieldName: 'systemLevels',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'markets',
				name: t('Markets'),
				fieldName: 'markets',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'keywords',
				name: t('Keywords'),
				fieldName: 'keywords',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'tags',
				name: t('Tags'),
				fieldName: 'tags',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'createdBy',
				name: t('CreatedBy'),
				fieldName: 'createdBy',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderPersona({size: PersonaSize.size8}),
			},
			{
				key: 'modifiedAt',
				name: t('ModifiedAt'),
				fieldName: 'modifiedAt',
				minWidth: 100,
				maxWidth: 100,
				sortable: true,
				isResizable: true,
				isMultiline: true,
				filterType: 'Date',
				onRender: renderDate(),
			},
			{
				key: 'category',
				name: t('Category'),
				fieldName: 'category',
				minWidth: 150,
				maxWidth: 150,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderRequirementCategory(),
			},
			{
				key: 'version',
				name: t('Version'),
				fieldName: 'version',
				minWidth: 70,
				maxWidth: 70,
			},
			{
				key: 'references',
				name: t('References'),
				fieldName: 'references',
				minWidth: 300,
				isIconOnly: true,
				iconName: 'Link',
				isResizable: true,
				isMultiline: true,
				onRender: renderReferenceField(),
			},
		];

		if (shouldShowFaq) {
			tempColumns.push({
				key: 'FAQ',
				name: 'Q&As',
				fieldName: 'faq',
				minWidth: 100,
				isResizable: true,
				onRender: renderFaqs(),
			});
		}

		return tempColumns;
	}, [shouldShowFaq]);

	const columnFilterOptions: InfinityListColumnFilterOptions[] = useMemo(
		() => [
			{
				key: 'name',
				fieldValuesUnique:
					dataQuery?.requirementsQuery.requirementFilterOptions.requirementOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'vexClusters',
				fieldValuesUnique:
					dataQuery?.requirementsQuery.requirementFilterOptions.vexClusterOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'regulations',
				fieldValuesUnique:
					dataQuery?.requirementsQuery.requirementFilterOptions.regOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'regulatoryDocuments',
				fieldValuesUnique:
					dataQuery?.requirementsQuery.requirementFilterOptions.regDocOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'status',
				fieldValuesUnique:
					dataQuery?.requirementsQuery.requirementFilterOptions.statusOptions.map(
						x => {
							return {id: x.optionKey, value: tStatus(x.name)};
						},
					) ?? [],
			},
			{
				key: 'systemLevels',
				fieldValuesUnique:
					dataQuery?.requirementsQuery.requirementFilterOptions.systemLevelOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'markets',
				fieldValuesUnique:
					dataQuery?.requirementsQuery.requirementFilterOptions.marketOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'keywords',
				fieldValuesUnique:
					dataQuery?.requirementsQuery.requirementFilterOptions.keywordOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'tags',
				fieldValuesUnique:
					dataQuery?.requirementsQuery.requirementFilterOptions.tagOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'createdBy',
				fieldValuesUnique:
					dataQuery?.requirementsQuery.requirementFilterOptions.createdByOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'category',
				fieldValuesUnique:
					dataQuery?.requirementsQuery.requirementFilterOptions.categoryOptions.map(
						x => {
							return {id: x.optionKey, value: tCategory(x.name)};
						},
					) ?? [],
			},
		],
		[dataQuery],
	);

	const createHref = (item: unknown) =>
		`/requirements/${(item as InfinityListItemWithId).id}`;

	const numberOfElements = useMemo(
		() =>
			dataQuery?.requirementsQuery.requirementIds
				.split(';')
				.filter(x => x.trim()).length ?? 1,
		[dataQuery],
	);

	return (
		<EntityContextProvider>
			<RequirementsTooltipTranslationProvider>
				<InfinityList
					pageName={t('RequirementsHeader')}
					items={arrRequirement}
					sticky={true}
					columns={columns}
					columnFilterOptions={columnFilterOptions}
					selectionMode={SelectionMode.single}
					stillLoading={loadingQuery || loadingDocuments}
					initalFilters={linksFilters}
					initialSortField={initialSortField}
					initialSortOrder={initialSortOrder}
					initialView={initialView}
					keyPrefix={keyPrefix}
					firstRender={firstRender}
					loadItemsNext={loadItemsNext}
					retrieveItemsAll={retrieveItemsAll}
					applyFilter={applyFilter}
					applySort={applySort}
					handleInvokeItem={handleViewingItem}
					createHref={createHref}
					numberOfElements={numberOfElements}
					views={views}
				/>
				<RequirementsPageRequirementForm />
			</RequirementsTooltipTranslationProvider>
		</EntityContextProvider>
	);
};

export default RequirementsPage;
