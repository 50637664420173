import {Stack, useTheme, List, Text} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {LoadWrapper} from 'components/LoadWrapper';
import {formatDateTime, DateTimeFormat} from 'i18n/localeDateFormat';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {DocumentStatus, WorkflowStatus} from 'types';
import {getListClassNames} from 'styles';
import {useGetWorkbenchItemsQuery} from '../../hooks/workbenchItems.generated';
import {WorkbenchHeader} from '../Headers';
import {getStatusTag} from 'components/EntityList/RequirementField';

type WorkbenchItemsProps = {
	halfHeight?: boolean;
	dataCountLoaded?: (count: number) => void;
};

export const WorkbenchItems: React.FC<WorkbenchItemsProps> = ({
	halfHeight = false,
}) => {
	const {i18n, t: tEnum} = useTranslation('common/enums', {
		keyPrefix: 'WorkflowStatus',
	});
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'WorkbenchList',
	});

	const {isAdmin, isServiceProvider, isVko, isVkoExternal} = useUserContext();
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	const status = React.useMemo(() => {
		if (isAdmin) {
			return [
				WorkflowStatus.New,
				WorkflowStatus.QualityControlInternal,
				WorkflowStatus.QualityControlExternal,
				WorkflowStatus.InProgressInternal,
				WorkflowStatus.InProgressExternal,
				WorkflowStatus.Clearing,
				WorkflowStatus.ClearingWithReservations,
				WorkflowStatus.Examination,
				WorkflowStatus.Finalized,
				WorkflowStatus.Archived,
			];
		}

		if (isServiceProvider) {
			return [
				WorkflowStatus.QualityControlInternal,
				WorkflowStatus.QualityControlExternal,
			];
		}

		if (isVko) {
			return [
				WorkflowStatus.New,
				WorkflowStatus.InProgressInternal,
				WorkflowStatus.Clearing,
				WorkflowStatus.ClearingWithReservations,
				WorkflowStatus.Examination,
				WorkflowStatus.QualityControlInternal,
				WorkflowStatus.QualityControlExternal,
				WorkflowStatus.Modified,
			];
		}

		if (isVkoExternal) {
			return [WorkflowStatus.InProgressExternal];
		}

		return [];
	}, [isAdmin, isVko, isVkoExternal, isServiceProvider]);

	const {data, loading} = useGetWorkbenchItemsQuery({
		variables: {
			workflowStatus: status,
		},
	});

	const items = React.useMemo(
		() =>
			data?.workbenchRegulatoryDocuments.filter(w => {
				if (
					status.includes(w.workflow.status) &&
					(w.workflow.status === WorkflowStatus.QualityControlInternal ||
						w.workflow.status === WorkflowStatus.QualityControlExternal) &&
					w.documentStatus !== DocumentStatus.SplittingFinished
				) {
					return false;
				}

				return status.includes(w.workflow.status);
			}) ?? [],
		[data],
	);

	const onRenderCell = (item: any): JSX.Element => {
		return (
			<div className={classNames.itemCell} data-is-focusable={true}>
				<div className={classNames.itemContent}>
					<Stack horizontal horizontalAlign={'space-between'}>
						<Stack>
							<Text>{item.regulation.regulationNumber}</Text>
							<Link to={`/regulatoryDocuments/${item.id}`}>
								<div className={classNames.itemName}>{item.name}</div>
							</Link>
						</Stack>
						<div className={classNames.itemStatus}>
							{getStatusTag(
								item?.workflow?.status,
								theme,
								tEnum,
								item?.workflow?.reapprovingVKO?.id || '',
							)}
						</div>
					</Stack>
					<div className={classNames.itemSubtext}>
						{formatDateTime(
							new Date(item.createdAt),
							i18n,
							DateTimeFormat.DateMonthWeekday,
						)}
					</div>
					{item.workflow.message && (
						<div>
							{t('Reason')}
							{item.workflow.message}
						</div>
					)}
				</div>
			</div>
		);
	};

	return (
		<LoadWrapper loading={loading}>
			<WorkbenchHeader
				halfHeight
				title={t('WorkbenchTitle')}
				items={items?.length}
			/>
			<div className={classNames.container} data-is-scrollable>
				<List items={items} onRenderCell={onRenderCell} />
			</div>
		</LoadWrapper>
	);
};
