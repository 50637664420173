import {
	FontIcon,
	IBasePickerSuggestionsProps,
	ITag,
	ITagPickerProps,
	Stack,
	TagPicker,
	Text,
} from '@fluentui/react';
import React, {useCallback, useMemo} from 'react';
import {Controller} from 'react-hook-form';
import {ErrorMessage} from './ErrorMessage';
import {HookFormProps} from './HookFormProps';
import {
	ActionTags,
	ActionTagsProps,
} from 'features/RegulatoryDocuments/components/DocumentDetails/ActionTags';
import {
	LabelWithTooltip,
	LabelWithTooltipProps,
} from 'features/localizedTooltips';
import {useCommonTagPickerTranslations} from './hooks/tagPickers.commonHooks';
import {useTranslation} from 'react-i18next';

export type TagOfControlledTagPicker<SelectableItem> = SelectableItem & {
	key: any;
	name: any;
};

export type ResolveSuggestionsFromBaseOnes<Item> = (
	tags: TagOfControlledTagPicker<Item>[],
) => TagOfControlledTagPicker<Item>[];

export type ControlledTagPickerProps<T> = HookFormProps &
	Omit<
		ITagPickerProps,
		| 'selectedItems'
		| 'onChange'
		| 'onBlur'
		// | 'defaultSelectedItems'
		| 'onResolveSuggestions'
		| 'onEmptyResolveSuggestions'
	> &
	Pick<LabelWithTooltipProps, 'tooltipHostProps'> & {
		label: string;
		selectableItems: T[];
		getKey?: (item: T) => string | number;
		getName?: (item: T) => string;
		actionTags?: ActionTagsProps['tags'];
		performExtraActionOnChange?: ITagPickerProps['onChange'];
		hideTagIcon?: boolean;
		resolveSuggestionsFromBaseOnes?: ResolveSuggestionsFromBaseOnes<T>;
		TagPickerComponent?: typeof TagPicker;
		placeholder?: string;
		// Default selected items
		defaultSelectedItems?: Partial<TagOfControlledTagPicker<T>>[];
	};

export function ControlledTagPicker<T>(props: ControlledTagPickerProps<T>) {
	const {t: getCommonTagPickerTranslation} = useCommonTagPickerTranslations();
	const {t} = useTranslation('components/ControlledTagPicker');

	const {
		resolveSuggestionsFromBaseOnes,
		hideTagIcon,
		TagPickerComponent = TagPicker,
	} = props;

	type Tags = TagOfControlledTagPicker<T>[];

	const getSuggestions = useCallback(
		(tags: Tags): Tags => {
			if (resolveSuggestionsFromBaseOnes)
				return resolveSuggestionsFromBaseOnes(tags);
			return tags;
		},
		[resolveSuggestionsFromBaseOnes],
	);

	const tags: Tags = React.useMemo(() => {
		const getKey = props.getKey ?? ((i: any) => i.id);
		const getName = props.getName ?? ((i: any) => i.name);

		return (props.selectableItems || []).map(i => ({
			...i,
			key: getKey(i),
			name: getName(i),
		}));
	}, [props.selectableItems]);

	const onResolveSuggestions = React.useCallback(
		(filter: string, selectedItems: ITag[] | undefined) => {
			const selectedKeys = selectedItems ? selectedItems.map(i => i.key) : [];
			const filteredTags: Tags = tags.filter(
				tag =>
					!selectedKeys.includes(tag.key) &&
					tag.name.toLowerCase().includes(filter?.toLowerCase() || ''),
			);
			return getSuggestions(filteredTags);
		},
		[tags, getSuggestions],
	);

	const onEmptyResolveSuggestions = React.useCallback(
		(selectedItems: ITag[] | undefined) => {
			const selectedKeys = selectedItems ? selectedItems.map(i => i.key) : [];
			const filteredTags: Tags = tags.filter(
				tag => !selectedKeys.includes(tag.key),
			);
			return getSuggestions(filteredTags);
		},
		[tags, getSuggestions],
	);

	// Explicit type will not work here. The actually accessible properties from the ref are not included.
	const tagPickerRef = React.useRef<any>(null);

	const suggestionProps = useMemo((): IBasePickerSuggestionsProps => {
		return {
			noResultsFoundText: getCommonTagPickerTranslation('TextWhenNoItemsFound'),
		};
	}, [getCommonTagPickerTranslation]);

	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			// Initialize the field with defaultSelectedItems if provided
			defaultValue={props.defaultSelectedItems || []}
			render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
				<>
					<Stack horizontal tokens={{childrenGap: 5}}>
						<LabelWithTooltip
							required={Boolean(props.rules?.required)}
							translationKey={props.name}
							tooltipHostProps={props.tooltipHostProps}
						>
							{props.label}
						</LabelWithTooltip>
						{hideTagIcon ? null : (
							<FontIcon iconName='Tag' style={{paddingTop: 8}} />
						)}
						<ActionTags tags={props.actionTags} itemStyle={{paddingTop: 8}} />
					</Stack>
					{props?.placeholder && (
						<Text style={{opacity: '0.8'}}>{props?.placeholder}</Text>
					)}
					<TagPickerComponent
						{...props}
						componentRef={tagPickerRef}
						selectedItems={(Array.isArray(value) ? value : []).map(
							(v: any) => ({
								...v,
								key: props.getKey?.(v) ?? v.id,
								name: props.getName?.(v) ?? v.name,
							}),
						)}
						onChange={items => {
							onChange(items);
							props.performExtraActionOnChange?.(items);
						}}
						onBlur={() => {
							onBlur();
							tagPickerRef.current?.input.current?._updateValue('');
						}}
						// defaultSelectedItems={undefined}
						onResolveSuggestions={onResolveSuggestions}
						onEmptyResolveSuggestions={onEmptyResolveSuggestions}
						removeButtonAriaLabel={getCommonTagPickerTranslation(
							'LabelForRemoveBtn',
						)}
						selectionAriaLabel={t('LabelForSelectedItems')}
						pickerSuggestionsProps={suggestionProps}
					/>
					<ErrorMessage error={error} />
				</>
			)}
		/>
	);
}
