import {Command} from 'hooks';
import {RegulatoryDocument, WorkflowStatus, UserRole} from 'types';
import {
	RegulatoryDocumentFormMode,
	RegulatoryDocumentFormModeInfo,
} from './components/RegulatoryDocumentsForm/useRegulatoryDocumentFormMode';
import {UserContextType} from 'authentication/UserContext';
import {DocumentChangeStateInfo} from './components/useDidDocumentChange';
import {QueryResult} from '@apollo/client';
import {useEditEntityCmd} from 'hooks/useEditEntityCmd';

type DocWithWorkflow = Pick<RegulatoryDocument, 'workflow'>;

export const getIfDocHasUneditableStatus = (
	doc: Partial<DocWithWorkflow>,
	isAdmin: UserContextType['isAdmin'],
	_username: UserContextType['username'],
): boolean => {
	if (!doc.workflow) return true;
	return false;
};

export interface EditRegulatoryDocCmdOptions
	extends Pick<DocumentChangeStateInfo, 'setDidDocumentChange'>,
		Pick<RegulatoryDocumentFormModeInfo, 'setMode'>,
		Command {
	extraDependencies?: unknown[];
}

export const useEditRegulatoryDocCmd = ({
	setDidDocumentChange,
	setMode,
	extraDependencies = [],
	...commandFields
}: EditRegulatoryDocCmdOptions) => {
	return useEditEntityCmd(
		{
			onClick() {
				setDidDocumentChange(false);
				setMode(RegulatoryDocumentFormMode.Update);
			},
			roles: [UserRole.SystemAdministrator, UserRole.Vko],
			...commandFields,
		},
		[...extraDependencies],
	);
};
