import {useTranslation} from 'react-i18next';

import React, {useState, useEffect} from 'react';

import _ from 'lodash';
import {CreateQuestionDialog} from './CreateQuestionDialog';
import {FaqPanelContent} from './FaqPanelContent';
import {useQuestionByIdLazyQuery} from './Faq.generated';
import {useNavigate, useParams} from 'react-router-dom';
import {useCommand} from 'hooks';
import {getParentUrl} from './EntityTypeMapper';
import {HeaderForPagesWithEntityPage} from 'components/HeaderForPagesWithEntityPage';
import {FaqPanelMode} from './FaqPanel';

export const QuestionPage: any = () => {
	const {t} = useTranslation('common/faq');

	const navigate = useNavigate();

	const {questionId}: any = useParams();

	const [showQuestionDialog, setShowQuestionDialog] = useState(false);
	const [question, setQuestion] = useState<any>(null);
	const [parentUrl, setParentUrl] = useState<string>('');

	const [fetchQuestionsId, {data, loading}] = useQuestionByIdLazyQuery({
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		fetchQuestionsId({
			variables: {id: questionId},
		});
	}, [questionId]);

	useEffect(() => {
		setQuestion(data?.question);
	}, [data]);

	useEffect(() => {
		setParentUrl(getParentUrl(question));
	}, [question]);

	useCommand(
		{
			key: 'QuestionRelatedObject',
			priority: 1,
			iconProps: {
				iconName: 'Link',
			},
			onClick: () => navigate(parentUrl),

			text: t('QuestionRelatedObject'),
		},
		[],
	);
	return (
		<>
			<HeaderForPagesWithEntityPage>
				<h3>{t('QuestionDetails')}</h3>
				{!loading && question && (
					<>
						<FaqPanelContent
							questions={[question]}
							styles={{
								display: 'flex',
								gap: '10px',
								minHeight: '100px',
							}}
							initQuestion={question}
						/>
						<CreateQuestionDialog
							showDialog={showQuestionDialog}
							setShowDialog={setShowQuestionDialog}
							faqPanelMode={FaqPanelMode.EntityList}
						/>
					</>
				)}
			</HeaderForPagesWithEntityPage>
		</>
	);
};
