import React from 'react';
import {Stack} from '@fluentui/react';

type TileContainerProps = {
	visibleTiles: Array<JSX.Element>;
};

/**
 * Adjust the tile dynamically based on the screen width.
 * If there are three tiles, then each tile wil be 33.33 &
 * If there are only two tiles, then each tile will be 50%
 * If it is only one tile, then it will be max 600px
 * @param param0
 * @returns
 */
export const TileContainer: React.FC<TileContainerProps> = ({visibleTiles}) => {
	const tileStyles = React.useMemo(() => {
		return {
			flexBasis: 0,
			flexGrow: 1,
			width: 'auto',
			overflow: 'auto',
		};
	}, []);

	return (
		<Stack
			horizontal
			horizontalAlign='space-between'
			style={{
				width: '100%',
				overflowX: 'hidden',
				padding: 0,
			}}
		>
			{visibleTiles.map(tile => (
				<div style={tileStyles} key={tile.key}>
					{tile}
				</div>
			))}
		</Stack>
	);
};
