import * as Types from '../../../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MatkonImdsApplicationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MatkonImdsApplicationsQuery = { __typename?: 'Query', matkonImdsApplications?: { __typename?: 'GetImdsApplicationResponse', totalCount: number, nodes?: Array<{ __typename?: 'ImdsApplication', id: number, code: string, isActive: boolean, iri: string, createdAt: any, createdBy: string, modifiedAt: any, modifiedBy: string }> | null } | null };

export type MatkonLimitsTemplateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MatkonLimitsTemplateQuery = { __typename?: 'Query', matkonLimitsTemplate: { __typename?: 'MatkonFileResult', fileName: string, contentType: string, base64Content: string } };

export type MatkonApplicationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MatkonApplicationsQuery = { __typename?: 'Query', matkonApplications?: { __typename?: 'GetApplicationResponse', totalCount: number, nodes?: Array<{ __typename?: 'ApplicationNode', description: string, iri: string, createdAt: any, createdBy: string, modifiedAt: any, modifiedBy: string, limits: Array<{ __typename?: 'Limit', value: number, unit: string, operator: Types.EOperator, referenceValue: Types.EReferenceValue, deadlineLaw: string, deadlineInternal: string, expirationDate: string, imdsAssessmentOverwritten: boolean, matConComplianceEvaluationOverwritten: boolean, limitations: Array<string>, limitToSubstances: Array<string>, iri: string, createdAt: any, createdBy: string, modifiedAt: any, modifiedBy: string }>, imdsApplication: { __typename?: 'ImdsApplication', id: number, code: string, isActive: boolean, iri: string, createdAt: any, createdBy: string, modifiedAt: any, modifiedBy: string } }> | null } | null };

export type CreateMatkonApplicationMutationVariables = Types.Exact<{
  input: Types.CreateApplicationInput;
}>;


export type CreateMatkonApplicationMutation = { __typename?: 'Mutation', createMatkonApplication: { __typename?: 'ApplicationNode', description: string, iri: string, createdAt: any, createdBy: string, modifiedAt: any, modifiedBy: string, limits: Array<{ __typename?: 'Limit', value: number, unit: string, operator: Types.EOperator, referenceValue: Types.EReferenceValue, deadlineLaw: string, deadlineInternal: string, expirationDate: string, imdsAssessmentOverwritten: boolean, matConComplianceEvaluationOverwritten: boolean, limitations: Array<string>, limitToSubstances: Array<string>, iri: string, createdAt: any, createdBy: string, modifiedAt: any, modifiedBy: string }>, imdsApplication: { __typename?: 'ImdsApplication', id: number, code: string, isActive: boolean, iri: string, createdAt: any, createdBy: string, modifiedAt: any, modifiedBy: string } } };

export type CreateMatkonLimitsMutationVariables = Types.Exact<{
  input: Types.CreateLimitInput;
}>;


export type CreateMatkonLimitsMutation = { __typename?: 'Mutation', createMatkonLimits: Array<{ __typename?: 'Limit', value: number, unit: string, operator: Types.EOperator, referenceValue: Types.EReferenceValue, deadlineLaw: string, deadlineInternal: string, expirationDate: string, imdsAssessmentOverwritten: boolean, matConComplianceEvaluationOverwritten: boolean, limitations: Array<string>, limitToSubstances: Array<string>, iri: string, createdAt: any, createdBy: string, modifiedAt: any, modifiedBy: string, application?: { __typename?: 'Application', description: string, iri: string, createdAt: any, createdBy: string, modifiedAt: any, modifiedBy: string } | null }> };

export type GetMatkonLimitsQueryVariables = Types.Exact<{
  regulationNumber: Types.Scalars['String'];
  paragraphId: Types.Scalars['ID'];
}>;


export type GetMatkonLimitsQuery = { __typename?: 'Query', matkonLimits?: { __typename?: 'GetParagraphAdditionalInformationResponse', totalCount: number, nodes?: Array<{ __typename?: 'ParagraphAdditionalInformation', regulationNumber: string, traceParagraphId: string, limits: Array<{ __typename?: 'Limit', value: number, unit: string, operator: Types.EOperator, referenceValue: Types.EReferenceValue, deadlineLaw: string, deadlineInternal: string, expirationDate: string, imdsAssessmentOverwritten: boolean, matConComplianceEvaluationOverwritten: boolean, limitations: Array<string>, limitToSubstances: Array<string>, iri: string, createdAt: any, createdBy: string, modifiedAt: any, modifiedBy: string, application?: { __typename?: 'Application', description: string, iri: string, createdAt: any, createdBy: string, modifiedAt: any, modifiedBy: string } | null }> }> | null } | null };


export const MatkonImdsApplicationsDocument = gql`
    query MatkonImdsApplications {
  matkonImdsApplications {
    totalCount
    nodes {
      id
      code
      isActive
      iri
      createdAt
      createdBy
      modifiedAt
      modifiedBy
    }
  }
}
    `;

/**
 * __useMatkonImdsApplicationsQuery__
 *
 * To run a query within a React component, call `useMatkonImdsApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatkonImdsApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatkonImdsApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMatkonImdsApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<MatkonImdsApplicationsQuery, MatkonImdsApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MatkonImdsApplicationsQuery, MatkonImdsApplicationsQueryVariables>(MatkonImdsApplicationsDocument, options);
      }
export function useMatkonImdsApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MatkonImdsApplicationsQuery, MatkonImdsApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MatkonImdsApplicationsQuery, MatkonImdsApplicationsQueryVariables>(MatkonImdsApplicationsDocument, options);
        }
export type MatkonImdsApplicationsQueryHookResult = ReturnType<typeof useMatkonImdsApplicationsQuery>;
export type MatkonImdsApplicationsLazyQueryHookResult = ReturnType<typeof useMatkonImdsApplicationsLazyQuery>;
export type MatkonImdsApplicationsQueryResult = Apollo.QueryResult<MatkonImdsApplicationsQuery, MatkonImdsApplicationsQueryVariables>;
export const MatkonLimitsTemplateDocument = gql`
    query MatkonLimitsTemplate {
  matkonLimitsTemplate {
    fileName
    contentType
    base64Content
  }
}
    `;

/**
 * __useMatkonLimitsTemplateQuery__
 *
 * To run a query within a React component, call `useMatkonLimitsTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatkonLimitsTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatkonLimitsTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useMatkonLimitsTemplateQuery(baseOptions?: Apollo.QueryHookOptions<MatkonLimitsTemplateQuery, MatkonLimitsTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MatkonLimitsTemplateQuery, MatkonLimitsTemplateQueryVariables>(MatkonLimitsTemplateDocument, options);
      }
export function useMatkonLimitsTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MatkonLimitsTemplateQuery, MatkonLimitsTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MatkonLimitsTemplateQuery, MatkonLimitsTemplateQueryVariables>(MatkonLimitsTemplateDocument, options);
        }
export type MatkonLimitsTemplateQueryHookResult = ReturnType<typeof useMatkonLimitsTemplateQuery>;
export type MatkonLimitsTemplateLazyQueryHookResult = ReturnType<typeof useMatkonLimitsTemplateLazyQuery>;
export type MatkonLimitsTemplateQueryResult = Apollo.QueryResult<MatkonLimitsTemplateQuery, MatkonLimitsTemplateQueryVariables>;
export const MatkonApplicationsDocument = gql`
    query MatkonApplications {
  matkonApplications {
    totalCount
    nodes {
      description
      iri
      createdAt
      createdBy
      modifiedAt
      modifiedBy
      limits {
        value
        unit
        operator
        referenceValue
        deadlineLaw
        deadlineInternal
        expirationDate
        imdsAssessmentOverwritten
        matConComplianceEvaluationOverwritten
        limitations
        limitToSubstances
        iri
        createdAt
        createdBy
        modifiedAt
        modifiedBy
      }
      imdsApplication {
        id
        code
        isActive
        iri
        createdAt
        createdBy
        modifiedAt
        modifiedBy
      }
    }
  }
}
    `;

/**
 * __useMatkonApplicationsQuery__
 *
 * To run a query within a React component, call `useMatkonApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatkonApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatkonApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMatkonApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<MatkonApplicationsQuery, MatkonApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MatkonApplicationsQuery, MatkonApplicationsQueryVariables>(MatkonApplicationsDocument, options);
      }
export function useMatkonApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MatkonApplicationsQuery, MatkonApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MatkonApplicationsQuery, MatkonApplicationsQueryVariables>(MatkonApplicationsDocument, options);
        }
export type MatkonApplicationsQueryHookResult = ReturnType<typeof useMatkonApplicationsQuery>;
export type MatkonApplicationsLazyQueryHookResult = ReturnType<typeof useMatkonApplicationsLazyQuery>;
export type MatkonApplicationsQueryResult = Apollo.QueryResult<MatkonApplicationsQuery, MatkonApplicationsQueryVariables>;
export const CreateMatkonApplicationDocument = gql`
    mutation CreateMatkonApplication($input: CreateApplicationInput!) {
  createMatkonApplication(input: $input) {
    description
    iri
    createdAt
    createdBy
    modifiedAt
    modifiedBy
    limits {
      value
      unit
      operator
      referenceValue
      deadlineLaw
      deadlineInternal
      expirationDate
      imdsAssessmentOverwritten
      matConComplianceEvaluationOverwritten
      limitations
      limitToSubstances
      iri
      createdAt
      createdBy
      modifiedAt
      modifiedBy
    }
    imdsApplication {
      id
      code
      isActive
      iri
      createdAt
      createdBy
      modifiedAt
      modifiedBy
    }
  }
}
    `;
export type CreateMatkonApplicationMutationFn = Apollo.MutationFunction<CreateMatkonApplicationMutation, CreateMatkonApplicationMutationVariables>;

/**
 * __useCreateMatkonApplicationMutation__
 *
 * To run a mutation, you first call `useCreateMatkonApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMatkonApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMatkonApplicationMutation, { data, loading, error }] = useCreateMatkonApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMatkonApplicationMutation(baseOptions?: Apollo.MutationHookOptions<CreateMatkonApplicationMutation, CreateMatkonApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMatkonApplicationMutation, CreateMatkonApplicationMutationVariables>(CreateMatkonApplicationDocument, options);
      }
export type CreateMatkonApplicationMutationHookResult = ReturnType<typeof useCreateMatkonApplicationMutation>;
export type CreateMatkonApplicationMutationResult = Apollo.MutationResult<CreateMatkonApplicationMutation>;
export type CreateMatkonApplicationMutationOptions = Apollo.BaseMutationOptions<CreateMatkonApplicationMutation, CreateMatkonApplicationMutationVariables>;
export const CreateMatkonLimitsDocument = gql`
    mutation CreateMatkonLimits($input: CreateLimitInput!) {
  createMatkonLimits(input: $input) {
    value
    unit
    operator
    referenceValue
    deadlineLaw
    deadlineInternal
    expirationDate
    imdsAssessmentOverwritten
    matConComplianceEvaluationOverwritten
    limitations
    limitToSubstances
    iri
    createdAt
    createdBy
    modifiedAt
    modifiedBy
    application {
      description
      iri
      createdAt
      createdBy
      modifiedAt
      modifiedBy
    }
  }
}
    `;
export type CreateMatkonLimitsMutationFn = Apollo.MutationFunction<CreateMatkonLimitsMutation, CreateMatkonLimitsMutationVariables>;

/**
 * __useCreateMatkonLimitsMutation__
 *
 * To run a mutation, you first call `useCreateMatkonLimitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMatkonLimitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMatkonLimitsMutation, { data, loading, error }] = useCreateMatkonLimitsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMatkonLimitsMutation(baseOptions?: Apollo.MutationHookOptions<CreateMatkonLimitsMutation, CreateMatkonLimitsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMatkonLimitsMutation, CreateMatkonLimitsMutationVariables>(CreateMatkonLimitsDocument, options);
      }
export type CreateMatkonLimitsMutationHookResult = ReturnType<typeof useCreateMatkonLimitsMutation>;
export type CreateMatkonLimitsMutationResult = Apollo.MutationResult<CreateMatkonLimitsMutation>;
export type CreateMatkonLimitsMutationOptions = Apollo.BaseMutationOptions<CreateMatkonLimitsMutation, CreateMatkonLimitsMutationVariables>;
export const GetMatkonLimitsDocument = gql`
    query GetMatkonLimits($regulationNumber: String!, $paragraphId: ID!) {
  matkonLimits(regulationNumber: $regulationNumber, paragraphId: $paragraphId) {
    totalCount
    nodes {
      regulationNumber
      traceParagraphId
      limits {
        value
        unit
        operator
        referenceValue
        deadlineLaw
        deadlineInternal
        expirationDate
        imdsAssessmentOverwritten
        matConComplianceEvaluationOverwritten
        limitations
        limitToSubstances
        iri
        createdAt
        createdBy
        modifiedAt
        modifiedBy
        application {
          description
          iri
          createdAt
          createdBy
          modifiedAt
          modifiedBy
        }
      }
    }
  }
}
    `;

/**
 * __useGetMatkonLimitsQuery__
 *
 * To run a query within a React component, call `useGetMatkonLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatkonLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatkonLimitsQuery({
 *   variables: {
 *      regulationNumber: // value for 'regulationNumber'
 *      paragraphId: // value for 'paragraphId'
 *   },
 * });
 */
export function useGetMatkonLimitsQuery(baseOptions: Apollo.QueryHookOptions<GetMatkonLimitsQuery, GetMatkonLimitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMatkonLimitsQuery, GetMatkonLimitsQueryVariables>(GetMatkonLimitsDocument, options);
      }
export function useGetMatkonLimitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMatkonLimitsQuery, GetMatkonLimitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMatkonLimitsQuery, GetMatkonLimitsQueryVariables>(GetMatkonLimitsDocument, options);
        }
export type GetMatkonLimitsQueryHookResult = ReturnType<typeof useGetMatkonLimitsQuery>;
export type GetMatkonLimitsLazyQueryHookResult = ReturnType<typeof useGetMatkonLimitsLazyQuery>;
export type GetMatkonLimitsQueryResult = Apollo.QueryResult<GetMatkonLimitsQuery, GetMatkonLimitsQueryVariables>;