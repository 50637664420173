import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckCanBeHiddenQueryVariables = Types.Exact<{
  regulationId: Types.Scalars['ID'];
}>;


export type CheckCanBeHiddenQuery = { __typename?: 'Query', canRegulationBeHidden: boolean };

export type CheckIfHiddenQueryVariables = Types.Exact<{
  regulationId: Types.Scalars['ID'];
}>;


export type CheckIfHiddenQuery = { __typename?: 'Query', regulation?: { __typename?: 'Regulation', hidden: boolean } | null };

export type MakeRegulationVisibleMutationVariables = Types.Exact<{
  regulationId: Types.Scalars['ID'];
}>;


export type MakeRegulationVisibleMutation = { __typename?: 'Mutation', unhideRegulation: { __typename?: 'Regulation', hidden: boolean } };


export const CheckCanBeHiddenDocument = gql`
    query CheckCanBeHidden($regulationId: ID!) {
  canRegulationBeHidden(id: $regulationId)
}
    `;

/**
 * __useCheckCanBeHiddenQuery__
 *
 * To run a query within a React component, call `useCheckCanBeHiddenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCanBeHiddenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCanBeHiddenQuery({
 *   variables: {
 *      regulationId: // value for 'regulationId'
 *   },
 * });
 */
export function useCheckCanBeHiddenQuery(baseOptions: Apollo.QueryHookOptions<CheckCanBeHiddenQuery, CheckCanBeHiddenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckCanBeHiddenQuery, CheckCanBeHiddenQueryVariables>(CheckCanBeHiddenDocument, options);
      }
export function useCheckCanBeHiddenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckCanBeHiddenQuery, CheckCanBeHiddenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckCanBeHiddenQuery, CheckCanBeHiddenQueryVariables>(CheckCanBeHiddenDocument, options);
        }
export type CheckCanBeHiddenQueryHookResult = ReturnType<typeof useCheckCanBeHiddenQuery>;
export type CheckCanBeHiddenLazyQueryHookResult = ReturnType<typeof useCheckCanBeHiddenLazyQuery>;
export type CheckCanBeHiddenQueryResult = Apollo.QueryResult<CheckCanBeHiddenQuery, CheckCanBeHiddenQueryVariables>;
export const CheckIfHiddenDocument = gql`
    query CheckIFHidden($regulationId: ID!) {
  regulation(id: $regulationId) {
    hidden
  }
}
    `;

/**
 * __useCheckIfHiddenQuery__
 *
 * To run a query within a React component, call `useCheckIfHiddenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfHiddenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfHiddenQuery({
 *   variables: {
 *      regulationId: // value for 'regulationId'
 *   },
 * });
 */
export function useCheckIfHiddenQuery(baseOptions: Apollo.QueryHookOptions<CheckIfHiddenQuery, CheckIfHiddenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckIfHiddenQuery, CheckIfHiddenQueryVariables>(CheckIfHiddenDocument, options);
      }
export function useCheckIfHiddenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckIfHiddenQuery, CheckIfHiddenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckIfHiddenQuery, CheckIfHiddenQueryVariables>(CheckIfHiddenDocument, options);
        }
export type CheckIfHiddenQueryHookResult = ReturnType<typeof useCheckIfHiddenQuery>;
export type CheckIfHiddenLazyQueryHookResult = ReturnType<typeof useCheckIfHiddenLazyQuery>;
export type CheckIfHiddenQueryResult = Apollo.QueryResult<CheckIfHiddenQuery, CheckIfHiddenQueryVariables>;
export const MakeRegulationVisibleDocument = gql`
    mutation MakeRegulationVisible($regulationId: ID!) {
  unhideRegulation(id: $regulationId) {
    hidden
  }
}
    `;
export type MakeRegulationVisibleMutationFn = Apollo.MutationFunction<MakeRegulationVisibleMutation, MakeRegulationVisibleMutationVariables>;

/**
 * __useMakeRegulationVisibleMutation__
 *
 * To run a mutation, you first call `useMakeRegulationVisibleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeRegulationVisibleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeRegulationVisibleMutation, { data, loading, error }] = useMakeRegulationVisibleMutation({
 *   variables: {
 *      regulationId: // value for 'regulationId'
 *   },
 * });
 */
export function useMakeRegulationVisibleMutation(baseOptions?: Apollo.MutationHookOptions<MakeRegulationVisibleMutation, MakeRegulationVisibleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeRegulationVisibleMutation, MakeRegulationVisibleMutationVariables>(MakeRegulationVisibleDocument, options);
      }
export type MakeRegulationVisibleMutationHookResult = ReturnType<typeof useMakeRegulationVisibleMutation>;
export type MakeRegulationVisibleMutationResult = Apollo.MutationResult<MakeRegulationVisibleMutation>;
export type MakeRegulationVisibleMutationOptions = Apollo.BaseMutationOptions<MakeRegulationVisibleMutation, MakeRegulationVisibleMutationVariables>;