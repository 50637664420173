import {Regulation, UserRole} from 'types';
import {LoadWrapper} from 'components/LoadWrapper';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import React, {useEffect, useMemo, useState} from 'react';
import {
	RegulationsPageRegulationsForm,
	RegulationsList,
} from '../Regulations/components';
import {RegulationsContextProvider} from '../Regulations/context';
import {useGetAllRegulationsQuery} from '../Regulations/hooks';
import {useTranslation} from 'react-i18next';
import {REGULATIONS_TRANSLATION_NAMESPACE} from '../Regulations/regulationsConstants';
import {HeaderForPagesWithEntityPage} from 'components/HeaderForPagesWithEntityPage';
import {
	InfinityList,
	InfinityListColumn,
	InfinityListColumnFilterOptions,
	InfinityListFilter,
	InfinityListItemWithId,
	InfinityListURLParams,
	InfinityListView,
} from 'components/InfinityList';
import {
	useGetRegulationsNextLazyQuery,
	useGetRegulationsQueryLazyQuery,
	useGetRegulationsSortOrderLazyQuery,
} from '../Regulations/hooks/regulationsInfinity.generated';
import {
	createSearchParams,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import {ConstrainMode, SelectionMode, useTheme} from '@fluentui/react';
import {RegulationNumberCell} from '../Regulations/components/RegulationsList/RegulationNumberCell';
import {
	renderArrayField,
	renderAttachments,
} from 'components/EntityList/ColumnRenderers';
import {renderKeywordsCell} from 'components/Keywords/Keywords';
import {parseTooltipNewlines} from 'helpers/tooltips';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';
import {PAGE_PATHS} from 'pages';
import {v4 as uuidv4} from 'uuid';
import {GetVehicleProjectDetailsOptimizedQuery} from './hooks';

interface DataStoreRegulation {
	[key: string]: Regulation;
}

interface DataStoreSortOrders {
	[key: string]: string;
}

interface IFilters2MongoQuery {
	(allFilters: InfinityListFilter[]): string;
}

interface IDecodeHex24 {
	(x: string): string;
}

const sortInvertedFields: string[] = [];

const sortFields = ['regulationNumber'];

const arrayFields = [
	'markets',
	'mainKeywords',
	'keywords',
	'standardPlusPcmsClusters',
];

const arrayFieldMongoDb = [
	'marketRefs._id',
	'mainKeywordRefs._id',
	'keywordRefs._id',
	'standardPlusPcmsClusterRefs._id',
];

const initialSortField = 'regulationNumber';
const initialSortOrder = 'ASC';
const intBatchSize = 40;

const funcFilters2MongoQuery: IFilters2MongoQuery = allFilters => {
	const arrQueryStrings: string[] = [];

	const lstIdOptions = allFilters
		.filter(x2 => x2.columnKey === 'regulationNumber')
		.map(x2 => `ObjectId('${x2.selection}')`);

	if (lstIdOptions.length > 0) {
		arrQueryStrings.push(`_id:{$in:[${lstIdOptions}]}`);
	}

	arrayFields
		.map((x1, index) => {
			const lstOptions = allFilters
				.filter(x2 => x2.columnKey === x1)
				.map(x2 => `'${x2.selection}'`);

			if (lstOptions.length === 0) {
				return '';
			}
			return `'${arrayFieldMongoDb.at(index)}':{$in:[${lstOptions}]}`;
		})
		.filter(x1 => x1 !== '')
		.forEach(x1 => arrQueryStrings.push(x1));

	return `{ ${arrQueryStrings.join(',')} }`;
};

const funcDecodeHex24: IDecodeHex24 = x => {
	return atob(x).split('\n')[1].slice(1);
};

export type VehicleProjectDetailsTabProps = {
	dataVehPro: GetVehicleProjectDetailsOptimizedQuery | undefined;
	loadingVehPro: boolean;
	hidden: boolean;
};

const VehicleProjectDetailsTabRegulations: React.FC<
	VehicleProjectDetailsTabProps
> = ({dataVehPro, loadingVehPro, hidden}) => {
	const {t} = useTranslation('features/regulations', {
		keyPrefix: 'RegulationsList',
	});

	const {t: tt} = useTranslation('features/regulations', {
		keyPrefix: 'TooltipsText',
	});
	const {t: tTitle} = useTranslation(REGULATIONS_TRANSLATION_NAMESPACE, {
		keyPrefix: 'RegulationDetail',
	});
	const {t: tViews} = useTranslation('features/regulations', {
		keyPrefix: 'Views',
	});

	const [keyPrefix, setKeyPrefix] = useState(uuidv4());

	const [lastFetchedId, setlastFetchedId] = useState('');

	const [cursor, setCursor] = useState(0);

	const [dsRegulation, setDSRegulation] = useState<DataStoreRegulation>({});

	const [dsSortOrders, setDSSortOrders] = useState<DataStoreSortOrders>({});

	const [arrRegulation, setArrRegulation] = useState<Regulation[]>([]);

	const [getSortOrder, {loading: loadingSortOrder, data: dataSortOrder}] =
		useGetRegulationsSortOrderLazyQuery();

	const [getRegulationsQuery, {loading: loadingQuery, data: dataQuery}] =
		useGetRegulationsQueryLazyQuery();

	const [getRegulationsNext, {loading: loadingDocuments, data: dataDocuments}] =
		useGetRegulationsNextLazyQuery();

	const [searchParams] = useSearchParams();

	const navigate = useNavigate();

	const views: InfinityListView[] = useMemo(() => {
		const lstRegIds =
			dataVehPro?.vehicleProjectDetailsOptimized.regulationRefs
				.split(';')
				.filter(x => x.trim() !== '')
				.map(x => `ObjectId('${x}')`) ?? [];
		return [
			{
				headerText: tViews('STD'),
				accessKey: 'STD',
				query:
					lstRegIds.length > 0
						? `{_id : { $in : [ ${lstRegIds.join(',')} ]}}`
						: '',
			},
		];
	}, [dataVehPro]);

	const {queryLink, linksFilters, initialView} = useMemo(() => {
		const linksFilters: InfinityListFilter[] = [];
		const queryLink = '';
		const initialView = 'STD';
		return {queryLink, linksFilters, initialView};
	}, [searchParams]);

	// *functions for summarizing prop functions --------------------------------------------------------------------------------------
	const getSortIds = async (lstFilteredIds: string[], sortField: string) => {
		const boolCanSortLocally =
			lstFilteredIds.filter(x => dsRegulation[x] !== undefined).length ===
			lstFilteredIds.length;

		const boolSortisCalculated =
			dsSortOrders[sortField] !== undefined &&
			dsSortOrders[sortField].length > 0;

		if (boolSortisCalculated) {
			return dsSortOrders[sortField].split(';');
		}

		const filteredIds = lstFilteredIds.join(';');
		const {data: dataSortOrder} = await getSortOrder({
			variables: {filteredIds, sortField},
		});
		if (dataSortOrder?.regulationsSortOrder) {
			dsSortOrders[sortField] = dataSortOrder?.regulationsSortOrder;
			setDSSortOrders({...dsSortOrders});
		}

		return dataSortOrder?.regulationsSortOrder.split(';') ?? lstFilteredIds;
	};

	const getSortOrderDefault = (sortField: string) => {
		return sortInvertedFields.includes(sortField) ? 'DESC' : 'ASC';
	};

	const getSortDirectionServer = (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		return (sortInvertedFields.includes(sortField) && sortOrder === 'ASC') ||
			(!sortInvertedFields.includes(sortField) && sortOrder === 'DESC')
			? 'DESC'
			: 'ASC';
	};

	const getSortInverted = (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		return (
			(sortInvertedFields.includes(sortField) && sortOrder === 'ASC') ||
			(!sortInvertedFields.includes(sortField) && sortOrder === 'DESC')
		);
	};

	const getIdsNext = async (
		arrIds: string[],
		sortField: string,
		sortOrderEff: string,
		start: number,
		end: number,
	) => {
		const arrSortIds = await getSortIds(arrIds, sortField);

		if (getSortInverted(sortField, sortOrderEff)) {
			arrSortIds.reverse();
		}

		return arrSortIds.slice(start, end);
	};

	const fetchServerNextData = async (arrIdsNext: string[]) => {
		const arrIdsToFetch = arrIdsNext.filter(x => dsRegulation[x] === undefined);

		if (arrIdsToFetch.length > 0) {
			const dsRegulationNext: DataStoreRegulation = {};

			const strIdsToFetch = arrIdsToFetch.join(';');
			const {data: dataDocumentsNext} = await getRegulationsNext({
				variables: {
					filteredIds: strIdsToFetch,
				},
			});

			(dataDocumentsNext?.regulationsNext ?? []).forEach(x => {
				dsRegulationNext[funcDecodeHex24(x.id)] = x as Regulation;
			});

			const dsRegulationNew = {
				...dsRegulation,
				...dsRegulationNext,
			};

			const arrRegulationNext = arrIdsNext
				.map(x => dsRegulationNew[x])
				.filter(x => x !== undefined);

			setDSRegulation(dsRegulationNew);

			return {dsRegulationNew, arrRegulationNext};
		}

		const arrRegulationNext = arrIdsNext
			.map(x => dsRegulation[x])
			.filter(x => x !== undefined);

		return {dsRegulation, arrRegulationNext};
	};

	// *prop functions ----------------------------------------------------------------------------------------------------------------
	const firstRender = async () => {
		const x = 1;
	};

	useEffect(() => {
		if (dataVehPro && !loadingVehPro && !dataQuery && hidden === false) {
			(async () => {
				const arrRegulationNext: Regulation[] = [];
				const dsRegulationNext: DataStoreRegulation = {};
				const dsSortOrdersNext: DataStoreSortOrders = {};
				const lstRegIds =
					dataVehPro?.vehicleProjectDetailsOptimized.regulationRefs
						.split(';')
						.filter(x => x.trim() !== '') ?? [];

				if (lstRegIds.length > 0) {
					const {data: dataQueryReg} = await getRegulationsQuery({
						variables: {
							query: '{}',
							sortField: initialSortField,
							filteredIds: lstRegIds.join(';'),
						},
					});

					(dataQueryReg?.regulationsQuery.regulations ?? []).forEach(x => {
						arrRegulationNext.push(x as Regulation);
					});

					arrRegulationNext.forEach(x => {
						dsRegulationNext[funcDecodeHex24(x.id)] = x;
					});

					sortFields.forEach(x => {
						dsSortOrdersNext[x] = '';
					});

					dsSortOrdersNext[initialSortField] =
						dataQueryReg?.regulationsQuery.regulationIds ?? '';
				}

				const lastFetchdIdNext = arrRegulationNext.at(-1)!.id;

				setDSRegulation(dsRegulationNext);

				setDSSortOrders(dsSortOrdersNext);

				setArrRegulation(arrRegulationNext);

				setlastFetchedId(lastFetchdIdNext);

				setCursor(arrRegulationNext.length);
			})().catch(() => {
				console.log('reg query failed');
			});
		}
	}, [dataVehPro, loadingVehPro, dataQuery, hidden]);

	const applyFilter = async (
		allFilters: InfinityListFilter[],
		sortField: string,
		sortOrder: string,
		currentView?: string,
	) => {
		const queryFilter =
			allFilters.length > 0 ? funcFilters2MongoQuery(allFilters) : '';

		const queryView =
			views.find(x => x.accessKey === (currentView ?? 'STD'))?.query ?? '{}';

		let queryFinal = '';
		if (queryFilter === '') {
			queryFinal = queryView;
		} else if (queryView === '') {
			queryFinal = queryFilter;
		} else {
			queryFinal = `{$and:[${queryView},${queryFilter}]}`;
		}

		const arrRegulationNext: Regulation[] = [];
		const dsRegulationNext: DataStoreRegulation = {};
		const dsSortOrdersNext: DataStoreSortOrders = {};

		const sortOrderServer = getSortDirectionServer(
			initialSortField,
			initialSortOrder,
		);

		const {data: dataQuery} = await getRegulationsQuery({
			variables: {
				query: queryFinal,
				sortField,
			},
		});

		(dataQuery?.regulationsQuery.regulations ?? []).forEach(x => {
			arrRegulationNext.push(x as Regulation);
		});

		arrRegulationNext.forEach(x => {
			dsRegulationNext[x.id] = x;
		});

		const lastFetchdIdNext = arrRegulationNext.at(-1)!.id;

		dsSortOrdersNext[initialSortField] =
			dataQuery?.regulationsQuery.regulationIds ?? '';

		setDSRegulation({
			...dsRegulation,
			...dsRegulationNext,
		});

		setArrRegulation(arrRegulationNext);

		setDSSortOrders({});

		setlastFetchedId(lastFetchdIdNext);

		setCursor(arrRegulationNext.length);

		setKeyPrefix(uuidv4());
	};

	const applySort = async (
		sortField: string,
		sortOrder: string | undefined,
		setSortOrder: (sortOrder: string) => void,
	) => {
		const arrIds = (dataQuery?.regulationsQuery.regulationIds ?? '').split(';');
		const sortOrderEff = sortOrder ? sortOrder : getSortOrderDefault(sortField);

		setSortOrder(sortOrderEff);

		const arrIdsNext = await getIdsNext(
			arrIds,
			sortField,
			sortOrderEff,
			0,
			intBatchSize,
		);

		if (arrIdsNext.length > 0) {
			const {dsRegulationNew, arrRegulationNext} = await fetchServerNextData(
				arrIdsNext,
			);

			const lastIdNext = arrRegulationNext.at(-1)!.id ?? '';

			setArrRegulation(arrRegulationNext);

			setlastFetchedId(lastIdNext);

			setCursor(arrIdsNext.length);

			setKeyPrefix(uuidv4());
		}
	};

	const loadItemsNext = async (
		currentId: string,
		sortField: string,
		sortOrder: string | undefined,
	) => {
		if (currentId === lastFetchedId) {
			// *currentId is equal to known lastFetchedId (last row has been mounted)
			// 		*ids are known -> fetch next batch -> set lastFetchdId and cursor correctly

			const arrIds = (dataQuery?.regulationsQuery.regulationIds ?? '').split(
				';',
			);
			const sortOrderEff = sortOrder
				? sortOrder
				: getSortOrderDefault(sortField);

			const intEnd = cursor + intBatchSize;

			const arrIdsNext = await getIdsNext(
				arrIds,
				sortField,
				sortOrderEff,
				cursor,
				intEnd,
			);

			if (arrIdsNext.length > 0) {
				const {dsRegulationNew, arrRegulationNext} = await fetchServerNextData(
					arrIdsNext,
				);

				const lastIdNext = arrRegulationNext.at(-1)!.id ?? '';

				setlastFetchedId(lastIdNext);

				setCursor(intEnd);

				setArrRegulation(prevItems => [...prevItems, ...arrRegulationNext]);
			}
		}
	};

	const retrieveItemsAll = async (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		const arrIds = (dataQuery?.regulationsQuery.regulationIds ?? '').split(';');
		const sortOrderEff = sortOrder ? sortOrder : getSortOrderDefault(sortField);
		const arrIdsNext = await getIdsNext(
			arrIds,
			sortField,
			sortOrderEff,
			0,
			arrIds.length,
		);

		const {dsRegulationNew, arrRegulationNext} = await fetchServerNextData(
			arrIdsNext,
		);

		return arrRegulationNext;
	};

	const handleViewingItem = (item: Regulation) => {
		navigate({
			pathname: `/regulations/${item.id}`,
		});
	};

	const createHref = (item: unknown) =>
		`${PAGE_PATHS.regulations}/${(item as InfinityListItemWithId).id}`;

	const numberOfElements = useMemo(() => {
		if (dataQuery?.regulationsQuery.regulationIds) {
			return dataQuery?.regulationsQuery.regulationIds.split(';').length;
		}
		return (
			dataVehPro?.vehicleProjectDetailsOptimized.regulationRefs.split(';')
				.length ?? 1
		);
	}, [dataQuery, arrRegulation, dataVehPro]);

	// *columns -----------------------------------------------------------------------------------------------------------------------
	const columns: InfinityListColumn[] = useMemo(() => {
		const colTemp = [
			{
				name: 'attachments',
				key: 'attachments',
				isIconOnly: true,
				iconName: 'Attach',
				fieldName: 'attachments',
				minWidth: 16,
				maxWidth: 16,
				onRender: renderAttachments(),
			},
			{
				key: 'regulationNumber',
				name: t('RegulationNumber'),
				fieldName: 'regulationNumber',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: RegulationNumberCell,
			},
			{
				key: 'name',
				name: t('Name'),
				fieldName: 'name',
				isMultiline: true,
				minWidth: 350,
				maxWidth: 450,
				isResizable: true,
			},

			{
				key: 'markets',
				name: t('Markets'),
				fieldName: 'markets',
				isMultiline: true,
				minWidth: 180,
				maxWidth: 200,
				isResizable: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},

			{
				key: 'mainKeywords',
				name: t('MainKeywords'),
				fieldName: 'mainKeywords',
				isMultiline: true,
				minWidth: 180,
				maxWidth: 250,
				isResizable: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'keywords',
				name: t('KeyWords'),
				fieldName: 'keywords',
				isMultiline: true,
				minWidth: 180,
				maxWidth: 250,
				isResizable: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderKeywordsCell,
			},
			{
				key: 'standardPlusPcmsClusters',
				name: t('StandardPlusPcmsClusters'),
				fieldName: 'standardPlusPcmsClusters',
				isMultiline: true,
				minWidth: 180,
				maxWidth: 200,
				isResizable: true,
				filterType: 'Array',
				onRender: renderArrayField(),
				tooltipHostProps: {
					content: parseTooltipNewlines(tt('standardPlusPcmsClusters')),
				},
			},
		];
		return colTemp;
	}, []);
	const columnFilterOptions: InfinityListColumnFilterOptions[] = useMemo(
		() => [
			{
				key: 'regulationNumber',
				fieldValuesUnique:
					dataQuery?.regulationsQuery.regulationFilterOptions.regulationOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'markets',
				fieldValuesUnique:
					dataQuery?.regulationsQuery.regulationFilterOptions.marketOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'mainKeywords',
				fieldValuesUnique:
					dataQuery?.regulationsQuery.regulationFilterOptions.mainKeywordOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'keywords',
				fieldValuesUnique:
					dataQuery?.regulationsQuery.regulationFilterOptions.keywordOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'standardPlusPcmsClusters',
				fieldValuesUnique:
					dataQuery?.regulationsQuery.regulationFilterOptions.pcmsOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
		],
		[dataQuery],
	);

	return (
		<>
			{!hidden && (
				<InfinityList
					pageName={tTitle('Regulations')}
					items={arrRegulation}
					sticky={true}
					columns={columns}
					columnFilterOptions={columnFilterOptions}
					selectionMode={SelectionMode.single}
					stillLoading={loadingQuery || loadingDocuments}
					initalFilters={linksFilters}
					initialSortField={initialSortField}
					initialSortOrder={initialSortOrder}
					initialView={initialView}
					keyPrefix={keyPrefix}
					firstRender={firstRender}
					loadItemsNext={loadItemsNext}
					retrieveItemsAll={retrieveItemsAll}
					applyFilter={applyFilter}
					applySort={applySort}
					handleInvokeItem={handleViewingItem}
					createHref={createHref}
					numberOfElements={numberOfElements}
					showHeader={false}
					views={views}
				/>
			)}
		</>
	);
};

export default VehicleProjectDetailsTabRegulations;
