/* eslint-disable react/display-name */
import {
	DirectionalHint,
	Link as FluentLink,
	FontIcon,
	IColumn,
	IImageStyles,
	IPersonaProps,
	IStackStyles,
	ITextStyles,
	ITooltipProps,
	Image,
	Label,
	Persona,
	Spinner,
	Stack,
	Text,
	Theme,
	TooltipDelay,
	TooltipHost,
	mergeStyleSets,
	useTheme,
} from '@fluentui/react';
import {useBoolean, useId} from '@fluentui/react-hooks';
import {RichtextDisplay} from 'components/RichtextDisplay';
import {ErrorModal} from 'components/ErrorModal';
import {HierarchyListIndicator} from 'components/HieraryListIndicator';
import {DateTimeFormat, formatDateTime} from 'i18n/localeDateFormat';
import {i18n} from 'i18next';
import React from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import 'react-quill/dist/quill.bubble.css';
import {Link, useNavigate} from 'react-router-dom';
import {
	AttachmentCategory,
	AttachmentRef,
	BlobRef,
	DocumentReference,
	DocumentStatus,
	NamedEntity,
	ParagraphElementType,
	PhaseDate,
	PogisDate,
	RegulatoryDocumentParagraph,
	RegulatoryDocumentParagraphElement,
	Requirement,
	RequirementChangeType,
	TextPosition,
	User,
} from 'types';
import {v4 as uuidv4} from 'uuid';
import {Personas} from 'components/Personas';
import {VexClusterOfVexClustersField} from './RequirementsFields/VexClustersField/VexClusterItem';
import {TableComponent} from 'components/TableComponent';
import DOMPurify from 'dompurify';
import {DEFAULT_DATE} from './EntityUtils';
import {FictiousSegment} from '../../features/RegulatoryDocuments/components/DocumentDetails/FictiousSegmentDetails/FictiousSegment';
import {FaqComponent} from 'components/Faq/FaqComponent';
import {getStatusTag, RequirementsField} from './RequirementField';

export type FieldRenderInput = {
	item: any;
	i?: number;
	col?: IColumn;
};

export type LazyFieldRenderInput = FieldRenderInput & {isLazy: boolean};

export type RenderFn = (
	item?: any,
	index?: number | undefined,
	column?: IColumn | undefined,
) => JSX.Element;

const getFieldValue = (
	item: any,
	_: number | undefined,
	col: IColumn | undefined,
) => {
	if (col?.fieldName) {
		return item[col.fieldName];
	}

	return null;
};

export type RichTextValue = string | null;

export const renderRichtext: (
	limit?: number,
	removeHtml?: boolean,
) => RenderFn =
	(limit = 400, removeHtml = false) =>
	(item, i, col) => {
		const fieldValue: RichTextValue = getFieldValue(item, i, col);

		if (fieldValue) {
			return (
				<RichtextDisplay
					value={fieldValue}
					limit={limit}
					removeHtml={removeHtml}
				/>
			);
		}

		return <></>;
	};

export type ValueForPersonaColumn = User | null;

export const renderPersona: (props?: Partial<IPersonaProps>) => RenderFn =
	props => (item, i, col) => {
		const fieldValue: ValueForPersonaColumn = getFieldValue(item, i, col);

		if (fieldValue) {
			return <Persona text={fieldValue.name} {...props} />;
		}

		return <></>;
	};

export const renderPersonas: (props?: Partial<IPersonaProps>) => RenderFn =
	props => (item, i, col) => {
		const fieldValue: User[] | null = getFieldValue(item, i, col);

		return (
			<div>
				{fieldValue ? <Personas persons={fieldValue} {...props} /> : <></>}
			</div>
		);
	};

export type ArrayValueProps<T> = {
	array: T[];
	getValue?: (arrayItem: T) => any;
	limit?: number;
	getTextStyles?: (arrayItem: T) => ITextStyles | undefined;
};

export interface ArrayItemWithName {
	name: string;
}

/**
 * @param array This can be any type of array. If each item in the array has a
 * "name" field, this component will render it automatically.
 */
export function ArrayValues<Item>({
	array,
	getValue,
	limit,
	getTextStyles,
}: ArrayValueProps<Item>) {
	const theme = useTheme();

	const classNames = mergeStyleSets({
		arrayWrapper: {
			display: 'flex !important',
			flexWrap: 'wrap',
		},
		arrayItem: {
			borderRadius: '10px',
			color: theme?.palette.neutralSecondary,
			background: theme?.palette.neutralLight,
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
			whiteSpace: 'nowrap',
		},
		arrayButton: {
			cursor: 'pointer',
		},
	});

	const [showAll, setShowAll] = React.useState(limit === undefined);
	const truncatedArray = React.useMemo(
		() => array.slice(0, limit),
		[array, limit],
	);
	const diff = React.useMemo(() => array.length - (limit ?? 0), [array, limit]);

	const renderValues = (arr: Item[]) =>
		arr.map(arrayItem => {
			const displayValue =
				getValue?.(arrayItem) ?? ((arrayItem as any)?.name || arrayItem);

			return displayValue ? (
				<Text
					key={uuidv4()}
					className={classNames.arrayItem}
					styles={getTextStyles?.(arrayItem)}
					title={displayValue}
				>
					{displayValue}
				</Text>
			) : (
				<></>
			);
		});
	if (limit === undefined || limit >= array.length) {
		return <div className={classNames.arrayWrapper}>{renderValues(array)}</div>;
	}

	return (
		<div className={classNames.arrayWrapper}>
			{showAll ? (
				<div className={classNames.arrayWrapper}>{renderValues(array)}</div>
			) : (
				<div className={classNames.arrayWrapper}>
					{renderValues(truncatedArray)}
					<Text
						onClick={() => setShowAll(true)}
						className={`${classNames.arrayItem} ${classNames.arrayButton}`}
					>{`+ ${diff}`}</Text>
				</div>
			)}
		</div>
	);
}
export const renderDateString: () => RenderFn = () => (item, i, col) => {
	const fieldValue = getFieldValue(item, i, col);
	const {i18n} = useTranslation();

	const date =
		fieldValue && fieldValue !== DEFAULT_DATE
			? formatDateTime(new Date(fieldValue), i18n)
			: '';
	return <div>{`${date}`}</div>;
};

export const renderDateStringOrDefaultDate: () => RenderFn =
	() => (item, i, col) => {
		const fieldValue = getFieldValue(item, i, col);
		const {i18n} = useTranslation();

		const date =
			fieldValue && fieldValue !== DEFAULT_DATE
				? formatDateTime(new Date(fieldValue), i18n)
				: formatDateTime(new Date(DEFAULT_DATE), i18n);
		return <div>{`${date}`}</div>;
	};

export function renderArrayField<T>(
	getValue?: (arrayItem: T) => any,
	limit?: number,
	getTextStyles?: (arrayItem: T) => ITextStyles | undefined,
): RenderFn {
	return (item, i, col) => {
		const fieldValue: T[] | null = getFieldValue(item, i, col);

		if (!Array.isArray(fieldValue)) {
			return <></>;
		}

		return (
			<ArrayValues
				array={fieldValue}
				getValue={getValue}
				getTextStyles={getTextStyles}
				limit={limit ?? 4}
			/>
		);
	};
}

const PhaseField: React.FC<FieldRenderInput> = ({item, i, col}) => {
	const {i18n} = useTranslation();

	return renderArrayField((phase: PhaseDate) => {
		return phase.status
			? `${getDateString(i18n, phase.date)} (${phase.status})`
			: `${getDateString(i18n, phase.date)}`;
	})(item, i, col);
};

export const renderPhase: () => RenderFn = () => (item, i, col) =>
	<PhaseField {...{item, i, col}} />;

export type RequirementWithoutDetails = Pick<Requirement, 'id' | 'name'>;
export interface RequirementOfRequirementsField
	extends Pick<
		Requirement,
		'id' | 'status' | 'name' | 'requirementId' | 'definition'
	> {
	vexClusters: VexClusterOfVexClustersField[];
}

export const renderRequirements: () => RenderFn = () => (item, i, col) =>
	<RequirementsField {...{item, i, col, isLazy: false}} />;

export const renderLazyRequirements: () => RenderFn = () => (item, i, col) =>
	<RequirementsField {...{item, i, col, isLazy: true}} />;

export const renderBoolean: () => RenderFn = () => (item, i, col) => {
	const fieldValue = getFieldValue(item, i, col);

	return fieldValue ? (
		<FontIcon iconName='CheckMark' style={{color: 'green'}} />
	) : (
		<FontIcon iconName='Cancel' style={{color: 'red'}} />
	);
};

export const renderDeleted: () => RenderFn = () => (item, i, col) => {
	const fieldValue = getFieldValue(item, i, col);

	return fieldValue ? (
		<FontIcon iconName='RemoveFromShoppingList' style={{color: 'red'}} />
	) : (
		<FontIcon iconName='AddToShoppingList' style={{color: 'green'}} />
	);
};

const getDateString = (
	i18n: i18n,
	fieldValue: Date | null,
	format?: DateTimeFormat,
) => {
	if (fieldValue instanceof Date && !isNaN(fieldValue as any)) {
		return formatDateTime(fieldValue, i18n, format);
	}

	if (typeof fieldValue === 'string') {
		return formatDateTime(new Date(fieldValue), i18n, format);
	}

	return '';
};

export type ValueForDateColumn = Date | null;

/**
 * By default, this requires setting a fieldName to get the item's value.
 */
const DateField: React.FC<FieldRenderInput & {format?: DateTimeFormat}> = ({
	item,
	i,
	col,
	format,
}) => {
	const {i18n} = useTranslation();

	const fieldValue: ValueForDateColumn = getFieldValue(item, i, col);

	return <span>{getDateString(i18n, fieldValue, format)}</span>;
};

export const renderDate: (format?: DateTimeFormat) => RenderFn =
	format => (item, i, col) =>
		<DateField {...{item, i, col, format}} />;

const PogisDateField: React.FC<
	FieldRenderInput & {format?: DateTimeFormat}
> = ({item, i, col, format}) => {
	const {i18n} = useTranslation();
	return renderArrayField(
		(pogisDate: PogisDate) =>
			`${getDateString(i18n, pogisDate.date, format)} (${pogisDate.dateType})`,
	)(item, i, col);
};

export const renderPogisDate: (format?: DateTimeFormat) => RenderFn =
	format => (item, i, col) =>
		<PogisDateField {...{item, i, col, format}} />;

type BlobRefWithOptionalFileName = {
	uri: BlobRef['uri'];
	fileName?: BlobRef['fileName'];
};
export interface FieldsForAttachmentColumn
	extends Pick<AttachmentRef, 'attachmentId'> {
	file: BlobRefWithOptionalFileName;
	category?: Pick<AttachmentCategory, 'name'> | null;
}

export type ValueForAttachmentColumn =
	| FieldsForAttachmentColumn[]
	| null
	| undefined;

const AttachmentField: React.FC<FieldRenderInput> = ({item, i, col}) => {
	const fieldValue: ValueForAttachmentColumn = getFieldValue(item, i, col);
	const {t} = useTranslation('components/columnrenderers', {
		keyPrefix: 'Attachments',
	});
	const theme = useTheme();

	return attachmentField({fieldValue, i, t, theme});
};

const AttachmentFieldPogis: React.FC<FieldRenderInput> = ({item, i, col}) => {
	const {t} = useTranslation('features/pogisdocuments', {
		keyPrefix: 'PogisDocumentsList',
	});
	const theme = useTheme();
	const regex = /POGIS\sSummary\s\d+\.pdf/g;

	const summaryAttachments: AttachmentRef[] = item?.pogisAttachments.filter(
		(x: any) => {
			return x?.file.fileName.match(regex);
		},
	);

	const otherAttachments: AttachmentRef[] = item?.pogisAttachments.filter(
		(x: any) => {
			return !x?.file.fileName.match(regex);
		},
	);

	return (
		<div>
			<Stack horizontal>
				<Stack>
					{summaryAttachments.length > 0 && (
						<>
							<b>{t('SummaryAttach')}:</b>
							<div style={{marginLeft: 3, marginTop: 3, marginBottom: 6}}>
								{(summaryAttachments || []).map((attachment, i) => (
									<Stack horizontal key={i} tokens={{childrenGap: 6}}>
										<FluentLink
											download
											href={attachment.file.uri}
											theme={theme}
										>
											<FontIcon iconName='Attach' />
											{attachment.attachmentId}
										</FluentLink>
										{attachment.category && (
											<Stack.Item>{`(${attachment.category?.name})`}</Stack.Item>
										)}
									</Stack>
								))}
							</div>
						</>
					)}
					{otherAttachments.length > 0 && (
						<>
							<b>{t('OtherAttach')}:</b>
							<div style={{marginLeft: 3, marginTop: 3}}>
								{(otherAttachments || []).map((attachment, i) => (
									<Stack horizontal key={i} tokens={{childrenGap: 6}}>
										<FluentLink
											download
											href={attachment.file.uri}
											theme={theme}
										>
											<FontIcon iconName='Attach' />
											{attachment.attachmentId}
										</FluentLink>
										{attachment.category && (
											<Stack.Item>{`(${attachment.category?.name})`}</Stack.Item>
										)}
									</Stack>
								))}
							</div>
						</>
					)}
				</Stack>
			</Stack>
		</div>
	);
};
const attachmentField: React.FC<{
	fieldValue: ValueForAttachmentColumn | any;
	i: number | undefined;
	t: TFunction;
	theme: Theme;
}> = ({fieldValue, i, t, theme}) => {
	return (
		<AttachmentFieldComponent
			fieldValue={fieldValue}
			i={i}
			t={t}
			theme={theme}
		/>
	);
};

export const AttachmentFieldComponent: React.FC<{
	fieldValue: ValueForAttachmentColumn | any;
	i: number | undefined;
	t: TFunction;
	theme: Theme;
}> = ({fieldValue, i, t, theme}) => {
	return fieldValue && fieldValue.length > 0 ? (
		<TooltipHost
			key={`${i}`}
			tooltipProps={{
				onRenderContent: () => (
					<Stack>
						<Text variant='medium'>{t('AttachmentsTooltipHeader')}</Text>
						{(fieldValue || []).map((attRef: any, i: number) => (
							<Stack.Item key={i}>
								<Stack horizontal key={i} tokens={{childrenGap: 6}}>
									<FluentLink
										target='_blank'
										download={false}
										href={
											attRef.file.originalFileName
												? `${attRef.file.uri}/${attRef.file.originalFileName}`
												: `${attRef.file.uri}`
										}
									>
										{attRef.file.originalFileName
											? attRef.file.originalFileName
											: attRef.attachmentId}
									</FluentLink>
									{attRef.category && (
										<Stack.Item
											style={{whiteSpace: 'nowrap'}}
										>{`(${attRef.category?.name})`}</Stack.Item>
									)}
								</Stack>
							</Stack.Item>
						))}
					</Stack>
				),
			}}
		>
			<FontIcon
				style={{fontSize: theme.fonts.mediumPlus.fontSize || undefined}}
				iconName='Attach'
			/>
		</TooltipHost>
	) : (
		<></>
	);
};

const LinkField: React.FC<FieldRenderInput> = ({item}) => {
	const navigate = useNavigate();

	return item?.linkVersion ? (
		<div
			onClick={() => navigate(`/${item?.linkVersion}`)}
			style={{cursor: 'pointer'}}
		>
			{item?.linkVersion}
		</div>
	) : (
		<></>
	);
};

export const renderLinkField: () => RenderFn = () => (item, i, col) =>
	<LinkField {...{item, i, col}} />;

export const renderAttachments: () => RenderFn = () => (item, i, col) =>
	<AttachmentField {...{item, i, col}} />;

export const renderFaqs: () => RenderFn = () => (item, i, col) => {
	if (!item.id) return <></>;
	return <FaqComponent questions={(item as any).question} />;
};

export const renderAttachmentsPogis: () => RenderFn = () => (item, i, col) =>
	<AttachmentFieldPogis {...{item, i, col}} />;

export interface ElementFieldsForParagraphColumn
	extends Pick<
		RegulatoryDocumentParagraphElement,
		'type' | 'text' | 'isHeader' | 'textPosition'
	> {
	asset?: Pick<BlobRef, 'uri'> | null;
}

type ParagraphFieldsSetToOptional = Pick<
	Partial<RegulatoryDocumentParagraph>,
	'enumeration' | 'isFootnote' | 'level'
>;

/**
 * Note that "enumeration" and "isFootnote" are required if you want to show the
 * enumeration.
 */
export interface FieldsForParagraphColumn extends ParagraphFieldsSetToOptional {
	elements: ElementFieldsForParagraphColumn[];
}

type ParagraphFieldProps = {
	item: FieldsForParagraphColumn;
	t: TFunction;
	showEnumeration?: boolean;
};

export const ParagraphField: React.FC<ParagraphFieldProps> = ({
	item,
	t,
	showEnumeration = true,
}) => {
	const classNames = mergeStyleSets({
		labels: {
			padding: 0,
			fontSize: 16,
		},
		text: {
			whiteSpace: 'pre-wrap',
		},
	});

	const imageStyles: IImageStyles = {
		root: {zIndex: 900},
		image: {maxWidth: '100%'},
	};

	const stackStyles: IStackStyles = {
		root: {
			paddingTop: 11,
			paddingBottom: 11,
			paddingLeft: 8,
		},
	};

	const renderTextElement = (
		text: string,
		textPosition?: TextPosition | null,
	) => {
		const textContent = DOMPurify.sanitize(text);
		const textElement = (
			<span dangerouslySetInnerHTML={{__html: textContent}} />
		);

		switch (textPosition) {
			case TextPosition.Subscript:
				return <sub>{textElement}</sub>;
			case TextPosition.Superscript:
				return <sup>{textElement}</sup>;

			default:
				return <>{textElement}</>;
		}
	};

	return (
		<Stack horizontal styles={{root: {height: '100%'}}}>
			<HierarchyListIndicator level={item.level ?? 0} horizontalLineY={32} />
			<Stack styles={stackStyles}>
				{showEnumeration && (
					<Label className={classNames.labels}>
						{item.isFootnote ? t('Footnote') : item.enumeration}
					</Label>
				)}
				<Text>
					{item.elements &&
					item.elements[0]?.text?.startsWith('VKO_PDF_DISMANTLED') ? (
						<>
							<FictiousSegment text={item.elements[0].text} item={item} />
						</>
					) : (
						(item.elements || []).map((elem, i) => {
							switch (elem.type) {
								case ParagraphElementType.Text:
									if (elem.asset) {
										return (
											<Image
												key={i}
												src={elem.asset?.uri || ''}
												styles={imageStyles}
											/>
										);
									}

									return (
										<span key={i} className={classNames.text}>
											{elem.isHeader
												? ''
												: renderTextElement(elem.text || '', elem.textPosition)}
										</span>
									);
								case ParagraphElementType.Table:
								case ParagraphElementType.Image:
									return (
										<Image
											key={i}
											src={elem.asset?.uri || ''}
											styles={imageStyles}
										/>
									);
								case ParagraphElementType.NewLine:
									return (
										<div key={i}>
											<br />
										</div>
									);
								case ParagraphElementType.HtmlTable:
									return <TableComponent key={i} htmlTable={elem.text ?? ''} />;
								default:
									return <></>;
							}
						})
					)}
				</Text>
			</Stack>
		</Stack>
	);
};

export const renderParagraph = (
	item: RegulatoryDocumentParagraph,
	t: TFunction,
	showEnumeration?: boolean,
) => <ParagraphField {...{item, t, showEnumeration}} />;

export const renderRegulatoryDocumentStatus: () => RenderFn = () =>
	renderStatus('RegulatoryDocumentStatus');

export const renderRequirementStatus = (): RenderFn =>
	renderStatus('RequirementStatus');

export const renderRequirementCategory = (): RenderFn =>
	renderStatus('RequirementCategory');

export const renderRequirementChangeType = (): RenderFn =>
	renderStatus('RequirementChangeType');

export interface StatusFieldProps extends FieldRenderInput {
	enumName: string;
	value?: string;
}

//TO DO - need to change the enumname to enumtype to avoid hardcoding
export const StatusField: React.FC<StatusFieldProps> = ({
	item,
	i,
	col,
	enumName,
	value,
}) => {
	const theme = useTheme();
	const {t} = useTranslation('common/enums', {
		keyPrefix: enumName,
	});
	const fieldValue: any = value ?? getFieldValue(item, i, col);
	if (
		enumName === 'RequirementChangeType' &&
		fieldValue === RequirementChangeType.None
	) {
		return <></>;
	}

	if (fieldValue) {
		return getStatusTag(fieldValue, theme, t);
	}

	return <></>;
};

/**
 * ! Important info
 *
 * To show the correct value, the column must have a fieldName, and
 * item[fieldName] must be defined.
 */
export const renderStatus =
	(enumName: string): RenderFn =>
	(item, i, col) =>
		<StatusField {...{item, i, col, enumName}} />;

const DocumentToolTipContent: React.FC<{
	documentStatus: DocumentStatus;
	showErrorModal: () => void;
}> = ({documentStatus, showErrorModal}) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'SplitDocumentLegend',
	});

	switch (documentStatus) {
		case DocumentStatus.NotSplit:
			return <div>{t('DocumentNotDismantled')}</div>;
		case DocumentStatus.SplittingInProgress:
			return <div>{t('DocumentIsDismantling')}</div>;
		case DocumentStatus.SplittingFinished:
			return <div>{t('DocumentIsDismantled')}</div>;
		case DocumentStatus.SplittingError:
			return (
				<div>
					<div>{t('DocumentError')}</div>
					<FluentLink as='button' onClick={showErrorModal}>
						Mehr anzeigen...
					</FluentLink>
				</div>
			);
		default:
			return <></>;
	}
};

const DocumentField: React.FC<FieldRenderInput> = ({item, i, col}) => {
	const theme = useTheme();
	const classNames = mergeStyleSets({
		fileIconHeaderIcon: {
			padding: 0,
			fontSize: '16px',
			color: theme.palette.neutralPrimary,
		},
		fileIconHeaderIconNotSplit: {
			padding: 0,
			fontSize: '16px',
			color: theme.palette.red,
		},
	});

	const documentStatus: DocumentStatus = getFieldValue(item, i, col);
	const {documentError} = item;
	const documentTooltipId = useId(`documentTooltip-${i}`);

	const [
		isDocumentErrorModalOpen,
		{setTrue: showDocumentErroModal, setFalse: hideDocumentErrorModal},
	] = useBoolean(false);

	let component = <></>;
	if (!documentStatus) {
		return component;
	}

	if (documentStatus === DocumentStatus.NotSplit) {
		component = (
			<FontIcon
				aria-label='Page'
				iconName='PDF'
				className={classNames.fileIconHeaderIconNotSplit}
			/>
		);
	}

	if (documentStatus === DocumentStatus.SplittingInProgress) {
		component = <Spinner />;
	}

	if (documentStatus === DocumentStatus.SplittingFinished) {
		component = (
			<FontIcon
				aria-label='Page'
				iconName='PDF'
				className={classNames.fileIconHeaderIcon}
			/>
		);
	}

	if (documentStatus === DocumentStatus.SplittingError) {
		component = (
			<FontIcon
				aria-label='Page'
				iconName='StatusErrorFull'
				className={classNames.fileIconHeaderIconNotSplit}
			/>
		);
	}

	const onRenderTooltipContent = () => (
		<DocumentToolTipContent
			documentStatus={documentStatus}
			showErrorModal={showDocumentErroModal}
		/>
	);

	const documentTooltipProps: ITooltipProps = {
		onRenderContent: onRenderTooltipContent,
	};

	return (
		<React.Fragment>
			<TooltipHost
				tooltipProps={documentTooltipProps}
				delay={TooltipDelay.zero}
				id={documentTooltipId}
				directionalHint={DirectionalHint.leftCenter}
			>
				{component}
			</TooltipHost>
			<ErrorModal
				isOpen={isDocumentErrorModalOpen}
				onDismiss={hideDocumentErrorModal}
				error={documentError}
			/>
		</React.Fragment>
	);
};

export const renderDocument: () => RenderFn = () => (item, i, col) =>
	<DocumentField {...{item, i, col}} />;

type FieldsForReferenceItem = Pick<NamedEntity, 'id' | 'name'>;

function ReferenceItem<T extends FieldsForReferenceItem>(props: {
	entities: T[];
	referenceName: string;
	getUrl: (entity: T) => string;
}) {
	const theme = useTheme();
	const {entities, referenceName, getUrl} = props;

	return (
		<React.Fragment>
			{entities.map(entity => (
				<Stack
					key={`${referenceName}-${entity.id}`}
					horizontal
					tokens={{childrenGap: 4}}
				>
					<span>{referenceName}:</span>
					<Link
						style={{
							color: theme.palette.themePrimary,
							textDecoration: 'none',
						}}
						to={getUrl(entity)}
					>
						{entity.name}
					</Link>
				</Stack>
			))}
		</React.Fragment>
	);
}

interface ReferenceForReferenceField
	extends Partial<
		Pick<
			DocumentReference,
			'regulationReferences' | 'regulatoryDocumentReferences'
		>
	> {
	requirementReferences?: FieldsForReferenceItem[];
	reference?: FieldsForReferenceItem | null;
}

export const ReferenceField: React.FC<{
	references: ReferenceForReferenceField[];
}> = ({references}) => {
	return (
		<React.Fragment>
			{references?.map((dr: ReferenceForReferenceField, i) => (
				<Stack key={i}>
					{dr.regulationReferences && dr.regulationReferences?.length > 0 && (
						<ReferenceItem
							entities={dr.regulationReferences}
							referenceName={dr.reference?.name ?? ''}
							getUrl={regulation => `/regulations/${regulation.id}`}
						/>
					)}
					{dr.requirementReferences && dr.requirementReferences?.length > 0 && (
						<ReferenceItem
							entities={dr.requirementReferences}
							referenceName={dr.reference?.name ?? ''}
							getUrl={requirement => `/requirements/${requirement.id}`}
						/>
					)}
					{dr.regulatoryDocumentReferences &&
						dr.regulatoryDocumentReferences?.length > 0 && (
							<ReferenceItem
								entities={dr.regulatoryDocumentReferences}
								referenceName={dr.reference?.name ?? ''}
								getUrl={regulatoryDocument =>
									regulatoryDocument.regulation
										? `/regulations/${regulatoryDocument.regulation?.id}/${regulatoryDocument.id}/paragraphs`
										: `/regulatorydocuments/${regulatoryDocument.id}`
								}
							/>
						)}
				</Stack>
			))}
		</React.Fragment>
	);
};

export type ValueForReferenceColumn = ReferenceForReferenceField[] | null;

export const renderReferenceField = (): RenderFn => (item, i, col) => {
	const fieldValue: ValueForReferenceColumn = getFieldValue(item, i, col);

	if (!fieldValue) {
		return <></>;
	}

	return <ReferenceField {...{references: fieldValue}} />;
};
