import {
	Checkbox,
	CommandButton,
	IBasePicker,
	IconButton,
	ITag,
	Separator,
	Stack,
	TagPicker,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {
	InfinityListColumn,
	InfinityListFilter,
	InfinityListFilterOption,
} from './InfinityList';
import {LocalizedDatePicker} from 'components/hookForms';
import {useTranslation} from 'react-i18next';
import {formatDateTime} from 'i18n/localeDateFormat';

type FilterItemProps = {
	col: InfinityListColumn;
	fieldValuesUnique?: InfinityListFilterOption[];
	allListFilters: InfinityListFilter[];
	setAllListFilters: (allListFilters: InfinityListFilter[]) => void;
};

export const FilterItem: React.FC<FilterItemProps> = ({
	col,
	fieldValuesUnique,
	allListFilters,
	setAllListFilters,
}) => {
	const {name: columnName, key: columnKey, filterOnFilter} = col;

	const [allFilters, setAllFilters] = useState<InfinityListFilter[]>(
		allListFilters.filter(x => x.columnKey === columnKey),
	);

	const {t} = useTranslation('components/infinitylist', {
		keyPrefix: 'FilterComponent',
	});

	const [isExpanded, {toggle: toggleExpanded}] = useBoolean(false);

	const [limitCheckboxes, setLimitChechboxes] = useState(50);

	const [searchedOptions, setSearchedOptions] = useState<
		InfinityListFilterOption[]
	>([]);

	const refTagPicker = useRef<IBasePicker<ITag>>(null);

	const valuesUnique = fieldValuesUnique ?? [];

	const selectedOptions = allFilters.map(x => x.selection);

	const checkboxes = useMemo(() => {
		const shownOptions = valuesUnique
			.filter(x => searchedOptions.indexOf(x) === -1 && x.value !== '')
			.slice(0, limitCheckboxes);

		const hiddenSelectedOptionsIds = selectedOptions.filter(
			x =>
				!(
					shownOptions.filter(y => y.id === x).length > 0 ||
					searchedOptions.filter(y => y.id === x).length > 0
				),
		);
		const hiddenSelectedOptions = valuesUnique.filter(x =>
			hiddenSelectedOptionsIds.includes(x.id),
		);
		const checkboxOptions = [
			...searchedOptions,
			...hiddenSelectedOptions,
			...shownOptions,
		];

		return checkboxOptions.map((val, j) => (
			<Checkbox
				key={uuidv4()}
				label={val.value}
				defaultChecked={selectedOptions.includes(val.id)}
				onChange={(_, checked) => {
					if (checked) {
						const newSelection: InfinityListFilter = {
							columnKey,
							selection: val.id,
							display: val.value,
							operation: 'eq',
						};
						setAllFilters([...allFilters, newSelection]);
						setAllListFilters([...allListFilters, newSelection]);
					} else {
						setAllFilters(allFilters.filter(x => !(x.selection === val.id)));
						setAllListFilters(
							allListFilters.filter(x => !(x.selection === val.id)),
						);
					}
				}}
			/>
		));
	}, [
		valuesUnique,
		allFilters,
		allListFilters,
		limitCheckboxes,
		searchedOptions,
	]);

	const numHiddenOptions = valuesUnique.length - checkboxes.length;

	const onResolveSuggestions = (
		filterText: string,
		selectedItems?: ITag[],
	): ITag[] => {
		if (!filterText) {
			return [];
		}

		const safeFilterText = filterText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		const reFilter = new RegExp((safeFilterText ?? '').toLowerCase());

		const fieldValueSuggestions = valuesUnique.filter(
			x =>
				reFilter.test(x.value.toLowerCase()) && !selectedOptions.includes(x.id),
		);

		const suggestions: ITag[] = (fieldValueSuggestions ?? [])
			.slice(0, 100)
			.map((val, j) => ({
				key: val.id,
				name: val.value,
			}));

		return suggestions;
	};

	const onEmptyResolveSuggestions = () => {
		const fieldValueSuggestions = valuesUnique;

		const suggestions: ITag[] = (fieldValueSuggestions ?? [])
			.slice(0, 100)
			.map((val, j) => ({
				key: val.id,
				name: val.value,
			}));

		return suggestions;
	};

	return (
		<>
			<Stack tokens={{childrenGap: 8}}>
				<CommandButton
					iconProps={{iconName: isExpanded ? 'ChevronUp' : 'ChevronDown'}}
					text={columnName}
					onClick={() => toggleExpanded()}
					ariaLabel={`Toggle filter ${columnName}`}
				/>

				<Stack.Item
					styles={{
						root: {
							display: isExpanded ? 'block' : 'none',
						},
					}}
				>
					<>
						{filterOnFilter && (
							<>
								<TagPicker
									onResolveSuggestions={onResolveSuggestions}
									onEmptyResolveSuggestions={onEmptyResolveSuggestions}
									pickerSuggestionsProps={{
										suggestionsHeaderText: columnName,
										noResultsFoundText: t('NoResult'),
									}}
									onItemSelected={selectedItem => {
										if (selectedItem) {
											const newSelection: InfinityListFilter = {
												columnKey,
												selection: selectedItem.key as string,
												display: selectedItem.name,
												operation: 'eq',
											};
											setAllFilters([...allFilters, newSelection]);
											setAllListFilters([...allListFilters, newSelection]);
											setSearchedOptions(prev => [
												...prev,
												...valuesUnique.filter(
													x => x.value === selectedItem?.name,
												),
											]);
										}

										return null;
									}}
									inputProps={{
										placeholder: t('Search'),
									}}
									componentRef={refTagPicker}
								/>
								<br />
							</>
						)}
						{<Stack tokens={{childrenGap: 4}}>{checkboxes}</Stack>}
						{valuesUnique.length > limitCheckboxes && (
							<div style={{margin: 10}}>
								{
									<Stack horizontal horizontalAlign='space-between'>
										<div>
											<IconButton
												iconProps={{iconName: 'DoubleChevronDown'}}
												onClick={() => {
													setLimitChechboxes(
														limitCheckboxes < 200
															? limitCheckboxes + 100
															: limitCheckboxes + 500,
													);
												}}
											/>
											<i>{`...${numHiddenOptions} ${t('MoreOptions')}`}</i>
										</div>
										<IconButton
											iconProps={{iconName: 'DoubleChevronUp'}}
											onClick={() => {
												refTagPicker.current?.focus();
												setLimitChechboxes(50);
											}}
										/>
									</Stack>
								}
							</div>
						)}
					</>
				</Stack.Item>
			</Stack>
			<Separator />
		</>
	);
};

export const FilterItemDate: React.FC<FilterItemProps> = ({
	col,
	allListFilters,
	setAllListFilters,
}) => {
	const {name: columnName, key: columnKey} = col;

	const [allFilters, setAllFilters] = useState<InfinityListFilter[]>(
		allListFilters.filter(x => x.columnKey === columnKey),
	);

	const {t} = useTranslation('components/infinitylist', {
		keyPrefix: 'FilterComponent',
	});

	const {i18n} = useTranslation();

	const [isExpanded, {toggle: toggleExpanded}] = useBoolean(false);

	const dateFrom = allFilters
		.filter(x => x.columnKey === columnKey && x.operation === 'gte')
		.map(x => x.selection)
		.at(0);

	const dateTo = allFilters
		.filter(x => x.columnKey === columnKey && x.operation === 'lte')
		.map(x => x.selection)
		.at(0);

	return (
		<>
			<Stack tokens={{childrenGap: 8}}>
				<CommandButton
					iconProps={{iconName: isExpanded ? 'ChevronUp' : 'ChevronDown'}}
					text={columnName}
					onClick={() => toggleExpanded()}
					ariaLabel={`Toggle filter ${columnName}`}
				/>

				<Stack.Item
					styles={{
						root: {
							display: isExpanded ? 'block' : 'none',
						},
					}}
				>
					<Stack horizontal tokens={{childrenGap: 4}}>
						<LocalizedDatePicker
							label={t('From')}
							value={dateFrom ? new Date(dateFrom) : undefined}
							onSelectDate={(date: Date | null | undefined) => {
								if (date) {
									const newSelection: InfinityListFilter = {
										columnKey,
										selection: date.toISOString(),
										display: '>' + formatDateTime(date, i18n),
										operation: 'gte',
									};
									setAllFilters([
										...allFilters.filter(
											x =>
												!(x.columnKey === columnKey && x.operation === 'gte'),
										),

										newSelection,
									]);
									setAllListFilters([
										...allListFilters.filter(
											x =>
												!(x.columnKey === columnKey && x.operation === 'gte'),
										),
										newSelection,
									]);
								} else {
									setAllFilters(
										allFilters.filter(
											x =>
												!(x.columnKey === columnKey && x.operation === 'gte'),
										),
									);
									setAllListFilters(
										allListFilters.filter(
											x =>
												!(x.columnKey === columnKey && x.operation === 'gte'),
										),
									);
								}
							}}
						/>
						<LocalizedDatePicker
							label={t('To')}
							value={dateTo ? new Date(dateTo) : undefined}
							onSelectDate={(date: Date | null | undefined) => {
								if (date) {
									const newSelection: InfinityListFilter = {
										columnKey,
										selection: date.toISOString(),
										display: '<' + formatDateTime(date, i18n),
										operation: 'lte',
									};
									setAllFilters([
										...allFilters.filter(
											x =>
												!(x.columnKey === columnKey && x.operation === 'lte'),
										),
										newSelection,
									]);
									setAllListFilters([
										...allListFilters.filter(
											x =>
												!(x.columnKey === columnKey && x.operation === 'lte'),
										),
										newSelection,
									]);
								} else {
									setAllFilters(
										allFilters.filter(
											x =>
												!(x.columnKey === columnKey && x.operation === 'lte'),
										),
									);
									setAllListFilters(
										allListFilters.filter(
											x =>
												!(x.columnKey === columnKey && x.operation === 'lte'),
										),
									);
								}
							}}
						/>
					</Stack>
				</Stack.Item>
			</Stack>
			<Separator />
		</>
	);
};
