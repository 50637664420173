import React from 'react';
import {RequirementUtilsService} from '../RequirementUtils.service';
import {
	useCreateRequirementMutation,
	useDeleteRequirementMutation,
} from '../hooks/requirements.generated';
import {useCloneRequirement} from '../hooks/useCloneRequirement';
import {useRequirementsVersions} from '../hooks/useRequirementsVersions';
import {useGetConvolutesQuery} from 'features/AdminSection/hooks/convolutes.generated';
import {ConvoluteType, Requirement, RequirementStatus, UserRole} from 'types';
import {useGetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';
import {useUserContext} from 'authentication/UserContext';
import {
	UpdateRequirement,
	UpdateRequirementAttachments,
	useRequirementAttachmentsUpdater,
	useRequirementsFormElementsRenderer,
	useRequirementUpdater,
} from '../hooks/requirementFormHooks';
import {createHrefFromRequirement} from '../requirementLinks.utils';
import {useNavigate} from 'react-router-dom';
import {useCommand, useSelection} from 'hooks';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import {ConfirmDeleteDialog, useDialogState} from 'components/Dialogs';
import {useEditEntityCmd} from 'hooks/useEditEntityCmd';
import {mergeStyles, PanelType} from '@fluentui/react';
import {EntityFormPanels} from 'components/EntityPage/EntityFormPanels';
import {
	StateForEntityFormPanels,
	useStateForEntityFormPanels,
} from 'components/EntityPage/hooksForEntityFormPanels';
import {useRequirementsTranslation} from '../hooks/useRequirementsTranslation';
import {InfinityPage} from 'components/InfinityPage/InfinityPage';
import {EntityPage} from 'components';
import {FaqCommand} from 'components/Faq/FaqCommand';
import {NewRequirementVersionPanel} from './NewRequirementVersionPanel';
import {FaqPanelMode} from 'components/Faq/FaqPanel';

const {createCommonCreationInputFields} = new RequirementUtilsService();

export const RequirementsPageRequirementForm: React.FC = () => {
	const {t} = useRequirementsTranslation();

	const [createRequirementMutation] = useCreateRequirementMutation();
	const [deleteRequirementMutation] = useDeleteRequirementMutation();
	const {cloneRequirement} = useCloneRequirement();
	const {hasEditableChildren} = useRequirementsVersions();

	const navigate = useNavigate();
	const onViewRequirement = React.useCallback(
		(req: Requirement) => {
			const href: string = createHrefFromRequirement(req);
			return navigate(href);
		},
		[navigate],
	);

	const hasCorrectRightsToEdit = (selectedItem: Requirement) => {
		const vexClusterMatch = selectedItem.vexClusters.some(vc =>
			myVexClusters.some(mvc => mvc.id === vc.id),
		);

		return !isAdmin && !vexClusterMatch;
	};

	const refetchQueries = React.useMemo(() => [], []);

	const {isAdmin, isVex, myVexClusters} = useUserContext();

	const disableDelete = React.useCallback(
		(selectedItem: Requirement) => {
			const requirementStatus = selectedItem.status;

			return (
				hasCorrectRightsToEdit(selectedItem) ||
				requirementStatus === RequirementStatus.Approval ||
				requirementStatus === RequirementStatus.Final ||
				selectedItem.status === RequirementStatus.Interim ||
				selectedItem.status === RequirementStatus.InterimCompleted
			);
		},
		[isAdmin, myVexClusters],
	);

	const disableEdit = React.useCallback(
		(selectedItem: Requirement) => {
			return (
				hasEditableChildren(selectedItem) ||
				hasCorrectRightsToEdit(selectedItem) ||
				selectedItem.status === RequirementStatus.Approval ||
				selectedItem.status === RequirementStatus.Interim ||
				selectedItem.status === RequirementStatus.InterimCompleted
			);
		},
		[isAdmin, myVexClusters],
	);

	const {data: convolutes} = useGetConvolutesQuery();
	const convolute = React.useMemo(
		() =>
			convolutes?.convolutes?.find(
				c => c.convoluteType === ConvoluteType.Requirement,
			),
		[convolutes],
	);

	const {data: gdprRule} = useGetGdprRuleQuery({
		variables: {
			id: convolute?.gdprRule?.id ?? '',
		},
	});

	const rule = React.useMemo(() => gdprRule?.gdprRule, [gdprRule]);

	const sortedRequirementsByName: Requirement[] = [];

	const createRequirement = React.useCallback(
		async (requirement: Requirement) => {
			if (requirement.status === RequirementStatus.Final) {
				const origComparableRequirement = sortedRequirementsByName.find(
					req => req.id === requirement.id,
				);

				const res = await cloneRequirement(
					requirement as Requirement,
					refetchQueries,
					rule,
					origComparableRequirement,
				);
				return res?.data?.createRequirement?.requirement;
			}

			const res = await createRequirementMutation({
				variables: {
					input: createCommonCreationInputFields(requirement, rule),
				},
				refetchQueries,
			});
			navigate(0);
			return res.data?.createRequirement?.requirement;
		},
		[
			cloneRequirement,
			createRequirementMutation,
			refetchQueries,
			rule,
			sortedRequirementsByName,
		],
	);

	const updateRequirement: UpdateRequirement =
		useRequirementUpdater(refetchQueries);

	const updateRequirementFinal = (item: Requirement) => {
		navigate(0);
		updateRequirement(item);
	};

	const deleteRequirement = React.useCallback(
		async (id: string): Promise<void> => {
			await deleteRequirementMutation({
				variables: {
					input: {id},
				},
				refetchQueries,
			});
		},
		[deleteRequirementMutation, refetchQueries],
	);

	const {
		formDataResult: {data: formData, loading: formDataLoading},
		renderFormElements,
	} = useRequirementsFormElementsRenderer<Requirement>();

	const updateAttachments: UpdateRequirementAttachments =
		useRequirementAttachmentsUpdater();

	const item: Requirement[] = [];
	return (
		<>
			<NewRequirementVersionPanel formData={formData} />
			<FaqCommand faqPanelMode={FaqPanelMode.EntityList} />
			<InfinityPage
				items={item}
				entityDisplayName={t('Requirements')}
				entityHeaderText={t('RequirementsHeader')}
				createEntity={createRequirement}
				updateEntity={updateRequirementFinal}
				deleteEntity={deleteRequirement}
				updateEntityAttachments={updateAttachments}
				additionalColumns={[]}
				renderAdditionalFormElements={renderFormElements}
				hideCreate={!isAdmin && !isVex}
				hideEdit={!isAdmin && !isVex}
				hideDelete={!isAdmin && !isVex}
				panelType={PanelType.medium}
				onViewItem={onViewRequirement}
				confirmUpdate
				refetchQueries={refetchQueries}
				disableDelete={disableDelete}
				disableEdit={disableEdit}
				withNameColumn={false}
				withNameColumnInPanel={true}
				showList={false}
			/>
		</>
	);
};
