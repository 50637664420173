import React, {useMemo, useState} from 'react';
import {
	DirectionalHint,
	ILabelStyles,
	IPivotItemProps,
	IStyleSet,
	Icon,
	Label,
	Pivot,
	PivotItem,
	SelectionMode,
	Stack,
	TooltipDelay,
	TooltipHost,
	mergeStyleSets,
	useTheme,
} from '@fluentui/react';
import {useReports} from './hooks';
import {ReportsList} from './_ReportsList';
import {useTranslation} from 'react-i18next';
import {LoadWrapper} from 'components/LoadWrapper';
import {HeaderForPagesWithEntityPage} from 'components/HeaderForPagesWithEntityPage';
import {REGULATION_FORECAST_LINK} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.constants';
import {
	InfinityList,
	InfinityListColumn,
	InfinityListColumnFilterOptions,
	InfinityListFilter,
	InfinityListFilterOption,
	InfinityListItemWithId,
	InfinityListURLParams,
	InfinityListView,
} from 'components/InfinityList';
import {CellWithEntityLink} from 'components/EntityList/CellWithEntityLink';
import {
	renderArrayField,
	renderDateString,
	renderPhase,
	renderRegulatoryDocumentStatus,
	renderStatus,
	StatusField,
} from 'components/EntityList/ColumnRenderers';
import {RegulatoryDocument} from 'types';
import {
	createSearchParams,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import {
	GetVoProRegDocQueryQuery,
	useGetVoProRegDocNextLazyQuery,
	useGetVoProRegDocQueryLazyQuery,
	useGetVoProRegDocSortOrderLazyQuery,
	useGetVoProSummaryLazyQuery,
} from './hooks/RegulatoryDocuments.generated';
import {xor} from 'lodash';
import {ItemSummaryTooltip} from './_ItemSummaryTooltip';
import {getStatusTag} from 'components/EntityList/RequirementField';
import {LoadSpinner} from 'components/LoadWrapper/LoadSpinner';
import {v4 as uuidv4} from 'uuid';
import {DEFAULT_DATE} from 'components/EntityList/EntityUtils';
import {getHistoryEntryStringHtml as getHistoryEntryHtmlString} from 'components/DetailsSection/HistoryField';
import {useUserContext} from 'authentication/UserContext';

interface DataStoreRegulatoryDocument {
	[key: string]: RegulatoryDocument;
}

interface DataStoreSortOrders {
	[key: string]: string;
}

interface IFilters2Link {
	(allFilters: InfinityListFilter[]): InfinityListURLParams;
}

interface IFilters2MongoQuery {
	(allFilters: InfinityListFilter[]): string;
}
interface ILink2MongoQuery {
	(searchParams: URLSearchParams): string;
}

interface ILink2Filter {
	(searchParams: URLSearchParams): InfinityListFilter[];
}

interface IDecodeHex24 {
	(x: string): string;
}

const sortInvertedFields = [
	'modifiedAt',
	'dateEffective',
	'dateNewTypes',
	'dateNewRegistration',
	'dateImplementation',
	'modelYear',
];

const sortFields = [
	'regulationNumber',
	'modifiedAt',
	'status',
	'dateEffective',
	'dateNewTypes',
	'dateNewRegistration',
	'dateImplementation',
	'modelYear',
	'workflowStatus',
];

const arrayFields = [
	'regulationNumber',
	'contactPersons',
	'markets',
	'mainKeywords',
	'keywords',
];

const enumFields = ['status', 'workflowStatus'];

const dateFilterFields = [
	'modifiedAt',
	'dateEffective',
	'dateNewTypes',
	'dateNewRegistration',
	'comprehensive',
	'modelYear',
	'phaseIn',
	'phaseOut',
];

const arrayFieldMongoDb = [
	'regulationRef._id',
	'contactPersonRefs._id',
	'marketRefs._id',
	'mainKeywordRefs._id',
	'keywordRefs._id',
];

const enumFieldsMongoDb = ['status', 'workflow.status'];

const initialSortField = 'modifiedAt';
const initialSortOrder = 'DESC';
const intBatchSize = 40;

const funcFilters2Link: IFilters2Link = allFilters => {
	const dataURL: InfinityListURLParams = {};
	allFilters
		.filter(x => arrayFields.includes(x.columnKey))
		.forEach(x => {
			const linkField = arrayFields.find(y => y === x.columnKey)!;
			if (dataURL[linkField] === undefined) {
				dataURL[linkField] = [x.selection];
			} else {
				dataURL[linkField].push(x.selection);
			}
		});

	allFilters
		.filter(x => enumFields.includes(x.columnKey))
		.forEach(x => {
			const linkField = enumFields.find(y => y === x.columnKey)!;
			if (dataURL[linkField] === undefined) {
				dataURL[linkField] = [x.selection];
			} else {
				dataURL[linkField].push(x.selection);
			}
		});

	allFilters
		.filter(x => dateFilterFields.includes(x.columnKey))
		.forEach(x => {
			const linkField = x.columnKey;
			const value = `${x.operation}_${x.selection.slice(0, 10)}`;
			if (dataURL[linkField] === undefined) {
				dataURL[linkField] = [value];
			} else {
				dataURL[linkField].push(value);
			}
		});

	return dataURL;
};

const funcLink2Filter: ILink2Filter = searchParams => {
	const result: InfinityListFilter[] = [];
	arrayFields
		.filter(x1 => searchParams.getAll(x1).length > 0)
		.forEach(x1 => {
			searchParams.getAll(x1).forEach(x2 =>
				result.push({
					columnKey: x1,
					selection: x2,
					display: 'UNKNOWN',
					operation: 'eq',
				}),
			);
		});

	enumFields
		.filter(x1 => searchParams.getAll(x1).length > 0)
		.forEach(x1 => {
			searchParams.getAll(x1).forEach(x2 =>
				result.push({
					columnKey: x1,
					selection: x2,
					display: 'UNKNOWN',
					operation: 'eq',
				}),
			);
		});

	dateFilterFields
		.filter(x1 => searchParams.getAll(x1).length > 0)
		.forEach(x1 => {
			searchParams.getAll(x1).forEach(x2 =>
				result.push({
					columnKey: x1,
					selection: x2.split('_').at(1) ?? DEFAULT_DATE,
					display:
						(x2.split('_').at(0) === 'gte' ? '>' : '') +
						(x2.split('_').at(0) === 'lte' ? '<' : '') +
						(x2.split('_').at(1) ?? DEFAULT_DATE),
					operation: x2.split('_').at(0) ?? 'gte',
				}),
			);
		});
	return result;
};

const funcFilters2MongoQuery: IFilters2MongoQuery = allFilters => {
	const arrQueryStrings: string[] = [];

	arrayFields
		.map((x1, index) => {
			const lstOptions = allFilters
				.filter(x2 => x2.columnKey === x1)
				.map(x2 => `'${x2.selection}'`);
			if (lstOptions.length === 0) {
				return '';
			}
			return `'${arrayFieldMongoDb.at(index)}':{$in:[${lstOptions}]}`;
		})
		.filter(x1 => x1 !== '')
		.forEach(x1 => arrQueryStrings.push(x1));

	enumFields
		.map((x1, index) => {
			const lstOptions = allFilters
				.filter(x2 => x2.columnKey === x1)
				.map(x2 => `${x2.selection}`);
			if (lstOptions.length === 0) {
				return '';
			}
			return `'${enumFieldsMongoDb.at(index)}':{$in:[${lstOptions}]}`;
		})
		.filter(x1 => x1 !== '')
		.forEach(x1 => arrQueryStrings.push(x1));

	dateFilterFields
		.map((x1, i1) => {
			const dateFrom = allFilters.find(
				x2 => x2.columnKey === x1 && x2.operation === 'gte',
			)?.selection;
			const dateTo = allFilters.find(
				x2 => x2.columnKey === x1 && x2.operation === 'lte',
			)?.selection;
			if (!(dateFrom || dateTo)) {
				return '';
			}

			if (x1 === 'modelYear') {
				let rangeCond = '';
				if (dateFrom && dateTo) {
					rangeCond = `$gte: ${new Date(
						dateFrom,
					).getFullYear()} , $lte: ${new Date(dateTo).getFullYear()}`;
				} else if (dateFrom) {
					rangeCond = `$gte: ${new Date(dateFrom).getFullYear()}`;
				} else if (dateTo) {
					rangeCond = `$lte: ${new Date(dateTo).getFullYear()}`;
				}
				return `${x1}:{${rangeCond}}`;
			}

			let rangeCond = '';
			if (dateFrom && dateTo) {
				rangeCond = `$gte: ISODate('${dateFrom.slice(
					0,
					10,
				)}') , $lte: ISODate('${dateTo.slice(0, 10)}')`;
			} else if (dateFrom) {
				rangeCond = `$gte: ISODate('${dateFrom.slice(0, 10)}')`;
			} else if (dateTo) {
				rangeCond = `$lte: ISODate('${dateTo.slice(0, 10)}')`;
			}

			if (x1.startsWith('phase')) {
				return `${x1}: {$elemMatch:{date:{${rangeCond} } } }`;
			}

			return `${x1}:{${rangeCond}}`;
		})
		.filter(x1 => x1 !== '')
		.forEach(x1 => arrQueryStrings.push(x1));

	return `{ ${arrQueryStrings.join(',')} }`;
};

const funcDecodeHex24: IDecodeHex24 = x => {
	return atob(x).split('\n')[1].slice(1);
};

// *page object ----------------------------------------------------------------------------------------------------------------------
export const ReportsPage = () => {
	const {t: tPage} = useTranslation('features/reports', {
		keyPrefix: 'ReportsPage',
	});

	const {t: tList} = useTranslation('features/reports', {
		keyPrefix: 'ReportsList',
	});

	const {t: tViews} = useTranslation('features/reports', {
		keyPrefix: 'Views',
	});

	const {t: tWorkFlow} = useTranslation('common/enums', {
		keyPrefix: 'WorkflowStatus',
	});

	const {t: tStatus} = useTranslation('common/enums', {
		keyPrefix: 'RegulatoryDocumentStatus',
	});
	const {t: tFav} = useTranslation('appShell/sidenavigation', {
		keyPrefix: 'SideNavigation',
	});

	const [keyPrefix, setKeyPrefix] = useState(uuidv4());

	const [lastFetchedId, setlastFetchedId] = useState('');

	const [cursor, setCursor] = useState(0);

	const [dsRegulatoryDocument, setDSRegulatoryDocument] =
		useState<DataStoreRegulatoryDocument>({});

	const [dsSummary, setDSSummary] = useState<DataStoreRegulatoryDocument>({});

	const [dsSortOrders, setDSSortOrders] = useState<DataStoreSortOrders>({});

	const [arrRegulatoryDocument, setArrRegulatoryDocument] = useState<
		RegulatoryDocument[]
	>([]);

	const [hoveredRegulatoryDocument, setHoveredRegulatoryDocument] =
		useState<RegulatoryDocument>();

	const [getSortOrder, {loading: loadingSortOrder, data: dataSortOrder}] =
		useGetVoProRegDocSortOrderLazyQuery();

	const [getVoProRegDocQuery, {loading: loadingQuery, data: dataQuery}] =
		useGetVoProRegDocQueryLazyQuery();

	const [
		getVoProRegDocsNext,
		{loading: loadingDocuments, data: dataDocuments},
	] = useGetVoProRegDocNextLazyQuery();

	const [getVoProSummary, {loading: loadingSummary, data: dataSummary}] =
		useGetVoProSummaryLazyQuery();

	const [searchParams] = useSearchParams();

	const navigate = useNavigate();

	const {favoriteIds} = useUserContext();

	const views: InfinityListView[] = useMemo(() => {
		const today = new Date();
		const date30 = new Date(new Date().setDate(today.getDate() - 30));
		const date90 = new Date(new Date().setDate(today.getDate() - 90));

		const viewsTemp = [
			{headerText: tViews('STD'), accessKey: 'STD', query: '{}'},
			{
				headerText: tViews('CHNG30'),
				accessKey: 'CHNG30',
				query: `{modifiedAt: { $gte : ISODate('${date30.toISOString()}') } }`,
			},
			{
				headerText: tViews('CHNG90'),
				accessKey: 'CHNG90',
				query: `{modifiedAt: { $gte : ISODate('${date90.toISOString()}') } }`,
			},
		];
		if (favoriteIds) {
			const favoriteRegIds = Object.keys(favoriteIds).filter(
				x => atob(x).split('\n')[0] === 'Regulation',
			);

			const favoriteRegDocIds = Object.keys(favoriteIds).filter(
				x => atob(x).split('\n')[0] === 'RegulatoryDocument',
			);
			if (favoriteRegIds.length > 0 || favoriteRegDocIds.length > 0) {
				const queryRegIds = favoriteRegIds
					.map(x => `'${funcDecodeHex24(x)}'`)
					.join(',');
				const queryRegDocIds = favoriteRegDocIds
					.map(x => `ObjectId('${funcDecodeHex24(x)}')`)
					.join(',');

				let queryviewFinal = `{}`;
				if (favoriteRegIds.length === 0 && favoriteRegDocIds.length > 0) {
					queryviewFinal = `{_id:{$in:[${queryRegDocIds}]}}`;
				}
				if (favoriteRegIds.length > 0 && favoriteRegDocIds.length === 0) {
					queryviewFinal = `{'regulationRef._id':{$in:[${queryRegIds}]}}`;
				}
				if (favoriteRegIds.length > 0 && favoriteRegDocIds.length > 0) {
					const query1 = `{'regulationRef._id':{$in:[${queryRegIds}]}}`;
					const query2 = `{_id:{$in:[${queryRegDocIds}]}}`;
					queryviewFinal = `{$or: [ ${query1}, ${query2}]}`;
				}

				viewsTemp.push({
					headerText: tFav('Favorites'),
					accessKey: 'FAV',
					query: queryviewFinal,
				});
			}
		}
		return viewsTemp;
	}, [favoriteIds]);

	const theme = useTheme();
	const initialView = 'STD';
	const {queryLink, linksFilters} = useMemo(() => {
		const linksFilters = funcLink2Filter(searchParams);
		const queryLink =
			linksFilters.length > 0 ? funcFilters2MongoQuery(linksFilters) : '';

		return {queryLink, linksFilters};
	}, [searchParams]);

	// *functions for summarizing prop functions -------------------------------------------------------------------------------------
	const getSortIds = async (lstFilteredIds: string[], sortField: string) => {
		const boolCanSortLocally =
			lstFilteredIds.filter(x => dsRegulatoryDocument[x] !== undefined)
				.length === lstFilteredIds.length;

		const boolSortisCalculated =
			dsSortOrders[sortField] !== undefined &&
			dsSortOrders[sortField].length > 0;

		if (boolSortisCalculated) {
			return dsSortOrders[sortField].split(';');
		}

		const filteredIds = lstFilteredIds.join(';');
		const {data: dataSortOrder} = await getSortOrder({
			variables: {filteredIds, sortField},
		});
		if (dataSortOrder?.voProRegDocSortOrder) {
			dsSortOrders[sortField] = dataSortOrder?.voProRegDocSortOrder;
			setDSSortOrders({...dsSortOrders});
		}

		return dataSortOrder?.voProRegDocSortOrder.split(';') ?? lstFilteredIds;
	};

	const getSortOrderDefault = (sortField: string) => {
		return sortInvertedFields.includes(sortField) ? 'DESC' : 'ASC';
	};

	const getSortDirectionServer = (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		return (sortInvertedFields.includes(sortField) && sortOrder === 'ASC') ||
			(!sortInvertedFields.includes(sortField) && sortOrder === 'DESC')
			? 'DESC'
			: 'ASC';
	};

	const getSortInverted = (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		return (
			(sortInvertedFields.includes(sortField) && sortOrder === 'ASC') ||
			(!sortInvertedFields.includes(sortField) && sortOrder === 'DESC')
		);
	};

	const getIdsNext = async (
		arrIds: string[],
		sortField: string,
		sortOrderEff: string,
		start: number,
		end: number,
	) => {
		const arrSortIds = await getSortIds(arrIds, sortField);

		if (getSortInverted(sortField, sortOrderEff)) {
			arrSortIds.reverse();
		}

		return arrSortIds.slice(start, end);
	};

	const fetchServerNextData = async (arrIdsNext: string[]) => {
		const arrIdsToFetch = arrIdsNext.filter(
			x => dsRegulatoryDocument[x] === undefined,
		);

		if (arrIdsToFetch.length > 0) {
			const dsRegulatoryDocumentNext: DataStoreRegulatoryDocument = {};

			const strIdsToFetch = arrIdsToFetch.join(';');
			const {data: dataDocumentsNext} = await getVoProRegDocsNext({
				variables: {
					filteredIds: strIdsToFetch,
				},
			});

			(dataDocumentsNext?.voProRegDocNext ?? []).forEach(x => {
				dsRegulatoryDocumentNext[funcDecodeHex24(x.id)] =
					x as RegulatoryDocument;
			});

			const dsRegulatoryDocumentNew = {
				...dsRegulatoryDocument,
				...dsRegulatoryDocumentNext,
			};

			const arrRegulatoryDocumentNext = arrIdsNext
				.map(x => dsRegulatoryDocumentNew[x])
				.filter(x => x !== undefined);

			setDSRegulatoryDocument(dsRegulatoryDocumentNew);

			return {dsRegulatoryDocumentNew, arrRegulatoryDocumentNext};
		}

		const arrRegulatoryDocumentNext = arrIdsNext
			.map(x => dsRegulatoryDocument[x])
			.filter(x => x !== undefined);

		return {dsRegulatoryDocument, arrRegulatoryDocumentNext};
	};

	const fetchSummaryData = async (arrIdsNext: string[]) => {
		const arrIdsToFetch = arrIdsNext.filter(x => dsSummary[x] === undefined);

		if (arrIdsToFetch.length > 0) {
			const dataSummaryNext: DataStoreRegulatoryDocument = {};

			const strIdsToFetch = arrIdsToFetch.join(';');
			const {data: dataSummary} = await getVoProSummary({
				variables: {
					filteredIds: strIdsToFetch,
				},
			});

			(dataSummary?.voProRegDocNext ?? []).forEach(x => {
				dataSummaryNext[funcDecodeHex24(x.id)] = x as RegulatoryDocument;
			});

			const dsSummaryNew = {
				...dsSummary,
				...dataSummaryNext,
			};

			const arrRegulatoryDocumentNext = arrIdsNext
				.map(x => dsSummaryNew[x])
				.filter(x => x !== undefined);

			setDSSummary(dsSummaryNew);

			return {dsSummaryNew, arrRegulatoryDocumentNext};
		}

		const arrRegulatoryDocumentNext = arrIdsNext
			.map(x => dsSummary[x])
			.filter(x => x !== undefined);

		return {dsSummary, arrRegulatoryDocumentNext};
	};

	// *prop functions ----------------------------------------------------------------------------------------------------------------
	const firstRender = async () => {
		// *firstRender called only once at begin
		const arrRegulatoryDocumentNext: RegulatoryDocument[] = [];
		const dsRegulatoryDocumentNext: DataStoreRegulatoryDocument = {};
		const dsSortOrdersNext: DataStoreSortOrders = {};

		const sortOrderServer = getSortDirectionServer(
			initialSortField,
			initialSortOrder,
		);

		const queryView =
			views.find(x => x.accessKey === (initialView ?? 'STD'))?.query ?? '{}';

		let queryFinal = '';
		if (queryLink === '{}') {
			queryFinal = queryView;
		} else if (queryView === '{}') {
			queryFinal = queryLink;
		} else {
			queryFinal = `{$and:[${queryView},${queryLink}]}`;
		}

		const {data: dataQuery} = await getVoProRegDocQuery({
			variables: {
				query: queryFinal,
				sortField: initialSortField,
				sortOrder: sortOrderServer,
			},
		});

		(dataQuery?.voProRegDocQuery.voProRegDocs ?? []).forEach(x => {
			arrRegulatoryDocumentNext.push(x as RegulatoryDocument);
		});

		arrRegulatoryDocumentNext.forEach(x => {
			dsRegulatoryDocumentNext[funcDecodeHex24(x.id)] = x;
		});

		const lastFetchdIdNext = arrRegulatoryDocumentNext.at(-1)!.id;

		sortFields.forEach(x => {
			dsSortOrdersNext[x] = '';
		});
		dsSortOrdersNext[initialSortField] =
			dataQuery?.voProRegDocQuery.voProRegDocIds ?? '';

		setDSRegulatoryDocument(dsRegulatoryDocumentNext);

		setDSSortOrders(dsSortOrdersNext);

		setArrRegulatoryDocument(arrRegulatoryDocumentNext);

		setlastFetchedId(lastFetchdIdNext);

		setCursor(arrRegulatoryDocumentNext.length);
	};

	const applyFilter = async (
		allFilters: InfinityListFilter[],
		sortField: string,
		sortOrder: string,
		currentView?: string,
	) => {
		const dataURL = funcFilters2Link(allFilters);
		dataURL.view = [currentView ?? 'STD'];
		const queryFilter =
			allFilters.length > 0 ? funcFilters2MongoQuery(allFilters) : '{}';

		const queryView =
			views.find(x => x.accessKey === (currentView ?? 'STD'))?.query ?? '{}';

		let queryFinal = '';
		if (queryFilter === '{}') {
			queryFinal = queryView;
		} else if (queryView === '{}') {
			queryFinal = queryFilter;
		} else {
			queryFinal = `{$and:[${queryView},${queryFilter}]}`;
		}

		navigate({
			pathname: '',
			search: createSearchParams(dataURL).toString(),
		});

		const arrRegulatoryDocumentNext: RegulatoryDocument[] = [];
		const dsRegulatoryDocumentNext: DataStoreRegulatoryDocument = {};
		const dsSortOrdersNext: DataStoreSortOrders = {};

		const sortOrderServer = getSortDirectionServer(
			initialSortField,
			initialSortOrder,
		);

		const {data: dataQuery} = await getVoProRegDocQuery({
			variables: {
				query: queryFinal,
				sortField,
				sortOrder: sortOrderServer,
			},
		});

		(dataQuery?.voProRegDocQuery.voProRegDocs ?? []).forEach(x => {
			arrRegulatoryDocumentNext.push(x as RegulatoryDocument);
		});

		arrRegulatoryDocumentNext.forEach(x => {
			dsRegulatoryDocumentNext[x.id] = x;
		});

		const lastFetchdIdNext = arrRegulatoryDocumentNext.at(-1)!.id;

		dsSortOrdersNext[initialSortField] =
			dataQuery?.voProRegDocQuery.voProRegDocIds ?? '';

		setDSRegulatoryDocument({
			...dsRegulatoryDocument,
			...dsRegulatoryDocumentNext,
		});

		setArrRegulatoryDocument(arrRegulatoryDocumentNext);

		setDSSortOrders({});

		setlastFetchedId(lastFetchdIdNext);

		setCursor(arrRegulatoryDocumentNext.length);
		setKeyPrefix(uuidv4());
	};

	const applySort = async (
		sortField: string,
		sortOrder: string | undefined,
		setSortOrder: (sortOrder: string) => void,
	) => {
		const arrIds = (dataQuery?.voProRegDocQuery.voProRegDocIds ?? '').split(
			';',
		);
		const sortOrderEff = sortOrder ? sortOrder : getSortOrderDefault(sortField);

		setSortOrder(sortOrderEff);

		const arrIdsNext = await getIdsNext(
			arrIds,
			sortField,
			sortOrderEff,
			0,
			intBatchSize,
		);

		if (arrIdsNext.length > 0) {
			const {dsRegulatoryDocumentNew, arrRegulatoryDocumentNext} =
				await fetchServerNextData(arrIdsNext);

			const lastIdNext = arrRegulatoryDocumentNext.at(-1)!.id ?? '';

			setArrRegulatoryDocument(arrRegulatoryDocumentNext);

			setlastFetchedId(lastIdNext);

			setCursor(arrIdsNext.length);
			setKeyPrefix(uuidv4());
		}
	};

	const loadItemsNext = async (
		currentId: string,
		sortField: string,
		sortOrder: string | undefined,
	) => {
		if (currentId === lastFetchedId) {
			// *currentId is equal to known lastFetchedId (last row has been mounted)
			// 		*ids are known -> fetch next batch -> set lastFetchdId and cursor correctly

			const arrIds = (dataQuery?.voProRegDocQuery.voProRegDocIds ?? '').split(
				';',
			);
			const sortOrderEff = sortOrder
				? sortOrder
				: getSortOrderDefault(sortField);

			const intEnd = cursor + intBatchSize;

			const arrIdsNext = await getIdsNext(
				arrIds,
				sortField,
				sortOrderEff,
				cursor,
				intEnd,
			);

			if (arrIdsNext.length > 0) {
				const {dsRegulatoryDocumentNew, arrRegulatoryDocumentNext} =
					await fetchServerNextData(arrIdsNext);

				const lastIdNext = arrRegulatoryDocumentNext.at(-1)!.id ?? '';

				setlastFetchedId(lastIdNext);

				setCursor(intEnd);

				setArrRegulatoryDocument(prevItems => [
					...prevItems,
					...arrRegulatoryDocumentNext,
				]);
			}
		}
	};

	const retrieveItemsAll = async (
		sortField: string,
		sortOrder: string | undefined,
	) => {
		const arrIds = (dataQuery?.voProRegDocQuery.voProRegDocIds ?? '').split(
			';',
		);
		const sortOrderEff = sortOrder ? sortOrder : getSortOrderDefault(sortField);
		const arrIdsNext = await getIdsNext(
			arrIds,
			sortField,
			sortOrderEff,
			0,
			arrIds.length,
		);

		const {dsRegulatoryDocumentNew, arrRegulatoryDocumentNext} =
			await fetchServerNextData(arrIdsNext);

		return arrRegulatoryDocumentNext;
	};

	const handleViewingItem = (item: RegulatoryDocument) => {
		navigate({
			pathname: `/${'regulatoryDocuments'}/${
				(item as InfinityListItemWithId).id
			}`,
		});
	};

	const createHref = (item: unknown) =>
		`/${'regulatoryDocuments'}/${(item as InfinityListItemWithId).id}`;

	const numberOfElements =
		dataQuery?.voProRegDocQuery.voProRegDocIds.split(';').filter(x => x.trim())
			.length ?? 1;
	// *columns -----------------------------------------------------------------------------------------------------------------------
	const columns: InfinityListColumn[] = useMemo(
		() => [
			{
				key: 'tooltip',
				name: tList('Summary'),
				fieldName: 'tooltip',
				minWidth: 120,
				maxWidth: 120,
				onRender(item) {
					return (
						<TooltipHost
							key={item.id}
							onTooltipToggle={async () => {
								const {arrRegulatoryDocumentNext} = await fetchSummaryData([
									funcDecodeHex24(item.id),
								]);
								setHoveredRegulatoryDocument(arrRegulatoryDocumentNext.at(0));
							}}
							delay={TooltipDelay.medium}
							id={`reqToolTip-${item.id}-${item.id}`}
							tooltipProps={{
								onRenderContent() {
									const historyHtmlString =
										hoveredRegulatoryDocument?.changeHistoryAuditLogs
											.filter(log => log.change.value)
											.sort((log1, log2) =>
												log1.createdAt > log2.createdAt ? 1 : -1,
											)
											.slice(0, 20)
											?.map((hitem: any) => {
												const historyHtml = getHistoryEntryHtmlString(
													new Date(hitem.createdAt),
													hitem.change.value,
												);
												return historyHtml;
											})
											.join('<br/>');
									const reportSummaryString = `<b>${tList('Summary')}</b>:${
										hoveredRegulatoryDocument?.summary ?? '<p>leer</p>'
									}`;
									const summaryString =
										(historyHtmlString ?? '').length > 0
											? `<b>${tList(
													'ChangeHistory',
											  )}</b>:<p>${historyHtmlString}</p>${reportSummaryString}`
											: reportSummaryString;
									return loadingSummary && !hoveredRegulatoryDocument ? (
										<LoadSpinner
											extraStyles={{root: {height: '60px', top: '30px'}}}
										/>
									) : (
										<div
											dangerouslySetInnerHTML={{
												__html: summaryString,
											}}
										/>
									);
								},
							}}
						>
							<Icon
								iconName='InfoSolid'
								style={{
									display: 'flex',
									alignItems: 'center',
									fontSize: 'small',
									justifyContent: 'center',
								}}
							/>
						</TooltipHost>
					);
				},
			},
			{
				key: 'regulationNumber',
				name: tList('Regulation'),
				fieldName: 'regulationNumber',
				minWidth: 200,
				maxWidth: 300,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				hiddenAlways: true,
			},
			{
				key: 'version',
				name: tList('Version'),
				fieldName: 'version',
				minWidth: 200,
				maxWidth: 300,
				isResizable: true,
				isMultiline: true,
				onRender: item => (
					<CellWithEntityLink href={`/regulatoryDocuments/${item.id}`}>
						<strong>{item.regulation?.regulationNumber ?? ''}</strong> |{' '}
						{item.name}
					</CellWithEntityLink>
				),
			},
			{
				key: 'modifiedAt',
				name: tList('ModifiedAt'),
				fieldName: 'modifiedAt',
				minWidth: 120,
				maxWidth: 120,
				isResizable: true,
				sortable: true,
				filterType: 'Date',
				onRender: renderDateString(),
			},
			{
				key: 'contactPersons',
				name: tList('ContactPerson'),
				fieldName: 'contactPersons',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'markets',
				name: tList('Markets'),
				fieldName: 'markets',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'mainKeywords',
				name: tList('MainKeywords'),
				fieldName: 'mainKeywords',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'keywords',
				name: tList('Keywords'),
				fieldName: 'keywords',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterOnFilter: true,
				filterType: 'Array',
				onRender: renderArrayField(),
			},
			{
				key: 'status',
				name: tList('Status'),
				fieldName: 'status',
				minWidth: 100,
				maxWidth: 100,
				sortable: true,
				filterType: 'Array',
				onRender: renderStatus('RegulatoryDocumentStatus'),
			},
			{
				key: 'dateEffective',
				name: tList('DateEffective'),
				fieldName: 'dateEffective',
				minWidth: 120,
				maxWidth: 120,
				sortable: true,
				filterType: 'Date',
				onRender: renderDateString(),
			},
			{
				key: 'dateNewTypes',
				name: tList('DateNewTypes'),
				fieldName: 'dateNewTypes',
				minWidth: 120,
				maxWidth: 120,
				sortable: true,
				filterType: 'Date',
				onRender: renderDateString(),
			},
			{
				key: 'dateNewRegistration',
				name: tList('DateNewRegistration'),
				fieldName: 'dateNewRegistration',
				minWidth: 120,
				maxWidth: 120,
				sortable: true,
				filterType: 'Date',
				onRender: renderDateString(),
			},
			{
				key: 'comprehensive',
				name: tList('DateImplementation'),
				fieldName: 'comprehensive',
				minWidth: 120,
				maxWidth: 120,
				sortable: true,
				filterType: 'Date',
				onRender: renderDateString(),
			},
			{
				key: 'modelYear',
				name: tList('ModelYear'),
				fieldName: 'modelYear',
				minWidth: 120,
				maxWidth: 120,
				sortable: true,
				filterType: 'Date',
			},
			{
				key: 'phaseIn',
				name: tList('PhaseIn'),
				fieldName: 'phaseIn',
				minWidth: 120,
				maxWidth: 120,
				filterType: 'Date',
				onRender: renderPhase(),
			},
			{
				key: 'phaseOut',
				name: tList('PhaseOut'),
				fieldName: 'phaseOut',
				minWidth: 120,
				maxWidth: 120,
				filterType: 'Date',
				onRender: renderPhase(),
			},
			{
				key: 'workflowStatus',
				name: tList('WorkflowStatus'),
				fieldName: 'workflowStatus',
				minWidth: 100,
				maxWidth: 100,
				sortable: true,
				filterType: 'Array',
				onRender: item => getStatusTag(item.workflow.status, theme, tWorkFlow),
			},
		],
		[loadingSummary, hoveredRegulatoryDocument],
	);

	const columnFilterOptions: InfinityListColumnFilterOptions[] = useMemo(
		() => [
			{
				key: 'regulationNumber',
				fieldValuesUnique: (
					dataQuery?.voProRegDocQuery.voProRegDocFilterOptions.regulationOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? []
				).sort((a, b) => a.value.localeCompare(b.value)),
			},
			{
				key: 'contactPersons',
				fieldValuesUnique:
					dataQuery?.voProRegDocQuery.voProRegDocFilterOptions.contactPersonOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'markets',
				fieldValuesUnique:
					dataQuery?.voProRegDocQuery.voProRegDocFilterOptions.marketOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'mainKeywords',
				fieldValuesUnique:
					dataQuery?.voProRegDocQuery.voProRegDocFilterOptions.mainKeywordOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'keywords',
				fieldValuesUnique:
					dataQuery?.voProRegDocQuery.voProRegDocFilterOptions.keywordOptions.map(
						x => {
							return {id: x.optionKey, value: x.name};
						},
					) ?? [],
			},
			{
				key: 'status',
				fieldValuesUnique:
					dataQuery?.voProRegDocQuery.voProRegDocFilterOptions.statusOptions.map(
						x => {
							return {id: x.optionKey, value: tStatus(x.name)};
						},
					) ?? [],
			},
			{
				key: 'workflowStatus',
				fieldValuesUnique:
					dataQuery?.voProRegDocQuery.voProRegDocFilterOptions.workFlowOptions.map(
						x => {
							return {id: x.optionKey, value: tWorkFlow(x.name)};
						},
					) ?? [],
			},
		],
		[dataQuery],
	);

	// *react object ------------------------------------------------------------------------------------------------------------------
	return (
		<>
			<p style={{marginTop: 10, paddingLeft: 10}}>
				{tPage('ReportsSubHeader')}&nbsp;
				<a href={REGULATION_FORECAST_LINK} target='_blank' rel='noreferrer'>
					SharePoint
				</a>
			</p>
			<InfinityList
				pageName={tPage('Reports')}
				items={arrRegulatoryDocument}
				sticky={true}
				columns={columns}
				columnFilterOptions={columnFilterOptions}
				selectionMode={SelectionMode.none}
				stillLoading={loadingQuery || loadingDocuments || loadingSummary}
				initalFilters={linksFilters}
				initialSortField={initialSortField}
				initialSortOrder={initialSortOrder}
				initialView={initialView}
				keyPrefix={keyPrefix}
				firstRender={firstRender}
				loadItemsNext={loadItemsNext}
				retrieveItemsAll={retrieveItemsAll}
				applyFilter={applyFilter}
				applySort={applySort}
				handleInvokeItem={handleViewingItem}
				createHref={createHref}
				numberOfElements={numberOfElements}
				views={views}
			/>
		</>
	);
};
