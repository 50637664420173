import {EntityPage} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {AttachmentCategoriesTooltipTranslationProvider} from 'features/localizedTooltips';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AttachmentCategory, UserRole} from 'types';
import {
	GetAllAttachmentCategoriesDocument,
	useCreateAttachmentCategoryMutation,
	useDeleteAttachmentCategoryMutation,
	useGetAllAttachmentCategoriesQuery,
	useUpdateAttachmentCategoryMutation,
} from './hooks/attachmentCategories.generated';
import {REGULATION_ATTACHMENT_CATEGORY_NAME} from 'features/RegulatoryDocuments/components/regDocConstants';

const AdminAttachmenCategoriesPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminAttachmentCategoriesPage',
	});
	const {loading, data} = useGetAllAttachmentCategoriesQuery();
	const [createAttachmentCategoryMutation] =
		useCreateAttachmentCategoryMutation();
	const [updateAttachmentCategoryMutation] =
		useUpdateAttachmentCategoryMutation();
	const [deleteAttachmentCategoryMutation] =
		useDeleteAttachmentCategoryMutation();

	const attachmentCategories = React.useMemo(
		() => data?.attachmentCategories ?? [],
		[data],
	);

	const refetchQueries = [GetAllAttachmentCategoriesDocument];

	const createAttachmentCategory = React.useCallback(
		(attachmentCategory: AttachmentCategory) => {
			createAttachmentCategoryMutation({
				variables: {
					input: {
						name: attachmentCategory.name,
					},
				},
				refetchQueries,
			});
		},
		[],
	);
	const updateAttachmentCategory = React.useCallback(
		(attachmentCategory: AttachmentCategory) => {
			updateAttachmentCategoryMutation({
				variables: {
					input: {
						id: attachmentCategory.id,
						name: attachmentCategory.name,
					},
				},
				refetchQueries,
			});
		},
		[],
	);
	const deleteAttachmentCategory = React.useCallback((id: string) => {
		deleteAttachmentCategoryMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<AttachmentCategoriesTooltipTranslationProvider>
					<EntityPage
						items={attachmentCategories}
						entityDisplayName={t('AttachmentCategory')}
						createEntity={createAttachmentCategory}
						updateEntity={updateAttachmentCategory}
						deleteEntity={deleteAttachmentCategory}
						disableDelete={(selectedItem: AttachmentCategory) =>
							selectedItem.name === REGULATION_ATTACHMENT_CATEGORY_NAME
						}
						disableEdit={(selectedItem: AttachmentCategory) =>
							selectedItem.name === REGULATION_ATTACHMENT_CATEGORY_NAME
						}
					/>
				</AttachmentCategoriesTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminAttachmenCategoriesPage;
