import {
	Persona,
	PersonaSize,
	PrimaryButton,
	Stack,
	useTheme,
	Link as FLuentLink,
	FontIcon,
	Label,
} from '@fluentui/react';

import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {
	QuestionByIdDocument,
	QuestionsDocument,
	useMarkAnswerAsFinalMutation,
} from './Faq.generated';

import {getLocaleForDateFns} from 'i18n/localeDateFormat';

import {format} from 'date-fns';
import _ from 'lodash';

import {CreateReply} from './CreateReply';
import {CheckmarkRegular} from '@fluentui/react-icons';
import {useUserContext} from 'authentication/UserContext';

export const FaqRepliesPanel: any = ({selectedQuestion, questions}: any) => {
	const {t, i18n} = useTranslation('common/faq');
	const theme = useTheme();
	const {encodedUserId} = useUserContext();

	const locale: Locale = getLocaleForDateFns(i18n.language);
	const [showReplyDialog, setShowReplyDialog] = useState(false);

	const [hasAlreadyFinal, setHasAlreadyFinal] = useState(false);

	const [togleSetAsFinal] = useMarkAnswerAsFinalMutation();

	const [isAuthor, setIsAuthor] = useState(false);

	useEffect(() => {
		if (selectedQuestion) {
			setIsAuthor(selectedQuestion?.createdBy.id === encodedUserId);
		}
	}, [selectedQuestion]);

	useEffect(() => {
		const currentQuestion = questions?.find(
			(e: any) => e.id === selectedQuestion?.id,
		);
		if (currentQuestion) {
			setHasAlreadyFinal(
				currentQuestion.answers?.some((item: any) => item.markedAsFinal),
			);
		}
	}, [questions, selectedQuestion]);

	const toggleMarkAsFinal = (elem: any) => {
		if (isAuthor) {
			togleSetAsFinal({
				variables: {
					questionId: selectedQuestion?.id,
					answerId: elem.id,
				},
				refetchQueries: [QuestionsDocument, QuestionByIdDocument],
			});
		}
	};

	return (
		<>
			<div
				style={{
					width: '50%',
					height: '100%',
					minHeight: '350px',
					overflowY: 'auto',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<div>
					{selectedQuestion
						? questions
								?.find((e: any) => e.id === selectedQuestion.id)
								.answers.map((elem: any) => (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '10px',
											boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
											border: elem.markedAsFinal
												? `${theme.palette.green} 3px solid`
												: ``,
										}}
										key={elem.id}
									>
										<Stack.Item>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',

													padding: '10px',
												}}
											>
												<strong>
													<Persona
														aria-label='persona'
														size={PersonaSize.size24}
														text={elem.createdBy.name}
													/>
												</strong>
												<div
													style={{
														color: theme.palette.neutralDark,
														opacity: '0.7',
														marginRight: '8px',
													}}
												>
													|
												</div>
												<div
													style={{
														color: theme.palette.neutralDark,
														opacity: '0.7',
													}}
												>
													{format(
														new Date(elem.createdAt),
														'dd LLLL yyyy HH:mm',
														{
															locale,
														},
													)}
												</div>
											</div>
										</Stack.Item>

										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												padding: '10px',
											}}
										>
											<div
												title={
													isAuthor && !hasAlreadyFinal ? t('MarkAsFinal') : ''
												}
												onClick={() => toggleMarkAsFinal(elem)}
												style={{
													borderRadius: '50%',
													color: 'rgb(255, 255, 255)',
													fontSize: '10px',
													fontWeight: 600,
													lineHeight: '24px',
													height: '24px',
													width: '24px',
													backgroundColor: elem.markedAsFinal
														? `${theme.palette.green}`
														: `${theme.palette.neutralSecondaryAlt}`,
													display: 'flex',
													justifyContent: 'center',
													cursor: `${isAuthor ? 'pointer' : ''} `,
												}}
											>
												<div style={{display: 'flex', alignItems: 'center'}}>
													<CheckmarkRegular
														className='hoverable'
														aria-label='check'
													/>
												</div>
											</div>
											<Stack.Item
												style={{
													marginBottom: '20px',
													paddingLeft: '10px',
													wordBreak: 'break-all',
													whiteSpace: 'break-spaces',
													maxWidth: 'calc(100% - 34px)',
												}}
											>
												{elem.text}
											</Stack.Item>
										</div>
										{elem.attachments.length ? (
											<Label
												style={{
													paddingLeft: '10px',
												}}
											>
												Attachments:
											</Label>
										) : (
											<Label
												style={{
													paddingLeft: '10px',
													backgroundColor: theme.palette.neutralLight,
													padding: '10px',
													cursor: 'pointer',
													color: theme.palette.red,
												}}
											>
												NoAttachments
											</Label>
										)}
										{elem.attachments?.map((attachment: any, i: number) => (
											<Stack
												key={i}
												tokens={{childrenGap: 6}}
												style={{
													marginBottom: '5px',
													paddingLeft: '10px',
													wordBreak: 'break-all',
													whiteSpace: 'break-spaces',
													maxWidth: 'calc(100% - 34px)',
												}}
											>
												<FLuentLink
													target='_blank'
													download={false}
													href={
														attachment.file.originalFileName
															? `${attachment.file.uri}/${attachment.file.originalFileName}`
															: `${attachment.file.uri}`
													}
												>
													<FontIcon iconName='Attach' />
													{attachment.file.originalFileName
														? attachment.file.originalFileName
														: attachment.attachmentId}
												</FLuentLink>
												{attachment.category && (
													<Stack.Item>{`(${attachment.category?.name})`}</Stack.Item>
												)}
											</Stack>
										))}
									</div>
								))
						: ''}
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						padding: '10px',
					}}
				>
					<PrimaryButton
						style={{marginRight: '10px'}}
						onClick={() => setShowReplyDialog(true)}
						disabled={!selectedQuestion}
						iconProps={{
							iconName: 'PageEdit',
						}}
					>
						{t('PostReply')}
					</PrimaryButton>
				</div>
			</div>
			<CreateReply
				showDialog={showReplyDialog}
				setShowDialog={setShowReplyDialog}
				selectedQuestion={selectedQuestion}
			/>
		</>
	);
};
