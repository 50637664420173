import {useEffect, useMemo} from 'react';
import {
	useGetAllKeywordsForFaqLazyQuery,
	useQuestionCategoryLazyQuery,
	useQuestionCountsByEntityTypeQuery,
	useVehicleProjectsForFaqLazyQuery,
} from '../Faq.generated';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';
import {useStore} from 'react-context-hook';

export const useQuestionsPropertiesData = ({t}: any) => {
	const [questionCategories, setQuestionCategories] = useStore<any>(
		'questionCategories',
		[],
	);
	const [vehicleProjects, setVehicleProjects] = useStore<any>(
		'vehicleProjects',
		[],
	);

	const [keywords, setKeywords] = useStore<any>('keywords', []);

	const [fetchVehicleProjects, {data: fetchedVehicleProjects}] =
		useVehicleProjectsForFaqLazyQuery({});
	const [fetchKeywords, {data: fetchedKeywords}] =
		useGetAllKeywordsForFaqLazyQuery({});

	const [fetchCategories, {data: categories}] = useQuestionCategoryLazyQuery();

	useEffect(() => {
		if (questionCategories?.length === 0) {
			fetchCategories();
		}
	}, [questionCategories]);

	useEffect(() => {
		if (vehicleProjects?.length === 0) {
			fetchVehicleProjects();
		}
	}, [vehicleProjects]);

	useEffect(() => {
		if (keywords?.length === 0) {
			fetchKeywords();
		}
	}, [keywords]);

	useEffect(() => {
		if (fetchedKeywords) {
			setKeywords(fetchedKeywords?.keywords || []);
		}
	}, [fetchedKeywords]);

	useEffect(() => {
		if (fetchedVehicleProjects) {
			setVehicleProjects(
				fetchedVehicleProjects?.vehicleProjectOverviewPageData || [],
			);
		}
	}, [fetchedVehicleProjects]);

	useEffect(() => {
		if (categories) {
			setQuestionCategories(
				categories?.questionCategories?.map((e: any) => {
					return {
						key: e.id,
						text: t(e.name),
					};
				}) || [],
			);
		}
	}, [categories]);

	return {questionCategories, vehicleProjects, keywords};
};
