import {useTranslation} from 'react-i18next';
import {
	useCopyVersionMetadataMutation,
	GetCompareDocumentDataQuery,
} from '../../../hooks';
import {useUserContext} from 'authentication/UserContext';
import {IComparedItem} from '../compareDocuments/compareDocuments';
import {RegulatoryDocumentParagraph} from 'types';
import {GetRegulatoryDocumentDetailsDocument} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {useAssignRegulatoryDocumentParagraphsRequirementsDocumentCompareMutation} from 'features/RegulatoryDocuments/hooks/useAssignRegulatoryDocumentParagraphsRequirementsDocumentCompare.generated';
import _ from 'lodash';
import {useCloneRequirement} from 'features/Requirements/hooks/useCloneRequirement';
import {
	prepareRequirementsIdsForUpdate,
	createCloneRequirements,
} from './CompareVersionPanel.utils';
import {useCallback, useEffect, useMemo, useState} from 'react';

export type ComparisonUpdateFieldType =
	| 'vehicleCategories'
	| 'keywords'
	| 'categories'
	| 'driveVariants'
	| 'tags'
	| 'requirements'
	| 'dateNewTypes'
	| 'dateNewRegistration'
	| 'comprehensive'
	| 'modelYear'
	| 'phaseIn'
	| 'phaseOut'
	| 'summary'
	| 'keywordAssignments'
	| 'attachments';

export type ComparisonUpdateFields = ComparisonUpdateFieldType[];

export const useComparisonUpdate = (
	selectedItems: IComparedItem[],
	sourceVersion: NonNullable<GetCompareDocumentDataQuery['regulatoryDocument']>,
	newVersion: NonNullable<GetCompareDocumentDataQuery['regulatoryDocument']>,
	data: any,
) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'CompareVersionPanel',
	});
	const {isVex} = useUserContext();

	const {createCloneForMerging, getInitialVersion} = useCloneRequirement();
	const [copyMetadata] = useCopyVersionMetadataMutation();
	const [updateParagraphsRequirementsCompareDocument] =
		useAssignRegulatoryDocumentParagraphsRequirementsDocumentCompareMutation();

	const [allFields, setAllFields] = useState<ComparisonUpdateFields>([]);
	const [selectedFields, setSelectedFields] = useState<ComparisonUpdateFields>(
		[],
	);

	useEffect(() => {
		let allFields: ComparisonUpdateFields = [];
		if (isVex) {
			allFields = ['tags', 'requirements'];
		} else {
			allFields = [
				'vehicleCategories',
				'keywords',
				'categories',
				'driveVariants',

				'dateNewTypes',
				'dateNewRegistration',
				'comprehensive',
				'modelYear',

				'phaseIn',
				'phaseOut',
				'keywordAssignments',
				'attachments',

				'summary',
				'tags',
				'requirements',
			];
		}
		setAllFields(allFields);
		setSelectedFields(allFields);
	}, [isVex]);

	const concatAndRemoveDuplicates = (arr1: any[], arr2: any[]): any[] => {
		return arr1.concat(arr2).reduce((acc, curr) => {
			if (!acc.some((el: {id: string}) => el.id === curr.id)) acc.push(curr);
			return acc;
		}, [] as any[]);
	};

	const concatField = (
		oldParagraph: RegulatoryDocumentParagraph,
		newParagraph: RegulatoryDocumentParagraph,
		fieldName: ComparisonUpdateFieldType,
	) => {
		if (selectedFields.indexOf(fieldName) !== -1) {
			if (Array.isArray(newParagraph[fieldName])) {
				newParagraph[fieldName] = concatAndRemoveDuplicates(
					oldParagraph[fieldName],
					newParagraph[fieldName],
				);
			} else if (oldParagraph[fieldName]) {
				newParagraph[fieldName] = oldParagraph[fieldName];
			} else {
				// Some fields are not supported yet
			}
		}
	};

	const getUpdatedComparedItems = (newComparisonItems: IComparedItem[]) => {
		selectedItems.forEach(selectedItem => {
			const paragraph = selectedItem.newParagraph
				?.paragraph as unknown as RegulatoryDocumentParagraph;
			const {id} = paragraph;
			const newComparisonIndex = newComparisonItems.findIndex(
				ci => (ci.newParagraph?.paragraph as any).id === id,
			);
			const newComparisonParagraph = newComparisonItems[newComparisonIndex]
				.newParagraph?.paragraph as any as RegulatoryDocumentParagraph;
			const oldComparisonParagraph = newComparisonItems[newComparisonIndex]
				.oldParagraph?.paragraph as any as RegulatoryDocumentParagraph;

			allFields.forEach(field =>
				concatField(oldComparisonParagraph, newComparisonParagraph, field),
			);
		});
		return newComparisonItems;
	};

	const updateHandler = async (
		newComparisonItems: IComparedItem[],
	): Promise<IComparedItem[]> => {
		await copyMetadata({
			variables: {
				input: {
					sourceRegulatoryDocumentId: sourceVersion.id,
					distRegulatoryDocumentId: newVersion.id,
					distParagraphIds: selectedItems.map(
						item =>
							(
								item.newParagraph
									?.paragraph as unknown as RegulatoryDocumentParagraph
							).id,
					),
					updateFields: {
						copyVehicleCategories:
							selectedFields.indexOf('vehicleCategories') !== -1,
						copyKeywords: selectedFields.indexOf('keywords') !== -1,
						copyCategories: selectedFields.indexOf('categories') !== -1,
						copyDriveVariants: selectedFields.indexOf('driveVariants') !== -1,

						copyNewTypes: selectedFields.indexOf('dateNewTypes') !== -1,
						copyNewRegistration:
							selectedFields.indexOf('dateNewRegistration') !== -1,
						copyComprehensive: selectedFields.indexOf('comprehensive') !== -1,
						copyModelYear: selectedFields.indexOf('modelYear') !== -1,

						copyPhaseIn: selectedFields.indexOf('phaseIn') !== -1,
						copyPhaseOut: selectedFields.indexOf('phaseOut') !== -1,
						copyKeywordAssignments:
							selectedFields.indexOf('keywordAssignments') !== -1,
						copyAttachments: selectedFields.indexOf('attachments') !== -1,

						copySummary: selectedFields.indexOf('summary') !== -1,
						copyTags: selectedFields.indexOf('tags') !== -1,
						copyRequirements: selectedFields.indexOf('requirements') !== -1,
					},
				},
			},
		});

		const ret = getUpdatedComparedItems(newComparisonItems);

		if (selectedFields.indexOf('requirements') === -1) {
			return ret;
		}

		const map = await createCloneRequirements(
			data,
			selectedItems,
			createCloneForMerging,
			getInitialVersion,
		);

		await updateParagraphsRequirementsCompareDocument({
			variables: {
				input: {
					regulatoryDocumentId: newVersion.id,
					requirementRefs: selectedItems.map(item =>
						prepareRequirementsIdsForUpdate(item, map),
					),
					paragraphIds: selectedItems.map(item => {
						const {newParagraph} = item;

						return newParagraph?.paragraph.id;
					}),
				},
			},
			refetchQueries: [GetRegulatoryDocumentDetailsDocument],
		});
		return ret;
	};

	const checkboxData = useMemo(
		() =>
			allFields.map(sf => {
				return {
					key: sf,
					value: t(sf[0].toUpperCase() + sf.substring(1)),
					isSelected: selectedFields.indexOf(sf) !== -1,
				};
			}),
		[allFields, selectedFields, t],
	);

	const selectField = useCallback(
		(field: ComparisonUpdateFieldType) => {
			if (selectedFields.indexOf(field) === -1) {
				setSelectedFields([...selectedFields, field]);
			}
		},
		[selectedFields],
	);

	const unselectField = useCallback(
		(field: ComparisonUpdateFieldType) => {
			const index = selectedFields.indexOf(field);
			if (index !== -1) {
				const newFields = [...selectedFields];
				newFields.splice(index, 1);
				setSelectedFields(newFields);
			}
		},
		[selectedFields],
	);

	const selectAll = useCallback(() => {
		setSelectedFields([...allFields]);
	}, [selectedFields]);

	const deselectAll = useCallback(() => {
		setSelectedFields([]);
	}, []);

	return {
		updateHandler,
		checkboxData,
		selectField,
		unselectField,
		selectAll,
		deselectAll,
		selectedFields,
	};
};
