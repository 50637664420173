import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {User} from 'types';

export type MockedNotificationType = {
	name: string;
	id: string;
	users: User[];
};

const useGetNotificationUsers = (): {
	loading: boolean;
	data: MockedNotificationType[];
} => {
	const {t: notificationTitles} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminNotificationTitles',
	});

	const data = useMemo(() => {
		return {
			loading: false,
			data: [
				{
					name: notificationTitles('KeywordMonthly'),
					id: '9e750803-9f70-43d8-ae2f-7796385f1956',
					users: [
						{
							name: 'Test User 1',
							id: 'b202e48e-5221-48eb-a203-aa347b474a9f',
						},
						{
							name: 'Test User 2',
							id: '04edb5b7-f95b-4540-b4c8-10a308883ac8',
						},
						{
							name: 'Test User 3',
							id: '71e3f6cf-9fba-49d5-ae48-153a36d64945',
						},
					],
				},
			],
		};
	}, []);

	return data;
};

export default useGetNotificationUsers;
