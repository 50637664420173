import React from 'react';
import {Icon, Label, mergeStyleSets} from '@fluentui/react';
import {CellWithEntityLink} from 'components/EntityList/CellWithEntityLink';
import {Regulation} from 'types';
import {createHrefFromRegulation} from './regulationsListLinks.utils';

export function RegulationNumberCell(item: Regulation): JSX.Element {
	const classNames = getClassNames();

	const detailsPageHref: string = createHrefFromRegulation(item);
	return (
		<>
			<CellWithEntityLink href={detailsPageHref}>
				<Label
					className={item.hidden ? classNames.labelsHidden : classNames.labels}
				>
					{item.regulationNumber}
				</Label>
			</CellWithEntityLink>
			{item.hidden && <Icon iconName='Hide2' style={{paddingLeft: 10}}></Icon>}
		</>
	);
}

const getClassNames = () =>
	mergeStyleSets({
		labels: {
			padding: 0,
			fontSize: 16,
			color: 'inherit',
		},
		labelsHidden: {
			padding: 0,
			fontSize: 16,
			color: 'red',
		},
	});
