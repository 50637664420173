import {
	ComboBox,
	DefaultButton,
	IComboBoxStyles,
	IDropdownOption,
	IScrollablePaneStyles,
	Label,
	Link,
	Panel,
	PanelType,
	PrimaryButton,
	ScrollablePane,
	Separator,
	Stack,
	Text,
	TextField,
	mergeStyleSets,
	useTheme,
} from '@fluentui/react';
import {
	ControlledDatePicker,
	ControlledDropdown,
	ControlledTagPicker,
	ControlledTextField,
	PhasePicker,
} from 'components/hookForms';
import {LoadWrapper} from 'components/LoadWrapper';
import {
	GetParagraphsFormDataQuery,
	useGetParagraphsFormDataQuery,
} from 'features/RegulatoryDocuments/hooks/useGetParagraphsFormData.generated';
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import {
	AttachmentInput,
	EOperator,
	EReferenceValue,
	InputMaybe,
	RegulatoryDocumentParagraph as RegDocParagraph,
	Scalars,
	UpdateParagraphAttachmentsInput,
	UserRole,
	WorkflowStatus,
} from 'types';
import {
	ParagraphsContextProps,
	useParagraphsContext,
} from '../ParagraphsContext';
import {
	GetRegulatoryDocumentDetailsDocument,
	GetVexRegulatoryDocumentParagraphsQuery,
} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {useRegulatoryDocumentsContext} from 'features/RegulatoryDocuments/context';
import {fileUploadDefaultValue, useCommand, useFileUpload} from 'hooks';
import {RegDocAuditLogsInfo} from 'features/RegulatoryDocuments/RegDocDetailsPage/useGetRegDocAuditLogs';
import {
	UpdateParagraphAttachmentsMutation,
	useUpdateParagraphAttachmentsMutation,
} from 'features/RegulatoryDocuments/hooks/useUpdateParagraphAttachments.generated';
import {useUserContext} from 'authentication/UserContext';
import {renderParagraph} from 'components/EntityList/ColumnRenderers';
import {ControlledRichtextEditor} from 'components/hookForms/ControlledRichtextEditor';
import {v4 as uuidv4} from 'uuid';
import {ConfirmDeleteDialog, useDialogState} from 'components/Dialogs';
import {
	useClearFieldForAllParagraphsMutation,
	useClearFieldForSelectedParagraphsMutation,
} from 'features/RegulatoryDocuments/hooks/clearFieldRegulatoryDocumentParagraphBatch.generated';
import {ActionTagProps} from '../ActionTags/ActionTag';
import {ActionButton} from 'components';
import _ from 'lodash';
import {
	LabelWithTooltip,
	ProviderThatEnablesGettingTooltipsFromContext,
	RegDocParagraphTooltipTranslationProvider,
} from 'features/localizedTooltips';
import {formatDateTime} from 'i18n/localeDateFormat';
import {
	ParagraphFormFields,
	ParagraphsCopy,
	SelectableItemsByType,
	SetParagraphsCopy,
} from './EditParagraphsForm.types';
import {useEditParagraphsFormTranslations} from './editParagraphsFormTranslationHooks';
import {
	FieldsToUseEditParagraphsFormSubmitter,
	useEditParagraphsFormSubmitter,
} from './editParagraphsForm.submissionHook';
import {RegulatoryDetailsPageFormMode} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.types';
import {DocOfRegDocDetailsPageQuery} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {ApolloClient, useApolloClient} from '@apollo/client';
import {MutationKeyOptimisticDataServiceFields} from './optimisticData/updateRegDocParagraphMutations/MutationKeyOptimisticData/MutationKeyOptimisticData.service';
import {
	AttachmentsMutationKeyOptimisticData,
	MutationKeyOptimisticDataService,
} from './optimisticData/updateParagraphAttachmentsMutation/MutationKeyOptimisticData.service';
import {useGetAttachmentCategoriesQuery} from 'components/hookForms/hooks/getAttachmentCategories.generated';
import {
	EditParagraphsFormSubmissionStatusContext,
	EditParagraphsFormSubmissionStatusInfo,
} from 'features/RegulatoryDocuments/RegDocDetailsPage/EditParagraphsFormSubmissionStatusProvider';
import {
	EditParagraphsFormDefaultValues,
	initialRegulatoryDocumentParagraph,
} from './editParagraphsForm.constants';
import {isRegDocEditDisabled} from 'features/RegulatoryDocuments/regulatoryUtils';
import {ConfirmModifiedVersionCreation} from './ConfirmModifiedVersionCreation';
import {useCloneRegulatoryDocumentMutation} from 'features/RegulatoryDocuments/hooks/useCloneRegulatoryDocument.generated';
import {useNavigate} from 'react-router-dom';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';
import ThreeStateToggle from 'components/ThreeStateToggle/ThreeStateToggle';
import {CreateMatkonApplicationDialog} from '../EditMatkonParagraphsForm/CreateMatkonApplicationDialog';
import {useMatkonsPropertiesData} from '../EditMatkonParagraphsForm/hooks/useMatkonsPropertiesData';
import {
	MatkonApplicationsDocument,
	useCreateMatkonLimitsMutation,
} from '../EditMatkonParagraphsForm/Matkon.generated';
import {FileUpload} from 'components/hookForms/ControlledFileUpload';

type ParagraphKeysWithInitialFields = keyof EditParagraphsFormDefaultValues;

type FieldsFromAuditLogsInfo = Pick<
	RegDocAuditLogsInfo,
	'refetchAuditLogsAndUpdateIsLoading'
>;

type FieldsFromSubmissionHook = Pick<
	FieldsToUseEditParagraphsFormSubmitter,
	'setIsFormRefetchingRegDoc' | 'regDoc' | 'setAreAuditLogsLoading'
>;

interface Props extends FieldsFromAuditLogsInfo, FieldsFromSubmissionHook {
	vexItems: GetVexRegulatoryDocumentParagraphsQuery | undefined;
	setIndexToScrollTo: ParagraphsContextProps['setIndexToScrollTo'];
	deleteFormMetadata?: (
		paragraphIds: [],
		metadata: ParagraphKeysWithInitialFields,
	) => void;
}

export const EditParagraphsForm = ({
	refetchAuditLogsAndUpdateIsLoading,
	setAreAuditLogsLoading,
	setIsFormRefetchingRegDoc,
	regDoc,
	setIndexToScrollTo,
}: Props) => {
	const {t, i18n} = useEditParagraphsFormTranslations();
	const navigate = useNavigate();
	const [showMatkonDialog, setShowMatkonDialog] = React.useState(false);

	// Matkon Limitations/ German
	// const limitations = [
	// 	{ id: 'Substanzen', name: 'Substanzen' },
	// 	{ id: 'Mixturen', name: 'Mixturen' },
	// 	{ id: 'Erzeugnisse', name: 'Erzeugnisse' },
	// ];

	// Matkon Limitations/ English => Integration with Mattias Needed
	const limitations = [
		{id: 'Substances', name: 'Substanzen'},
		{id: 'Mixtures', name: 'Mixturen'},
		{id: 'Erzeugnisse', name: 'Erzeugnisse'},
	];

	const [
		deleteMetadataDialogState,
		showDeleteMetadataDialog,
		hideDeleteMetadataDialog,
	] = useDialogState();

	const [metadataToPossiblyDelete, setMetadataToPossiblyDelete] =
		React.useState<keyof ParagraphFormFields | null>(null);

	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [showConfirmationDialogDelete, setShowConfirmationDialogDelete] =
		useState(false);
	const status = useContext(
		EditParagraphsFormSubmissionStatusContext,
	) as EditParagraphsFormSubmissionStatusInfo;

	/**
	 * * Mutations
	 */
	const [updateParagraphAttachments] = useUpdateParagraphAttachmentsMutation();
	const [clearFieldForSelectedParagraphsMutation] =
		useClearFieldForSelectedParagraphsMutation();

	const [clearFieldForAllParagraphsMutation] =
		useClearFieldForAllParagraphsMutation();
	const [cloneRegulatoryDocumentMutation] =
		useCloneRegulatoryDocumentMutation();

	/**
	 * * Selected paragraphs
	 */
	const {selectedParagraphs} = useParagraphsContext();

	type SelectedParagraphsState = [ParagraphsCopy, SetParagraphsCopy];
	const [
		selectedParagraphsCopy,
		setSelectedParagraphsCopy,
	]: SelectedParagraphsState = useState<ParagraphsCopy>([]);

	/**
	 * We must copy the paragraphs because we will modify them whenever we delete
	 * a field's metadata. We want to avoid changing @see selectedParagraphs
	 * because that would cause the form to reset, so we create a copy.
	 */
	const copySelectedParagraphsAndSetCopy = (): void => {
		const paragraphsCopy: ParagraphsCopy = _.cloneDeep(selectedParagraphs);
		setSelectedParagraphsCopy(paragraphsCopy);
	};

	useEffect(copySelectedParagraphsAndSetCopy, [selectedParagraphs]);

	/**
	 * * MatkonAPI
	 */
	const {
		limits: matkonsLimits,
		applications,
		limitsTemplate,
		fetchLimits,
	} = useMatkonsPropertiesData({
		t,
		regulationNumber: regDoc?.regulation?.regulationNumber,
		paragraphId: selectedParagraphs.length > 0 ? selectedParagraphs[0].id : '',
	});

	/**
	 * * Cleared fields
	 */
	type ClearedFields = MutationKeyOptimisticDataServiceFields['clearedFields'];

	type SetClearedFields = Dispatch<SetStateAction<ClearedFields>>;

	type ClearedFieldsState = [ClearedFields, SetClearedFields];

	const [clearedFields, setClearedFields]: ClearedFieldsState =
		useState<ClearedFields>([]);

	const addClearedField = useCallback(
		(field: keyof ParagraphFormFields): void => {
			setClearedFields([...clearedFields, field]);
		},
		[clearedFields],
	);

	/**
	 * * Other hooks
	 */
	const apolloClient: ApolloClient<unknown> = useApolloClient();
	const {selectedRegulatoryDocument} = useRegulatoryDocumentsContext();
	const {
		isVko,
		isVkoExternal,
		isVex,
		isAdmin,
		isReader,
		username,
		encodedUserId,
	} = useUserContext();

	const {setSubmissionStarted, setSubmissionFinished} = useContext(
		EditParagraphsFormSubmissionStatusContext,
	) as EditParagraphsFormSubmissionStatusInfo;

	const {
		handleSubmit,
		control,
		getValues,
		reset: resetFields,
		setValue,
		trigger,
		watch,
	} = useForm<ParagraphFormFields>({
		reValidateMode: 'onSubmit',
		mode: 'all',
		defaultValues: initialRegulatoryDocumentParagraph,
	});

	// The form values interface – an array of Limit objects.
	interface FormValues {
		limits: Limit[];
	}

	const {
		handleSubmit: matkonHandleMatkonSubmit,
		control: matkonControl,
		getValues: matKonGetValues,
		reset: matkonResetFields,
		setValue: matkonSetValue,
		trigger: matkonTrigger,
		watch: matkonWatch,
	} = useForm<FormValues>({
		reValidateMode: 'onSubmit',
		mode: 'all',
		defaultValues: {
			limits: [
				{
					id: 0,
					deadlineInternal: new Date().toISOString(),
					deadlineLaw: new Date().toISOString(),
					expirationDate: new Date().toISOString(),
					iri: '',
					limitations: [], // initially, no limitations are selected
					operator: EOperator.Equal,
					referenceValue: EReferenceValue.Component,
					unit: '',
					value: 0,
				},
			],
		},
	});

	// useFieldArray manages the dynamic array of limits.
	const {fields, append, remove} = useFieldArray({
		control: matkonControl,
		name: 'limits',
	});

	const [mode, setMode] = React.useState(RegulatoryDetailsPageFormMode.None);
	const [everCleared, setEverCleared] = React.useState<boolean>(false);

	const {attachments, FileUploadComponent, setAttachments} = useFileUpload(
		selectedParagraphs.length > 1 ? undefined : selectedParagraphs[0],
		{
			enableVkoOnlyOption: true,
		},
	);

	const [
		currentParagraphScrollTopPosition,
		setCurrentParagraphScrollTopPosition,
	] = React.useState<number | undefined>(undefined);

	const handleScrolling = React.useCallback(
		(resetTopPosition: boolean) => {
			const container = document.querySelector(
				'.ms-ScrollablePane--contentContainer',
			);
			if (currentParagraphScrollTopPosition && container) {
				container.scrollTop = currentParagraphScrollTopPosition;
				if (resetTopPosition) setCurrentParagraphScrollTopPosition(undefined);
			}
		},
		[
			currentParagraphScrollTopPosition,
			setIndexToScrollTo,
			setCurrentParagraphScrollTopPosition,
		],
	);

	const editRegulationForbidden = React.useMemo(() => {
		const isReapprovingVko =
			selectedRegulatoryDocument?.workflow?.reapprovingVKO?.id ===
			encodedUserId;
		const couldEdit = isRegDocEditDisabled(
			selectedRegulatoryDocument,
			encodedUserId,
		);
		return !isReapprovingVko && couldEdit;
	}, [selectedParagraphs, selectedRegulatoryDocument]);

	const hideMetadataEditForm = React.useMemo(() => {
		const status = selectedRegulatoryDocument?.workflow?.status;
		if (!status) {
			return false;
		}

		const clearingVko = selectedRegulatoryDocument.workflow?.clearingVKO[0];
		const isClearingVko = clearingVko?.userPrincipalName === username;
		const isVKoAllowed =
			[
				WorkflowStatus.QualityControlInternal,
				WorkflowStatus.QualityControlExternal,
			].includes(status) &&
			(isVko || isVkoExternal);

		const isClearing = status === WorkflowStatus.Clearing && !isClearingVko;

		const isHidden = isVKoAllowed || isClearing;
		return isHidden;
	}, [selectedRegulatoryDocument, username, isVko, isVkoExternal, isAdmin]);

	const handleEdit = () => {
		storeCurrentParagraphPosition();
		setMode(RegulatoryDetailsPageFormMode.Edit);
	};

	const storeCurrentParagraphPosition = React.useCallback(() => {
		const distance =
			document.querySelector('.ms-ScrollablePane--contentContainer')
				?.scrollTop || -1;

		if (distance > 0) {
			setCurrentParagraphScrollTopPosition(distance);
		}
	}, []);

	const handleEditMatKon = () => {
		setLimits([emptyLimit]);
		matkonResetFields({
			limits: [emptyLimit],
		});
		fetchLimits();
		storeCurrentParagraphPosition();
		setMode(RegulatoryDetailsPageFormMode.EditMatKon);
	};

	const disableMetadataEditForm = React.useMemo(() => {
		return (
			status.isSubmitting ||
			(editRegulationForbidden && !isVex) ||
			(selectedRegulatoryDocument?.workflow?.status !==
				WorkflowStatus.Finalized &&
				isReader) ||
			selectedParagraphs.length === 0
		);
	}, [
		editRegulationForbidden,
		isVex,
		isReader,
		selectedParagraphs,
		status?.isSubmitting,
		selectedRegulatoryDocument?.workflow?.status,
	]);

	const editMetadataTooltip = React.useMemo(() => {
		if (status?.isSubmitting === true)
			return t('DisabledEditMetadataSubmissionInProgress');
		if (editRegulationForbidden) return t('DisabledEditMetadataModifiedExists');
		return '';
	}, [editRegulationForbidden, status?.isSubmitting]);

	useCommand(
		{
			key: 'edit',
			text: t('EditMetadata'),
			priority: 3,
			iconProps: {
				iconName: 'Edit',
			},
			title: editMetadataTooltip,
			disabled: disableMetadataEditForm,
			hidden: hideMetadataEditForm,
			onClick: handleEdit,
			roles: [
				UserRole.SystemAdministrator,
				UserRole.Vko,
				UserRole.VkoExternal,
				UserRole.Vex,
			],
		},
		[mode, selectedParagraphs.length, hideMetadataEditForm],
	);

	useCommand(
		{
			key: 'editMatKon',
			text: t('EditMatKonMetadata'),
			priority: 3,
			iconProps: {
				iconName: 'Edit',
			},
			title: editMetadataTooltip,
			disabled: disableMetadataEditForm,
			hidden:
				hideMetadataEditForm || !(isDevEnvironment() || isTestEnvironment()),
			onClick: handleEditMatKon,
			roles: [
				UserRole.SystemAdministrator,
				UserRole.Vko,
				UserRole.VkoExternal,
				UserRole.Vex,
			],
		},
		[mode, selectedParagraphs.length, hideMetadataEditForm],
	);

	const getDefaultFieldsAndReset = (): void => {
		resetFields(
			selectedParagraphs.length === 1
				? {
						...(selectedParagraphs[0] as ParagraphFormFields),
				  }
				: initialRegulatoryDocumentParagraph,
		);
	};

	const reset = (): void => {
		if (hideMetadataEditForm) return;

		getDefaultFieldsAndReset();
		/**
		 * We don't need to store the cleared fields for multiple submissions
		 * because the paragraphs' data will be updated.
		 */
		setClearedFields([]);
		/**
		 * We reset this so we can determine whether or not the attachments have
		 * been modified. This allows us to generate the optimistic data for
		 * attachments.
		 */
		setAttachments(fileUploadDefaultValue);
	};

	React.useEffect(reset, [mode, hideMetadataEditForm]);

	/**
	 * * Post-save functionality
	 */
	const {
		data: attachmentCategoriesData,
		loading: isLoadingAttachmentCategories,
	} = useGetAttachmentCategoriesQuery();

	interface AttachmentsInput
		extends Omit<UpdateParagraphAttachmentsInput, 'attachments'> {
		attachments: NonNullable<UpdateParagraphAttachmentsInput['attachments']>;
	}

	interface FieldsToCreateAttachmentsOptimisticData {
		regDoc: DocOfRegDocDetailsPageQuery;
		input: AttachmentsInput;
	}

	const createAttachmentsMutationKeyOptimisticData = useCallback(
		({
			regDoc,
			input,
		}: FieldsToCreateAttachmentsOptimisticData): AttachmentsMutationKeyOptimisticData => {
			return MutationKeyOptimisticDataService.createMutationKeyOptimisticData({
				...input,
				/**
				 * The paragraphs will always exist because we wouldn't be able to open
				 * the form unless they did.
				 */
				paragraphs: regDoc.paragraphs,
				/**
				 * This will always be defined because we wait for it in the loading
				 * screen when there we are editing a single paragraph.
				 */
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				attachmentCategories: attachmentCategoriesData!.attachmentCategories,
				idsToUpdate: input.paragraphIds,
				regDocId: input.regulatoryDocumentId,
				selectedParagraphs,
			});
		},
		[selectedParagraphs, attachmentCategoriesData],
	);

	const createAttachmentsOptimisticData = useCallback(
		(
			fields: FieldsToCreateAttachmentsOptimisticData,
		): UpdateParagraphAttachmentsMutation => {
			return {
				updateParagraphAttachments:
					createAttachmentsMutationKeyOptimisticData(fields),
			};
		},
		[createAttachmentsMutationKeyOptimisticData],
	);

	const onParagraphsSaved = React.useCallback(async () => {
		if (selectedParagraphs.length > 0 && regDoc?.id && attachments) {
			const input: AttachmentsInput = {
				regulatoryDocumentId: regDoc.id,
				attachments: attachments.map(f => ({
					attachmentId: f.file.name,
					file: f.file,
					categoryId: f.attachmentCategoryId,
					isVkoOnly: f.isVkoOnly,
				})),
				paragraphIds: selectedParagraphs.map(p => p.id),
			};

			await updateParagraphAttachments({
				variables: {input},
				optimisticResponse: createAttachmentsOptimisticData({
					regDoc,
					input,
				}),
			});
		}

		/**
		 * We must refetch the audit logs because the reg doc's "modelYear" can
		 * change if we update a paragraph. Since the reg doc's history can
		 * change, we must refetch the audit logs.
		 */
		await refetchAuditLogsAndUpdateIsLoading();
	}, [
		attachments,
		selectedParagraphs,
		regDoc,
		createAttachmentsOptimisticData,
		refetchAuditLogsAndUpdateIsLoading,
	]);

	const clearFieldRegulatoryDocumentParagraphsForFinalVersion =
		React.useCallback(
			async (fieldName: ParagraphKeysWithInitialFields) => {
				setSubmissionStarted();
				addClearedField(fieldName);

				if (!regDoc) return;
				const cloneResult = await cloneRegulatoryDocumentMutation({
					variables: {
						input: {
							originalRegulatoryDocumentId: regDoc.id,
							workflowStatus: WorkflowStatus.Modified,
						},
					},
				});

				const clonedRegDoc =
					cloneResult?.data?.cloneRegulatoryDocument?.regulatoryDocument;

				if (!clonedRegDoc) {
					return;
				}

				if (selectedParagraphs.length === regDoc?.paragraphs.length) {
					await clearFieldForAllParagraphsMutation({
						variables: {
							input: {
								fieldName,
								regulatoryDocumentId: clonedRegDoc?.id ?? '',
							},
						},
					}).catch((e: Error) => {
						console.error(
							'[EditParagraphForm/clearFieldRegulatoryDocumentParagraphsForFinalVersion]',
							e,
						);
					});
				} else {
					const positions: number[] = (regDoc?.paragraphs ?? []).reduce(
						(acc, elem, index) => {
							if (selectedParagraphs.map(sp => sp.id).includes(elem.id)) {
								acc.push(index);
							}
							return acc;
						},
						[] as number[],
					);

					const paragraphIds = clonedRegDoc.paragraphs.map(p => p.id);

					const paragraphsIdsInClonedVersion = positions.map(
						(elem: number) => paragraphIds[elem],
					);

					const cloneId = clonedRegDoc.id;

					await clearFieldForSelectedParagraphsMutation({
						variables: {
							input: {
								fieldName,
								paragraphIds: paragraphsIdsInClonedVersion ?? [0],
								regulatoryDocumentId: cloneId ?? '',
							},
						},
					}).catch((e: Error) => {
						console.error(
							'[EditParagraphForm/clearFieldRegulatoryDocumentParagraphsForFinalVersion]',
							e,
						);
					});
				}
				setEverCleared(true);
				setSubmissionFinished();
				navigate(`/regulations/${regDoc?.regulation?.id || ''}`);
			},
			[selectedParagraphs, selectedRegulatoryDocument, addClearedField],
		);

	/**
	 * * Other
	 */

	const clearFieldRegulatoryDocumentParagraphs = React.useCallback(
		async (fieldName: ParagraphKeysWithInitialFields) => {
			setSubmissionStarted();
			addClearedField(fieldName);

			const clearFunc =
				selectedParagraphs.length === regDoc?.paragraphs.length
					? () =>
							clearFieldForAllParagraphsMutation({
								variables: {
									input: {
										fieldName,
										regulatoryDocumentId: selectedRegulatoryDocument?.id ?? '',
									},
								},
							})
					: () =>
							clearFieldForSelectedParagraphsMutation({
								variables: {
									input: {
										fieldName,
										paragraphIds: selectedParagraphs.map(sp => sp.id) ?? [0],
										regulatoryDocumentId: selectedRegulatoryDocument?.id ?? '',
									},
								},
							});

			await clearFunc()
				.then(() => setEverCleared(true))
				.catch((e: Error) => {
					console.error(
						'[EditParagraphForm/clearFieldRegulatoryDocumentParagraphs]',
						e,
					);
				});
			setSubmissionFinished();
		},
		[selectedParagraphs, selectedRegulatoryDocument, addClearedField],
	);

	const handleSaveClick = useEditParagraphsFormSubmitter({
		handleSubmit,
		setMode,
		setSelectedParagraphsCopy,
		onParagraphsSaved,
		clearedFields,
		setIsFormRefetchingRegDoc,
		regDoc,
		setAreAuditLogsLoading,
		control,
		trigger,
		isVex,
	});

	const [createMatkonLimitsMutation] = useCreateMatkonLimitsMutation();

	interface Limit {
		id: number;
		applicationIri?: Scalars['String'];
		application?: {
			iri: string;
			createdBy: string;
			createdAt: Scalars['DateTime'];
			modifiedAt: Scalars['DateTime'];
			modifiedBy: string;
			description: string;
		};
		attachment?: InputMaybe<AttachmentInput>;
		deadlineInternal: Scalars['DateTime'];
		deadlineLaw: Scalars['DateTime'];
		expirationDate: Scalars['DateTime'];
		imdsAssessmentOverwritten?: InputMaybe<Scalars['Boolean']>;
		iri: Scalars['String'];
		limitations: Array<Scalars['String']>;
		matConComplianceEvaluationOverwritten?: InputMaybe<Scalars['Boolean']>;
		operator: EOperator;
		referenceValue: EReferenceValue;
		unit: Scalars['String'];
		value: Scalars['Int'];
	}

	const emptyLimit: Limit = {
		id: Date.now(), // For UI uniqueness, can also use a UUID
		iri: 'generatedIri', // TODO: generate appropriate IRI if needed
		application: {
			iri: '',
			createdBy: '',
			createdAt: undefined,
			modifiedAt: undefined,
			modifiedBy: '',
			description: '',
		}, // This will be set via the form
		deadlineInternal: new Date().toISOString(),
		deadlineLaw: new Date().toISOString(),
		expirationDate: new Date().toISOString(),
		attachment: undefined,
		imdsAssessmentOverwritten: false,
		limitations: [],
		matConComplianceEvaluationOverwritten: false,
		operator: EOperator.Equal,
		referenceValue: EReferenceValue.Component,
		unit: '',
		value: 0,
		applicationIri: undefined,
	};
	const [limits, setLimits] = useState<Limit[]>([emptyLimit]);

	useEffect(() => {
		if (matkonsLimits.length > 0) {
			const fetchedLimits: Limit[] = matkonsLimits.flatMap(matkon =>
				matkon.limits.map((limit: Limit) => ({
					id: Date.now(),
					iri: limit.iri,
					applicationIri: limit.application?.iri,
					deadlineInternal: limit.deadlineInternal,
					deadlineLaw: limit.deadlineLaw,
					expirationDate: limit.expirationDate,
					attachment: limit.attachment,
					imdsAssessmentOverwritten: limit.imdsAssessmentOverwritten,
					limitations: limit.limitations,
					matConComplianceEvaluationOverwritten:
						limit.matConComplianceEvaluationOverwritten,
					operator: limit.operator,
					referenceValue: limit.referenceValue,
					unit: limit.unit,
					value: limit.value,
					application: limit.application,
				})),
			);

			setLimits(fetchedLimits);

			matkonResetFields({
				limits: fetchedLimits,
			});
		}
	}, [matkonsLimits, matkonResetFields]);

	const addApplication = () => {
		const newLimits = [...limits, emptyLimit];
		setLimits(newLimits);
		matkonResetFields({limits: newLimits});
	};

	const postLimit = () => {
		const regulationNumber = regDoc?.regulation?.regulationNumber || '';
		const paragraphId = selectedParagraphs[0].id;

		createMatkonLimitsMutation({
			variables: {
				input: {
					traceParagraphId: paragraphId,
					limits: limits.map(limit => {
						return {
							iri: 'test',
							applicationIri: limit.applicationIri ?? '',
							deadlineInternal: limit.deadlineInternal,
							deadlineLaw: limit.deadlineLaw,
							expirationDate: new Date(),
							attachment: limit.attachment,
							imdsAssessmentOverwritten: limit.imdsAssessmentOverwritten,
							limitations: limit.limitations.map(tag => tag),
							matConComplianceEvaluationOverwritten:
								limit.matConComplianceEvaluationOverwritten,
							operator: limit.operator,
							referenceValue: limit.referenceValue,
							unit: limit.unit,
							value: Number(limit.value),
							application: undefined,
						};
					}),
					traceRegulationNumber: regulationNumber,
				},
			},
			refetchQueries: [MatkonApplicationsDocument],
		});
	};

	const onRenderFooterContent: any = () => (
		<form className={classNames.form}>
			<ActionButton
				onClick={async () => {
					if (regDoc?.workflow?.status === WorkflowStatus.Finalized && !isVex) {
						setShowConfirmationDialog(true);
					} else {
						handleSaveClick();
					}
				}}
				styles={buttonStyles}
			>
				{t('UpdateButton')}
			</ActionButton>

			<DefaultButton onClick={handleCancelClick}>
				{t('CancelButton')}
			</DefaultButton>
		</form>
	);

	const onRenderMatKonFooterContent: any = () => (
		<form className={classNames.form}>
			<ActionButton
				disabled={
					limits.some(limit => !limit.value) ||
					limits.some(limit => !limit.unit)
				}
				onClick={async () => {
					postLimit();
				}}
				styles={buttonStyles}
			>
				{t('UpdateButton')}
			</ActionButton>

			<DefaultButton onClick={handleCancelClick}>
				{t('CancelButton')}
			</DefaultButton>
		</form>
	);

	const refetchRegDoc = async (): Promise<void> => {
		await apolloClient.refetchQueries({
			include: [GetRegulatoryDocumentDetailsDocument],
		});
	};

	const handleCancelClick = React.useCallback(async () => {
		setMode(RegulatoryDetailsPageFormMode.None);
		if (!clearedFields.length) return;
		setSubmissionStarted();
		/**
		 * When the user clears a field, the mutation doesn't refetch the data, so
		 * we need to refetch it here. We cannot have the mutation refetch the data
		 * or return the new data because that might cause the app to reset the form
		 * while the user is using it.
		 */
		// await refetchRegDoc();
		setSubmissionFinished();

		if (everCleared) {
			await onParagraphsSaved().catch((e: Error) =>
				console.error('[EditParagraphForm/handleCancelClick]', e),
			);
		}
		handleScrolling(true);
	}, [everCleared]);

	const {data, loading: isLoadingFormData} = useGetParagraphsFormDataQuery();
	const {
		driveVariantData,
		vehicleCategorieData,
		categoriesData,
		keywordData,
		tagData,
	} = React.useMemo(() => mapFormEdgeNodes(data), [data]);
	const theme = useTheme();

	const classNames = mergeStyleSets({
		arrayWrapper: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		arrayItem: {
			borderRadius: '10px',
			color: theme.palette.neutralSecondary,
			background: theme.palette.neutralLight,
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
		},
		form: {
			display: 'flex',
		},
		separatorStyle: {
			borderBottom: '2px dashed gray',
			paddingBottom: '20px',
			paddingTop: '20px',
		},
		application: {
			color: 'rgb(164, 38, 44)',
			paddingRight: '12px',
			paddingLeft: '4px',
		},
	});

	const handleDeleteMetadata = React.useCallback(
		(metadata: keyof ParagraphFormFields) => {
			setMetadataToPossiblyDelete(metadata);

			if (regDoc?.workflow?.status === WorkflowStatus.Finalized && !isVex) {
				setShowConfirmationDialogDelete(true);
			} else {
				showDeleteMetadataDialog();
			}
		},
		[showDeleteMetadataDialog],
	);

	const getParagraphWithInitialFormValue = (
		key: ParagraphKeysWithInitialFields,
	) => {
		return (paragraph: RegDocParagraph): RegDocParagraph => {
			const value: RegDocParagraph[ParagraphKeysWithInitialFields] =
				initialRegulatoryDocumentParagraph[key];
			return {...paragraph, [key]: value};
		};
	};

	/**
	 * So that the this component's paragraphs list shows the correct value when
	 * we clear the field.
	 */
	const setParagraphsCopyWithInitialFormValue = (
		key: ParagraphKeysWithInitialFields,
	): void => {
		const paragraphs: ParagraphsCopy = selectedParagraphsCopy.map(
			getParagraphWithInitialFormValue(key),
		);
		setSelectedParagraphsCopy(paragraphs);
	};

	const setFormFieldValueToInitialValue = (
		key: ParagraphKeysWithInitialFields,
	): void => {
		const initialFormValue: ParagraphFormFields[ParagraphKeysWithInitialFields] =
			initialRegulatoryDocumentParagraph[key];
		setValue(key, initialFormValue);
	};

	const handleDeleteMetadataConfirmed = React.useCallback(async () => {
		const key = metadataToPossiblyDelete as ParagraphKeysWithInitialFields;
		setFormFieldValueToInitialValue(key);
		await clearFieldRegulatoryDocumentParagraphs(key)
			.catch((e: any) =>
				console.error('[EditParagraphForm/handleDeleteMetadataConfirmed]', e),
			)
			.finally(() => {
				hideDeleteMetadataDialog();
				setMode(RegulatoryDetailsPageFormMode.None);
				handleSaveClick();
			});
		setParagraphsCopyWithInitialFormValue(key);
	}, [metadataToPossiblyDelete, selectedParagraphsCopy]);

	const handleDeleteMetadataConfirmedForFinalVersion =
		React.useCallback(async () => {
			const key = metadataToPossiblyDelete as ParagraphKeysWithInitialFields;
			setFormFieldValueToInitialValue(key);
			await clearFieldRegulatoryDocumentParagraphsForFinalVersion(key)
				.catch((e: any) =>
					console.error(
						'[EditParagraphForm/handleDeleteMetadataConfirmedForFinalVersion]',
						e,
					),
				)
				.finally(() => {
					setShowConfirmationDialogDelete(false);
					setMode(RegulatoryDetailsPageFormMode.None);
				});
			setParagraphsCopyWithInitialFormValue(key);
		}, [metadataToPossiblyDelete, selectedParagraphsCopy]);

	const createDeletionTag = React.useCallback(
		(key: keyof ParagraphFormFields): ActionTagProps[] => {
			if (!isVko && !isAdmin) {
				return [];
			}

			return [
				{
					iconName: 'Cancel',
					title: t('DeleteMetadataButtonLabel'),
					onClick: () => handleDeleteMetadata(key),
				},
			];
		},
		[isVko, isAdmin],
	);

	const getIfIsLoading = (): boolean => {
		/**
		 * We wait for attachment categories when editing a single paragraph in case
		 * we need to create optimistic attachments, which requires the attachment categories.
		 */
		if (selectedParagraphs.length === 1) {
			return isLoadingFormData || isLoadingAttachmentCategories;
		}

		return isLoadingFormData;
	};

	const referenceValueOptions: IDropdownOption[] = Object.entries(
		EReferenceValue,
	).map(([key, value]) => ({
		key: value.replace(/_/g, ''),
		text: key.replace(/([A-Z])/g, ' $1').trim(),
	}));
	const operatorOptions: IDropdownOption[] = Object.entries(EOperator).map(
		([key, value]) => ({
			key: value,
			text: key.replace(/([A-Z])/g, ' $1').trim(),
		}),
	);

	const comboBoxStyles: Partial<IComboBoxStyles> = {root: {width: '100%'}};

	return (
		<>
			<Panel
				isLightDismiss
				type={PanelType.extraLarge}
				isFooterAtBottom={true}
				onDismiss={handleCancelClick}
				isOpen={mode === RegulatoryDetailsPageFormMode.EditMatKon}
				closeButtonAriaLabel='Close'
				headerText={t('EditMatKonMetadataHeader')}
				onRenderFooterContent={onRenderMatKonFooterContent}
			>
				<LoadWrapper loading={getIfIsLoading()}>
					<Stack
						horizontal
						styles={{root: {height: '100%', position: 'relative'}}}
					>
						<ScrollablePane styles={scrollablePaneStyles}>
							<Stack tokens={{childrenGap: 8}} styles={{root: {marginTop: 4}}}>
								{selectedParagraphsCopy.map(p => (
									<div key={`${p.id}-paragraph`}>
										<div key={p.id}>{renderParagraph(p, t)}</div>
										{selectedParagraphsCopy.length > 1 && (
											<div>
												{/* Date New Types */}
												{Boolean(p.dateNewTypes) && (
													<div
														key={`${p.id}-new-types`}
														className={classNames.arrayWrapper}
													>
														<div className={classNames.arrayItem}>
															{formatDateTime(new Date(p.dateNewTypes), i18n)}
														</div>
													</div>
												)}
												{/* Date New Regsitration */}
												{Boolean(p.dateNewRegistration) && (
													<div
														key={`${p.id}-new-registration`}
														className={classNames.arrayWrapper}
													>
														<div className={classNames.arrayItem}>
															{formatDateTime(
																new Date(p.dateNewRegistration),
																i18n,
															)}
														</div>
													</div>
												)}
												{/* Keywords */}
												<div
													key={`${p.id}-keywords`}
													className={classNames.arrayWrapper}
												>
													{p.keywords.map(k => (
														<div
															key={uuidv4()}
															className={classNames.arrayItem}
														>
															{k.name}
														</div>
													))}
												</div>
												{/* Drive variants */}
												<div
													key={`${p.id}-driveVariants`}
													className={classNames.arrayWrapper}
												>
													{p.driveVariants.map(dv => (
														<div
															key={uuidv4()}
															className={classNames.arrayItem}
														>
															{dv.name}
														</div>
													))}
												</div>
												{/* Vehicle Categories */}
												<div
													key={`${p.id}-vehicleCategories`}
													className={classNames.arrayWrapper}
												>
													{p.vehicleCategories.map(vc => (
														<div
															key={uuidv4()}
															className={classNames.arrayItem}
														>
															{vc.name}
														</div>
													))}
												</div>
												{/* Categories */}
												<div
													key={`${p.id}-categories`}
													className={classNames.arrayWrapper}
												>
													{p.categories.map(category => (
														<div
															key={category.id}
															className={classNames.arrayItem}
														>
															{category.name}
														</div>
													))}
												</div>
												{/* Tags */}
												<div
													key={`${p.id}-tags`}
													className={classNames.arrayWrapper}
												>
													{p.tags.map(t => (
														<div
															key={uuidv4()}
															className={classNames.arrayItem}
														>
															{t.name}
														</div>
													))}
												</div>
												{/* Phase In */}
												<div
													key={`${p.id}-phase-in`}
													className={classNames.arrayWrapper}
												>
													{p.phaseIn?.map(phaseIn => (
														<div
															key={phaseIn.status}
															className={classNames.arrayItem}
														>
															{formatDateTime(new Date(phaseIn.date), i18n)} (
															{phaseIn.status})
														</div>
													))}
												</div>
												{/* Phase Out */}
												<div
													key={`${p.id}-phase-out`}
													className={classNames.arrayWrapper}
												>
													{p.phaseOut?.map(phaseOut => (
														<div
															key={phaseOut.status}
															className={classNames.arrayItem}
														>
															{formatDateTime(new Date(phaseOut.date), i18n)} (
															{phaseOut.status})
														</div>
													))}
												</div>
											</div>
										)}
									</div>
								))}
							</Stack>
						</ScrollablePane>
						<Separator vertical />
						<Stack horizontal={false} tokens={tokens} styles={stackStyles}>
							<ScrollablePane styles={scrollablePaneStyles}>
								<RegDocParagraphTooltipTranslationProvider>
									<ProviderThatEnablesGettingTooltipsFromContext>
										<Label>Application:</Label>
										<Separator />
										{fields.map((limit, index) => {
											return (
												<div
													key={limit.id}
													className={classNames.separatorStyle}
												>
													<Stack horizontal tokens={tokens}>
														<Stack.Item grow={1} styles={stackItemStyles}>
															<ControlledTagPicker
																name={`limits[${index}].limitations`}
																label='RestrictionFor'
																control={matkonControl}
																selectableItems={limitations}
																defaultSelectedItems={
																	limit.limitations.map(item => ({
																		key: item,
																		name: item,
																	})) || []
																}
																getKey={(item: any) =>
																	typeof item === 'string' ? item : item.id
																}
																getName={(item: any) =>
																	typeof item === 'string' ? item : item.name
																}
																performExtraActionOnChange={items => {
																	const updatedLimits = [...limits];

																	updatedLimits[index].limitations =
																		items?.map(item => `${item.key}`) ?? [];
																	setLimits(updatedLimits);
																}}
															/>
														</Stack.Item>
													</Stack>
													<Stack horizontal tokens={tokens}>
														<Stack.Item grow={1} styles={stackItemStyles}>
															<ThreeStateToggle
																label='Relevant for IMDS check:'
																value={limit.imdsAssessmentOverwritten}
																onChange={newValue => {
																	setLimits(prevLimits => {
																		const newLimits = [...prevLimits];
																		newLimits[index].imdsAssessmentOverwritten =
																			newValue;
																		return newLimits;
																	});
																}}
															/>
														</Stack.Item>
													</Stack>
													<Stack horizontal tokens={tokens}>
														<Stack.Item grow={1} styles={stackItemStyles}>
															<ThreeStateToggle
																label='Relevant for material compliance check:'
																value={
																	limit.matConComplianceEvaluationOverwritten
																}
																onChange={newValue => {
																	setLimits(prevLimits => {
																		const newLimits = [...prevLimits];
																		newLimits[
																			index
																		].matConComplianceEvaluationOverwritten =
																			newValue;
																		return newLimits;
																	});
																}}
															/>
														</Stack.Item>
													</Stack>
													<Separator />
													<Stack horizontal tokens={tokens}>
														<Stack.Item grow={1} styles={stackItemStyles}>
															<ControlledDatePicker
																control={matkonControl}
																name={`limits[${index}].deadlineLaw`}
																label='Legal entry date'
																onChange={date => {
																	setLimits(prevLimits => {
																		const newLimits = [...prevLimits];
																		newLimits[index].deadlineLaw =
																			date instanceof Date
																				? date.toISOString()
																				: '';
																		return newLimits;
																	});
																}}
																defaultValue={new Date(limit.deadlineLaw)}
															/>
														</Stack.Item>
														<Stack.Item grow={2} styles={stackItemStyles}>
															<ControlledDatePicker
																control={matkonControl}
																name={'InternalEntryDate'}
																label='Internal entry date'
																onChange={date => {
																	setLimits(prevLimits => {
																		const newLimits = [...prevLimits];
																		newLimits[index].deadlineInternal =
																			date instanceof Date
																				? date.toISOString()
																				: '';
																		return newLimits;
																	});
																}}
																defaultValue={new Date(limit.deadlineInternal)}
															/>
														</Stack.Item>
													</Stack>
													<Separator />
													<Stack horizontal tokens={tokens}>
														<Stack.Item grow={1} styles={stackItemStyles}>
															<Controller
																name={`limits.${index}.value`}
																control={matkonControl}
																render={({field: {onChange, value}}) => (
																	<TextField
																		required={true}
																		value={limit.value.toString()}
																		onChange={(e, val) => {
																			onChange(val);

																			setLimits(prevLimits => {
																				const newLimits = [...prevLimits];
																				newLimits[index].value = Number(val);
																				return newLimits;
																			});

																			limit.value = Number(val);
																		}}
																		label={t('Limit')}
																		name='Limit'
																		type='number'
																	/>
																)}
															/>
														</Stack.Item>
														<Stack.Item grow={2} styles={stackItemStyles}>
															<Controller
																name={`limits.${index}.operator`}
																control={matkonControl}
																render={({field: {onChange, value}}) => (
																	<ComboBox
																		onChange={(e, val) => {
																			onChange(val?.key);

																			setLimits(prevLimits => {
																				const newLimits = [...prevLimits];

																				if (val?.key !== undefined) {
																					newLimits[index].operator =
																						val.key as EOperator;
																				}
																				return newLimits;
																			});
																		}}
																		label={t('Operator')}
																		options={operatorOptions}
																		styles={comboBoxStyles}
																		autoComplete='on'
																		defaultSelectedKey={limit.operator}
																	/>
																)}
															/>
														</Stack.Item>
														<Stack.Item grow={3} styles={stackItemStyles}>
															<Controller
																name={`limits.${index}.unit`}
																control={matkonControl}
																render={({field: {onChange, value}}) => (
																	<TextField
																		required={true}
																		value={limit.unit}
																		onChange={(e, val) => {
																			onChange(val);

																			setLimits(prevLimits => {
																				const newLimits = [...prevLimits];
																				newLimits[index].unit = val || '';
																				return newLimits;
																			});

																			limit.unit = val ?? '';
																		}}
																		label={t('Unit')}
																		name='Unit'
																	/>
																)}
															/>
														</Stack.Item>
														<Stack.Item grow={3} styles={stackItemStyles}>
															<Controller
																name={`limits.${index}.referenceValue`}
																control={matkonControl}
																render={({field: {onChange, value}}) => (
																	<ComboBox
																		onChange={(e, val) => {
																			onChange(val?.key);
																			setLimits(prevLimits => {
																				const newLimits = [...prevLimits];

																				if (val?.key !== undefined) {
																					newLimits[index].referenceValue =
																						val.key as EReferenceValue;
																				}
																				return newLimits;
																			});
																		}}
																		label={t('ReferenceValue')}
																		options={referenceValueOptions}
																		styles={comboBoxStyles}
																		autoComplete='on'
																		defaultSelectedKey={limit.referenceValue}
																	/>
																)}
															/>
														</Stack.Item>
													</Stack>
													<Separator />
													<Stack>
														<Label>
															{t('Application')}
															<span className={classNames.application}>*</span>
															<PrimaryButton
																text=''
																iconProps={{iconName: 'Add'}}
																onClick={() => setShowMatkonDialog(true)}
															/>
														</Label>
													</Stack>
													<Stack horizontal tokens={tokens}>
														<Stack.Item grow={1} styles={stackItemStyles}>
															<ControlledDropdown
																// label={`Application`}
																name={`limits.${index}.applicationIri`}
																control={matkonControl}
																options={applications}
																required={true}
																rules={{required: true}}
																styles={comboBoxStyles}
																defaultSelectedKey={limit.application?.iri}
																onChange={(e, val) => {
																	setLimits(prevLimits => {
																		const newLimits = [...prevLimits];
																		newLimits[index].applicationIri =
																			(val?.key ?? '') as string;
																		return newLimits;
																	});
																}}
															/>
														</Stack.Item>
													</Stack>
													<Separator />
													<Label>Add Substances</Label>
													<Stack horizontal tokens={tokens}>
														<Stack.Item grow={1} styles={stackItemStyles}>
															<Link
																onClick={() => {
																	const binary = atob(
																		limitsTemplate.base64Content,
																	);
																	const array = new Uint8Array(binary.length);
																	for (let i = 0; i < binary.length; i++) {
																		array[i] = binary.charCodeAt(i);
																	}
																	const blob = new Blob([array], {
																		type: limitsTemplate.contentType,
																	});

																	// Create a link to download the file
																	const link = document.createElement('a');
																	link.href = window.URL.createObjectURL(blob);
																	link.download = limitsTemplate.filename;
																	link.click();
																}}
															>
																Export (Download file)
															</Link>
															<Separator />
															<FileUpload
																renderCategory={false}
																onChange={files => {
																	const newDocument = files[0]?.file;

																	setLimits(prevLimits => {
																		const newLimits = [...prevLimits];
																		newLimits[index].attachment = {
																			attachmentId: newDocument.name,
																			file: newDocument,
																		};
																		return newLimits;
																	});
																}}
															/>
															<Label>
																<span>
																	Export + Import von der Excel-Liste -&gt;
																	Zugriff von PURE (Automatische Prüfung, welche
																	CAS-Nr. neu sind, und diese in DB anlegen)
																</span>
															</Label>
														</Stack.Item>
													</Stack>
												</div>
											);
										})}
										<Separator />
										<PrimaryButton onClick={addApplication}>
											Add Application
										</PrimaryButton>
									</ProviderThatEnablesGettingTooltipsFromContext>
								</RegDocParagraphTooltipTranslationProvider>
							</ScrollablePane>
							<ConfirmDeleteDialog
								{...deleteMetadataDialogState}
								onConfirm={() => {
									handleDeleteMetadataConfirmed();
									handleSaveClick();
								}}
							/>
							<ConfirmModifiedVersionCreation
								showConfirmationDialog={showConfirmationDialog}
								setShowConfirmationDialog={setShowConfirmationDialog}
								handleSaveClick={handleSaveClick}
							/>
							<ConfirmModifiedVersionCreation
								showConfirmationDialog={showConfirmationDialogDelete}
								setShowConfirmationDialog={setShowConfirmationDialogDelete}
								handleSaveClick={handleDeleteMetadataConfirmedForFinalVersion}
							/>
						</Stack>
					</Stack>
				</LoadWrapper>
				{showMatkonDialog && (
					<RegDocParagraphTooltipTranslationProvider>
						<CreateMatkonApplicationDialog
							showDialog={showMatkonDialog}
							setShowDialog={setShowMatkonDialog}
						/>
					</RegDocParagraphTooltipTranslationProvider>
				)}
			</Panel>
			<Panel
				isLightDismiss
				type={PanelType.extraLarge}
				isFooterAtBottom={true}
				onDismiss={handleCancelClick}
				isOpen={mode === RegulatoryDetailsPageFormMode.Edit}
				closeButtonAriaLabel='Close'
				headerText={t('EditMetaDataPanelHeader')}
				onRenderFooterContent={onRenderFooterContent}
			>
				<LoadWrapper loading={getIfIsLoading()}>
					<Stack
						horizontal
						styles={{root: {height: '100%', position: 'relative'}}}
					>
						<ScrollablePane styles={scrollablePaneStyles}>
							<Stack tokens={{childrenGap: 8}} styles={{root: {marginTop: 4}}}>
								{selectedParagraphsCopy.map(p => (
									<div key={`${p.id}-paragraph`}>
										<div key={p.id}>{renderParagraph(p, t)}</div>
										{selectedParagraphsCopy.length > 1 && (
											<div>
												{/* Date New Types */}
												{Boolean(p.dateNewTypes) && (
													<div
														key={`${p.id}-new-types`}
														className={classNames.arrayWrapper}
													>
														<div className={classNames.arrayItem}>
															{formatDateTime(new Date(p.dateNewTypes), i18n)}
														</div>
													</div>
												)}
												{/* Date New Regsitration */}
												{Boolean(p.dateNewRegistration) && (
													<div
														key={`${p.id}-new-registration`}
														className={classNames.arrayWrapper}
													>
														<div className={classNames.arrayItem}>
															{formatDateTime(
																new Date(p.dateNewRegistration),
																i18n,
															)}
														</div>
													</div>
												)}
												{/* Keywords */}
												<div
													key={`${p.id}-keywords`}
													className={classNames.arrayWrapper}
												>
													{p.keywords.map(k => (
														<div
															key={uuidv4()}
															className={classNames.arrayItem}
														>
															{k.name}
														</div>
													))}
												</div>
												{/* Drive variants */}
												<div
													key={`${p.id}-driveVariants`}
													className={classNames.arrayWrapper}
												>
													{p.driveVariants.map(dv => (
														<div
															key={uuidv4()}
															className={classNames.arrayItem}
														>
															{dv.name}
														</div>
													))}
												</div>
												{/* Vehicle Categories */}
												<div
													key={`${p.id}-vehicleCategories`}
													className={classNames.arrayWrapper}
												>
													{p.vehicleCategories.map(vc => (
														<div
															key={uuidv4()}
															className={classNames.arrayItem}
														>
															{vc.name}
														</div>
													))}
												</div>
												{/* Categories */}
												<div
													key={`${p.id}-categories`}
													className={classNames.arrayWrapper}
												>
													{p.categories.map(category => (
														<div
															key={category.id}
															className={classNames.arrayItem}
														>
															{category.name}
														</div>
													))}
												</div>
												{/* Tags */}
												<div
													key={`${p.id}-tags`}
													className={classNames.arrayWrapper}
												>
													{p.tags.map(t => (
														<div
															key={uuidv4()}
															className={classNames.arrayItem}
														>
															{t.name}
														</div>
													))}
												</div>
												{/* Phase In */}
												<div
													key={`${p.id}-phase-in`}
													className={classNames.arrayWrapper}
												>
													{p.phaseIn?.map(phaseIn => (
														<div
															key={phaseIn.status}
															className={classNames.arrayItem}
														>
															{formatDateTime(new Date(phaseIn.date), i18n)} (
															{phaseIn.status})
														</div>
													))}
												</div>
												{/* Phase Out */}
												<div
													key={`${p.id}-phase-out`}
													className={classNames.arrayWrapper}
												>
													{p.phaseOut?.map(phaseOut => (
														<div
															key={phaseOut.status}
															className={classNames.arrayItem}
														>
															{formatDateTime(new Date(phaseOut.date), i18n)} (
															{phaseOut.status})
														</div>
													))}
												</div>
											</div>
										)}
									</div>
								))}
							</Stack>
						</ScrollablePane>
						<Separator vertical />
						<Stack horizontal={false} tokens={tokens} styles={stackStyles}>
							<ScrollablePane styles={scrollablePaneStyles}>
								<RegDocParagraphTooltipTranslationProvider>
									<ProviderThatEnablesGettingTooltipsFromContext>
										{selectedParagraphs.length > 1 && (
											<Text>
												Hinweis: Getroffene Auswahl wird zu den bereits
												vorhandenen Einträgen hinzugefügt
											</Text>
										)}
										<ControlledTagPicker
											name={'keywords'}
											label={t('KeywordTagPickerLabel')}
											control={control}
											selectableItems={keywordData}
											disabled={isVex}
											getKey={item => item.id}
											getName={item => item.name}
											actionTags={createDeletionTag('keywords')}
										/>
										<ControlledTagPicker
											name={'driveVariants'}
											label={t('DriveVariantTagPickerLabel')}
											control={control}
											selectableItems={driveVariantData}
											disabled={isVex}
											getKey={item => item.id}
											getName={item => item.name}
											actionTags={createDeletionTag('driveVariants')}
										/>
										<ControlledTagPicker
											name={'vehicleCategories'}
											label={t('VehicleCategoryTagPickerLabel')}
											control={control}
											selectableItems={vehicleCategorieData}
											disabled={isVex}
											getKey={item => item.id}
											getName={item => item.name}
											actionTags={createDeletionTag('vehicleCategories')}
										/>
										<ControlledTagPicker
											name={'categories'}
											label={t('CategoryTagPickerLabel')}
											control={control}
											selectableItems={categoriesData}
											disabled={isVex}
											getKey={item => item.id}
											getName={item => item.name}
											actionTags={createDeletionTag('categories')}
										/>
										<ControlledTagPicker
											name={'tags'}
											label={t('TagTagPickerLabel')}
											control={control}
											selectableItems={tagData}
											getKey={item => item.id}
											getName={item => item.name}
											actionTags={createDeletionTag('tags')}
										/>
										{selectedParagraphs.length > 1 && (
											<div>
												<Separator />
												<Text>
													Hinweis: Vorhandene Einträge werden mit der
													getroffenen Auswahl überschrieben
												</Text>
											</div>
										)}
										<Stack horizontal tokens={tokens}>
											<Stack.Item grow={1} styles={stackItemStyles}>
												<ControlledTextField
													placeholder={`${t('ForExample')} ${new Date()
														.getFullYear()
														.toString()}`}
													control={control}
													disabled={isVex}
													name={'modelYear'}
													label={t('ModelYearSpinButton')}
													rules={{
														pattern: {
															value: /^[1-9][0-9]{3}$/,
															message: t('ErrorModelYear'),
														},
													}}
													actionTags={createDeletionTag('modelYear')}
												/>
											</Stack.Item>
											<Stack.Item grow={1} styles={stackItemStyles}>
												<ControlledDatePicker
													control={control}
													name={'comprehensive'}
													label={t('ComprehensiveDatePicker')}
													disabled={isVex}
													actionTags={createDeletionTag('comprehensive')}
												/>
											</Stack.Item>
										</Stack>
										<Stack horizontal tokens={tokens}>
											<Stack.Item grow={1} styles={stackItemStyles}>
												<ControlledDatePicker
													control={control}
													name={'dateNewTypes'}
													label={t('DateNewTypesDatePicker')}
													disabled={isVex}
													actionTags={createDeletionTag('dateNewTypes')}
												/>
											</Stack.Item>
											<Stack.Item grow={1} styles={stackItemStyles}>
												<ControlledDatePicker
													control={control}
													name={'dateNewRegistration'}
													label={t('DateNewRegistrationDatePicker')}
													disabled={isVex}
													actionTags={createDeletionTag('dateNewRegistration')}
												/>
											</Stack.Item>
										</Stack>
									</ProviderThatEnablesGettingTooltipsFromContext>
									<Separator />
									<PhasePicker
										control={control}
										label='Phase In'
										name={'phaseIn'}
										disabled={isVex}
										actionTags={createDeletionTag('phaseIn')}
									/>
									<Separator />
									<PhasePicker
										control={control}
										label='Phase Out'
										name={'phaseOut'}
										disabled={isVex}
										actionTags={createDeletionTag('phaseOut')}
									/>
									<Separator />
									<ProviderThatEnablesGettingTooltipsFromContext>
										<ControlledRichtextEditor
											label={t('SummaryFieldLabel')}
											control={control}
											name={'summary'}
											disabled={isVex}
											/* NOTE: temporarily commented since ControlledRichTextEditor isn't reactive to values
								actionTags={[
									{
										iconName: 'Cancel',
										onClick: () => handleDeleteMetadata('summary'),
									},
								]}
								*/
										/>
										{selectedParagraphs.length === 1 && !isVex && (
											<>
												<Separator />
												<LabelWithTooltip translationKey='attachments'>
													{t('AttachmentsFieldLabel')}
												</LabelWithTooltip>
												<FileUploadComponent />
											</>
										)}
									</ProviderThatEnablesGettingTooltipsFromContext>
								</RegDocParagraphTooltipTranslationProvider>
							</ScrollablePane>
							<ConfirmDeleteDialog
								{...deleteMetadataDialogState}
								onConfirm={handleDeleteMetadataConfirmed}
							/>
							<ConfirmModifiedVersionCreation
								showConfirmationDialog={showConfirmationDialog}
								setShowConfirmationDialog={setShowConfirmationDialog}
								handleSaveClick={handleSaveClick}
							/>
							<ConfirmModifiedVersionCreation
								showConfirmationDialog={showConfirmationDialogDelete}
								setShowConfirmationDialog={setShowConfirmationDialogDelete}
								handleSaveClick={handleDeleteMetadataConfirmedForFinalVersion}
							/>
						</Stack>
					</Stack>
				</LoadWrapper>
			</Panel>
		</>
	);
};

const mapFormEdgeNodes = (
	data?: GetParagraphsFormDataQuery,
): SelectableItemsByType => ({
	mainKeywordData: data?.keywords ?? [],
	vehicleCategorieData: data?.vehicleCategories ?? [],
	categoriesData: data?.categories ?? [],
	driveVariantData: data?.driveVariants ?? [],
	keywordData: data?.keywords ?? [],
	tagData: data?.tags ?? [],
});

const buttonStyles = {
	root: {
		marginRight: 8,
	},
};

const stackStyles = {
	root: {width: '100%', height: '100%'},
};

const stackItemStyles = {
	root: {width: '100%'},
};

const tokens = {
	childrenGap: '5px',
};

const scrollablePaneStyles = {
	root: {
		position: 'relative',
		width: '100%',
		height: '100%',
	},
	contentContainer: {
		overflowX: 'hidden',
	},
} as Partial<IScrollablePaneStyles>;
