import {useTheme, Text, Stack} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {useTranslation} from 'react-i18next';
import {VexWorkbenchList} from './components/VexWorkbench/VexWorkbenchList';
import {WorkbenchRequirementsList} from './components/VexWorkbench/WorkbenchRequirementsList';
import {KeywordStatus} from 'types';
import {WorkbenchapprovalList} from './components/ApproverWorkbench/WorkbenchApprovalList';
import {
	BaseWorkBenchComponent,
	getDashboardClassNames,
	MyRecentActivity,
	stackItemStyles,
} from './components/Common/BaseWorkBenchComponent';
import {SummaryComponent} from './components/Common/SummaryComponent';
import React, {useState} from 'react';
import VkoWorkbenchComponent from './components/VkoWorkbench/VkoWorkbenchComponent';

const DashboardPage = () => {
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'DashboardPage',
	});
	const theme = useTheme();
	const classNames = getDashboardClassNames(theme);
	const {
		isVex,
		isVko,
		isAdmin,
		isApprover,
		isVkoExternal,
		isServiceProvider,
		isReader,
	} = useUserContext();

	type ItemCountType = number | undefined;

	const [vwNewItemCount, setVwNewItemCount] =
		useState<ItemCountType>(undefined);
	const [vwAcceptedItemCount, setVwAcceptedItemCount] =
		useState<ItemCountType>(undefined);
	const [vwFinalizedItemCount, setVwFinalizedItemCount] =
		useState<ItemCountType>(undefined);

	const [vexRequirementCount, setVexRequirementCount] =
		useState<ItemCountType>(undefined);
	const [approverReviewCount, setApproverReviewCount] =
		useState<ItemCountType>(undefined);
	const [activityItemCount, setActivityItemCount] =
		useState<ItemCountType>(undefined);

	const renderVexWorkbenchLists = () => (
		<>
			{vwNewItemCount !== 0 && (
				<VexWorkbenchList
					halfHeight
					keywordStatus={KeywordStatus.New}
					itemCountLoaded={(count: number) => setVwNewItemCount(count)}
				/>
			)}
			{vwAcceptedItemCount !== 0 && (
				<VexWorkbenchList
					halfHeight
					keywordStatus={KeywordStatus.Accepted}
					itemCountLoaded={(count: number) => setVwAcceptedItemCount(count)}
				/>
			)}
			{vwFinalizedItemCount !== 0 && (
				<VexWorkbenchList
					halfHeight
					keywordStatus={KeywordStatus.Finalized}
					itemCountLoaded={(count: number) => setVwFinalizedItemCount(count)}
				/>
			)}
		</>
	);

	const isVexWorkbenchEmpty = React.useCallback(() => {
		return (
			vwNewItemCount === 0 &&
			vwAcceptedItemCount === 0 &&
			vwFinalizedItemCount === 0
		);
	}, [vwAcceptedItemCount, vwFinalizedItemCount, vwNewItemCount]);

	return (
		<div style={{background: theme.palette.neutralLight}}>
			{isVko && <VkoWorkbenchComponent />}
			{isVex && <SummaryComponent requirementCount={vexRequirementCount} />}
			{isVex && !isVexWorkbenchEmpty() && (
				<Stack horizontalAlign='start' className={classNames.WorkbenchHeader}>
					<Text className={classNames.WorkbenchHeaderText}>
						{t('RegulationsOverviewHeader')}
					</Text>
				</Stack>
			)}
			<Stack horizontal horizontalAlign='start'>
				{isVex || isApprover ? (
					<Stack horizontal styles={{root: {width: '100%'}}} grow={1}>
						{isVex && !isVexWorkbenchEmpty() && renderVexWorkbenchLists()}
					</Stack>
				) : (
					<BaseWorkBenchComponent
						isVkoExternal={isVkoExternal}
						isVko={isVko}
						isAdmin={isAdmin}
						isReader={isReader}
						isServiceProvider={isServiceProvider}
						stackItemStyles={stackItemStyles}
					/>
				)}
			</Stack>

			{isVex && vexRequirementCount !== 0 && (
				<Stack horizontalAlign='start' className={classNames.WorkbenchHeader}>
					<Text className={classNames.WorkbenchHeaderText}>
						{t('ReqsOverviewHeader')}
					</Text>
				</Stack>
			)}
			{isVex && vexRequirementCount !== 0 && (
				<Stack
					horizontal
					horizontalAlign='start'
					styles={{root: {width: '100%', overflowX: 'hidden'}}}
					grow={1}
				>
					<WorkbenchRequirementsList
						halfHeight
						itemCountLoaded={(count: number) => setVexRequirementCount(count)}
					/>
				</Stack>
			)}
			{isApprover && approverReviewCount !== 0 && (
				<Stack horizontalAlign='start' className={classNames.WorkbenchHeader}>
					<Text className={classNames.WorkbenchHeaderText}>
						{t('ApproverOveriewHeader')}
					</Text>
				</Stack>
			)}
			{isApprover && (
				<Stack
					horizontal
					horizontalAlign='start'
					styles={{root: {width: '100%'}}}
					grow={1}
				>
					<WorkbenchapprovalList
						halfHeight
						itemCountLoaded={(count: number) => setApproverReviewCount(count)}
					/>
				</Stack>
			)}
			{isVex && activityItemCount !== 0 && (
				<MyRecentActivity
					itemCountLoaded={(count: number) => setActivityItemCount(count)}
				/>
			)}
		</div>
	);
};

export default DashboardPage;
