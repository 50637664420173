import {List, Stack, Text, TooltipHost, useTheme} from '@fluentui/react';
import {LoadWrapper} from 'components/LoadWrapper';
import {DateTimeFormat, formatDateTime} from 'i18n/localeDateFormat';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getListClassNames} from 'styles';
import {KeywordStatus} from 'types';
import {GetWorkbenchItemsVexQuery} from '../../hooks/workbenchItems.generated';
import {RegulationHeaderRow, WorkbenchHeader} from '../Headers';
import {useDashboardQueries} from 'features/Dashboard/hooks/useDashboardQueries';

export type DashboardCardProps = {
	halfHeight?: boolean;
	itemCountLoaded?: (count: number) => void;
};

type VexWorkbenchItemsProps = {
	keywordStatus:
		| KeywordStatus.Finalized
		| KeywordStatus.Accepted
		| KeywordStatus.New;
} & DashboardCardProps;

type VexWorkbenchItem =
	GetWorkbenchItemsVexQuery['workbenchRegulatoryDocumentParagraphs'][0];

export const VexWorkbenchItems: React.FC<VexWorkbenchItemsProps> = ({
	halfHeight = false,
	keywordStatus,
	itemCountLoaded,
}) => {
	const {i18n} = useTranslation('common/enums', {
		keyPrefix: 'WorkflowStatus',
	});

	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'VexWorkbenchItems',
	});

	const {getWorkbenchItemsVexQuery} = useDashboardQueries();
	const {data, loading} = getWorkbenchItemsVexQuery(keywordStatus);

	const items: VexWorkbenchItem[] = React.useMemo(
		() => data?.workbenchRegulatoryDocumentParagraphs ?? [],
		[data],
	);

	React.useEffect(() => {
		if (!loading && data?.workbenchRegulatoryDocumentParagraphs) {
			itemCountLoaded?.(data.workbenchRegulatoryDocumentParagraphs?.length);
		}
	}, [data, loading]);

	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	const getTitle = (keywordStatus: any) => {
		switch (keywordStatus) {
			case KeywordStatus.Accepted:
				return t('InProcessTitle');
			case KeywordStatus.Finalized:
				return t('CompletedTitle');
			case KeywordStatus.New:
				return t('AssessResponsibilityTitle');
			default:
				return t('DefaultTitle');
		}
	};

	const isOverDueDate = React.useCallback(
		(item: VexWorkbenchItem) => {
			let dueDate;

			// TODO: Share this code block with getDueDate below
			if (keywordStatus === KeywordStatus.Accepted) {
				dueDate = item.regulatoryDocument.workflow.dueDateInProcess;
			} else if (keywordStatus === KeywordStatus.New) {
				dueDate = item.regulatoryDocument.workflow.dueDate;
			}

			return new Date() > new Date(dueDate);
		},
		[keywordStatus],
	);

	const getDueDate = React.useCallback(
		(item: VexWorkbenchItem) => {
			let dueDate;

			if (keywordStatus === KeywordStatus.Accepted) {
				dueDate = item.regulatoryDocument.workflow.dueDateInProcess;
			} else if (keywordStatus === KeywordStatus.New) {
				dueDate = item.regulatoryDocument.workflow.dueDate;
			}

			return dueDate
				? formatDateTime(
						new Date(dueDate),
						i18n,
						DateTimeFormat.DateMonthWeekday,
				  )
				: '';
		},
		[keywordStatus],
	);

	const onRenderCell = (
		item?: VexWorkbenchItem,
		columnKey?: string,
	): JSX.Element => {
		if (!item) {
			return <></>;
		}

		const itemClasses = isOverDueDate(item)
			? `${classNames.itemCell} ${classNames.itemCellError}`
			: classNames.itemCell;

		return (
			<Link
				to={`/regulations/${item?.regulatoryDocument?.regulation?.id}/${
					item?.regulatoryDocument?.id
				}/paragraphs?filter=${
					keywordStatus === KeywordStatus.Accepted ? 'inProcess' : 'new'
				}`}
				style={{textDecoration: 'none'}}
			>
				<div
					key={item.regulatoryDocument.id}
					className={itemClasses}
					data-is-focusable={true}
				>
					<div className={classNames.itemContent}>
						<Stack horizontal horizontalAlign={'space-between'}>
							<Stack>
								<Text>
									{item.regulatoryDocument.regulation?.regulationNumber ?? ''}
								</Text>
								<div style={{color: theme.palette.neutralDark}}>
									{item?.regulatoryDocument?.name}
								</div>
							</Stack>
							{columnKey !== t('CompletedTitle') && (
								<TooltipHost
									content={`${item?.paragraphCount} Segmente zu bearbeiten`}
								>
									<Text className={classNames.itemCountInline}>
										{item?.paragraphCount}
									</Text>
								</TooltipHost>
							)}
						</Stack>
						{columnKey !== t('CompletedTitle') && (
							<div className={classNames.itemSubtext}>
								{t('DueDate')}
								{': '}
								{getDueDate(item)}
							</div>
						)}
					</div>
				</div>
			</Link>
		);
	};

	return (
		<div>
			<WorkbenchHeader
				halfHeight
				title={getTitle(keywordStatus)}
				items={
					keywordStatus === KeywordStatus.Finalized ? undefined : items.length
				}
			/>
			<div className={classNames.container} data-is-scrollable>
				<RegulationHeaderRow columnKey={getTitle(keywordStatus)} t={t} />
				<LoadWrapper loading={loading}>
					<List
						items={items}
						onRenderCell={item => onRenderCell(item, getTitle(keywordStatus))}
					/>
				</LoadWrapper>
			</div>
		</div>
	);
};
