import {DialogInfo, useDialogState} from 'components/Dialogs';
import React from 'react';
import {RegulationForm, RegulationFormProps} from './RegulationsForm';
import {GetRegulationDocument} from '../hooks';

export default function RegulationDetailRegulationForm(
	props: Pick<RegulationFormProps, 'mode' | 'setMode' | 'regulation'>,
) {
	const [dialogState, , hideDialog]: DialogInfo = useDialogState();

	/**
	 * We must not start polling if reg docs are selected because polling will
	 * reset the selection data once the app detects a change to the regulation.
	 */
	return (
		<RegulationForm
			dialogState={dialogState}
			hideDialog={hideDialog}
			queriesToRefetchAfterSaveOrDelete={[GetRegulationDocument]}
			{...props}
		/>
	);
}
