/* eslint-disable prettier/prettier */
/* eslint-disable no-negated-condition */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Label } from '@fluentui/react';
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling';

const theme = getTheme();
const { palette } = theme;

const containerClass = mergeStyles({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '20px',
  cursor: 'pointer',
  height: '32px',
  width: '180px',
  backgroundColor: palette.neutralLight,
  position: 'relative',
});

const toggleThumbClass = mergeStyles({
  position: 'absolute',
  height: '32px',
  width: '60px',
  backgroundColor: palette.themePrimary,
  borderRadius: '20px',
  transition: 'left 0.3s',
});

const toggleLabelClass = mergeStyles({
  flex: '1 1 33%',
  textAlign: 'center',
  lineHeight: '32px',
  zIndex: 1,
  fontWeight: 'bold',
  cursor: 'pointer',
});

const inlineLabelClass = mergeStyles({
  marginRight: '10px',
  fontWeight: 'bold',
  paddingTop: '10px',
  minWidth: '300px',
});

interface ThreeStateToggleProps {
  label: string;
  onChange?: (value: boolean | null) => void;
  value?: boolean | null;
}

const ThreeStateToggle: React.FC<ThreeStateToggleProps> = ({ label, onChange, value = false  }) => {
  // const [state, setState] = useState('no');

  const [state, setState] = useState<boolean | null>(value);

  const getThumbPosition = () => {
    if (state === true) {
      return '0%';
    }
    if (state === null) {
      return '33%';
    }
    return '66%'; // state === false
  };

  const handleClick = (newState: boolean | null) => {
    setState(newState);
    if (onChange) {
      onChange(newState);
    }
  };

  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }} styles={{ root: { paddingTop: '10px' } }}>
      <Label className={inlineLabelClass}>{label}</Label>
      <div className={containerClass}>
        <div
          className={toggleThumbClass}
          style={{ left: getThumbPosition() }}
        />
        <div
          className={mergeStyles(toggleLabelClass, { color: state === true ? palette.white : palette.neutralDark })}
          onClick={() => handleClick(true)}
        >
          Yes
        </div>
        <div
          className={mergeStyles(toggleLabelClass, { color: state === null ? palette.white : palette.neutralDark })}
          onClick={() => handleClick(null)}
        >
          Wait
        </div>
        <div
          className={mergeStyles(toggleLabelClass, { color: state === false ? palette.white : palette.neutralDark })}
          onClick={() => handleClick(false)}
        >
          No
        </div>
      </div>
    </Stack>
  );
};

ThreeStateToggle.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default ThreeStateToggle;
