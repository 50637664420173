import {EntityListColumn} from 'components/EntityList';
import {
	renderArrayField,
	renderAttachmentsPogis,
	renderLinkField,
	renderPogisDate,
} from 'components/EntityList/ColumnRenderers';
import {TFunction} from 'i18next';

export const getPogisArchiveColumns = (t: TFunction): EntityListColumn[] => {
	return [
		{
			key: 'pogisId',
			name: t('POGISID'),
			fieldName: 'pogisId',
			filterable: true,
			isMultiline: false,
			minWidth: 100,
			maxWidth: 100,
			isResizable: true,
			sortable: true,
			filterOnFilter: true,
		},
		{
			key: 'keywords',
			name: t('Keywords'),
			fieldName: 'keywords',
			getFieldValue: (item: any) => item.keywords,
			getFilterKey: (item: any) => item,
			getFilterLabel: (item: any) => item,
			filterable: true,
			isMultiline: true,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
			onRender: renderArrayField(),
			filterOnFilter: true,
		},
		{
			key: 'markets',
			name: t('Markets'),
			fieldName: 'markets',
			getFieldValue: (item: any) => item.markets,
			getFilterKey: (item: any) => item,
			getFilterLabel: (item: any) => item,
			filterable: true,
			isMultiline: true,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
			onRender: renderArrayField(),
			filterOnFilter: true,
		},
		{
			key: 'regulationStatus',
			name: t('RegulationStatus'),
			fieldName: 'regulationStatus',
			filterable: true,
			isMultiline: false,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
		},
		{
			key: 'shortInfo',
			name: t('ShortInfo'),
			fieldName: 'shortInfo',
			isMultiline: true,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
		},
		{
			key: 'dates',
			name: t('Dates'),
			fieldName: 'dates',
			isMultiline: true,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
			onRender: renderPogisDate(),
		},
		{
			key: 'standards',
			name: t('Standards'),
			fieldName: 'standards',
			getFieldValue: (item: any) => item.standards,
			getFilterKey: (item: any) => item,
			getFilterLabel: (item: any) => item,
			filterable: true,
			isMultiline: true,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
			onRender: renderArrayField(),
			filterOnFilter: true,
		},
		{
			key: 'associations',
			name: t('Associations'),
			fieldName: 'associations',
			getFieldValue: (item: any) => item.associations,
			getFilterKey: (item: any) => item,
			getFilterLabel: (item: any) => item,
			filterable: true,
			isMultiline: true,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
			onRender: renderArrayField(),
			filterOnFilter: true,
		},
		{
			key: 'editor',
			name: t('Editor'),
			fieldName: 'editor',
			filterable: true,
			isMultiline: false,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
			filterOnFilter: true,
		},
		{
			key: 'relatedDocuments',
			name: t('RelatedDocuments'),
			fieldName: 'relatedDocuments',
			getFieldValue: (item: any) => item.relatedDocuments,
			getFilterKey: (item: any) => item,
			getFilterLabel: (item: any) => item,
			filterable: true,
			isMultiline: true,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
			onRender: renderArrayField(),
			filterOnFilter: true,
		},
		{
			key: 'summaryAttachments',
			name: t('Attachments'),
			fieldName: 'summaryAttachments',
			isMultiline: true,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
			onRender: renderAttachmentsPogis(),
		},
		{
			key: 'otherAttachments',
			name: t('Attachments'),
			fieldName: 'otherAttachments',
			isMultiline: true,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
			onRender: renderAttachmentsPogis(),
		},
		{
			key: 'docStatus',
			name: t('DocStatus'),
			fieldName: 'docStatus',
			isMultiline: false,
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
		},
		{
			key: 'linkVersion',
			name: t('LinkVersion'),
			fieldName: 'linkVersion',
			isMultiline: true,
			minWidth: 200,
			maxWidth: 250,
			isResizable: true,
			sortable: true,
			onRender: renderLinkField(),
		},
	];
};
