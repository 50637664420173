import {Command, useCommand} from 'hooks';
import {
	RegulationFormMode,
	SetRegulationFormMode,
} from './useRegulationFormMode';
import {UserRole} from 'types';

type OptionalFields = Partial<Omit<Command, 'onClick'>>;

type RequiredFields = Pick<Command, 'key'>;

type CommandFields = OptionalFields & RequiredFields;

interface Fields extends CommandFields {
	setMode: SetRegulationFormMode;
}

export const useEditRegulationCmd = (
	{setMode, ...other}: Fields,
	dependencies?: unknown[],
) => {
	return useCommand(
		{
			iconProps: {iconName: 'Edit'},
			onClick() {
				setMode(RegulationFormMode.Update);
			},
			roles: [UserRole.SystemAdministrator, UserRole.Vko],
			...other,
		},
		dependencies,
	);
};
