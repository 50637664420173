import {Stack, useTheme} from '@fluentui/react';
import React from 'react';
import {DashboardCardProps, VexWorkbenchItems} from './VexWorkbenchItems';
import {getListClassNames} from 'styles';
import {KeywordStatus} from 'types';

type VexWorkbenchListProps = {
	keywordStatus:
		| KeywordStatus.Finalized
		| KeywordStatus.Accepted
		| KeywordStatus.New;
} & DashboardCardProps;

export const VexWorkbenchList: React.FC<VexWorkbenchListProps> = ({
	halfHeight = false,
	keywordStatus,
	itemCountLoaded,
}) => {
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);
	return (
		<Stack.Item grow={1} basis={0}>
			<div className={classNames.dashboardActivityContainer}>
				<VexWorkbenchItems
					halfHeight
					keywordStatus={keywordStatus}
					itemCountLoaded={itemCountLoaded}
				/>
			</div>
		</Stack.Item>
	);
};
