import {
	DefaultButton,
	Panel,
	PanelType,
	PrimaryButton,
	Separator,
	Stack,
} from '@fluentui/react';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MockedNotificationType} from './hooks/useGetNotificationUsers';
import {PeoplePicker} from 'components/hookForms/ControlledPeoplePicker';
import {User, UserRole} from 'types';

const AdminNotificatonEditKeywordForm = (props: {
	data: MockedNotificationType;
	isOpen: boolean;
	onClose: () => void;
	onConfirm: (users: User[]) => void;
}) => {
	const {data, isOpen, onClose, onConfirm} = props;
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminNotifications',
	});
	const [currentUsers, setCurrentUsers] = useState<User[]>(data?.users || []);

	const onUserInputChange = (items: any[]) => {
		const newUsers = items.map(item => item as User);
		setCurrentUsers(newUsers);
	};

	const getForm = () => {
		if (!data) {
			return <div>Data is missing</div>;
		}
		return (
			<div>
				<PeoplePicker
					selectedItems={currentUsers.map(u => {
						return {...u, text: u.name};
					})}
					onChange={items => onUserInputChange(items ?? [])}
					roles={[UserRole.Vko, UserRole.Vex]}
				/>
			</div>
		);
	};

	const onRenderFooterContent = () => (
		<>
			<Stack horizontal tokens={{childrenGap: 10}}>
				<PrimaryButton
					text={t('Confirm')}
					onClick={() => {
						onConfirm(currentUsers);
						onClose();
					}}
				/>
				<DefaultButton text={t('Cancel')} onClick={onClose} />
			</Stack>
			<Separator />
		</>
	);

	return (
		<Panel
			type={PanelType.medium}
			isOpen={isOpen}
			onDismiss={onClose}
			headerText={t('Notifications')}
			isFooterAtBottom={true}
			onRenderFooterContent={onRenderFooterContent}
		>
			<Stack>
				<div>{t('EditKeywordSubheader')}</div>
				{getForm()}
			</Stack>
		</Panel>
	);
};

export default AdminNotificatonEditKeywordForm;
