import {useEffect} from 'react';
import {
	useGetMatkonLimitsLazyQuery,
	useMatkonApplicationsLazyQuery,
	useMatkonImdsApplicationsLazyQuery,
	useMatkonLimitsTemplateLazyQuery,
} from '../Matkon.generated';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';
import {useStore} from 'react-context-hook';

export const useMatkonsPropertiesData = ({
	data,
	regulationNumber,
	paragraphId,
}: any) => {
	// IMDS-Applications
	const [imdsApplications, setImdsApplications] = useStore<
		{text: string; key: string}[]
	>('imdsApplications', []);

	const [fetchImdsApplications, {data: fetchedImdsApplications}] =
		useMatkonImdsApplicationsLazyQuery();

	useEffect(() => {
		if (imdsApplications?.length === 0) {
			fetchImdsApplications();
		}
	}, [imdsApplications]);

	useEffect(() => {
		if (fetchedImdsApplications) {
			setImdsApplications(
				fetchedImdsApplications.matkonImdsApplications?.nodes?.map((e: any) => {
					return {
						key: e.iri,
						text: e.code,
					};
				}) || [],
			);
		}
	}, [fetchedImdsApplications]);

	// MatkonLimitsTemplate
	const [limitsTemplate, setlimitsTemplate] = useStore<{
		filename: string;
		contentType: string;
		base64Content: string;
	}>('limitsTemplate');

	const [fetchLimitsTemplate, {data: fetchedLimitsTemplate}] =
		useMatkonLimitsTemplateLazyQuery();

	useEffect(() => {
		if (limitsTemplate === undefined) {
			fetchLimitsTemplate();
		}
	}, [limitsTemplate]);

	useEffect(() => {
		if (fetchedLimitsTemplate) {
			setlimitsTemplate({
				filename: fetchedLimitsTemplate.matkonLimitsTemplate.fileName,
				contentType: fetchedLimitsTemplate.matkonLimitsTemplate.contentType,
				base64Content: fetchedLimitsTemplate.matkonLimitsTemplate.base64Content,
			});
		}
	}, [fetchedLimitsTemplate]);

	// Applications
	const [applications, setApplications] = useStore<
		{text: string; key: string}[]
	>('applications', []);

	const [fetchApplications, {data: fetchedApplications}] =
		useMatkonApplicationsLazyQuery();

	useEffect(() => {
		if (applications?.length === 0) {
			fetchApplications();
		}
	}, [applications]);

	useEffect(() => {
		if (fetchedApplications) {
			setApplications(
				fetchedApplications.matkonApplications?.nodes?.map((e: any) => {
					return {
						key: e.iri,
						text: e.description,
					};
				}) || [],
			);
		}
	}, [fetchedApplications]);

	// Limits
	const [limits, setLimits] = useStore<any[]>('limits', []);

	const [fetchLimits, {data: fetchedLimits}] = useGetMatkonLimitsLazyQuery({
		variables: {
			regulationNumber,
			paragraphId,
		},
	});

	useEffect(() => {
		if (fetchedLimits) {
			setLimits(
				fetchedLimits.matkonLimits?.nodes?.map((e: any) => {
					return e;
				}) || [],
			);
		}
	}, [fetchedLimits]);

	return {imdsApplications, limitsTemplate, applications, limits, fetchLimits};
};
