import {
	Breadcrumb,
	IBreadcrumbItem,
	IconButton,
	Pivot,
	PivotItem,
	SelectionMode,
	Stack,
} from '@fluentui/react';
import _ from 'lodash';
import React, {useMemo, useCallback, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {
	ProjectDetailsPageParams,
	createGenPagePath,
	encodeGenAndModelSeries,
} from './vehicleProjectPagePaths';
import {useCommand} from 'hooks';
import {VehicleProject} from 'types';
import {
	AssignVehicleProjectRequirementsForm,
	PermissionsForm,
} from './components';
import {DetailsField, DetailsSection} from 'components';
import {
	GetVehicleProjectDetailsOptimizedQuery,
	useGetVehicleProjectDetailsOptimizedQuery,
} from './hooks';
import {BaselineCreateDialog} from './components/BaselineCreateDialog';
import {BaselineCmdsInfo, useBaselineCmds} from './baselines/baselineCmds';
import VehicleProjectDetailsTabRequirements from './VehicleProjectDetailsTabRequirements';
import VehicleProjectDetailsTabRegulations from './VehicleProjectDetailsTabRegulations';
import VehicleProjectDetailsTabParagraphs from './VehicleProjectDetailsTabParagraphs';

const VehicleProjectDetailsPage: React.FC = () => {
	const {t} = useTranslation('features/vehicleprojects');

	const [currentViewKey, setCurrentViewKey] = useState<string | undefined>(
		'AssignedRequirements',
	);

	const {modelSeries, generation} = useParams() as ProjectDetailsPageParams;

	const {loading: loadingVehPro, data: dataVehPro} =
		useGetVehicleProjectDetailsOptimizedQuery({
			variables: {
				generation: generation ?? '',
				modelSeries: modelSeries ?? '',
			},
		});

	const onLinkClick = (item?: PivotItem) => {
		setCurrentViewKey(item?.props.accessKey);
	};

	return (
		<>
			<VehicleProjectBreadCrumb />
			<VehicleProjectMarketsCommand />
			<VehicleProjectsAssignRequirements
				vehicleProjectIdWithDefault={
					dataVehPro?.vehicleProjectDetailsOptimized.vehicleProjectId ?? ''
				}
			/>
			<VehicleProjectsDetailsPagePermission />
			<VehicleProjectsDetailsPageBaseLine />
			<VehicleProjectDetailsSection dataVehPro={dataVehPro} />

			<Pivot
				aria-label='Views'
				style={{paddingLeft: 20}}
				onLinkClick={onLinkClick}
			>
				<PivotItem
					headerText={t('AssignedRequirements')}
					accessKey='AssignedRequirements'
					itemIcon={
						currentViewKey === 'AssignedRequirements' ? 'List' : undefined
					}
				></PivotItem>
				{dataVehPro && (
					<PivotItem
						headerText={t('AssignedRegulations')}
						accessKey='AssignedRegulations'
						itemIcon={
							currentViewKey === 'AssignedRegulations' ? 'List' : undefined
						}
					></PivotItem>
				)}
				{dataVehPro && (
					<PivotItem
						headerText={t('AssignedParagraphs')}
						accessKey='AssignedParagraphs'
						itemIcon={
							currentViewKey === 'AssignedParagraphs' ? 'List' : undefined
						}
					></PivotItem>
				)}
			</Pivot>

			<VehicleProjectDetailsTabRequirements
				dataVehPro={dataVehPro}
				loadingVehPro={loadingVehPro}
				hidden={currentViewKey !== 'AssignedRequirements'}
			/>
			<VehicleProjectDetailsTabRegulations
				dataVehPro={dataVehPro}
				loadingVehPro={loadingVehPro}
				hidden={currentViewKey !== 'AssignedRegulations'}
			/>
			<VehicleProjectDetailsTabParagraphs
				dataVehPro={dataVehPro}
				loadingVehPro={loadingVehPro}
				hidden={currentViewKey !== 'AssignedParagraphs'}
			/>
		</>
	);
};

const VehicleProjectBreadCrumb: React.FC = () => {
	const {t} = useTranslation('features/vehicleprojects');

	const navigate = useNavigate();

	const {modelSeries, generation} = useParams() as ProjectDetailsPageParams;

	const itemsWithHref: IBreadcrumbItem[] = [
		{
			text: t('VehicleProjects'),
			key: 'vehicleProjects',
			onClick: () => navigate('/vehicleProjects'),
		},
		{
			text: `${modelSeries}`,
			key: 'modelSeries',
		},
		{
			text: `${generation}`,
			key: 'generation',
		},
	];

	return <Breadcrumb items={itemsWithHref} />;
};

const VehicleProjectMarketsCommand: React.FC = () => {
	const {t} = useTranslation('features/vehicleprojects');
	const navigate = useNavigate();

	const {modelSeries, generation} = useParams() as ProjectDetailsPageParams;

	const generationOrDefaultValue: VehicleProject['generation'] =
		generation ?? '';

	const createPathOfActiveTypeMarketsPage = useCallback((): string => {
		const encodeGenAndModelSeriesOrDefaults = (): ProjectDetailsPageParams => {
			return encodeGenAndModelSeries({
				generation: generationOrDefaultValue,
				modelSeries: modelSeries ?? '',
			});
		};

		const createGenPathWithEncodedParams = (): string => {
			const encodedFields: ProjectDetailsPageParams =
				encodeGenAndModelSeriesOrDefaults();
			return createGenPagePath(encodedFields);
		};

		const genPath: string = createGenPathWithEncodedParams();
		return `${genPath}/projects`;
	}, [generationOrDefaultValue, modelSeries]);

	useCommand(
		{
			key: 'regions',
			text: t('ActiveTypeMarketsButton'),
			iconProps: {iconName: 'Market'},
			priority: 3,
			onClick() {
				const path: string = createPathOfActiveTypeMarketsPage();
				navigate(path);
			},
		},
		[navigate, t, createPathOfActiveTypeMarketsPage],
	);

	return <></>;
};

type VehicleProjectDetailsSectionProps = {
	dataVehPro: GetVehicleProjectDetailsOptimizedQuery | undefined;
};
const VehicleProjectDetailsSection: React.FC<
	VehicleProjectDetailsSectionProps
> = ({dataVehPro}) => {
	const {t} = useTranslation('features/vehicleprojects');

	const {generation} = useParams() as ProjectDetailsPageParams;

	const initialFieldRows: DetailsField[][] = React.useMemo(() => {
		return [
			[
				{
					data: dataVehPro?.vehicleProjectDetailsOptimized.markets,
					type: 'array',
					label: t('SalesMarkets'),
				},
				{
					data: dataVehPro?.vehicleProjectDetailsOptimized.derivatives.filter(
						x => x.trim() !== '',
					),
					type: 'stringarray',
					label: t('Derivative'),
				},
				{
					data: dataVehPro?.vehicleProjectDetailsOptimized.sopVsiValue,
					type: 'date',
					label: t('EarliestSOP'),
				},
				{
					data: dataVehPro?.vehicleProjectDetailsOptimized.eopVsiValue,
					type: 'date',
					label: t('LatestEOP'),
				},
			],
		];
	}, [dataVehPro]);

	return (
		<DetailsSection title={generation} initialFieldRows={initialFieldRows} />
	);
};

interface VehicleProjectsAssignRequirementsProps {
	vehicleProjectIdWithDefault: string;
}

const VehicleProjectsAssignRequirements: React.FC<
	VehicleProjectsAssignRequirementsProps
> = ({vehicleProjectIdWithDefault}) => {
	const navigate = useNavigate();
	// Use the refetch function somewhere in your component
	const refreshData = async () => {
		navigate(0);
	};

	const getWithDefaultArray = useCallback(() => {
		return [];
	}, []);

	return (
		<AssignVehicleProjectRequirementsForm
			vehicleProjectId={vehicleProjectIdWithDefault}
			refreshData={refreshData}
		/>
	);
};

const VehicleProjectsDetailsPagePermission: React.FC = () => {
	// Use the refetch function somewhere in your component
	const refreshData = async () => {
		console.log('s');
	};

	const getWithDefaultArray = useCallback(() => {
		return [];
	}, []);

	return (
		<PermissionsForm
			refreshData={refreshData}
			userArray={getWithDefaultArray()}
			loading={false}
		/>
	);
};

const VehicleProjectsDetailsPageBaseLine: React.FC = () => {
	const params = useParams() as ProjectDetailsPageParams;

	const {infoForIsSuccessDialogOpen}: BaselineCmdsInfo = useBaselineCmds({
		params,
		showOverviewCmd: true,
	});

	return (
		<>
			<BaselineCreateDialog
				generation={params.generation}
				modelSeries={params.modelSeries}
				infoForIsOpen={infoForIsSuccessDialogOpen}
			/>
		</>
	);
};

export default VehicleProjectDetailsPage;
