import {
	DefaultButton,
	Dialog,
	ITextFieldProps,
	PrimaryButton,
	Stack,
} from '@fluentui/react';

import {useForm} from 'react-hook-form';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import {TextFieldWithTooltip} from 'features/localizedTooltips';
import React, {useState, useMemo, useEffect} from 'react';
import {mapToRef} from 'helpers';
import {useTranslation} from 'react-i18next';
import {
	useCreateQuestionMutation,
	QuestionsDocument,
	QuestionCountsByEntityTypeDocument,
} from './Faq.generated';

import {ControlledDropdown, ControlledTagPicker} from 'components/hookForms';

import _ from 'lodash';
import {useFileUpload} from 'hooks';
import {AttachmentsField} from 'components/AttachmentsField';
import {
	VEHICLE_PROJECT_TYPE,
	VehicleProjectsForFAQ,
} from './VehicleProjectsForFAQ ';
import {
	getCollectionName,
	getKeywords,
	ParagraphDocumentsCollectionName,
	RegulatoryDocumentEntity,
	RegulatoryDocumentParagraphEntity,
	RegulatoryDocumentsCollectionName,
	RequirementEntity,
	RequirementsCollectionName,
} from './EntityTypeMapper';
import {useQuestionsPropertiesData} from './hooks/useQuestionsPropertiesData';
import {ControlledPeoplePicker} from 'components/hookForms/ControlledPeoplePicker';
import {UserRole} from 'types';
import {FaqPanelMode} from './FaqPanel';

const Roles = [{name: 'VKO'}, {name: 'VEX'}];

interface ICreateQuestionDialog {
	showDialog: boolean;
	setShowDialog: (show: boolean) => void;
	faqPanelMode: FaqPanelMode;
	initQuestion?: any;
	parentId?: any;
}

export const CreateQuestionDialog: React.FC<ICreateQuestionDialog> = ({
	showDialog,
	setShowDialog,
	faqPanelMode,
	initQuestion = null,
	parentId = null,
}) => {
	const {t} = useTranslation('common/faq');
	const {questionCategories, vehicleProjects} = useQuestionsPropertiesData({t});
	const [selectedItem, setSelectedItem] = useState(initQuestion);
	const {selectedItems} = useEntityContext<any>();
	const [keywords, setKeywords] = useState<string[]>([]);

	// useEntityContext is set to null after clicking on any button, panel so we need to preserve selection
	useEffect(() => {
		if (selectedItems && selectedItems.length > 0) {
			setSelectedItem(selectedItems[0]);
		}
	}, [selectedItems]);

	useEffect(() => {
		if (selectedItem) {
			// setValFrom(
			// 	KEYWORDS,
			// 	getKeywords((selectedItem as any).__typename, selectedItem),
			// );
			const newKeywords = getKeywords(
				(selectedItem as any).__typename,
				selectedItem,
			);
			// setValFrom(KEYWORDS, newKeywords);
			setKeywords(newKeywords);
		}
	}, [selectedItem]);

	const [value, setValue] = useState('');

	const [createQuestionMutation] = useCreateQuestionMutation();

	const {
		control,
		getValues,
		reset,
		watch,
		setValue: setValFrom,
	} = useForm<any>({
		reValidateMode: 'onSubmit',
		mode: 'onBlur',
	});

	const QUESTION_TYPE = 'questionType';
	const ROLES_TYPE = 'roles';
	const KEYWORDS = 'keywords';

	const vehicleProjectsData = useMemo((): any[] => {
		const projects: any[] = vehicleProjects ?? [];

		return _.sortBy(projects, ['modelSeries']);
	}, [vehicleProjects]);

	type OnChange = Exclude<ITextFieldProps['onChange'], undefined>;

	const handleChange: OnChange = (_event, newValue): void => {
		setValue(newValue ?? '');
	};

	const closeDialog = () => {
		setShowDialog(false);
		setValue('');
		reset();
	};

	const getTargetCollection = () => {
		switch (faqPanelMode) {
			case FaqPanelMode.RegDocDetails:
				return RegulatoryDocumentsCollectionName;
			case FaqPanelMode.RequirementDetails:
				return RequirementsCollectionName;
			case FaqPanelMode.ParagraphDetails:
				return ParagraphDocumentsCollectionName;
			default:
				return getCollectionName((selectedItem as any).__typename);
		}
	};

	const getTargetRecord = () => {
		switch (faqPanelMode) {
			case FaqPanelMode.RegDocDetails:
				return {
					entityId: parentId,
					entityType: RegulatoryDocumentEntity,
					parentId: null,
				};
			case FaqPanelMode.RequirementDetails:
				return {
					entityId: parentId,
					entityType: RequirementEntity,
					parentId: null,
				};
			case FaqPanelMode.ParagraphDetails:
				return {
					entityId: parentId,
					entityType: RegulatoryDocumentParagraphEntity,
					parentId: null,
				};
			case FaqPanelMode.EntityList:
			default:
				return {
					entityId: (selectedItem as any).id,
					entityType: (selectedItem as any).__typename,
					parentId,
				};
		}
	};

	const postQuestion = () => {
		const vehicleProject = getValues(VEHICLE_PROJECT_TYPE);
		createQuestionMutation({
			variables: {
				input: {
					name: value,
					targetUserRefs: mapToRef(getValues('TargetUser')),
					modelSeriesReference:
						vehicleProject?.length > 0 ? vehicleProject[0].modelSeries : '',
					generationReference:
						vehicleProject?.length > 0 ? vehicleProject[0].generation : '',
					targetCollection: getTargetCollection(),
					keywordRefs: mapToRef(getValues(KEYWORDS)),
					targetRecords: [getTargetRecord()],
					categoryRef: {
						id: getValues(QUESTION_TYPE),
					},
					attachments: attachments?.map(f => ({
						attachmentId: f.file.name,
						file: f.file,
					})),
					roles: getValues(ROLES_TYPE)?.map((e: any) => e.name),
				},
			},
			refetchQueries: [QuestionsDocument, QuestionCountsByEntityTypeDocument],
		});

		closeDialog();
	};

	const {attachments, FileUploadComponent} = useFileUpload(undefined, {
		renderCategory: false,
	});

	const [users, setUsers] = useState([]);
	const [isVex, setisVex] = useState<boolean>(false);

	const onUserInputChange = React.useCallback((items: any) => {
		setUsers(items || []);
		return items;
	}, []);

	const onRoleTypeInputChange = React.useCallback((item: any) => {
		setisVex(item.name === 'VEX');
		return item;
	}, []);

	return (
		<Dialog
			hidden={!showDialog}
			styles={{main: {maxWidth: '600px !important'}}}
			modalProps={{
				isBlocking: true,
				isDarkOverlay: true,
				styles: {main: {width: '750px !important'}},
			}}
			dialogContentProps={{
				title: `${t('PostQuestion')} ${
					(selectedItem as any)?.name || (selectedItem as any)?.enumeration
				}`,
			}}
			onDismiss={closeDialog}
		>
			<ControlledDropdown
				label={`${t(`QuestionType`)}`}
				name={QUESTION_TYPE}
				control={control}
				options={questionCategories}
				required={true}
				rules={{required: true}}
			/>
			<VehicleProjectsForFAQ
				control={control}
				t={t}
				vehicleProjects={vehicleProjectsData}
			/>

			<ControlledTagPicker
				name={KEYWORDS}
				control={control}
				selectableItems={keywords}
				label={t(`SelectKeywords`)}
				hideTagIcon
				placeholder={t('KeywordsHint')}
				getKey={item => (item as any)?.id}
				getName={item => (item as any)?.name}
				rules={{required: true}}
			/>
			<TextFieldWithTooltip
				multiline
				label={t('QuestionContent')}
				placeholder={t('QuestionContent')}
				onChange={handleChange}
				value={value}
				styles={{root: {margin: '1rem 0 0.5rem'}}}
				autoAdjustHeight
				required={true}
			/>
			<AttachmentsField t={t} FileUploadComponent={FileUploadComponent} />
			<ControlledTagPicker
				name={ROLES_TYPE}
				control={control}
				selectableItems={Roles}
				label={`${t(`SelectRoles`)}`}
				hideTagIcon
				placeholder=''
				getKey={item => (item as any)?.id}
				getName={item => (item as any)?.name}
				rules={{required: true}}
				onItemSelected={items => onRoleTypeInputChange(items ?? [])}
			/>
			<ControlledPeoplePicker
				onItemSelected={items => onUserInputChange(items ?? [])}
				name='TargetUser'
				control={control}
				label='Target User'
				excludeCurrentUser
				roles={[UserRole.Vko, UserRole.Vex, UserRole.SystemAdministrator]}
				rules={{required: isVex}}
			/>
			<Stack
				horizontal
				horizontalAlign='end'
				tokens={{childrenGap: 5, padding: '20px 0 0 0'}}
			>
				<Stack.Item>
					<PrimaryButton
						onClick={postQuestion}
						ariaLabel={'ConfirmDialogButton'}
						disabled={
							watch(QUESTION_TYPE)?.length === 0 ||
							!value ||
							!watch(ROLES_TYPE)?.length ||
							watch(ROLES_TYPE)?.length === 0 ||
							(isVex && users.length === 0)
						}
					>
						{t('Confirm')}
					</PrimaryButton>
				</Stack.Item>
				<Stack.Item>
					<DefaultButton onClick={closeDialog} ariaLabel={'CancelDialogButton'}>
						{t('Cancel')}
					</DefaultButton>
				</Stack.Item>
			</Stack>
		</Dialog>
	);
};
