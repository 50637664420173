import {
	IconButton,
	IDetailsList,
	mergeStyleSets,
	Stack,
	Text,
	useTheme,
} from '@fluentui/react';
import React, {useCallback, useMemo} from 'react';
import {
	InfinityListColumn,
	InfinityListColumnFilterOptions,
	InfinityListFilter,
	InfinityListFilterOption,
} from './InfinityList';
import {v4 as uuidv4} from 'uuid';
import value from 'require-context.macro';
import {formatDateTime} from 'i18n/localeDateFormat';
import {useTranslation} from 'react-i18next';
type FilterBubbleProps = {
	relevantFilters: InfinityListFilter[];
	colName: string;
	fieldValuesUnique?: InfinityListFilterOption[];
	onBubbleClick: (
		filterToRemove: InfinityListFilter,
	) => (_event: React.MouseEvent<HTMLElement>) => void;
};
type InfinityListFilterBubblesProps = {
	sortField: string;
	sortOrder: string;
	currentView: string;
	columns: InfinityListColumn[];
	columnFilterOptions?: InfinityListColumnFilterOptions[];
	allListFilters: InfinityListFilter[];
	appliedFilters: InfinityListFilter[];
	listRef: React.MutableRefObject<IDetailsList | undefined>;
	setAllListFilters: (allListFilters: InfinityListFilter[]) => void;
	setAppliedFilters: (appliedFilters: InfinityListFilter[]) => void;
	applyFilter: (
		filters: InfinityListFilter[],
		sortField: string,
		sortOrder: string,
		currentView?: string,
	) => void;
};

export const InfinityListFilterBubbles: React.FC<
	InfinityListFilterBubblesProps
> = ({
	sortField,
	sortOrder,
	currentView,
	columns,
	columnFilterOptions,
	allListFilters,
	appliedFilters,
	listRef,
	setAllListFilters,
	setAppliedFilters,
	applyFilter,
}) => {
	const arrFieldValuesUnique = useMemo(
		() =>
			columns.map(
				col =>
					(columnFilterOptions ?? []).find(x => x.key === col.key)
						?.fieldValuesUnique,
			),
		[columns, columnFilterOptions],
	);

	const onBubbleClick = useCallback(
		(filterToRemove: InfinityListFilter) =>
			(_event: React.MouseEvent<HTMLElement>): void => {
				setAllListFilters(
					allListFilters.filter(
						x =>
							!(
								x.columnKey === filterToRemove.columnKey &&
								x.selection === filterToRemove.selection
							),
					),
				);
				setAppliedFilters(
					allListFilters.filter(
						x =>
							!(
								x.columnKey === filterToRemove.columnKey &&
								x.selection === filterToRemove.selection
							),
					),
				);
				listRef.current?.scrollToIndex(0);
				applyFilter(
					allListFilters.filter(
						x =>
							!(
								x.columnKey === filterToRemove.columnKey &&
								x.selection === filterToRemove.selection
							),
					),
					sortField,
					sortOrder,
					currentView,
				);
			},
		[
			allListFilters,
			sortField,
			sortOrder,
			appliedFilters,
			listRef.current,
			currentView,
		],
	);
	return (
		<>
			{appliedFilters.length > 0 && (
				<Stack horizontal tokens={{childrenGap: 20}}>
					{columns.map((x, i) => {
						const relevantFilters = appliedFilters.filter(
							y => y.columnKey === x.key,
						);
						if (relevantFilters.length > 0) {
							return (
								<FilterBubble
									key={uuidv4()}
									colName={x.name}
									fieldValuesUnique={arrFieldValuesUnique.at(i)}
									relevantFilters={relevantFilters}
									onBubbleClick={onBubbleClick}
								/>
							);
						}
						return <></>;
					})}
				</Stack>
			)}
		</>
	);
};

export const FilterBubble: React.FC<FilterBubbleProps> = ({
	relevantFilters,
	colName,
	fieldValuesUnique,
	onBubbleClick,
}) => {
	const theme = useTheme();

	const {i18n} = useTranslation();

	const classNames = mergeStyleSets({
		arrayWrapper: {
			display: 'flex !important',
			flexWrap: 'wrap',
		},
		arrayItem: {
			borderRadius: '10px',
			color: theme?.palette.neutralSecondary,
			background: theme?.palette.neutralLight,
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
			whiteSpace: 'nowrap',
		},
		arrayButton: {
			cursor: 'pointer',
		},
	});
	return (
		<div className={classNames.arrayWrapper}>
			<div className={classNames.arrayWrapper}>
				{colName}:&nbsp;
				{relevantFilters
					.filter(
						x =>
							x.display !== 'UNKNOWN' ||
							fieldValuesUnique?.find(y => y.id === x.selection),
					)
					.map((value, index) => (
						<div key={uuidv4()} className={classNames.arrayItem}>
							<Text key={uuidv4()} title={value.columnKey}>
								{value.display === 'UNKNOWN'
									? fieldValuesUnique?.find(y => y.id === value.selection)
											?.value
									: value.display}
								&nbsp;
							</Text>
							<IconButton
								iconProps={{iconName: 'ChromeClose'}}
								styles={{
									root: {
										width: 18,
										height: 18,
										color: 'black',
									},
									icon: {fontSize: 9},
								}}
								onClick={onBubbleClick(value)}
							></IconButton>
						</div>
					))}
			</div>
		</div>
	);
};
