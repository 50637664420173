import {DefaultButton, Panel, PanelType, PrimaryButton} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {useStore} from 'react-context-hook';
import React, {useState, useEffect, FC} from 'react';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import _ from 'lodash';
import {CreateQuestionDialog} from './CreateQuestionDialog';
import {FaqPanelContent} from './FaqPanelContent';
import {useQuestionsLazyQuery} from './Faq.generated';

export enum FaqPanelMode {
	Closed,
	EntityList,
	RegDocDetails,
	RequirementDetails,
	ParagraphDetails,
}

export const FaqPanel: FC<{parentId?: string}> = props => {
	const [faqPanelMode, setFaqPanelMode] = useStore(
		'faq-panel',
		FaqPanelMode.Closed,
	);
	const {selectedItems} = useEntityContext<any>();
	const {t} = useTranslation('common/faq');
	const [showQuestionDialog, setShowQuestionDialog] = useState(false);
	const [selectedItem, setSelectedItem] = useState(undefined);
	const [questions, setQuestions] = useState(undefined);
	const [fetchQuestions, {data}] = useQuestionsLazyQuery({
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		switch (faqPanelMode) {
			case FaqPanelMode.RegDocDetails:
			case FaqPanelMode.RequirementDetails:
			case FaqPanelMode.ParagraphDetails:
				setSelectedItem(props.parentId as any);
				fetchQuestions({
					variables: {
						targetRecordId: props.parentId || '',
					},
				});
				break;
			case FaqPanelMode.EntityList:
				if (selectedItems.length > 0) {
					setSelectedItem(selectedItems[0]);
					fetchQuestions({
						variables: {
							targetRecordId: selectedItems[0].id,
						},
					});
				}
				break;
			case FaqPanelMode.Closed:
			default:
				break;
		}
	}, [selectedItems, faqPanelMode, props.parentId]);

	useEffect(() => {
		if (data) {
			setQuestions((data as any)?.questions); // Save the fetched data in state
		}
	}, [data]); // Update state whenever queryData changes

	const closePanel = () => {
		setFaqPanelMode(FaqPanelMode.Closed);
		setQuestions(undefined);
	};

	const onRenderFooterContent: any = () => (
		<form>
			<PrimaryButton
				style={{marginRight: '10px'}}
				onClick={() => setShowQuestionDialog(true)}
			>
				{t('PostQuestionButton')}
			</PrimaryButton>
			<DefaultButton text={t('Cancel')} onClick={closePanel} />
		</form>
	);

	return (
		<Panel
			type={PanelType.large}
			isLightDismiss
			isOpen={faqPanelMode !== FaqPanelMode.Closed}
			onDismiss={closePanel}
			closeButtonAriaLabel='Close'
			headerText={t('Header')}
			onRenderFooterContent={onRenderFooterContent}
			isFooterAtBottom={true}
			styles={{
				commands: {background: 'white !important'},
				content: {overflowY: 'hidden'},
				footer: {background: 'white !important'},
			}}
		>
			{faqPanelMode !== FaqPanelMode.Closed && (
				<>
					<FaqPanelContent questions={questions} />
					<CreateQuestionDialog
						showDialog={showQuestionDialog}
						setShowDialog={setShowQuestionDialog}
						faqPanelMode={faqPanelMode}
						parentId={props.parentId}
					/>
				</>
			)}
		</Panel>
	);
};
