import {GetActivityItemsQuery} from 'components/ActivityList/hooks/activityItems.generated';

const RegulatoryDocumentMockup = {
	type: 'RegulatoryDocument' as const,
	id: 'UmVndWxhdG9yeURvY3VtZW50CmQ2Nzk3M2VmMDE0MDk5ZDNhOGQxZDdhOTY=',
	name: 'Pablo Reg Doc 28',
	modifiedAt: '2025-01-27T09:17:03.217Z',
	regulation: {
		id: 'UmVndWxhdGlvbgpkNjc3M2I0MDM4MjIwMDA5YzdiNzRlNDg1',
		regulationNumber: 'Pablo Regulation 05',
		__typename: 'Regulation' as const,
	},
};

export const ActivityItemsMockup: GetActivityItemsQuery = {
	regulatoryDocuments: {
		nodes: [
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 28',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 29',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 30',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 28',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 29',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 30',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 28',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 29',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 30',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 28',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 29',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 30',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 28',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 29',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 30',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 28',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 29',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 30',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 28',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 29',
			},
			{
				...RegulatoryDocumentMockup,
				name: 'Pablo Reg Doc 30',
			},
		],
		__typename: 'RegulatoryDocumentsConnection',
	},
	requirements: [],
	userCreatedRegulations: [],
};
