import {GetMyRequirementsQuery} from 'features/Dashboard/hooks/myRequirements.generated';
import {RequirementStatus} from 'types';

const RequirementMockup = {
	id: 'UmVxdWlyZW1lbnQKZDY3MGU0NjI0YmQxZGNlY2Vj1234567890==',
	requirementId: 'R-10001',
	name: 'Mockup Blue Req 01',
	status: RequirementStatus.Approval,
	version: 'V1.0',
	createdAt: '2024-10-15T10:38:28.027Z',
	modifiedAt: '2024-11-13T11:48:24.937Z',
	vexClusters: [
		{
			id: 'VmV4Q2x1c3RlcgpkNjcwZTQwNjNiZDFkY2VjZWMzZTdjMzIz',
			name: 'Pablo VEX-Cluster',
			__typename: 'VexCluster' as const,
		},
	],
	__typename: 'Requirement' as const,
};

export const MyRequirementsMockup: GetMyRequirementsQuery = {
	myRequirements: [
		{
			...RequirementMockup,
			name: 'Mockup Red Req 01',
			status: RequirementStatus.Draft,
		},
		{
			...RequirementMockup,
			name: 'Mockup Red Req 02',
			status: RequirementStatus.Draft,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req 01',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req 02',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req 03',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req 04',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req 05',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req 06',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req 07',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req 08',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req 09',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req 10',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req ',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Blue Req 1',
			status: RequirementStatus.Approval,
		},
		{
			...RequirementMockup,
			name: 'Mockup Green Req',
			status: RequirementStatus.Final,
		},
	],
};
