import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPogisDocumentsQueryQueryVariables = Types.Exact<{
  query: Types.Scalars['String'];
  sortField: Types.Scalars['String'];
  sortOrder: Types.Scalars['String'];
}>;


export type GetPogisDocumentsQueryQuery = { __typename?: 'Query', pogisDocumentsQuery: { __typename?: 'PogisDocumentsQueryResults', pogisIds: string, pogisFilterOptions: { __typename?: 'PogisFilterOptions', pogisIdOptions: string, editorOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }>, keywordOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }>, marketOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }>, regStatusOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }>, standardOptions: Array<{ __typename?: 'FilterOption', optionKey: string, name: string }> }, pogisDocuments: Array<{ __typename?: 'PogisDocument', associations?: Array<string> | null, docStatus: string, editor: string, id: string, keywords: Array<string>, linkVersion?: string | null, markets: Array<string>, modifiedAt?: any | null, pogisId: string, regulationStatus: string, relatedDocuments?: Array<string> | null, shortInfo: string, standards?: Array<string> | null, pogisAttachments?: Array<{ __typename?: 'AttachmentRef', attachmentId: string, file: { __typename?: 'BlobRef', fileName: string, uri: any }, category?: { __typename?: 'AttachmentCategory', name: string } | null }> | null, dates: Array<{ __typename?: 'PogisDate', date: any, dateType: string }> }> } };

export type GetPogisDocumentsNextQueryVariables = Types.Exact<{
  filteredIds: Types.Scalars['String'];
}>;


export type GetPogisDocumentsNextQuery = { __typename?: 'Query', pogisDocumentsNext: Array<{ __typename?: 'PogisDocument', associations?: Array<string> | null, docStatus: string, editor: string, id: string, keywords: Array<string>, linkVersion?: string | null, markets: Array<string>, modifiedAt?: any | null, pogisId: string, regulationStatus: string, relatedDocuments?: Array<string> | null, shortInfo: string, standards?: Array<string> | null, pogisAttachments?: Array<{ __typename?: 'AttachmentRef', attachmentId: string, file: { __typename?: 'BlobRef', fileName: string, uri: any }, category?: { __typename?: 'AttachmentCategory', name: string } | null }> | null, dates: Array<{ __typename?: 'PogisDate', date: any, dateType: string }> }> };

export type GetPogisSortOrdersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPogisSortOrdersQuery = { __typename?: 'Query', pogisSortOrders: { __typename?: 'PogisSortOrders', byEditor: string, byEffectiveDate: string, byMarkets: string, byModelYear: string, byNF: string, byNT: string, byPogisIds: string, byRegulationStatus: string } };

export type CreatePogisDocumentMutationVariables = Types.Exact<{
  input: Types.CreatePogisDocumentInput;
}>;


export type CreatePogisDocumentMutation = { __typename?: 'Mutation', createPogisDocument: { __typename?: 'PogisDocumentPayload', pogisDocument: { __typename?: 'PogisDocument', id: string } } };

export type UpdatePogisDocumentMutationVariables = Types.Exact<{
  input: Types.UpdatePogisDocumentInput;
}>;


export type UpdatePogisDocumentMutation = { __typename?: 'Mutation', updatePogisDocument: { __typename?: 'PogisDocumentPayload', pogisDocument: { __typename?: 'PogisDocument', id: string } } };


export const GetPogisDocumentsQueryDocument = gql`
    query GetPogisDocumentsQuery($query: String!, $sortField: String!, $sortOrder: String!) {
  pogisDocumentsQuery(
    strQuery: $query
    strSortField: $sortField
    strSortOrder: $sortOrder
  ) {
    pogisIds
    pogisFilterOptions {
      editorOptions {
        optionKey
        name
      }
      keywordOptions {
        optionKey
        name
      }
      marketOptions {
        optionKey
        name
      }
      pogisIdOptions
      regStatusOptions {
        optionKey
        name
      }
      standardOptions {
        optionKey
        name
      }
    }
    pogisDocuments {
      associations
      docStatus
      editor
      id
      keywords
      linkVersion
      markets
      modifiedAt
      pogisId
      regulationStatus
      relatedDocuments
      shortInfo
      standards
      pogisAttachments {
        file {
          fileName
          uri
        }
        attachmentId
        category {
          name
        }
      }
      dates {
        date
        dateType
      }
    }
  }
}
    `;

/**
 * __useGetPogisDocumentsQueryQuery__
 *
 * To run a query within a React component, call `useGetPogisDocumentsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPogisDocumentsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPogisDocumentsQueryQuery({
 *   variables: {
 *      query: // value for 'query'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetPogisDocumentsQueryQuery(baseOptions: Apollo.QueryHookOptions<GetPogisDocumentsQueryQuery, GetPogisDocumentsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPogisDocumentsQueryQuery, GetPogisDocumentsQueryQueryVariables>(GetPogisDocumentsQueryDocument, options);
      }
export function useGetPogisDocumentsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPogisDocumentsQueryQuery, GetPogisDocumentsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPogisDocumentsQueryQuery, GetPogisDocumentsQueryQueryVariables>(GetPogisDocumentsQueryDocument, options);
        }
export type GetPogisDocumentsQueryQueryHookResult = ReturnType<typeof useGetPogisDocumentsQueryQuery>;
export type GetPogisDocumentsQueryLazyQueryHookResult = ReturnType<typeof useGetPogisDocumentsQueryLazyQuery>;
export type GetPogisDocumentsQueryQueryResult = Apollo.QueryResult<GetPogisDocumentsQueryQuery, GetPogisDocumentsQueryQueryVariables>;
export const GetPogisDocumentsNextDocument = gql`
    query GetPogisDocumentsNext($filteredIds: String!) {
  pogisDocumentsNext(strFilteredIds: $filteredIds) {
    associations
    docStatus
    editor
    id
    keywords
    linkVersion
    markets
    modifiedAt
    pogisId
    regulationStatus
    relatedDocuments
    shortInfo
    standards
    pogisAttachments {
      file {
        fileName
        uri
      }
      attachmentId
      category {
        name
      }
    }
    dates {
      date
      dateType
    }
  }
}
    `;

/**
 * __useGetPogisDocumentsNextQuery__
 *
 * To run a query within a React component, call `useGetPogisDocumentsNextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPogisDocumentsNextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPogisDocumentsNextQuery({
 *   variables: {
 *      filteredIds: // value for 'filteredIds'
 *   },
 * });
 */
export function useGetPogisDocumentsNextQuery(baseOptions: Apollo.QueryHookOptions<GetPogisDocumentsNextQuery, GetPogisDocumentsNextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPogisDocumentsNextQuery, GetPogisDocumentsNextQueryVariables>(GetPogisDocumentsNextDocument, options);
      }
export function useGetPogisDocumentsNextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPogisDocumentsNextQuery, GetPogisDocumentsNextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPogisDocumentsNextQuery, GetPogisDocumentsNextQueryVariables>(GetPogisDocumentsNextDocument, options);
        }
export type GetPogisDocumentsNextQueryHookResult = ReturnType<typeof useGetPogisDocumentsNextQuery>;
export type GetPogisDocumentsNextLazyQueryHookResult = ReturnType<typeof useGetPogisDocumentsNextLazyQuery>;
export type GetPogisDocumentsNextQueryResult = Apollo.QueryResult<GetPogisDocumentsNextQuery, GetPogisDocumentsNextQueryVariables>;
export const GetPogisSortOrdersDocument = gql`
    query GetPogisSortOrders {
  pogisSortOrders {
    byEditor
    byEffectiveDate
    byMarkets
    byModelYear
    byNF
    byNT
    byPogisIds
    byRegulationStatus
  }
}
    `;

/**
 * __useGetPogisSortOrdersQuery__
 *
 * To run a query within a React component, call `useGetPogisSortOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPogisSortOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPogisSortOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPogisSortOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetPogisSortOrdersQuery, GetPogisSortOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPogisSortOrdersQuery, GetPogisSortOrdersQueryVariables>(GetPogisSortOrdersDocument, options);
      }
export function useGetPogisSortOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPogisSortOrdersQuery, GetPogisSortOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPogisSortOrdersQuery, GetPogisSortOrdersQueryVariables>(GetPogisSortOrdersDocument, options);
        }
export type GetPogisSortOrdersQueryHookResult = ReturnType<typeof useGetPogisSortOrdersQuery>;
export type GetPogisSortOrdersLazyQueryHookResult = ReturnType<typeof useGetPogisSortOrdersLazyQuery>;
export type GetPogisSortOrdersQueryResult = Apollo.QueryResult<GetPogisSortOrdersQuery, GetPogisSortOrdersQueryVariables>;
export const CreatePogisDocumentDocument = gql`
    mutation CreatePogisDocument($input: CreatePogisDocumentInput!) {
  createPogisDocument(input: $input) {
    pogisDocument {
      id
    }
  }
}
    `;
export type CreatePogisDocumentMutationFn = Apollo.MutationFunction<CreatePogisDocumentMutation, CreatePogisDocumentMutationVariables>;

/**
 * __useCreatePogisDocumentMutation__
 *
 * To run a mutation, you first call `useCreatePogisDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePogisDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPogisDocumentMutation, { data, loading, error }] = useCreatePogisDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePogisDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePogisDocumentMutation, CreatePogisDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePogisDocumentMutation, CreatePogisDocumentMutationVariables>(CreatePogisDocumentDocument, options);
      }
export type CreatePogisDocumentMutationHookResult = ReturnType<typeof useCreatePogisDocumentMutation>;
export type CreatePogisDocumentMutationResult = Apollo.MutationResult<CreatePogisDocumentMutation>;
export type CreatePogisDocumentMutationOptions = Apollo.BaseMutationOptions<CreatePogisDocumentMutation, CreatePogisDocumentMutationVariables>;
export const UpdatePogisDocumentDocument = gql`
    mutation UpdatePogisDocument($input: UpdatePogisDocumentInput!) {
  updatePogisDocument(input: $input) {
    pogisDocument {
      id
    }
  }
}
    `;
export type UpdatePogisDocumentMutationFn = Apollo.MutationFunction<UpdatePogisDocumentMutation, UpdatePogisDocumentMutationVariables>;

/**
 * __useUpdatePogisDocumentMutation__
 *
 * To run a mutation, you first call `useUpdatePogisDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePogisDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePogisDocumentMutation, { data, loading, error }] = useUpdatePogisDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePogisDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePogisDocumentMutation, UpdatePogisDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePogisDocumentMutation, UpdatePogisDocumentMutationVariables>(UpdatePogisDocumentDocument, options);
      }
export type UpdatePogisDocumentMutationHookResult = ReturnType<typeof useUpdatePogisDocumentMutation>;
export type UpdatePogisDocumentMutationResult = Apollo.MutationResult<UpdatePogisDocumentMutation>;
export type UpdatePogisDocumentMutationOptions = Apollo.BaseMutationOptions<UpdatePogisDocumentMutation, UpdatePogisDocumentMutationVariables>;